import api from './api';

const getPromotions = (section, data) => {
    return api.get(`promotion/section/${section}`, data).then(res => res.data);
}

const PromotionService = {
    getPromotions
}

export default PromotionService;