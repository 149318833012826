import api from './api'

const refreshToken = async () => {
    api.post('/auth/refresh', {refreshToken: TokenService.getLocalRefreshToken(),}).then(response => {
        const {token} = response.data;
        TokenService.updateLocalToken(token);
        return response.data;
    }, error => {
        TokenService.removeUser();
        return Promise.reject(error);
    });
}

const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
};

const getLocalToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
};

const updateLocalToken = (token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.token = token;
    localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = (redirect) => {
    localStorage.removeItem("user");
};

const getShareFolderToken = () => {
    return JSON.parse(localStorage.getItem("shareFolder"));
};

const setShareFolderToken = (token) => {
    localStorage.setItem("shareFolder", JSON.stringify(token));
};

const removeShareFolderToken = () => {
    localStorage.removeItem("shareFolder");
};

const TokenService = {
    getLocalRefreshToken,
    getLocalToken,
    updateLocalToken,
    getUser,
    setUser,
    removeUser,
    getShareFolderToken,
    setShareFolderToken,
    removeShareFolderToken,
    refreshToken
};

export default TokenService;
