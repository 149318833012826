import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Link from '../../components/Link';
import NewContentCard from '../../components/cards/NewContentCard';
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";
import { AppContext } from '../../AppContext';
import HubspotForm from 'react-hubspot-form'
import ArticleService from '../../services/articles.service';
import MiniGuideService from '../../services/miniGuide.service';
import FeaturedService from '../../services/featured.service';
import HomeHeadlineService from '../../services/homeHeadline.service';

function NewHome() {

    const { t, i18n } = useTranslation();
    const { state: { categories, user } } = useContext(AppContext)

    const [articles, setArticles] = useState();
    const [miniGuides, setMiniGuides] = useState();
    const [featured, setFeatured] = useState()
    const [homeHeadline, setHomeHeadline] = useState({
        description: "",
        text: [],
        imageUrl: ""
    });

    const newsletterFormLang = {
        es: "7e9cacf1-5001-4357-b1f6-fd2f6f9e910e",
        en: "22093985-7dbd-4927-bd94-dab2bb1217a3",
        de: "994aabc2-9f24-4ee7-a77f-9e48ff813079"
    }

    const getArticles = async () => {
        try {
            const data = await ArticleService.getArticles({ length: 6 })
            setArticles(data?.content)
        } catch (error) {
            console.error(error);
        }
    }

    const getMiniGuides = async () => {
        try {
            const data = await MiniGuideService.getMiniGuide({ length: 3 })
            setMiniGuides(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getHomeHeadline = async () => {
        try {
            const data = await HomeHeadlineService.getHeadline();
            setHomeHeadline(data);
        } catch (error) {
            console.error(error);
        }
    }

    const getFeaturedContent = async () => {
        try {
            const data = await FeaturedService.getFeatured({
                isHome: true
            })

            setFeatured(data?.[0])
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getArticles();
        getMiniGuides();
        getFeaturedContent();
        getHomeHeadline();
    }, [])

    return (
        <>
            <SeoHelmet title={t('seo.home.title')} metaTitle={t('seo.home.metaTitle')} metaDescription={t('seo.home.metaDescription')} ogImage={'/img/home-image.jpg'} noSuffix />
            {homeHeadline?.description && homeHeadline?.imageUrl && <section className='d-flex bg-laurel home-hero mb-5'>
                <div className='d-flex flex-grow-1'>
                    <Row className="flex-grow-1 g-0 flex-column-reverse flex-lg-row">
                        <Col xs={12} lg={4} className='d-flex align-items-center justify-content-center offset-lg-1 content'>
                            <h1 className='h1 text-white fitzgerald-regular lh-sm mb-0 px-4 px-lg-0 fitzgerald-regular d-block'>{homeHeadline?.description}</h1>
                        </Col>
                        <Col xs={12} lg={6} className='offset-lg-1 hero-image'>
                            <Image src={homeHeadline?.imageUrl} />
                        </Col>
                    </Row>
                </div>
            </section>}
            <section className='d-flex container-lg mb-5 featured-container'>
                <Link href={
                    featured?.type === "ARTICLE" ? `${t('pages.guide')}/${featured?.categorySlug}/${featured?.slug}`
                        : featured?.type === "MINIGUIDE" ? `${t('pages.miniGuide')}/${featured?.categorySlug}/${featured?.slug}`
                            : featured?.type === "INTERESTSITE" ? `${t('pages.explore')}/${featured?.categorySlug}/${featured?.slug}`
                                : featured?.type === "ACCOMMODATION" && `${t('pages.villas')}/${featured?.slug}`
                }>
                    <Row className='py-4 border-1 border-top border-bottom border-coal g-0 flex-column-reverse flex-lg-row'>
                        <Col xs={12} lg={5} className='d-flex flex-column align-items-start justify-content-between flex-grow-1 mt-4 mt-lg-0 gap-4 gap-lg-0 flex-lg-grow-1'>
                            <div className='d-flex flex-column gap-3'>
                                <h2 className='inter-regular fs-6 fs-lg-5 letter-spacing-2 text-dark-green mb-0 text-uppercase'>{t('home.featured')}</h2>
                                <h3 className='fitzgerald-regular h1 text-dark-green mb-0'>{featured?.title}</h3>
                                <p className='inter-regular fs-6 fs-lg-5 text-dark-green mb-2 mb-lg-0'>{featured?.description}</p>
                            </div>
                            <span className={"text-uppercase new-link-laurel inter-regular letter-spacing-2 mb-2"}>{t('general.components.readMore')}</span>
                        </Col>
                        <Col xs={12} lg={5} className='offset-lg-2 flex-grow-1'>
                            <Image src={featured?.imgUrl} />
                        </Col>
                    </Row>
                </Link>
            </section>
            <section className='d-flex flex-column container-lg mb-5'>
                <h2 className='inter-regular text-uppercase letter-spacing-2 mb-4 text-dark-green fs-6 fs-lg-5 text-dark-green'>{t('home.articles')}</h2>
                <Row className='gy-5 gx-4 mb-5'>
                    {articles?.map((article, i) => (
                        <Col lg={4} key={i}>
                            <NewContentCard theme={"laurel"} image={article?.listImageUrl} project={article?.projectTitle} category={article?.categoryTitle} title={`${article?.titleFirstPart || ""} ${article?.titleSecondPart || ""}`.trim()} stamp={article?.stamp?.image} slug={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={article?.id} data={article} />
                        </Col>
                    ))}
                </Row>
                <Link href={t('pages.guide')} className={"align-self-center"}>
                    <Button className='btn-laurel-outline letter-spacing-1 inter-regular py-3 px-5'>{t('general.components.seeAll')}</Button>
                </Link>
            </section>
            <section className='bg-dark-green home-category-section'>
                <h2 className='inter-regular h5 letter-spacing-2 text-white text-uppercase mb-0 text-center'>{t('home.categories')}</h2>
                {categories?.map((category, i) => (
                    <Link href={`${t('pages.guide')}/${category?.slugs?.[i18n.language]}`} key={i}>
                        <div className='border-top border-white category-container'>
                            <div className='d-flex flex-row gap-3 container-lg text-white'>
                                <span className='inter-regular letter-spacing-2'>{(i + 1) < 10 ? `0${i + 1}` : i + 1}</span>
                                <span className='h1 fitzgerald-regular mb-0 letter-spacing-2'>{category.name}</span>
                            </div>
                        </div>
                    </Link>
                ))}
            </section>
            <section className='bg-beige py-5 home-must-container'>
                <div className='d-flex flex-column container-lg gap-4 gap-lg-0'>
                    <h2 className='fitzgerald-regular text-center fs-title text-brown mb-0 mb-lg-2'>{t('home.miniGuides.title')}</h2>
                    <p className='mb-0 mb-lg-5 inter-regular text-uppercase letter-spacing-2 text-center text-brown'>{t('home.miniGuides.subtitle')}</p>
                    <p className='inter-regular letter-spacing-1 fs-6 fs-lg-5 text-center align-self-center lh-md mb-lg-5 text-dark-green description'>{t('home.miniGuides.description')}</p>
                    <Row className='gy-5 gx-4 mb-5'>
                        {miniGuides?.map((miniGuide, i) => (
                            <Col lg={4} key={i}>
                                <NewContentCard theme={"brown"} image={miniGuide?.mainPicture} category={miniGuide?.category} title={`${miniGuide?.title}`.trim()} slug={`${t('pages.miniGuide')}/${miniGuide?.categorySlug}/${miniGuide?.slug?.[i18n.language]}`} id={miniGuide?.id} type={"MINIGUIDE"} />
                            </Col>
                        ))}
                    </Row>
                    <Link href={t('pages.miniGuide')} className={"align-self-center"}>
                        <Button className='btn-brown-outline letter-spacing-1 inter-regular py-3 px-5 letter-uppercase'>{t('general.components.seeAllM')}</Button>
                    </Link>
                </div>
            </section>
            <section className='d-flex explore-hero px-lg-0'>
                <div className='d-flex flex-grow-1'>
                    <Row className="flex-grow-1 g-0 flex-column-reverse flex-lg-row">
                        <Col lg={4} className='d-flex align-items-center justify-content-center offset-lg-1 px-4 py-5 px-lg-0 py-lg-0'>
                            <div className='d-flex flex-column gap-3 gap-lg-4 content'>
                                <Image src="/img/home/explore-icon.svg" className='icon' />
                                <h2 className='fitzgerald-regular fs-title text-dark-green pe-lg-5'>{t('home.explora.title')}</h2>
                                <div className='mb-lg-4'>
                                    <p className='inter-regular letter-spacing-1 text-dark-green mb-0 fs-6 fs-lg-5 lh-md'>{t('home.explora.description1')}</p>
                                    <p className='inter-regular fs-6 fs-lg-5 letter-spacing-1 lh-base text-dark-green pe-3'>{t('home.explora.description2')}</p>
                                </div>
                                <Link href={t('pages.explore')} className={"text-uppercase new-link-dark-green inter-regular letter-spacing-2 mb-1 align-self-start"}>{t('general.components.seeMore')}</Link>
                            </div>
                        </Col>
                        <Col lg={6} className='offset-lg-1 hero-image'>
                            <Image src="/img/home/hero-explore.webp" />
                        </Col>
                    </Row>
                </div>
            </section>
            {!user && <section className='bg-dark-green banner-section'>
                <div className='d-flex justify-content-center align-items-center flex-column container-lg lh-sm'>
                    <p className='fs-title fitzgerald-regular text-white letter-spacing-1 w-lg-75 text-center mb-5'>{t('home.registerBanner.title')}</p>
                    <Link href={t('pages.register')}>
                        <Button className='btn-beige-outline text-uppercase inter-regular letter-spacing-1 py-3 px-5'>{t('home.registerBanner.button')}</Button>
                    </Link>
                </div>
            </section>}
            <section className='container-lg my-5'>
                <Row className='g-0'>
                    <Col lg={5}>
                        <Image src={"/img/home/hero-dossier.webp"} className='w-100 h-100 fit-cover' />
                    </Col>
                    <Col lg={6} className='d-flex flex-column gap-4 offset-lg-1 pt-4 py-lg-4'>
                        <h2 className='inter-regular fs-6 fs-lg-5 letter-spacing-2 mb-0 text-dark-green text-uppercase'>{t('home.dossier.title')}</h2>
                        <div>
                            <p className='fs-title fitzgerald-regular mb-0 lh-sm mb-0 letter-spacing-1 text-dark-green'>Travel <i className='fitzgerald-italic'>Slow</i>.</p>
                            <p className='fs-title fitzgerald-regular lh-sm mb-0 letter-spacing-1 text-dark-green'>Live <i className='fitzgerald-italic'>Slow</i>.</p>
                        </div>
                        <p className='inter-regular fs-6 fs-lg-5 letter-spacing-1 lh-base text-dark-green mb-0 mb-lg-2'>{t('home.dossier.description1')}</p>
                        <p className='inter-regular fs-6 fs-lg-5 letter-spacing-1 lh-base text-dark-green mb-0 mb-lg-2'>{t('home.dossier.description2')}</p>
                        <p className='inter-semi-bold fs-6 fs-lg-5 letter-spacing-1 lh-base text-dark-green mb-0'>{t('home.dossier.description3')}</p>
                    </Col>
                </Row>
            </section>
            <section className='container-lg mb-lg-5 px-0 px-lg-4'>
                <div className='position-relative newsletter-banner'>
                    <Image src='/img/home/banner-form.webp' fluid className='background' />
                    <Row className='overlay g-0'>
                        <Col lg={5} className='d-flex align-items-center justify-content-center mb-4 mb-lg-0'>
                            <div>
                                <span className="fitzgerald-regular h1 text-white mb-0 letter-spacing-1">{t('home.newsletterBanner.text1')} </span>
                                <span className="fitzgerald-regular h1 text-white mb-0 letter-spacing-1"><span className='fitzgerald-bold'>{t('home.newsletterBanner.text2.highlight')}</span>{t('home.newsletterBanner.text2.text')} </span>
                                <span className="fitzgerald-regular h1 text-white mb-0 letter-spacing-1">{t('home.newsletterBanner.text3')}</span>
                            </div>
                        </Col>
                        <Col lg={5} className='offset-lg-2'>
                            <div className='bg-white p-4'>
                                <HubspotForm
                                    region="eu1"
                                    portalId="26078053"
                                    formId={newsletterFormLang[i18n.language]}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <aside className='mb-lg-5 my-5 mt-lg-0 px-5 px-lg-0'>
                <Container>
                    <p className='mb-4'><b className='text-uppercase d-lg-inline d-block mb-3'>{t('footer.grant.title')}</b> {t('footer.grant.text')}</p>
                    <div className='d-flex flex-column flex-lg-row justify-content-between financing-logos gap-3 gap-lg-0'>
                        <Image src="/img/financing/moves.webp" fluid className='d-block d-lg-none' />
                        <Image src="/img/financing/eu.webp" fluid />
                        <Image src="/img/financing/idae.webp" fluid />
                        <Image src="/img/financing/recovery-plan.webp" fluid />
                        <Image src="/img/financing/moves.webp" fluid className='d-none d-lg-block' />
                    </div>
                </Container>
            </aside>
        </>
    )
}

export default NewHome