import React from 'react'

function FilterGrid({ width, height, active, onClick }) {
    return (
        <svg id="filter-grid" className={active ? "active" : null} width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g>
                <path id="Rectangle 9" d="M0.909524 0.65574H6.01395V5.76017H0.909524V0.65574Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 12" d="M8.20152 0.65574H13.3059V5.76017H8.20152V0.65574Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 15" d="M15.4935 0.65574H20.5979V5.76017H15.4935V0.65574Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 10" d="M0.909524 7.94779H6.01395V13.0522H0.909524V7.94779Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 13" d="M8.20152 7.94779H13.3059V13.0522H8.20152V7.94779Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 16" d="M15.4935 7.94779H20.5979V13.0522H15.4935V7.94779Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 11" d="M0.909524 15.2398H6.01395V20.3443H0.909524V15.2398Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 14" d="M8.20152 15.2398H13.3059V20.3443H8.20152V15.2398Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
                <path id="Rectangle 17" d="M15.4935 15.2398H20.5979V20.3443H15.4935V15.2398Z" fill={"white"} stroke="#5A5C69" strokeWidth="0.729204" />
            </g>
        </svg>

    )
}

export default FilterGrid