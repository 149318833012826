import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'

const useSheetDisplay = () => {
    const closeSheet = (action) => {
        document.getElementsByClassName('mobile-sheet')?.[0]?.classList?.replace("active", "close")

        setTimeout(() => {
            action()
        }, 625);
    }

    const openSheet = () => {
        document.getElementsByClassName('mobile-sheet')?.[0]?.classList?.replace("close", "active")
    }

    return ({ openSheet, closeSheet })
}

function TzoSheet({ show, children, title, close, stickyHead, returnShow, returnAction }) {

    const { openSheet, closeSheet } = useSheetDisplay()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event?.target?.className?.includes?.("mobile-sheet")) {
                closeSheet(close)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    useEffect(() => {
        if (show) {
            openSheet();
        }
    }, [show])

    return (
        show ? (
            <div className={`d-flex d-lg-none mobile-sheet close`.trim()}>
                <div className='sheet-container'>
                    <div className={`d-flex justify-content-between py-3 px-3 header ${stickyHead ? "sticky" : "position-relative"} ${returnShow ? "flex-row" : "flex-row-reverse"}`.trim()}>
                        {returnShow && <FontAwesomeIcon icon={"fa-solid fa-chevron-left"} width={12} className='text-coal control' onClick={() => returnAction()} />}
                        <span className='w-100 px-5 h6 inter-medium text-muted text-center mb-0 flex-grow-1 position-absolute top-50 start-50 translate-middle'>{title || "Lorem ipsum"}</span>
                        <FontAwesomeIcon width={22} height={22} icon={"fa-solid fa-xmark"} className='border border-2 border-coal text-coal radius control opacity-50' onClick={() => closeSheet(close)} />
                    </div>
                    <div className='position-relative d-flex flex-column content'>
                        <div className='d-flex flex-column flex-grow-1'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )
    )
}

export default TzoSheet