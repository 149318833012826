import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Image, Row } from 'react-bootstrap'
import Link from '../Link';
import { useTranslation } from 'react-i18next';

function useHandleSlider() {
	const [activeSlider, setActiveSlider] = useState(0);
	const [swipeDistance, setSwipeDistance] = useState({
		start: 0,
		end: 0,
		top: 0,
		bottom: 0
	});
	const [blockScroll, setBlockScroll] = useState();
	const [delayRedirect, setDelayRedirect] = useState(false)
	const swipeTolerance = 30;

	const checkIndex = (trigger, totalSliders, infinite, slideIndex) => {
		if (trigger === "left") {
			if ((activeSlider - 1) < 0) {
				infinite && setActiveSlider(totalSliders)
			} else {
				setActiveSlider(prevState => prevState - 1);
			}
		} else if (trigger === "right") {
			if ((activeSlider + 1) > totalSliders) {
				infinite && setActiveSlider(0)
			} else {
				setActiveSlider(prevState => prevState + 1);
			}
		} else if (trigger === "slideTo") {
			setActiveSlider(slideIndex)
		}
	}

	const handleTouchStart = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setSwipeDistance(prevState => ({ ...prevState, start: event.touches?.[0]?.clientX, top: event.touches?.[0]?.clientY }));
	}

	const handleTouchMove = (event) => {
		setSwipeDistance(prevState => ({ ...prevState, end: event.touches?.[0]?.clientX }));
		let checkSwipeDown = Math.abs((event.touches?.[0]?.clientY - swipeDistance.top)) // touch down (scrolling up)
		let checkSwipeUp = Math.abs((swipeDistance.top - event.touches?.[0]?.clientY)) // touch up (scrolling down)

		if (blockScroll === undefined && (checkSwipeDown > 20) && (checkSwipeUp > 20)) {
			setBlockScroll(true)
		}

		if ((event.touches?.[0]?.clientX - swipeDistance.start) > swipeTolerance) {
			if (!blockScroll) {
				document.body.style.overflowY = "hidden"
				blockScroll === undefined && setBlockScroll(false)
			}
		} else if ((swipeDistance.start - event.touches?.[0]?.clientX) > swipeTolerance) {
			if (!blockScroll) {
				document.body.style.overflowY = "hidden"
				blockScroll === undefined && setBlockScroll(false)
			}
		}
	}

	const handleTouchEnd = (totalSliders, infinite) => {
		if (Math.abs(swipeDistance.end) > 0 && !blockScroll) { // Avoid click & change slide on scroll not blocked
			if ((swipeDistance.end - swipeTolerance) >= swipeDistance.start) {
				checkIndex("left", totalSliders, infinite)
			} else if ((swipeDistance.end + swipeTolerance) <= swipeDistance.start) {
				checkIndex("right", totalSliders, infinite)
			}
		}

		document.body.removeAttribute("style")
		setSwipeDistance({ start: 0, end: 0 })
		setBlockScroll()
	}

	const handleRedirectBlock = () => {
		setDelayRedirect(true);

		setTimeout(() => {
			setDelayRedirect(false)
		}, 650);
	}

	return {
		activeSlider,
		delayRedirect,
		changeSlider: checkIndex,
		touchStart: handleTouchStart,
		touchMove: handleTouchMove,
		touchEnd: handleTouchEnd,
		handleRedirectBlock
	}
}

function VillaDetailedCard({ data, guests, sliderInfinite, featured }) {

	const { t, i18n } = useTranslation();

	const { activeSlider, delayRedirect, changeSlider, touchStart, touchMove, touchEnd, handleRedirectBlock } = useHandleSlider();
	const [villaSlider, setVillaSlider] = useState([
		"/img/bestof-home.png",
		"/img/bg-section-2.png",
		"/img/home-image.jpg",
		"/img/landscape.png",
		"/img/landscape-v1.png",
	])

	function shuffle(array) {
		let currentIndex = array.length, randomIndex;

		// While there remain elements to shuffle.
		while (currentIndex > 0) {

			// Pick a remaining element.
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex], array[currentIndex]];
		}

		return array;
	}

	useEffect(() => {
		const data = shuffle(villaSlider)
		setVillaSlider(data)
	}, [])

	useEffect(() => {
		if ((activeSlider === 0 && !sliderInfinite) || (activeSlider === villaSlider?.length - 1 && !sliderInfinite)) {
			handleRedirectBlock();
		}
	}, [activeSlider])


	return (
		<Link href={`${t("pages.villa")}/${t("pages.villaDetail")}/${data?.id}`} className={"text-coal"} noRedirect={delayRedirect}>
			<div className='d-flex flex-row p-4 bg-white card-shadow villa-detailed-card gap-4'>
				<div className={`slider overflow-hidden flex-shrink-0 ${featured ? "featured" : ""}`.trim()}>
					<Button variant={"none"} className={`position-absolute top-50 translate-middle-y position-absolute p-0 shadow-none border-0 arrow left ${(activeSlider === 0 && !sliderInfinite) ? "d-none" : ''}`.trim()} onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						changeSlider("left", villaSlider.length - 1, sliderInfinite)
					}}>
						<FontAwesomeIcon icon={"fa-solid fa-chevron-left"} width={18} className='text-white' />
					</Button>
					<Button variant={"none"} className={`position-absolute top-50 translate-middle-y position-absolute p-0 shadow-none border-0 arrow right ${(activeSlider === villaSlider?.length - 1 && !sliderInfinite) ? "d-none" : ''}`.trim()} onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						changeSlider("right", villaSlider.length - 1, sliderInfinite)
					}}>
						<FontAwesomeIcon icon={"fa-solid fa-chevron-right"} width={18} className='text-white' />
					</Button>
					{data?.images.map((slide, i) =>
						<img key={i} src={slide} className={activeSlider === i ? "active" : null} draggable={false} />
					)}
					<div className='d-flex flex-row gap-2 position-absolute start-50 translate-middle-x dot-control'>
						{villaSlider.map((slide, i) =>
							<Button key={i} variant={"none"} className={`p-0 shadow-none border-0 dot ${activeSlider === i ? "active" : ''}`.trim()} onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								changeSlider("slideTo", villaSlider.length - 1, false, i)
							}} />
						)}
					</div>
				</div>
				{/* <Image fluid src={"/img/bestof-home.png"} className='picture radius flex-shrink-1' /> */}
				<Row className='gx-4 flex-grow-1 flex-shrink-1'>
					<Col lg={7} className='d-flex flex-column gap-3 justify-content-between'>
						<div>
							<p className='mb-1 h4 inter-bold clamp-1'>{data?.title}</p>
							<p className='inter-medium h6 clamp-1'>{data?.city}</p>
						</div>
						<div className='d-flex flex-row gap-3'>
							<Image src='/img/svg/umbrella-beach.svg' height={20} />
							<p className='mb-0 inter-regular small'>A {data?.distance?.distance}{data?.distance?.unit} de la {data?.distance?.category?.text}</p>
						</div>
						<div>
							{/* <p className='tt-norms-bolder mb-1'>Chalet de 3 dormitorios</p> */}
							<p className='inter-regular mb-2 clamp-3'>{data?.shortDescription}</p>
							<div className='d-flex flex-row flex-wrap gap-2 inter-bold'>
								{data?.numBathroom && <div className='d-flex align-items-center gap-2'>
									{/* <Image src={"/img/svg/guests.svg"} height={18} /> */}
									<Image src={"/img/svg/baths.svg"} height={18} />
									<span className='inter-regular'>{data?.numBathroom} baño</span>
								</div>}
								{data?.numBedroom && <div className='d-flex align-items-center gap-2'>
									<Image src={"/img/svg/beds.svg"} height={18} />
									<span className='inter-regular'>{data?.numBedroom} camas</span>
								</div>}
								{data?.plotArea && <div className='d-flex align-items-center gap-2'>
									<Image src={"/img/svg/plot-area.svg"} height={18} />
									<span className='inter-regular'>{data?.plotArea}</span>
								</div>}
								{data?.chargingPoint && <div className='d-flex align-items-center gap-2'>
									<Image src={"/img/svg/car-ve.svg"} height={18} />
									<span className='inter-regular'>Point-VE</span>
								</div>}
							</div>
							{/* <p className='tt-norms-bold mb-0'>3 camas (2 dobles, 1 sofá cama)</p> */}
						</div>
					</Col>
					<Col lg={5} className='d-flex flex-column justify-content-between gap-3 text-end'>
						<div className='d-flex flex-row gap-2 justify-content-end'>
							<div className='d-flex align-items-center text-end'>
								<p className='mb-0 inter-medium lh-sm'>Bien</p>
								{/* <p className='mb-0 tt-norms-bold small lh-sm'>17 comentarios</p> */}
							</div>
							<div className='d-flex px-2 bg-primary radius align-items-center justify-content-center rating'>
								<span className='inter-semi-bold mb-0 h5'>{data?.rating}</span>
							</div>
						</div>
						<div className='d-flex flex-column gap-3'>
							{data?.nightPrice && (
								<div>
									{/* <p className='mb-1'>{`${data?.numNights ? `${data?.numNights} Noches` : ""} ${guests ? `${data?.numNights ? ", " : ""} ${guests} Personas` : ""}`.trim()}</p> */}
									<div className='d-flex gap-2 align-items-end justify-content-end'>
										<span className='small inter-regular'>{t('villas.cards.from')}</span>
										<span className='h3 inter-bold mb-0'>{data?.nightPrice}€</span>
									</div>
								</div>
							)}
							<div className='d-flex flex-column'>
								<p className='inter-regular small mb-2'>{t('villas.cards.tax')}</p>
								<Button variant='none' className='btn-laurel inter-medium flex-grow-1'>{t('villas.cards.disponibility')}</Button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Link>
	)
}

export default VillaDetailedCard