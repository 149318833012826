import { Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";

const CookiePolicy = () => {

    const { t } = useTranslation();

    return (
        <>
            <SeoHelmet title={t('seo.cookies.title')} metaTitle={t('seo.cookies.metaTitle')} metaDescription={t('seo.cookies.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <Container className="text-break">
                <Row className="text-center pt-5 pb-3">
                    <h2>
                        <span className="joane-title m-0">{t("cookiePolicy.title1")} </span>
                        <span className="tt-norms-title m-0">{t("cookiePolicy.title2")}</span>
                    </h2>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("cookiePolicy.section1.title")}</b>
                    </h4>
                    <p>{t("cookiePolicy.section1.text1")}</p>
                    <p>{t("cookiePolicy.section1.text2")}</p>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("cookiePolicy.section2.title")}</b>
                    </h4>
                    <p>{t("cookiePolicy.section2.text1")}</p>
                    <p>{t("cookiePolicy.section2.text2")}</p>
                    <p>{t("cookiePolicy.section2.text3")}</p>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("cookiePolicy.section3.title")}</b>
                    </h4>
                    <p>{t("cookiePolicy.section3.text1")}</p>

                    <h6>
                        <b>{t("cookiePolicy.section3.block1.title")}</b>
                    </h6>
                    <p>{t("cookiePolicy.section3.block1.text1")}</p>
                    <p>{t("cookiePolicy.section3.block1.text2")}</p>

                    <h6>
                        <b>{t("cookiePolicy.section3.block2.title")}</b>
                    </h6>
                    <p>{t("cookiePolicy.section3.block2.text1")}</p>
                    <p>{t("cookiePolicy.section3.block2.text2")}</p>

                    <h6>
                        <b>{t("cookiePolicy.section3.block3.title")}</b>
                    </h6>
                    <p>{t("cookiePolicy.section3.block3.text1")}</p>
                    <p>{t("cookiePolicy.section3.block3.text2")}</p>
                    <p>{t("cookiePolicy.section3.block3.text3")}</p>
                    <p>{t("cookiePolicy.section3.block3.textList.text1")}<br />{t("cookiePolicy.section3.block3.textList.text2")}</p>
                    <p>{t("cookiePolicy.section3.block3.text4")}</p>
                    <p>{t("cookiePolicy.section3.block3.text5")}</p>
                    <p>{t("cookiePolicy.section3.block3.text6")}</p>
                    <p>{t("cookiePolicy.section3.block3.textList2.text1")}<br />{t("cookiePolicy.section3.block3.textList2.text2")}<br />{t("cookiePolicy.section3.block3.textList2.text3")}<br />{t("cookiePolicy.section3.block3.textList2.text4")}</p>

                    <h6>
                        <b>{t("cookiePolicy.section3.block4.title")}</b>
                    </h6>
                    <p>{t("cookiePolicy.section3.block4.text1")}</p>
                    <p>{t("cookiePolicy.section3.block4.text2")}</p>
                    <p>{t("cookiePolicy.section3.block4.text3")}</p>
                    <p>{t("cookiePolicy.section3.block4.text4")}</p>
                    <p>{t("cookiePolicy.section3.block4.text5")}</p>
                    <p>{t("cookiePolicy.section3.block4.text6")}</p>
                    <p>{t("cookiePolicy.section3.block4.text7")}</p>

                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("cookiePolicy.section4.title")}</b>
                    </h4>
                    <p>{t("cookiePolicy.section4.text1")}</p>
                </Row>
                <Row className="pb-5">
                    <h4>
                        <b>{t("cookiePolicy.section5.title")}</b>
                    </h4>
                    <p>{t("cookiePolicy.section5.text1")}</p>
                    <p>{t("cookiePolicy.section5.text2")}</p>
                    <p>{t("cookiePolicy.section5.textList.text1")}<br />{t("cookiePolicy.section5.textList.text2")}<br />{t("cookiePolicy.section5.textList.text3")}<br />{t("cookiePolicy.section5.textList.text4")}</p>
                    <p>{t("cookiePolicy.section5.text3")}</p>
                    <p>{t("cookiePolicy.section5.text4")}</p>
                    <p>{t("cookiePolicy.section5.text5")}</p>
                </Row>
            </Container>
        </>
    )
}

export default CookiePolicy