import api from './api';

const getLanguages = (data) => api.get('/language', data).then(response => {
    return response.data
});

const LanguageService = {
    getLanguages,
}

export default LanguageService;