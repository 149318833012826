import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from "react-router-dom"
import Link from '../Link';
import { fas } from "@fortawesome/free-solid-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

function BreadcrumbPage({ crumbArticle, category, search, filter, mobileHide, title, categoryParam, section }) {

	library.add(fas);
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const path = location.pathname.split('/');
	const [breadcrumb, setBreadcrumb] = useState()
	const { promiseInProgress } = usePromiseTracker();
	const [scrollAnimation, setScrollAnimation] = useState(false);

	let resetTimeOut;

	useEffect(() => {
		if (i18n?.language === path[1]) { // check lang code
			setBreadcrumb(breadcrumsRoutes())
		}
	}, [i18n?.language])

	useEffect(() => {
		if (category && filter) {
			setBreadcrumb(breadcrumsRoutes())
		} else if (!category && !filter) {
			setBreadcrumb(breadcrumsRoutes())
		} else {
			setBreadcrumb(breadcrumsRoutes())
		}
	}, [category, filter, title])

	useEffect(() => {

		const breadcrumbsDOM = document.getElementsByClassName('breadcrumb-container')?.[0]

		if (breadcrumbsDOM && !scrollAnimation && !promiseInProgress) {
			setScrollAnimation(true)
		}
	}, [document.getElementsByClassName('breadcrumb-container'), location, promiseInProgress])

	useEffect(() => {
		setScrollAnimation(false)
	}, [location])

	useEffect(() => {

		const offsetY = document.getElementsByClassName('breadcrumb-container')?.[0].getBoundingClientRect().height

		if (scrollAnimation) {
			resetTimeOut = setTimeout(() => {
				if (window.pageYOffset <= offsetY && window.innerWidth < 576) {
					window.scrollTo({ top: 0 + offsetY, behavior: "smooth" })
				}
			}, 1000);
		}

		return () => {
			clearTimeout(resetTimeOut)
		}
	}, [scrollAnimation])

	function breadcrumsRoutes() {
		let crumbs = [];
		let pathStr = ""
		let finish = false;

		path.forEach((path, i) => {
			if (!finish) {
				if (path !== "" && i === 2) {
					pathStr = pathStr + `${path}`

					switch (path) {

						case t('pages.guide'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.guide') })
							break;

						case t('pages.profile'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.profile') })
							break;

						case t('pages.explore'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.explore') })
							break;

						case t('pages.miniGuide'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.must') })
							break;

						case t('pages.promos'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.promos') })
							break;

						case t('pages.login'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.login') })
							break;

						case t('pages.favourites'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.favorites') })
							break;

						case t('pages.villa'):
							crumbs.push({ url: pathStr, text: t('breadcrumbs.villa') })
							break;

						default:
							crumbs.push({ url: pathStr, text: section })
							break;
					}

				} else if (path !== "" && i === 3) {
					if (filter === 'siteDetail' || filter === "exploreCategory") { // Avoid insert category route in 4th position
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: category })
					} else if (search) { // Custom article search crumb [/{code}/{guide}/{search}]
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: t("breadcrumbs.search") })
						finish = true;
					} else if (category) {
						if (!filter && !categoryParam) {
							pathStr = pathStr + `/${path}`
							crumbs.push({ url: pathStr, text: category })
						} else if (filter && categoryParam) {
							if (filter === "miniguide") {
								pathStr = `${t('pages.miniGuide')}`
								crumbs.push({ url: pathStr, text: category, param: categoryParam })
							} else {
								pathStr = pathStr + `/${path}`
								crumbs.push({ url: pathStr, text: category })
							}
						} else {
							pathStr = `${t('pages.guide')}/${path}`
							crumbs.push({ url: pathStr, text: category })
						}

					} else if (path === t('pages.favourites')) {
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: t('breadcrumbs.favorites') })
					} else if (location.pathname.split('/')?.[2] === (t("pages.register"))) {
						finish = true;
					} else if (location.pathname.split('/')?.[2] === (t("pages.login"))) {
						finish = true;
					} else if (path === t('pages.villaSearcher')) {
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: t('breadcrumbs.villaSearcher') })
					} else if (path === t('pages.villaDetail')) {
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: `${t('pages.villa')}/${t('pages.villaSearcher')}`, text: t('breadcrumbs.villaDetail') })
					} else if (path !== "") {
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: path })
					}

				} else if (path !== "" && i === 4) {
					if (title) { // Use title in interest site detail & miniguide detail
						if (filter === "miniguide") {
							pathStr = pathStr + `/${categoryParam}/${path}`
							crumbs.push({ url: pathStr, text: title })
						} else {
							pathStr = pathStr + `/${path}`
							crumbs.push({ url: pathStr, text: title })
						}
					} else {
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: path })
					}
				} else if (i >= 1) { // avoid empty route and language code
					if (path === i18n.language) {
						pathStr = pathStr
						crumbs.push({ url: pathStr, text: path })
					} else {
						pathStr = pathStr + `/${path}`
						crumbs.push({ url: pathStr, text: path })
					}
				}
			} else {
				return crumbs;
			}
		})

		return crumbs
	}

	return (
		<Breadcrumb as={"div"} className={`py-2 container-fluid text-white breadcrumb-container ${mobileHide ? "d-none d-lg-block" : ""}`}>
			{breadcrumb?.map((crumbs, i) => {
				if (i !== breadcrumb.length - 1) {
					return (
						<li key={i} className='breadcrumb-item text-decoration-none'>
							{!crumbs.noRoute ?
								<Link href={crumbs.url} className="text-decoration-none tt-norms" params={crumbs?.param}>
									{i === 0 ? t('breadcrumbs.home') : crumbs.text}
								</Link>
								:
								<span to={crumbs.url} className="text-decoration-none tt-norms">
									{i === 0 ? t('breadcrumbs.home') : crumbs.text}
								</span>}
						</li>
					)
				} else {
					return (
						<li key={i} className='breadcrumb-item tt-norms'>
							<Link href={crumbs.url} className="text-decoration-none tt-norms active">{crumbArticle ? crumbArticle : crumbs.text}</Link>
						</li>
					)
				}
			})}
		</Breadcrumb>
	);
}

export default BreadcrumbPage;
