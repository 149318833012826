import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react'
import { Alert, Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegisterService from '../../services/register.service';
import { useState } from 'react';
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialApple } from "reactjs-social-login";
import AuthService from '../../services/auth.service';
import TokenService from '../../services/token.service';
import UserService from '../../services/user.service';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router';
import Link from '../Link';
import UserDataService from '../../services/userData.service';

const SubscribeModal = ({ show, close }) => {

    // library.add(fas);
    const { t, i18n } = useTranslation();
    const { state, update } = useContext(AppContext)
    const navigate = useNavigate();



    const { register, handleSubmit, formState, reset } = useForm({
        mode: "all",
        defaultValues: {}
    })

    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [confirmEmail, setConfirmEmail] = useState(false);
    const [errors, setErrors] = useState({
        password: false,
        email: false,
        server: false
    });

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 992)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => {
            window.removeEventListener('resize', checkDesktop)
            clearTimeout(waitResize)
        }
    }, [isDesktop])

    const formSubmit = (data) => {
        createAccount(data)
    }

    const createAccount = async () => {
        setErrors({
            password: false,
            email: false,
            server: false
        })

        if (password === confirmPassword) {
            try {
                await RegisterService.sendEmail({
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    password: password,
                    validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
                })
                setConfirmEmail(true)
            } catch ({ response }) {
                if (response?.data?.errorCode) {
                    switch (response.data.errorCode) {
                        case 13002:
                            setErrors({ ...errors, email: true });
                            break;
                        default:
                            setErrors({ ...errors, server: true });
                    }
                }
            }
        }
    }

    const reSendMail = async () => {
        try {
            await RegisterService.reSendEmail({
                email,
                validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
            })
        } catch (error) {
            console.error(error);
        }
    }

    const closeModal = () => {
        close()
        setTimeout(() => {
            setErrors({
                password: false,
                email: false,
                server: false
            })
            setConfirmEmail(false);
        }, 225);
    }

    const socialLogin = async (provider, data) => {
        setErrors({
            password: false,
            email: false,
            server: false
        });

        try {
            const token = await AuthService.socialLoginToken({ provider, token: data.access_token || data.accessToken })
            await TokenService.setUser(token)
            const user = await UserService.userInfo();
            update({
                ...state,
                user
            });
            close()
        } catch (error) {
            setErrors({ ...errors, server: true });
        }
    }

    return (
        <Modal show={show} onHide={close} size='xl' centered backdrop={'static'} className='shadow-sm subscribe-modal-container'>
            <Modal.Body className='subscribe-modal p-0'>
                <Row className='h-100 g-0 d-none d-lg-flex'>
                    <Col xs={6} className='h-auto position-relative overflow-hidden'>
                        <video autoPlay loop muted playsInline className='position-absolute top-0 start-0'>
                            <source autoPlay src={`/img/promotion/banner-default-card.mp4`} type={"video/mp4"} />
                        </video>
                    </Col>
                    <Col xs={6} className='p-5 d-flex justify-content-center flex-column modal-form'>
                        {!confirmEmail
                            ? <Row className='justify-content-center flex-column align-items-center'>
                                <Col xs={12} xl={10}>
                                    <p className='h1 tt-norms-semi-bolder mb-3 mb-xl-4'>{t('modals.subscribe.title')}</p>
                                    <p className='tt-norms mb-3 mb-xl-4 h5'>{t('modals.subscribe.subtitle')}</p>
                                    <Form className='d-flex flex-column gap-4 mb-5' onSubmit={(e) => { e.preventDefault(); createAccount() }}>
                                        <Form.Group>
                                            <Form.Control type="email" placeholder={t('modals.subscribe.form.email')} onChange={(e) => { setEmail(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="password" placeholder={t('modals.subscribe.form.password')} onChange={(e) => { setPassword(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="password" placeholder={t('modals.subscribe.form.confirm')} onChange={(e) => { setConfirmPassword(e.target.value) }} required />
                                        </Form.Group>
                                        <Button type={"submit"} className='btn-dark-green-outline tt-norms-semi-bolder px-5 align-self-center modal-btn-size'>{t('modals.subscribe.title')}</Button>
                                    </Form>
                                    {isDesktop && <div className='d-flex flex-column justify-content-center align-items-center social-login-modal'>
                                        <p className='mb-4 tt-norms text-coal h5'>{t('modals.subscribe.social.text')}</p>
                                        <div className='d-inline-flex flex-column aling-items-center justify-content-center'>
                                            <LoginSocialGoogle
                                                isOnlyGetToken
                                                client_id={'319399754923-seppuac5mkupbjv2hninb8tqutiael4i.apps.googleusercontent.com'}
                                                scope="profile email"
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2'
                                            >
                                                <Button className="google fw-bolder px-4 w-100 shadow-sm modal-btn-size d-flex flex-row align-items-center justify-content-center py-2">
                                                    <div className='logo me-2'></div>
                                                    <span className="inter-regular letter-spacing-1 small">{t('modals.subscribe.social.google')}</span>
                                                </Button>
                                            </LoginSocialGoogle>
                                            <LoginSocialFacebook
                                                appId={'827246235309311'}
                                                fieldsProfile={
                                                    'id,first_name,last_name,middle_name,name,short_name,email'
                                                }
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2'
                                            >
                                                <Button className="mb-3 mb-sm-0 facebook fw-bolder px-4 w-100 shadow-sm modal-btn-size d-flex flex-row align-items-center justify-content-center py-2">
                                                    <div className='logo me-2'></div>
                                                    <span className="inter-regular letter-spacing-1 small">{t('modals.subscribe.social.facebook')}</span>
                                                </Button>
                                            </LoginSocialFacebook>
                                            <LoginSocialApple
                                                client_id={'com.proadata.traveltozero.signin'}
                                                scope={'name email'}
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                redirect_uri={`${window.origin}/${UserDataService.getLanguagePreference()}/login`}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='w-100 mb-2'
                                            >
                                                <Button variant='none' className="google fw-bolder px-4 w-100 shadow-sm modal-btn-size d-flex flex-row align-items-center justify-content-center py-2">
                                                    <div className='apple-logo me-2'></div>
                                                    <span className="inter-regular letter-spacing-1 small">{t('modals.subscribe.social.apple')}</span>
                                                </Button>
                                            </LoginSocialApple>
                                            <Link href={`${t('pages.login')}`}>
                                                <Button className="mb-3 mb-sm-0 tzo fw-bolder px-4 w-100 shadow-sm modal-btn-size">
                                                    <Image width={22} src={'/img/icons/wheel-icon.svg'} className="me-2" />
                                                    <span className="inter-regular letter-spacing-1 small">{t('modals.subscribe.social.tzo')}</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>}
                                    <Alert variant={"danger"} className="mt-4" show={errors.password} dismissible onClose={() => setErrors({ ...errors, password: false })}>
                                        {t("register.errors.password")}
                                    </Alert>
                                    <Alert variant={"danger"} className="mt-4" show={errors.email} dismissible onClose={() => setErrors({ ...errors, email: false })}>
                                        {t("register.errors.email")}
                                    </Alert>
                                    <Alert variant={"danger"} className="mt-4" show={errors.server} dismissible onClose={() => setErrors({ ...errors, email: false })}>
                                        {t("register.errors.server")}
                                    </Alert>
                                </Col>
                            </Row>
                            : <Row className='justify-content-center flex-column align-items-center'>
                                <Col xs={12} xl={10} className='validate'>
                                    <p className='h1 tt-norms-semi-bolder mb-3 mb-xl-4'>{t('register.rightCard.confirm.title')}</p>
                                    <p className='tt-norms mb-3 mb-xl-4 h5'>{t('register.rightCard.confirm.text1')} <b>{email}</b> {t('register.rightCard.confirm.text2')}</p>
                                    <p className="tt-norms mb-4h">{t('register.rightCard.confirm.text3')}</p>
                                    <h6 className="tt-norms-semi-bolder text-center mt-5 mb-3">{t('register.rightCard.confirm.text4')}</h6>
                                    <div className="text-center">
                                        <Button className="btn-white tt-norms-semi-bolder px-5 align-self-center" variant="primary" onClick={() => reSendMail()}>
                                            {t('register.rightCard.confirm.button')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>}
                        <Button variant='none' className='shadow-none position-absolute close p-0' onClick={closeModal} >
                            <FontAwesomeIcon icon="fa-solid fa-xmark" width={16} />
                        </Button>
                    </Col>
                </Row>
                <Row className='h-100 g-0 d-flex d-lg-none'>
                    <Col className='h-100 position-relative overflow-auto'>
                        <div className='position-absolute top-0 overlay modal-form register-banner'>
                            {!confirmEmail
                                ? <div className='p-5 text-white d-flex flex-column justify-content-center align-content-center h-100 overlay  content'>
                                    <p className='h1 tt-norms-semi-bolder mb-3 mb-xl-4'>{t('modals.subscribe.title')}</p>
                                    <p className='tt-norms mb-3 mb-md-5 mb-xl-4 h5'>{t('modals.subscribe.subtitle')}</p>
                                    <Form className='d-flex flex-column gap-4 mb-5' onSubmit={handleSubmit(formSubmit)}>
                                        <Form.Group>
                                            <Form.Control type="email" placeholder={t('modals.subscribe.form.email')} onChange={(e) => { setEmail(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder={t('modals.subscribe.form.name')} onChange={(e) => { setFirstName(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder={t('modals.subscribe.form.surname')} onChange={(e) => { setLastName(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="password" placeholder={t('modals.subscribe.form.password')} onChange={(e) => { setPassword(e.target.value) }} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="password" placeholder={t('modals.subscribe.form.confirm')} onChange={(e) => { setConfirmPassword(e.target.value) }} required />
                                        </Form.Group>
                                        <Button type={"submit"} variant='none' className='btn-white tt-norms-semi-bolder px-5 align-self-center modal-btn-size'>{t('modals.subscribe.title')}</Button>
                                    </Form>
                                    {!isDesktop && <div className='d-flex flex-column justify-content-center align-items-center social-login-modal'>
                                        <p className='mb-4 tt-norms text-white h5'>{t('modals.subscribe.social.text')}</p>
                                        <div className='d-inline-flex flex-column aling-items-center justify-content-center'>

                                            <LoginSocialGoogle
                                                isOnlyGetToken
                                                client_id={'319399754923-seppuac5mkupbjv2hninb8tqutiael4i.apps.googleusercontent.com'}
                                                scope="profile email"
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2'
                                            >
                                                <Button className="google fw-bolder px-4 w-100 shadow-sm modal-btn-size d-flex flex-row align-items-center justify-content-center py-2">
                                                    <div className='logo me-2'></div>
                                                    <span className="tt-norms-semi-bolder small">{t('modals.subscribe.social.google')}</span>
                                                </Button>
                                            </LoginSocialGoogle>
                                            <LoginSocialFacebook
                                                appId={'827246235309311'}
                                                fieldsProfile={
                                                    'id,first_name,last_name,middle_name,name,short_name,email'
                                                }
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='mb-2'
                                            >
                                                <Button className="mb-sm-0 facebook fw-bolder px-4 w-100 shadow-sm modal-btn-size d-flex flex-row align-items-center justify-content-center py-2">
                                                    <div className='logo me-2'></div>
                                                    <span className="inter-regular letter-spacing-1 small">{t('modals.subscribe.social.facebook')}</span>
                                                </Button>
                                            </LoginSocialFacebook>
                                            <LoginSocialApple
                                                client_id={'com.proadata.traveltozero.signin'}
                                                scope={'name email'}
                                                onResolve={({ provider, data }) => {
                                                    socialLogin(provider, data)
                                                }}
                                                redirect_uri={`${window.origin}/${UserDataService.getLanguagePreference()}/login`}
                                                onReject={(err) => {
                                                    console.error(err)
                                                }}
                                                className='w-100 mb-2'
                                            >
                                                <Button variant='none' className="google fw-bolder px-4 w-100 shadow-sm modal-btn-size d-flex flex-row align-items-center justify-content-center py-2">
                                                    <div className='apple-logo me-2'></div>
                                                    <span className="inter-regular letter-spacing-1 small">{t('modals.subscribe.social.apple')}</span>
                                                </Button>
                                            </LoginSocialApple>

                                            <Link href={`${t('pages.login')}`}>
                                                <Button className="mb-3 mb-sm-0 tzo fw-bolder px-4 w-100 shadow-sm modal-btn-size">
                                                    <Image width={22} src={'/img/icons/wheel-icon.svg'} className="me-2" />
                                                    <span className="tt-norms-semi-bolder small">{t('modals.subscribe.social.tzo')}</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>}
                                    <Alert variant={"danger"} className="mt-4" show={errors.password} dismissible onClose={() => setErrors({ ...errors, password: false })}>
                                        {t("register.errors.password")}
                                    </Alert>
                                    <Alert variant={"danger"} className="mt-4" show={errors.email} dismissible onClose={() => setErrors({ ...errors, email: false })}>
                                        {t("register.errors.email")}
                                    </Alert>
                                    <Alert variant={"danger"} className="mt-4" show={errors.server} dismissible onClose={() => setErrors({ ...errors, email: false })}>
                                        {t("register.errors.server")}
                                    </Alert>
                                </div>
                                : (
                                    <div className='p-5 text-white d-flex flex-column justify-content-center align-content-center h-100 content'>
                                        <p className='h2 tt-norms-semi-bolder mb-3 mb-xl-4'>{t('register.rightCard.confirm.title')}</p>
                                        <p className='tt-norms mb-3 mb-xl-4 h5'>{t('register.rightCard.confirm.text1')} <b>{email}</b> {t('register.rightCard.confirm.text2')}</p>
                                        <p className="tt-norms mb-4h">{t('register.rightCard.confirm.text3')}</p>
                                        <h6 className="tt-norms-semi-bolder text-center mt-5 mb-3">{t('register.rightCard.confirm.text4')}</h6>
                                        <div className="text-center">
                                            <Button className="btn-white tt-norms-semi-bolder px-5 align-self-center" variant="primary" onClick={() => reSendMail()}>
                                                {t('register.rightCard.confirm.button')}
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }
                            <Button variant='none' className='shadow-none position-absolute close p-0' onClick={closeModal} >
                                <FontAwesomeIcon icon="fa-solid fa-xmark" width={16} />
                            </Button>
                            <video autoPlay loop muted playsInline className='position-absolute top-0'>
                                <source autoPlay src={`/img/promotion/banner-default-card.mp4`} type={"video/mp4"} />
                            </video>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default SubscribeModal