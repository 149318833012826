import { useEffect, useReducer } from "react";
import { createContext } from "react";

export const MiniGuideContext = createContext();
export const PromotionsContext = createContext();
export const FavouritesContext = createContext();
export const NavbarContext = createContext();
export const VillasContext = createContext();

export function MiniGuideProvider({ children }) {
    const reducer = (state, pair) => ({ ...state, ...pair })

    const initialState = {
        reviewIndex: null,
        visitedReview: null,
        miniGuideSlugs: null,
        articles: null
    }

    const [miniGuideState, miniGuideUpdate] = useReducer(reducer, initialState)

    return (
        <MiniGuideContext.Provider value={{ miniGuideState, miniGuideUpdate }}>
            {children}
        </MiniGuideContext.Provider>
    )
}

export function PromotionsProvider({ children }) {
    const reducer = (state, pair) => ({ ...state, ...pair })

    const initialState = {
        promotionModal: false,
        promotionTimelapse: null,
    }

    const [promotionState, promotionsUpdate] = useReducer(reducer, initialState)

    return (
        <PromotionsContext.Provider value={{ promotionState, promotionsUpdate }}>
            {children}
        </PromotionsContext.Provider>
    )
}

export function FavouriteProvider({ children }) {
    const reducer = (state, pair) => ({ ...state, ...pair })

    const initialState = {
        favourites: [],
        favouritesList: [],
        mainFolder: null,
        favoriteModal: false,
        articlesFav: [],
        miniGuidesFav: [],
        interestSitesFav: [],
        dataFolderPersist: null,
        folderEditPersist: false
    }

    const [favouriteState, favouriteUpdate] = useReducer(reducer, initialState)

    return (
        <FavouritesContext.Provider value={{ favouriteState, favouriteUpdate }}>
            {children}
        </FavouritesContext.Provider>
    )
}

export function NavbarProvider({ children }) {
    const reducer = (state, pair) => ({ ...state, ...pair })

    const initialState = {
        navbarHide: false,
        bottomNavHide: false,
        blockNavHide: false,
        showBottomNav: true
    }

    const [navbarState, navbarUpdate] = useReducer(reducer, initialState)

    return (
        <NavbarContext.Provider value={{ navbarState, navbarUpdate }}>
            {children}
        </NavbarContext.Provider>
    )
}

export function VillasProvider({ children }) {
    const reducer = (state, pair) => ({ ...state, ...pair })

    const initialState = {
        features: {
            bedrooms: 0,
            beds: 0,
            baths: 0
        },
        map: {
            hover: undefined,
            active: undefined
        },
        filter: {
            startDate: null,
            endDate: null,
            totalGuest: 2,
            guests: null
        }
    }

    const [villasState, villasUpdate] = useReducer(reducer, "")

    useEffect(() => {
        if (localStorage.getItem("villaFilters") && villasState) {
            localStorage.setItem("villaFilters", JSON.stringify({
                ...villasState,
                expirationTime: new Date(),
            }))

        } else if (localStorage.getItem("villaFilters") && !villasState) {
            const villaStorage = JSON.parse(localStorage.getItem("villaFilters"))

            let expirationDate = new Date(villaStorage?.expirationTime)

            let diff = Math.abs(expirationDate - new Date());
            let minutes = Math.floor((diff / 1000) / 60);

            if (minutes < 15) {
                villasUpdate({
                    ...villaStorage,
                    filter: {
                        ...villaStorage.filter,
                        startDate: villaStorage.filter.startDate ? new Date(villaStorage.filter.startDate) : null,
                        endDate: villaStorage.filter.endDate ? new Date(villaStorage.filter.endDate) : null
                    },
                    expirationTime: new Date(),
                })

                localStorage.setItem("villaFilters", JSON.stringify({
                    ...villaStorage,
                    expirationTime: new Date(),
                }))
            } else {
                localStorage.setItem("villaFilters", JSON.stringify({
                    ...initialState,
                    expirationTime: new Date()
                }))

                villasUpdate(initialState)
            }
        } else if(!localStorage.getItem("villaFilters")) {
            localStorage.setItem("villaFilters", JSON.stringify({
                ...initialState,
                expirationTime: new Date()
            }))

            villasUpdate(initialState)
        }
    }, [villasState])

    return (
        <VillasContext.Provider value={{ villasState, villasUpdate }}>
            {children}
        </VillasContext.Provider>
    )
}

