import React from 'react'
import { Button, Col, Image, Row } from 'react-bootstrap'
import Link from '../Link'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

function WebBanners() {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Row className='d-none d-lg-flex'>
                <Col lg={4}>
                    <Link href={t('pages.miniGuide')}>
                        <div className='banner-card position-relative'>
                            <Image src='/img/guide/banner-1.webp' />
                            <div className='d-flex flex-column justify-content-between p-5 overlay text-white'>
                                <div className='d-flex flex-column'>
                                    <span className='inter-regular fs-2 text-center mb-2 text-uppercase'>It's-a-must</span>
                                    <span className='fitzgerald-bold-italic fs-4 text-center'>{t('sectionBanners.miniGuides.subtitle')}</span>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <span className='inter-regular'>{t('sectionBanners.miniGuides.content.text1')}</span>
                                    <span className='inter-regular'>{t('sectionBanners.miniGuides.content.text2')}</span>
                                    <span className='inter-regular'>{t('sectionBanners.miniGuides.content.text3')}</span>
                                    <span className='inter-regular'>{t('sectionBanners.miniGuides.content.text4')}</span>
                                    <span className='inter-regular mb-3'>{t('sectionBanners.miniGuides.content.text5')}</span>
                                    <Button className='btn-beige-outline letter-spacing-1 inter-regular py-1 px-4 text-uppercase small fs-6'>{t('sectionBanners.miniGuides.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link href={t('pages.guide')}>
                        <div className='banner-card position-relative'>
                            <Image src='/img/guide/banner-2.webp' />
                            <div className='d-flex flex-column justify-content-between p-5 overlay text-white'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-column'>
                                        <span className='inter-regular fs-2 text-center text-uppercase mb-0 lh-1'>{t('sectionBanners.guide.title.text1')}</span>
                                        <span className='inter-regular fs-2 text-center mb-2 text-uppercase'>{t('sectionBanners.guide.title.text2')}</span>
                                    </div>
                                    <span className='fitzgerald-bold-italic fs-4 text-center'>{t('sectionBanners.guide.subtitle')}</span>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <span className='inter-regular'>{t('sectionBanners.guide.content.text1')}</span>
                                    <span className='inter-regular'>{t('sectionBanners.guide.content.text2')}</span>
                                    <span className='inter-regular'>{t('sectionBanners.guide.content.text3')}</span>
                                    <span className='inter-regular mb-3'>{t('sectionBanners.guide.content.text4')}</span>
                                    <Button className='btn-beige-outline letter-spacing-1 inter-regular py-1 px-4 text-uppercase small fs-6'>{t('sectionBanners.guide.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col lg={4}>
                    <Link href={t('pages.explore')}>
                        <div className='banner-card position-relative'>
                            <Image src='/img/guide/banner-3.webp' />
                            <div className='d-flex flex-column justify-content-between p-5 overlay text-white'>
                                <div className='d-flex flex-column'>
                                    <span className='inter-regular fs-2 text-center mb-2 text-uppercase'>{t('sectionBanners.explore.title')}</span>
                                    <span className='fitzgerald-bold-italic fs-4 text-center'>{t('sectionBanners.explore.subtitle')}</span>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <Button className='btn-beige-outline letter-spacing-1 inter-regular py-1 px-4 text-uppercase small fs-6'>{t('sectionBanners.explore.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Col>
            </Row>
            <Swiper
                slidesPerView={1.225}
                spaceBetween={8}
                slidesOffsetBefore={24}
                slidesOffsetAfter={24}
                className='w-100 py-3 d-lg-none'
                shortSwipes
                longSwipes
                freeMode
                modules={[FreeMode, Navigation]}
            >
                <SwiperSlide>
                    <Link href={t('pages.miniGuide')}>
                        <div className='banner-card position-relative'>
                            <Image src='/img/guide/banner-1.webp' />
                            <div className='d-flex flex-column justify-content-between px-4 py-5 overlay text-white'>
                                <div className='d-flex flex-column'>
                                    <span className='inter-regular fs-2 text-center mb-2 text-uppercase'>It's-a-must</span>
                                    <span className='fitzgerald-bold-italic fs-4 text-center'>{t('sectionBanners.miniGuides.subtitle')}</span>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <span className='inter-regular small'>{t('sectionBanners.miniGuides.content.text1')}</span>
                                    <span className='inter-regular small'>{t('sectionBanners.miniGuides.content.text2')}</span>
                                    <span className='inter-regular small'>{t('sectionBanners.miniGuides.content.text3')}</span>
                                    <span className='inter-regular small'>{t('sectionBanners.miniGuides.content.text4')}</span>
                                    <span className='inter-regular small mb-3'>{t('sectionBanners.miniGuides.content.text5')}</span>
                                    <Button className='btn-beige-outline letter-spacing-1 inter-regular py-1 px-4 text-uppercase small fs-6'>{t('sectionBanners.miniGuides.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link href={t('pages.guide')}>
                        <div className='banner-card position-relative'>
                            <Image src='/img/guide/banner-2.webp' />
                            <div className='d-flex flex-column justify-content-between px-4 py-5 overlay text-white'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-column'>
                                        <span className='inter-regular fs-2 text-center text-uppercase mb-0 lh-1'>{t('sectionBanners.guide.title.text1')}</span>
                                        <span className='inter-regular fs-2 text-center mb-2 text-uppercase'>{t('sectionBanners.guide.title.text2')}</span>
                                    </div>
                                    <span className='fitzgerald-bold-italic fs-4 text-center'>{t('sectionBanners.guide.subtitle')}</span>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <span className='inter-regular text-center small'>{t('sectionBanners.guide.content.text1')}</span>
                                    <span className='inter-regular text-center small'>{t('sectionBanners.guide.content.text2')}</span>
                                    <span className='inter-regular text-center small'>{t('sectionBanners.guide.content.text3')}</span>
                                    <span className='inter-regular text-center small mb-3'>{t('sectionBanners.guide.content.text4')}</span>
                                    <Button className='btn-beige-outline letter-spacing-1 inter-regular py-1 px-4 text-uppercase small fs-6'>{t('sectionBanners.guide.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link href={t('pages.explore')}>
                        <div className='banner-card position-relative'>
                            <Image src='/img/guide/banner-3.webp' />
                            <div className='d-flex flex-column justify-content-between px-4 py-5 overlay text-white'>
                                <div className='d-flex flex-column'>
                                    <span className='inter-regular fs-2 text-center mb-2 text-uppercase'>{t('sectionBanners.explore.title')}</span>
                                    <span className='fitzgerald-bold-italic fs-4 text-center'>{t('sectionBanners.explore.subtitle')}</span>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                    <Button className='btn-beige-outline letter-spacing-1 inter-regular py-1 px-4 text-uppercase small fs-6'>{t('sectionBanners.explore.button')}</Button>
                                </div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default WebBanners