import React from 'react'

function BookmarkIcon({ className, onClick, width }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width + 10} viewBox="0 0 26 36" fill="none" className={className} onClick={onClick}>
            <path d="M26 36L12.9929 27.0287L0 36V0H26V36Z" fill="#F3F0DF" />
        </svg>
    )
}

export default BookmarkIcon