import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

const DesktopSwiper = ({ children, className, slidesPerView, spaceBetween, slidesOffsetBefore, slidesOffsetAfter }) => {
    return (
        <>
            <Swiper
                navigation={true}
                modules={[Navigation]}
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView}
                slidesOffsetBefore={slidesOffsetBefore}
                slidesOffsetAfter={slidesOffsetAfter}
                className={className}
                freeMode={true}
            >
                {children}
            </Swiper>
        </>
    );
}

export default DesktopSwiper