import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import MiniGuideService from '../../services/miniGuide.service';
import ContentCards from '../../components/cards/ContentCards';
import SubscribeBanner from '../../components/banners/SubscribeBanner';
import ItemSliderMiniGuide from '../../components/sliders/ItemSliderMiniGuide';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FavouritesContext, MiniGuideContext } from '../../Contexts';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HubspotForm from 'react-hubspot-form'
import Link from '../../components/Link';
import ContentCardPlaceholder from '../../components/cards/ContentCardPlaceholder';
import { AppContext } from '../../AppContext';
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb';
import UserDataService from '../../services/userData.service';
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';
import NewContentCard from '../../components/cards/NewContentCard';
import NewContentCardPlaceholder from '../../components/cards/NewContentCardPlaceholder';
import SubscribeModal from '../../components/modals/SubscribeModal';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';

function NewMiniGuideDetail() {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate()

    const { state: { user } } = useContext(AppContext)
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const [miniGuideDetail, setMiniguideDetail] = useState()
    const [miniGuides, setMiniGuides] = useState();
    const [miniGuidesOffset, setMiniGuideOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true)

    const [show, setShow] = useState(false);
    const [registerModal, setRegisterModal] = useState(false)

    const [swiperInstance, setSwiperInstance] = useState();
    const [beginButton, setBeginButton] = useState(false);
    const [endButton, setEndButton] = useState(false)

    const swiperRef = useRef();

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const newsletterFormLang = {
        es: "df0c8aaf-2e86-45f3-84a9-379e86068ae6",
        en: "c1a32ac7-ba03-4506-88fa-cb758a65742a",
        de: "ee794b73-fb1b-4004-81ce-fc16b51637e4"
    }

    const { state: { categories, banners } } = useContext(AppContext);
    const { miniGuideState: { reviewIndex, miniGuideSlugs, visitedReview }, miniGuideState, miniGuideUpdate } = useContext(MiniGuideContext)
    const { state: { detailSlugs }, state, update } = useContext(AppContext)
    const [activeSlider, setActiveSlider] = useState(false)

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)

    const cancelSwiperHandler = useRef();

    const getMiniGuideDetail = async () => {
        try {
            if (firstLoad) {
                setFirstLoad(false);
            }

            const data = await MiniGuideService.getMiniGuideDetail(params.slug)
            setMiniguideDetail(data)

            if (!visitedReview) {
                update({
                    ...state,
                    detailSlugs: data.slug
                })

                miniGuideUpdate({
                    ...miniGuideState,
                    miniGuideSlugs: data.slug,
                    articles: data?.articles,
                    format: data?.format
                });
            } else {
                update({
                    ...state,
                    detailSlugs: data.slug
                })

                miniGuideUpdate({
                    ...miniGuideState,
                    articles: data?.articles,
                    format: data?.format
                });
            }

            getMiniGuides(data?.id);

        } catch (error) {

        }
    }

    const getMiniGuides = async (miniGuideId) => {
        try {
            let miniGuidesList = !miniGuides ? [] : miniGuides;

            const data = await MiniGuideService.getMiniGuide({
                length: 6,
                dontReadMiniGuideId: miniGuideId,
                offset: miniGuidesOffset
            });

            miniGuidesList = miniGuidesList.concat(data)

            setMiniGuides(miniGuidesList);
            setMiniGuideOffset(miniGuidesOffset + 1)
            setHasMore(data.length < 6 ? false : true)
        } catch (error) {
            console.error(error);
        }
    }

    const getCategoryNameObj = (mainCategory, lang) => {
        const cat = categories.find(cat => cat.slugs[i18n.language].toLowerCase() === mainCategory);
        return cat;
    }

    const handleCustomControls = (swiper) => {
        if (swiper?.isBeginning && !endButton) {
            setBeginButton(true)
        } else if (!swiper?.isBeginning && beginButton) {
            setBeginButton(false)
        }

        if (swiper?.isEnd && !endButton) {
            setEndButton(true)
        } else if (!swiper?.isEnd && endButton) {
            setEndButton(false)
        }
        // swiper?.isBeginning ? setBeginButton(true) : setBeginButton(false)
        // swiper?.isEnd ? setEndButton(true) : setEndButton(false)
    }

    useEffect(() => {
        if (visitedReview && params.slug === miniGuideSlugs?.[i18n.language]) {
            setActiveSlider(true)
        }

        if (visitedReview) {
            miniGuideUpdate({
                ...miniGuideState,
                visitedReview: false
            })
        }
    }, [visitedReview])

    useEffect(() => {

        setMiniGuideOffset(0);
        setMiniguideDetail();
        setMiniGuides();
    }, [location])

    useEffect(() => {
        if (!miniGuideDetail && !miniGuides && !miniGuidesOffset && !firstLoad) {
            getMiniGuideDetail();
        }
    }, [miniGuideDetail, miniGuides, miniGuidesOffset])

    useEffect(() => {
        if (categories.length > 0 && !miniGuideDetail) {
            getMiniGuideDetail();
        }
        UserDataService.visitItsAMust();
    }, [state?.categories])

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 992)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    useEffect(() => {
        if (swiperInstance?.slides?.length > 0 && reviewIndex) {
            swiperInstance?.slideTo(reviewIndex, 500)
        }

        if (swiperInstance?.slides?.length > 0) {
            setBeginButton(swiperInstance?.isBeginning)
            setEndButton(swiperInstance?.isEnd)
        }
    }, [swiperInstance?.slides])

    return (
        <>
            <BreadcrumbPage mobileHide category={getCategoryNameObj(params.category, i18n.language)?.name} title={miniGuideDetail?.title} categoryParam={getCategoryNameObj(params.category, i18n.language)?.slugs[i18n.language]} filter={"miniguide"} />
            {miniGuideDetail && <SeoHelmet title={miniGuideDetail?.title} metaTitle={miniGuideDetail?.title} metaDescription={miniGuideDetail?.description} ogImage={'/img/home-image.jpg'} />}
            <main>
                <section className='miniguide-content bg-dark-brown py-5'>
                    <div className='px-0 px-lg-4 container-lg'>
                        <div className='px-4 px-lg-0 pb-lg-2 mb-2 mb-lg-4 border-lg-bottom border-white title'>
                            <h1 className='fs-1 fitzgerald-regular text-white mb-0 mb-lg-2'>{miniGuideDetail?.title}</h1>
                        </div>
                        {miniGuideDetail?.description && <p className='mb-4 inter-regular text-white d-none d-lg-block'>{miniGuideDetail?.description}</p>}
                        <div className='position-relative d-none d-lg-block'>
                            {miniGuideDetail && <Swiper
                                slidesPerView={4}
                                spaceBetween={16}
                                slidesOffsetBefore={0}
                                slidesOffsetAfter={0}
                                className='w-100 my-3'
                                shortSwipes
                                longSwipes
                                freeMode
                                modules={[FreeMode]}
                                ref={swiperRef}
                                onAfterInit={(swiper) => {
                                    handleCustomControls(swiper)
                                }}
                                onSliderMove={(swiper) => {
                                    handleCustomControls(swiper)
                                }}
                                onSlideChangeTransitionStart={(swiper) => {
                                    handleCustomControls(swiper)
                                }}
                                onSlideChangeTransitionEnd={(swiper) => {
                                    handleCustomControls(swiper)
                                }}
                            >
                                {miniGuideDetail?.articles.map((article, i) => (
                                    <SwiperSlide className='overflow-hidden' key={i}>
                                        <Link key={i} href={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className={"d-flex flex-column flex-grow-1 text-coal h-100 overflow-hidden"}>
                                            <div key={i} className='d-flex flex-column gap-2 slider-card flex-grow-1' onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                miniGuideUpdate({
                                                    ...miniGuideState,
                                                    reviewIndex: !isDesktop ? i + 1 : i
                                                })
                                                navigate(`/${i18n.language}/${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`)
                                            }}>
                                                <div className='overflow-hidden'>
                                                    <Image src={article?.listImageUrl || "/img/no-photo.svg"} className='w-100' />
                                                    {miniGuideDetail?.format === 1 && <div className='ranking text-white fs-2 lh-1 d-flex flex-column justify-content-center align-items-center'>
                                                        <span>
                                                            {miniGuideDetail?.articles?.length - i}
                                                        </span>
                                                    </div>}
                                                </div>
                                                <div className='d-flex flex-column gap-2 flex-grow-1 justify-content-between'>
                                                    <p className='inter-regular text-white fs-5 clamp-1 lh-sm text-center mb-0'>{article?.projectTitle}</p>
                                                    <p className='inter-regular text-white mb-0 clamp-2 text-center mb-3'>{`${article?.titleFirstPart} ${article?.titleSecondPart}`?.trim()}</p>
                                                    <span className={"text-uppercase new-link-white inter-regular letter-spacing-2 mb-2 align-self-center"}>{t('general.components.seeMore')}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>}
                            {!miniGuideDetail && (
                                <Row>
                                    {[1, 2, 3, 4].map(() => (
                                        <Col lg={3} className='slider-card'>
                                            <NewContentCardPlaceholder />
                                        </Col>
                                    ))}
                                </Row>
                            )}
                            {(!beginButton && miniGuideDetail) && <Button variant='none' className='shadow-none swiper-control left border-0' onClick={() => swiperRef.current?.swiper?.slidePrev(325)}><FontAwesomeIcon icon={"fa-sold fa-chevron-left"} className='text-white' width={18} /></Button>}
                            {(!endButton && miniGuideDetail) && <Button variant='none' className='shadow-none swiper-control right border-0' onClick={() => swiperRef.current?.swiper?.slideNext(325)}><FontAwesomeIcon icon={"fa-sold fa-chevron-right"} className='text-white' width={18} /></Button>}
                        </div>
                        {!miniGuideDetail && (
                            <Row className='d-lg-none px-4'>
                                {[1].map(() => (
                                    <Col lg={3} className='slider-card'>
                                        <NewContentCardPlaceholder />
                                    </Col>
                                ))}
                            </Row>
                        )}
                        {miniGuideDetail && <Swiper
                            slidesPerView={1.25}
                            spaceBetween={12}
                            slidesOffsetBefore={24}
                            slidesOffsetAfter={24}
                            className='w-100 my-3 d-lg-none'
                            shortSwipes
                            longSwipes
                            freeMode
                            modules={[FreeMode, Navigation]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            onSliderMove={(swiper) => {
                                handleCustomControls(swiper)
                            }}
                            onSlideChangeTransitionStart={(swiper) => {
                                handleCustomControls(swiper)
                            }}
                            onSlideChangeTransitionEnd={(swiper) => {
                                handleCustomControls(swiper)
                            }}
                        >
                            <SwiperSlide>
                                <div className='h-100 d-flex flex-column justify-content-center p-4 border border-1 border-white text-white'>
                                    <p className='mb-0 inter-regular'>{miniGuideDetail?.description}</p>
                                </div>
                            </SwiperSlide>
                            {miniGuideDetail?.articles.map((article, i) => (
                                <SwiperSlide className='overflow-hidden' key={i}>
                                    <Link key={i} href={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className={"d-flex flex-column flex-grow-1 text-coal h-100 overflow-hidden"}>
                                        <div key={i} className='d-flex flex-column gap-2 slider-card flex-grow-1' onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            miniGuideUpdate({
                                                ...miniGuideState,
                                                reviewIndex: !isDesktop ? i + 1 : i
                                            })
                                            navigate(`/${i18n.language}/${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`)
                                        }}>
                                            <div className='overflow-hidden'>
                                                <Image src={article?.listImageUrl || "/img/no-photo.svg"} className='w-100' />
                                                {miniGuideDetail?.format === 1 && <div className='ranking text-white fs-2 lh-1 d-flex flex-column justify-content-center align-items-center'>
                                                    <span>
                                                        {miniGuideDetail?.articles?.length - i}
                                                    </span>
                                                </div>}
                                            </div>
                                            <div className='d-flex flex-column gap-2 flex-grow-1 justify-content-between'>
                                                <p className='inter-regular text-white fs-5 clamp-3 lh-sm text-center mb-0'>{article?.projectTitle}</p>
                                                <p className='inter-regular text-white mb-0 clamp-2 text-center mb-3'>{`${article?.titleFirstPart} ${article?.titleSecondPart}`?.trim()}</p>
                                                <span className={"text-uppercase new-link-white inter-regular letter-spacing-2 mb-2 align-self-center"}>{t('general.components.seeMore')}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>}
                    </div>
                </section>
                <section className='my-5'>
                    <div className='pb-4 py-lg-5 ps-4 container-lg'>
                        <p className='joane-bolder h5 tzo-title text-brown'>{t('miniGuide.topSelection')}</p>
                        <hr className='opacity-100 mt-2 mb-2 text-brown' />
                    </div>
                    <div className='container'>
                        <Row className='gy-5 gx-4 mb-5'>
                            {!miniGuides ? (
                                [1, 2, 3, 4, 5, 6].map((val, i) =>
                                    <Col key={i} xs={12} lg={4} className='d-flex'>
                                        <NewContentCardPlaceholder />
                                    </Col>
                                )
                            ) : miniGuides?.length > 0 ? (
                                miniGuides.map((data, i) =>
                                    <Col key={i} xs={12} lg={4} className='d-flex'>
                                        <NewContentCard theme={"brown"} image={data?.mainPicture} category={data?.category} title={`${data?.title}`.trim()} slug={`${t('pages.miniGuide')}/${data?.categorySlug}/${data?.slug?.[i18n.language]}`} id={data?.id} type={"MINIGUIDE"} />
                                    </Col>
                                )
                            ) : (
                                <></>
                            )}
                        </Row>
                        {hasMore && <div className='d-flex justify-content-center mt-3'>
                            <Button variant='none' className='btn-brown-outline letter-spacing-1 inter-regular py-3 px-5 align-self-center' onClick={() => getMiniGuides(miniGuideDetail?.id)}>{t("miniGuide.seeMore")}</Button>
                        </div>}
                    </div>
                </section>
                <aside className='bg-laurel py-5 py-lg-3 mb-5'>
                    <div className='container-lg d-flex flex-column flex-lg-row justify-content-between align-items-center'>
                        <span className='fs-lg-2 fs-title fitzgerald-regular text-white lh-sm mb-2 mb-lg-0'>{t('miniGuide.registerBanner.title')}</span>
                        <div className='d-flex flex-column flex-lg-row gap-3 align-items-center'>
                            <span className='inter-regular text-white fs-6 fs-lg-5'>{t('miniGuide.registerBanner.text')}</span>
                            <Button variant='none' className='btn-beige-outline text-uppercase inter-regular letter-spacing-1 py-2 px-4' onClick={() => setRegisterModal(true)}>{t('miniGuide.registerBanner.button')}</Button>
                        </div>
                    </div>
                </aside>
                <aside className='container-lg my-4'>
                    <Link href={t('pages.promos')} noRedirect>
                        <div onClick={() => {
                            user ? navigate(`/${i18n.language}/${t('pages.profile')}/${t('pages.favourites')}`) : favouriteUpdate({ ...favouriteState, favoriteModal: true })
                        }}>
                            <video autoPlay loop muted playsInline className="promotion-banner d-block d-lg-none">
                                <source autoPlay src={banners?.mobile} type={"video/mp4"} />
                            </video>
                            <video autoPlay loop muted playsInline className="promotion-banner d-none d-lg-block">
                                <source autoPlay src={banners?.desktop} type={"video/mp4"} />
                            </video>
                        </div>
                    </Link>
                </aside>
                <SubscribeModal show={registerModal} close={() => setRegisterModal(false)} />
            </main>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{t("miniGuide.suggest.modalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HubspotForm
                        region="eu1"
                        portalId="26078053"
                        formId={newsletterFormLang[i18n.language]}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewMiniGuideDetail