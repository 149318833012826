import api from './api';

const updateUser = (data) => api.post('/user/manage-data', data).then(res => res.data);

const initUserData = (currentLanguage) => {
    let userData = JSON.parse(localStorage.getItem("userData")) ||
    {
        languagePreference: currentLanguage,
        exploreOnBoardingShowed: false,
        guideOnBoardingShowed: false,
        itsAMustOnBoardingShowed: false,
        lastAccessExplore: null,
        lastAccessGuide: null,
        lastAccessItsAMust: null
    };

    localStorage.setItem("userData", JSON.stringify(userData));
};

const isAuthenticated = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    return user ? true : false;
}

const isExploreOnBoardingShowed = () => {
    let user = JSON.parse(localStorage.getItem("userData"));
    return user?.exploreOnBoardingShowed;
}

const isGuideOnBoardingShowed = () => {
    let user = JSON.parse(localStorage.getItem("userData"));
    return user?.guideOnBoardingShowed;
}

const isItsAMustOnBoardingShowed = () => {
    let user = JSON.parse(localStorage.getItem("userData"));
    return user?.itsAMustOnBoardingShowed;
}

const updateUserInLogin = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));

    await saveUserData(userData, true);
}

const saveUserData = async (userData, fromLogin) => {
    if (isAuthenticated()) {
        userData = await updateUser(fromLogin ? { ...userData, fromLogin: true } : userData);
    }

    localStorage.setItem("userData", JSON.stringify(userData));
}

const changeLanguagePreference = async (language) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.languagePreference = language;
    await saveUserData(userData);
}

const setExploreOnBoardingShowed = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.exploreOnBoardingShowed = true;
    await saveUserData(userData);
}

const setGuideOnBoardingShowed = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.guideOnBoardingShowed = true;
    await saveUserData(userData);
}

const setItsAMustOnBoardingShowed = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.itsAMustOnBoardingShowed = true;
    await saveUserData(userData);
}

const visitExplore = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.lastAccessExplore = new Date();
    await saveUserData(userData);
}

const visitItsAMust = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.lastAccessItsAMust = new Date();
    await saveUserData(userData);
}

const visitGuide = async () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.lastAccessGuide = new Date();
    await saveUserData(userData);
}

const getLanguagePreference = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    return userData?.languagePreference;
}

const UserDataService = {
    initUserData,
    setExploreOnBoardingShowed,
    setGuideOnBoardingShowed,
    setItsAMustOnBoardingShowed,
    visitExplore,
    visitItsAMust,
    visitGuide,
    changeLanguagePreference,
    updateUserInLogin,
    isExploreOnBoardingShowed,
    isGuideOnBoardingShowed,
    isItsAMustOnBoardingShowed,
    getLanguagePreference
}

export default UserDataService;