import React from 'react'
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { FavouritesContext } from '../Contexts';
import { useCallback } from 'react';
import { useState } from 'react';
import FavouriteService from '../services/favourites.service';
import { useEffect } from 'react';

function useHandleFavorite() {

    const { state: { user }, state, update } = useContext(AppContext);
    const { favouriteState: { favouritesList, articlesFav, miniGuidesFav, interestSitesFav, favourites, mainFolder, favoriteModal }, favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const [addingFlag, setAddingFlag] = useState(false)
    const [favoriteTypeList, setFavoriteTypeList] = useState();
    const [type, setType] = useState();
    const [favoriteId, setFavoriteId] = useState();
    const [isFavoriteActive, setIsFavoriteActive] = useState();

    const updateFavouriteContext = async () => {
        const favouritesData = await FavouriteService.getFavourites();
        let favouriteIdList = [];

        let articlesList = [];
        let miniGuidesList = [];
        let interestSitesList = [];

        favouritesData.forEach((favourite) => {
            favouriteIdList.push(favourite?.elementId)

            if (favourite?.favouriteType === "ARTICLE") {
                articlesList.push(favourite?.elementId);
            } else if (favourite?.favouriteType === "MINIGUIDE") {
                miniGuidesList.push(favourite?.elementId);
            } else if (favourite?.favouriteType === "INTERESTSITE") {
                interestSitesList.push(favourite?.elementId);
            }

        })

        favouriteUpdate({
            favourites: favouritesData,
            favouritesList: favouriteIdList,
            articlesFav: articlesList,
            miniGuidesFav: miniGuidesList,
            interestSitesFav: interestSitesList
        })
    }

    const handleFavourite = useCallback(async (type, id) => {
        setAddingFlag(true)
        if (type === "MINIGUIDE") {
            try {
                await FavouriteService.createMiniGuideFavourite({
                    value: id,
                    folderIds: [mainFolder?.id]
                })
                setFavoriteId(id);
                setType(type)

                updateFavouriteContext()
                setIsFavoriteActive(isFavorite(type, id));
            } catch (error) {
                console.error(error);
                setAddingFlag(false);
            }
        } else if (type === "ARTICLE") {
            try {
                await FavouriteService.createArticleFavourite({
                    value: id,
                    folderIds: [mainFolder?.id]
                })
                setFavoriteId(id);
                setType(type)

                updateFavouriteContext();
                setIsFavoriteActive(isFavorite(type, id));
            } catch (error) {
                console.error(error);
                setAddingFlag(false);
            }
        } else if (type === "INTERESTSITE") {
            try {
                await FavouriteService.createInterestSiteFavourite({
                    value: id,
                    folderIds: [mainFolder?.id]
                })
                setFavoriteId(id);
                setType(type)

                updateFavouriteContext()
                setIsFavoriteActive(isFavorite(type, id));
            } catch (error) {
                console.error(error);
                setAddingFlag(false);

            }
        }
    })

    const removeFavourite = useCallback(async (type, id) => {
        try {
            const favoriteType = type

            const favouriteDetail = favourites.find(favourite => (favourite?.elementId === (id) && favoriteType === favourite?.favouriteType))

            await FavouriteService.deleteFavorite((favouriteDetail?.id), {
                deleteInOtherFolders: true
            })
            setFavoriteId(id);
            setType(type)

            updateFavouriteContext()
            setIsFavoriteActive(isFavorite(type, id));
            setAddingFlag(false)
        } catch (error) {
            console.error(error);
        }
    })

    const isFavorite = (type, id) => {
        if (type === "ARTICLE") {
            if (articlesFav?.includes(id)) {
                return "active"
            } else {
                return ""
            }
        } else if (type === "MINIGUIDE") {
            if (miniGuidesFav?.includes(id)) {
                return "active"
            } else {
                return ""
            }
        } else if (type === "INTERESTSITE") {
            if (interestSitesFav?.includes(id)) {
                return "active"
            } else {
                return ""
            }
        } else {
            setFavoriteTypeList(favouritesList)
        }
    }

    useEffect(() => {
        setIsFavoriteActive(isFavorite(type, favoriteId));
        setAddingFlag(false);
    }, [articlesFav, miniGuidesFav, interestSitesFav, type, favoriteId])

    return ({
        handleFavourite,
        removeFavourite,
        isFavorite,
        isFavoriteActive,
        setIsFavoriteActive,
        addingFlag,
        setAddingFlag
    })
}

export default useHandleFavorite