const APP_CONF = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    ITEMS_PER_PAGE: 10,
    APPLICATION_ID: 1,
    MAILCHIMP_URLS: {
        es: "https://proadata.us7.list-manage.com/subscribe/post?u=3daeebc0ff3afd4fd8520e3b7&amp;id=e6688cac45",
        en: "https://proadata.us7.list-manage.com/subscribe/post?u=3daeebc0ff3afd4fd8520e3b7&amp;id=359fb84bcc",
        de: "https://proadata.us7.list-manage.com/subscribe/post?u=3daeebc0ff3afd4fd8520e3b7&amp;id=ac132198e1"
    },
    PROMOTION_URLS: {
        es: "https://promociones.proagroup.com/es/sorteo-flem-2023",
        en: "https://promociones.proagroup.com/en/raffle-flem-2023",
        de: "https://promociones.proagroup.com/de/verlosung-flem-2023"
    },
    MAPS_API_KEY: "AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"
};

export default APP_CONF;
