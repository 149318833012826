import React from 'react'

function GuideNav({ theme }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 25 25" className={`guide ${theme}`}>
            <path d="M22.0402 2.55233C20.2852 1.73421 18.5939 1.36934 17.1093 1.33693C14.7909 1.38309 13.1868 2.19385 12.3809 2.69818C11.575 2.19385 9.9326 1.38211 7.6753 1.33595C6.14753 1.33349 4.45169 1.74599 2.72148 2.55233C1.92246 2.92358 1.38086 3.90965 1.38086 5.00425V19.8788C1.38086 20.5987 1.64345 21.2567 2.10235 21.6859C2.55343 22.1049 3.11985 22.2251 3.65801 22.0189C8.19933 20.2344 11.6521 22.9544 11.8431 23.1086C11.9913 23.2181 12.1211 23.3359 12.3465 23.3359C12.5955 23.3359 12.733 23.2194 12.8839 23.1111C13.2956 22.7827 16.6384 20.2796 21.0689 22.0175C21.6079 22.2262 22.1712 22.1034 22.6254 21.683C23.1173 21.2538 23.3809 20.5958 23.3809 19.8788V5.00425C23.3809 3.91014 22.8385 2.92456 22.0402 2.55233Z" className='background' />
            <path d="M22.0402 2.55233C20.2852 1.73421 18.5939 1.36934 17.1093 1.33693C14.7909 1.38309 13.1868 2.19385 12.3809 2.69818C11.575 2.19385 9.9326 1.38211 7.6753 1.33595C6.14753 1.33349 4.45169 1.74599 2.72148 2.55233C1.92246 2.92358 1.38086 3.90965 1.38086 5.00425V19.8788C1.38086 20.5987 1.64345 21.2567 2.10235 21.6859C2.55343 22.1049 3.11985 22.2251 3.65801 22.0189C8.19933 20.2344 11.6521 22.9544 11.8431 23.1086C11.9913 23.2181 12.1211 23.3359 12.3465 23.3359C12.5955 23.3359 12.733 23.2194 12.8839 23.1111C13.2956 22.7827 16.6384 20.2796 21.0689 22.0175C21.6079 22.2262 22.1712 22.1034 22.6254 21.683C23.1173 21.2538 23.3809 20.5958 23.3809 19.8788V5.00425C23.3809 3.91014 22.8385 2.92456 22.0402 2.55233ZM11.7697 21.2783C10.8168 20.6876 9.05756 19.8361 6.78537 19.8361C5.72242 19.8361 4.551 20.0233 3.30242 20.5123C3.13414 20.5783 2.95867 20.5414 2.81773 20.4118C2.68023 20.2815 2.60308 20.0507 2.60308 19.8788V5.00425C2.60308 4.56921 2.81552 4.17778 3.13246 4.03046C4.72898 3.2855 6.26593 2.90492 7.66385 2.90738C9.64232 2.94715 11.0899 3.65675 11.7697 4.06925V21.2783ZM22.1586 19.8788C22.1586 20.0936 22.0816 20.2831 21.945 20.4111C21.8011 20.5439 21.6281 20.5792 21.4603 20.5155C17.5791 18.9949 14.4166 20.4092 12.992 21.2882V4.06925C13.6718 3.65675 15.1194 2.94715 17.0941 2.90738C18.4427 2.90431 20.0098 3.27489 21.6316 4.03095C21.9447 4.17827 22.1586 4.56916 22.1586 5.00425V19.8788Z" className='outline' />
        </svg>
    )
}

export default GuideNav