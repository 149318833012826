import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { usePromiseTracker } from "react-promise-tracker";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import APP_CONF from "./scripts/constants";

import { AppContext } from "./AppContext";
import CookiePolicy from "./pages/cookies/CookiePolicy";
import Dossier from "./pages/dossier/Dossier";
import LegalWarning from "./pages/legal/LegalWarning";
import Main from "./pages/main/Main";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import UserValidation from "./pages/user-validation/UserValidation";
import Profile from "./pages/profile/Profile";
import RecoverPassword from "./pages/recover-password/RecoverPassword";
import Search from "./pages/search/Search";

import './scss/index.scss';
import UpdateEmail from "./pages/update-email/UpdateEmail";
import Sponsor from "./pages/sponsor/Sponsor";
import PromotionModal from "./components/modals/PromotionModal";
import UserDataService from "./services/userData.service";
import Promotions from "./components/promotions/Promotions";
import { FavouritesContext, MiniGuideContext, NavbarContext } from "./Contexts";
import Folder from "./pages/folder/Folder";
import FavoriteLoginModal from "./components/modals/FavoriteLoginModal";
import VillaLanding from "./pages/villas/VillaLanding";
import VillaSearcher from "./pages/villas/VillaSearcher";
import VillaMap from "./pages/villas/VillaMap";
import VillaDetail from "./pages/villas/VillaDetail";
import NewHome from "./pages/home/NewHome";
import NewGuide from "./pages/guide/NewGuide";
import NewCategory from "./pages/category/NewCategory";
import NewMiniGuide from "./pages/mini-guide/NewMiniGuide";
import NewMiniGuideDetail from "./pages/mini-guide-detail/NewMiniGuideDetail";
import NewExplore from "./pages/explore/NewExplore";
import NewInterestSite from "./pages/interest-site/NewInterestSite";
import NewLogin from "./pages/login/NewLogin";
import NewRegister from "./pages/register/NewRegister";
import NewArticle from "./pages/article/NewArticle";

const App = () => {

    const { promiseInProgress } = usePromiseTracker();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { state: { languages, user, currentLanguage }, update, state } = useContext(AppContext);
    const { miniGuideState: { reviewIndex, miniGuideSlugs, visitedReview, articles }, miniGuideState, miniGuideUpdate } = useContext(MiniGuideContext);
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext);
    const { navbarState, navbarUpdate } = useContext(NavbarContext)


    document.documentElement.lang = currentLanguage || "es"

    useEffect(() => {
        UserDataService.initUserData(currentLanguage);
        UserDataService.changeLanguagePreference(currentLanguage);
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage, i18n]);


    useEffect(() => {
        if (miniGuideSlugs && articles) {
            const urlSplit = location?.pathname.split('/');
            const reviewCheck = articles.find(article => article?.slugs?.[i18n.language] === urlSplit[urlSplit.length - 1]) ? true : false;
            const miniGuideCheck = miniGuideSlugs?.[i18n.language] === urlSplit[urlSplit.length - 1];

            if (!reviewCheck && !miniGuideCheck) {
                miniGuideUpdate({
                    ...miniGuideState,
                    reviewIndex: null,
                    visitedReview: null,
                    miniGuideSlugs: null,
                    articles: null
                })
            }
        }

        if (!location.pathname?.includes(t('pages.profile'))) {
            favouriteUpdate({
                ...favouriteState,
                dataFolderPersist: null,
                folderEditPersist: false
            })
        }
    }, [location])

    useEffect(() => {
        if (!user) {
            favouriteUpdate({
                favourites: [],
                favouritesList: [],
                mainFolder: null,
                favoriteModal: false,
                articlesFav: [],
                miniGuidesFav: [],
                interestSitesFav: []
            })
        }
    }, [user])

    useEffect(() => {
        update({
            ...state,
            banners: {
                desktop: `/img/promotion/banner-${i18n.language}.mp4`,
                mobile: `/img/promotion/banner-mobile-${i18n.language}.mp4`,
                card: `/img/promotion/banner-card-${i18n.language}.mp4`,
                desktopLogged: `/img/promotion/banner-logged-${i18n.language}.mp4`,
                mobileLogged: `/img/promotion/banner-logged-mobile-${i18n.language}.mp4`
            }
        })
    }, [i18n.language])

    return (
        <>
            <Routes>
                <Route path={currentLanguage} element={<Main />}>
                    <Route path={t("pages.dossier", { lng: currentLanguage })} element={<Dossier />} />
                    <Route path="" element={<NewHome />} />
                    <Route path={t("pages.cookies", { lng: currentLanguage })} element={<CookiePolicy />} />
                    <Route path={t("pages.privacy", { lng: currentLanguage })} element={<PrivacyPolicy />} />
                    <Route path={t("pages.legal", { lng: currentLanguage })} element={<LegalWarning />} />
                    <Route path={t("pages.guide", { lng: currentLanguage })} element={<NewGuide />} />
                    <Route path={`${t("pages.guide", { lng: currentLanguage })}/${t("pages.search")}/:keyword`} element={<Search />} />
                    <Route path={`${t("pages.guide", { lng: currentLanguage })}/:category`} element={<NewCategory />} />
                    <Route path={`${t("pages.guide", { lng: currentLanguage })}/:category/:slug`} element={<NewArticle />} />
                    {/* <Route path={t("pages.login", { lng: currentLanguage })} element={<Login />} /> */}
                    <Route path={t("pages.login", { lng: currentLanguage })} element={<NewLogin />} />
                    <Route path={`${t("pages.login", { lng: currentLanguage })}/:share`} element={<NewLogin />} />
                    {/* <Route path={t("pages.register", { lng: currentLanguage })} element={<Register />} /> */}
                    <Route path={t("pages.register", { lng: currentLanguage })} element={<NewRegister />} />
                    <Route path={`${t("pages.register", { lng: currentLanguage })}/:share`} element={<NewRegister />} />
                    <Route path={t("pages.validation", { lng: currentLanguage })} element={<UserValidation />} />
                    {user && <Route path={t("pages.profile", { lng: currentLanguage })} element={<Profile />} />}
                    <Route path={t("pages.recoverPassword", { lng: currentLanguage })} element={<RecoverPassword />} />
                    <Route path={t("pages.updateEmail", { lng: currentLanguage })} element={<UpdateEmail />} />
                    {/* <Route path={t("pages.explore", { lng: currentLanguage })} element={<Explore />} /> */}
                    <Route path={t("pages.explore", { lng: currentLanguage })} element={<NewExplore />} />
                    <Route path={`${t("pages.explore", { lng: currentLanguage })}/:category`} element={<NewExplore />} />
                    {/* <Route path={`${t("pages.explore", { lng: currentLanguage })}/:category/:slug`} element={<InterestSite />} /> */}
                    <Route path={`${t("pages.explore", { lng: currentLanguage })}/:category/:slug`} element={<NewInterestSite />} />
                    <Route path={t("pages.miniGuide", { lng: currentLanguage })} element={<NewMiniGuide />} />
                    <Route path={`${t("pages.miniGuide", { lng: currentLanguage })}/:category/:slug`} element={<NewMiniGuideDetail />} />
                    <Route path={`${t("pages.villa", { lng: currentLanguage })}`} element={<VillaLanding />} />
                    <Route path={`${t("pages.villa", { lng: currentLanguage })}/${t('pages.villaSearcher', { lng: currentLanguage })}`} element={<VillaSearcher />} />
                    <Route path={`${t("pages.villa", { lng: currentLanguage })}/${t('pages.villaMap', { lng: currentLanguage })}`} element={<VillaMap />} />
                    <Route path={`${t("pages.villa", { lng: currentLanguage })}/${t('pages.villaDetail', { lng: currentLanguage })}/:id`} element={<VillaDetail />} />
                    <Route path={`${t("pages.promos", { lng: currentLanguage })}`} element={<Sponsor />} />
                    {user && <Route path={`${t("pages.profile")}/${t("pages.favourites", { lng: currentLanguage })}`} element={<Folder />} />}

                </Route>
                <Route path="*" element={<Navigate to={currentLanguage} />} />
            </Routes>
            {promiseInProgress &&
                <div className="spinner-overlay d-flex justify-content-center align-items-center">
                    <Spinner animation="border" className="spinner-center" variant="light" size="xl" role="status" />
                </div>
            }
            <Promotions />
            <FavoriteLoginModal />
        </>
    );
};

export default App;
