import React, { useEffect, useState } from 'react';

const AddThis = (props) => {
    return (
        <>
            <div className={!props.noSpace ? "mb-3" : ""}>
                <div>
                    {props.facebook && <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}`} className="me-2" rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-facebook.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                    {props.twitter && <a target="_blank" href={`https://twitter.com/intent/tweet?text=${props.title}&url=${props.url}`} className="me-2" rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-twitter.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                    {props.pinterest && <a target="_blank" href={``} className="me-2" rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-pinterest.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                    {props.email && <a href={``} className="me-2" rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-email.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                    {props.linkedin && <a target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${props.url}`} className="me-2" rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-link.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                    {props.whatsapp && <a target="_blank" href={`https://api.whatsapp.com/send?text=${props.title + ' ' + props.url}`} className="me-2" rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-whatsapp.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                    {props.instagram && <a target="_blank" href={`https://www.instagram.com/traveltozero/`} rel="nofollow noreferrer" onClick={(e) => { props.stopPropagation && e.stopPropagation() }}>
                        <img src="/img/svg/shared-instagram.svg" alt="" style={{ width: `${props.width}px` }} className={props.white && "invert-color"} />
                    </a>}
                </div>
            </div>
        </>
    );
}

export default AddThis;