import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Button, Col, Container, FormCheck, Image, Modal, ModalHeader, Row, Alert } from 'react-bootstrap'
import StickyBox, { useStickyBox } from 'react-sticky-box';
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb';
import Gallery from '../../components/gallery/Gallery';
import VillasService from '../../services/villas.service';
import AnalyticService from '../../services/analytic.service';
import AuthService from '../../services/auth.service';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import GuestSelect from '../../components/selects/GuestSelect';
import CheckInOutSelect from '../../components/selects/CheckInOutSelect';
import { VillasContext } from '../../Contexts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';
import TzoSheet from '../../components/mobile-sheet/TzoSheet';
import { AppContext } from '../../AppContext';

function VillaDetail() {

	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	let windowOpen = window.open; // Avoid popup blocker

	const { villasState: { filter }, villasState, villasUpdate } = useContext(VillasContext);
	const { state: { categories, showBottomNav }, state, update } = useContext(AppContext);

	const stickyRef = useStickyBox({ offsetTop: 60 })

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: "AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"
	})


	const [villaDetail, setVillaDetail] = useState();
	const [villaBudget, setVillaBudget] = useState();
	const [totalDays, setTotalDays] = useState();
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [errors, setErrors] = useState({
		stay_not_met: false,
		max_guests_exceeded: false,
		property_not_available: false,
		server: false
	});

	const [sheetShow, setSheetShow] = useState();
	const [modalShow, setModalShow] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [directBooking, setDirectBooking] = useState(false);

	const [localFilter, setLocalFilter] = useState()

	const [locationMap, setLoactionMap] = useState(null); // map instance
	const onLoadMap = useCallback(setLoactionMap, [setLoactionMap]);

	const filterTimeout = useRef()

	const containerStyle = {
		width: '100%',
		// height: '100%'
	};

	const getVillaDetail = async () => {
		try {
			const data = await VillasService.getVillaDetail(params?.id)
			setVillaDetail(data)
			if (villaBudget) {
				setVillaBudget();
			}
		} catch (error) {
			console.error(error);
		}
	}

	const getVillaBudget = async () => {
		setErrors({
			stay_not_met: false,
			max_guests_exceeded: false,
			property_not_available: false,
			invalid_dates: false,
			server: false
		})

		try {
			const data = await VillasService.getBudget((villaDetail?.id || params?.id?.split('-')?.[params?.id?.split('-').length - 1]), {
				guests: filter?.totalGuest,
				startDate: handleTimeZone(filter?.startDate),
				endDate: handleTimeZone(filter?.endDate)
			})

			setVillaBudget(data);
			setTotalDays(datediff(filter?.startDate, filter?.endDate))

			if (data?.errorCode) {
				switch (data.errorCode) {
					case "017":
						setErrors({
							stay_not_met: true,
							max_guests_exceeded: false,
							property_not_available: false,
							invalid_dates: false,
							server: false
						});
						break;
					case "016":
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: true,
							property_not_available: false,
							invalid_dates: false,
							server: false
						});
						break;
					case "015":
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: false,
							property_not_available: true,
							invalid_dates: false,
							server: false
						});
						break;
					case "008":
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: false,
							property_not_available: false,
							invalid_dates: true,
							server: false
						});
						break;
					default:
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: false,
							property_not_available: false,
							invalid_dates: false,
							server: true
						});
				}
			}
		} catch (error) {

		}
	}

	const saveAnalyticBudget = async () => {
		try {
			var user = AuthService.getCurrentUser();
			if (user ? true : false) {
				await AnalyticService.saveAnalyticBudget(
					{
						accommodationId: villaDetail?.id,
						guests: filter?.totalGuest,
						dateFrom: handleTimeZone(filter?.startDate),
						dateTo: handleTimeZone(filter?.endDate),
						amount: villaBudget?.amount
					}
				)
			} else {
				await AnalyticService.saveAnonymousAnalyticBudget(
					{
						accommodationId: villaDetail?.id,
						guests: filter?.totalGuest,
						dateFrom: handleTimeZone(filter?.startDate),
						dateTo: handleTimeZone(filter?.endDate),
						amount: villaBudget?.amount
					}
				)
			}

			setModalShow(false)
			// windowOpen().location = villaBudget?.bookingUrl
			window.location.assign(villaBudget?.bookingUrl)
		} catch (error) {
		}
	}

	const handleTimeZone = (date) => {
		const offset = date?.getTimezoneOffset()
		date = new Date(date?.getTime() - (offset * 60 * 1000))
		return date?.toISOString().split('T')[0]
	}

	function datediff(first, second) {
		return Math.round((second - first) / (1000 * 60 * 60 * 24));
	}

	useEffect(() => {
		getVillaDetail();

		if (filter?.startDate && filter?.endDate) {
			getVillaBudget();
		}
	}, [])

	useEffect(() => {
		if (!showFullDescription && villaDetail) {
			window.scrollTo({ top: document.getElementById('description').offsetTop - 80, behavior: 'instant' })
		}
	}, [showFullDescription])

	useEffect(() => {
		if ((!filter?.startDate || !filter?.endDate) || (localFilter?.startDate?.getTime() !== filter?.startDate?.getTime() || localFilter?.endDate?.getTime() !== filter?.endDate?.getTime()) || localFilter?.totalGuest !== filter?.totalGuest) {
			if (filter?.startDate && filter?.endDate && filter?.totalGuest && filter?.totalGuest) {
				filterTimeout.current = setTimeout(() => {
					getVillaBudget();
				}, 1000);
			}
		}

		setLocalFilter({
			...filter
		})

		return () => {
			clearTimeout(filterTimeout.current)
		}
	}, [filter])

	useEffect(() => {
		if (showBottomNav && categories) {
			update({
				...state,
				showBottomNav: false
			})
		}
	}, [state])

	useEffect(() => {
		if (directBooking && villaBudget) {
			setModalShow(true);
		} else {
			setModalShow(false)
		}
	}, [directBooking])

	useEffect(() => {
		if (!modalShow && directBooking) {
			setDirectBooking(false)
		}
	}, [modalShow])

	return (
		<>
			<SeoHelmet title={`${villaDetail?.title} - ${villaDetail?.city}`} metaTitle={villaDetail?.title} metaDescription={villaDetail?.shortDescription} ogImageUrl={villaDetail?.images?.[0]?.imgUrl} ogUrl={window?.origin} siteName={`TraveltoZero - ${t("seo.villas.siteName")}`} />
			<BreadcrumbPage title={villaDetail?.title} />
			<div className='py-4 container-lg px-0 px-lg-4 villa-detail-container'>
				<Row className='g-0 g-lg-4'>
					<Col xs={12} lg={8}>
						{location?.key !== "default" && <div className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-start mb-3 gap-3 gap-lg-0 px-4 px-lg-0'>
							<Button variant='none' className='d-flex flex-row gap-3 align-items-center shadow-none border-0 opacity-50 py-1 px-0' onClick={() => navigate(-1)}>
								<FontAwesomeIcon icon={"fa-solid fa-chevron-left"} width={10} className='text-coal' />
								<span className='inter-medium'>{t('villas.detail.return')}</span>
							</Button>
						</div>}
						<div className='d-flex d-lg-none flex-row justify-content-between mb-4 px-4 px-lg-0'>
							<div className='d-flex flex-row gap-2'>
								<div className='d-flex justify-content-center align-items-center bg-primary align-self-center px-2 py-1 villa-rating'>
									<span className='inter-bold'>{villaDetail?.rating}</span>
								</div>
								<div className='align-self-center'>
									<p className='inter-bold mb-0 lh-sm'>Bien</p>
									<p className='mb-0 lh-sm'>{villaDetail?.rates?.length} {t('villas.detail.reviews')}</p>
								</div>
							</div>
							<div className='d-flex flex-column align-items-center justify-content-center'>
								{(villaBudget && !villaBudget?.error) ? (
									<div className='d-flex flex-column'>
										{!villaBudget && <span className='inter-semi-bold small'>{t('villas.map.prices.from')}</span>}
										<p className='inter-bold h3 mb-0'>{(villaBudget?.amount / totalDays).toFixed(0)}€ <span className='fs-6'>/ {t('villas.map.prices.nights')}</span></p>
										{/* <p className='mb-0 text-end'>/noche</p> */}
									</div>
								) : (
									<div className='d-flex flex-column lh-sm'>
										<span className='inter-semi-bold small'>{t('villas.map.prices.from')}</span>
										<p className='mb-0 text-end h3 inter-bold'>{villaDetail?.baseNightPrice}€ <span className='fs-6 inter-semi-bold'>/ {t('villas.map.prices.nights')}</span> </p>
									</div>
								)}
							</div>
						</div>
						<Gallery images={villaDetail?.images} />
						<div className='villa-budget fixed card-shadow bg-white'>
							<div className='h-100 d-flex d-lg-none flex-row fixed-price py-2 px-4'>
								{!villaBudget?.amount ? (
									<>
										{/* <div className='d-flex flex-column gap-0'>
											<span className='inter-semi-bold small'>{t('villas.detail.budget.from')}</span>
											<div className='inter-bold'>
												<span className='h5'>{villaDetail?.baseNightPrice}€</span> <span className='small'>/ {t('villas.detail.budget.nights')}</span>
											</div>
										</div>
										<div className='vertical-separator mx-3' /> */}
										<Button variant='none' className='d-flex flex-grow-1 btn-dark-green-outline align-self-center justify-content-center text-white shadow-none' onClick={() => setSheetShow(true)}>{t('villas.detail.budget.disponibility')}</Button>
									</>
								) : (
									<>
										<div className='d-flex flex-row gap-2'>
											<div className='d-flex flex-column gap-0'>
												<span className='inter-bold'>{villaBudget?.amount}€</span>
												<span className='inter-semi-bold'>{filter?.startDate?.getDate()} {filter?.startDate?.toLocaleString('default', { month: 'short' })} - {filter?.endDate?.getDate()} {filter?.endDate?.toLocaleString('default', { month: 'short' })}</span>
											</div>
											<FontAwesomeIcon icon={"fa-solid fa-pencil"} height={18} className='text-coal ps-2 pe-3 opacity-75' onClick={() => setSheetShow(true)} />
										</div>
										<div className='vertical-separator' />
										<Button variant='none' className='d-flex flex-grow-1 btn-dark-green-outline align-self-center justify-content-center text-white shadow-none ms-3' onClick={() => !acceptTerms ? setDirectBooking(true) : saveAnalyticBudget()}>{t('villas.detail.budget.button')}</Button>
									</>
								)}
							</div>
						</div>
						<div className='d-none d-lg-block py-4 border-coal-semi-transparent mt-4 d-lg-none border-1 border-top border-bottom mx-4 mx-0'>
							<div className='d-flex flex-row justify-content-between mb-4'>
								<div className='d-flex flex-row gap-2'>
									<div className='d-flex justify-content-center align-items-center bg-primary align-self-center px-2 py-1 villa-rating'>
										<span className='inter-bold'>{villaDetail?.rating}</span>
									</div>
									<div className='align-self-center'>
										<p className='inter-bold mb-0 lh-sm'>Bien</p>
										<p className='mb-0 lh-sm'>{villaDetail?.rates?.length} {t('villas.detail.reviews')}</p>
									</div>
								</div>
								<div className='d-flex flex-column align-items-center justify-content-center'>
									{(villaBudget && !villaBudget?.error) ? (
										<div>

											<p className='inter-bold h3 mb-0'>{villaBudget?.amount}€</p>
											{/* <p className='mb-0 text-end'>/noche</p> */}
										</div>
									) : (
										<div className='d-flex flex-column lh-sm'>
											<span className='inter-semi-bold small'>{t('villas.map.prices.from')}</span>
											<p className='mb-0 text-end h3 inter-bold'>{villaDetail?.baseNightPrice}€ <span className='fs-6 inter-semi-bold'>/ {t('villas.map.prices.nights')}</span> </p>
										</div>
									)}
								</div>
							</div>
							<div>
								<FormCheck id='terms' name='terms' label={t('villas.landing.termsConditions.label')} className='mt-3' onChange={() => setModalShow(true)} />
								<Button disabled={!villaBudget || villaBudget?.error} onClick={() => !acceptTerms ? setDirectBooking(true) : saveAnalyticBudget()} variant='none' className='btn-dark-green-outline inter-medium py-2 text-center text-white text-uppercase mt-4 w-100 booking-btn'>{t('villas.detail.budget.button')}</Button>
								<p className='inter-regular text-center small mt-2 mb-0'>{t('villas.detail.budget.claim')}</p>
							</div>
							{(villaBudget && villaBudget?.error) && <div className='d-flex flex-column gap-1 pb-3 mb-3 border-1 border-bottom border-coal-semi-transparent mt-4'>
								<div className='d-flex flex-row justify-content-between'>
									<Alert variant={"none"} className="inter-semi-bold mt-4 border-0 bg-none text-danger" show={errors.property_not_available} onClose={() => setErrors({ ...errors, property_not_available: false })}>
										{t('villas.detail.budget.error.propertyNotAvailable')}
									</Alert>
									<Alert variant={"danger"} className="inter-semi-bold mt-4 border-0 bg-none text-danger" show={errors.max_guests_exceeded} onClose={() => setErrors({ ...errors, max_guests_exceeded: false })}>
										{t('villas.detail.budget.error.maxGuestsExceeded')}
									</Alert>
									<Alert variant={"none"} className="mt-4 border-0 bg-none text-danger" show={errors.stay_not_met} onClose={() => setErrors({ ...errors, stay_not_met: false })}>
										<p className='mb-0 inter-semi-bold'>{t('villas.detail.budget.error.minStayNotMet.text1')}</p>
										<p className='text-center inter-semi-bold'>{t('villas.detail.budget.error.minStayNotMet.text2')} {(villaBudget.minNights + 1)}</p>
									</Alert>
									<Alert variant={"danger"} className='inter-semi-bold mt-4 border-0 bg-none text-danger' show={errors.invalid_dates} onClose={() => setErrors({ ...errors, invalid_dates: false })}>
										{t('villas.detail.budget.error.invalidDates')}
									</Alert>
									<Alert variant={"danger"} className="inter-semi-bold mt-4 border-0 bg-none text-danger" show={errors.server} onClose={() => setErrors({ ...errors, server: false })}>
										{t("register.errors.server")}
									</Alert>
								</div>
							</div>}
							{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-column gap-1 pb-3 mb-3 border-1 border-bottom border-coal-semi-transparent mt-4'>
								<div className='d-flex flex-row justify-content-between'>
									{(villaBudget && !villaBudget?.error) && (
										<>
											<span className='inter-regular'>{(villaBudget?.amount / totalDays).toFixed(2)}€ X {totalDays} {t('villas.map.prices.nights')}</span>
											<span className='inter-regular'>{villaBudget?.amount}€</span>
										</>
									)}
								</div>
							</div>}
							{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-row justify-content-between'>
								<span className='inter-bold'>{t('villas.detail.budget.total')}</span>
								<span className='inter-bold'>{villaBudget?.amount}€</span>
							</div>}
						</div>
						<div className='mt-4 px-4 px-lg-0'>
							<h1 className='mb-0 inter-bold h4 mb-2'>{villaDetail?.title}</h1>
							<p className='villa-description mb-0'>{villaDetail?.shortDescription}</p>
						</div>
						<div className='my-4 px-4 px-lg-0'>
							<p className='mb-0 inter-medium h4 mb-4'>{t('villas.filter.features')}</p>
							<Row className='d-flex flex-row flex-wrap gy-3'>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src={"/img/svg/guests.svg"} height={18} />
									{villaDetail?.capacity} {t('villas.detail.features.capacity')}
								</Col>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src={"/img/svg/door-closed.svg"} height={18} />
									{villaDetail?.numBedroom} {t('villas.detail.features.bedrooms')}
								</Col>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src={"/img/svg/beds.svg"} height={18} />
									{villaDetail?.totalBed} {t('villas.detail.features.beds')}
								</Col>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src={"/img/svg/baths.svg"} height={18} />
									{villaDetail?.numBathroom} {t('villas.detail.features.baths')}
								</Col>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src={"/img/svg/car-ve.svg"} height={18} />
									{villaDetail?.chargingPoint} {t('villas.detail.features.chargerPoint')}
								</Col>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src={"/img/svg/plot-area.svg"} height={18} />
									{villaDetail?.plotArea}
								</Col>
								<Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<FontAwesomeIcon icon={"fa-solid fa-home"} height={18} className='text-coal' />
									{villaDetail?.houseArea}
								</Col>
							</Row>
						</div>
						<div className='pb-4 border-bottom border-1 border-coal-semi-transparent mx-4 mx-lg-0' id='description'>
							<p className='mb-4 inter-medium h4 mb-4'>{t('villas.map.villaDetail.about')}</p>
							<p className='villa-description mb-1'>{showFullDescription ? villaDetail?.description : villaDetail?.description?.split(/\r\n|\r|\n/)?.[0]}</p>
							<Button variant='none' className='shadow-none border-0 p-0 inter-medium opacity-75' onClick={() => setShowFullDescription(!showFullDescription)}>{!showFullDescription ? `${t('villas.detail.about.more')}` : `${t('villas.detail.about.less')}`}</Button>
						</div>
						<div className='my-4 pb-4 border-bottom border-1 border-coal-semi-transparent mx-4 mx-lg-0'>
							<p className='mb-0 inter-medium h4 mb-4'>{t('villas.detail.services.title')}</p>
							<Row className='d-flex flex-row flex-wrap gy-3'>
								{villaDetail?.privatePool && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/water-ladder.svg' />
									{t('villas.detail.services.pool')}
								</Col>}
								{villaDetail?.airConditioning && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/air-conditioner.svg' />
									{t('villas.detail.services.airConditioning')}
								</Col>}
								{villaDetail?.barbecue && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/grill.svg' />
									{t('villas.detail.services.barbecue')}
								</Col>}
								{villaDetail?.chargingPoint && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/charging-station.svg' />
									{t('villas.detail.services.chargingPoint')}
								</Col>}
								{villaDetail?.dryer && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/dryer.svg' />
									{t('villas.detail.services.dryer')}
								</Col>}
								{(villaDetail?.gasOven || villaDetail?.electricOven) && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/oven.svg' />
									{t('villas.detail.services.oven')}
								</Col>}
								{villaDetail?.television && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/tv.svg' />
									{t('villas.detail.services.television')}
								</Col>}
								{villaDetail?.wifi && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/wifi.svg' />
									{t('villas.detail.services.wifi')}
								</Col>}
								{villaDetail?.washingMachine && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<Image src='/img/svg/villas-equip/washing-machine.svg' />
									{t('villas.detail.services.washingMachine')}
								</Col>}
								{(villaDetail && villaDetail?.pet?.type !== "NOTALLOWED") && <Col xs={6} lg={3} className='d-flex flex-row align-items-center gap-2 inter-regular'>
									<FontAwesomeIcon icon="fa-solid fa-paw" height={19} />
									{t('villas.detail.services.pets')}
								</Col>}
							</Row>
						</div>
						<div className='mb-4 px-4 px-lg-0'>
							<p className='mb-0 inter-medium h4 mb-4'>{t('villas.detail.ubication')}</p>
							{isLoaded && <GoogleMap
								onLoad={onLoadMap}
								mapContainerStyle={containerStyle}
								mapContainerClassName='villa-location'
								center={(villaDetail?.latitude && villaDetail?.longitude) ? { lat: parseFloat(villaDetail?.latitude), lng: parseFloat(villaDetail?.longitude) } : { lat: 39.620349, lng: 2.905260 }}
								zoom={16}
								options={{
									clickableIcons: false,
									disableDefaultUI: true,
									fullscreenControl: true,
									zoomControl: true,
									styles: [
										{
											"featureType": "poi.business",
											"stylers": [
												{
													"visibility": "off"
												}
											]
										},
										{
											"featureType": "poi.school",
											"stylers": [
												{
													"visibility": "off"
												}
											]
										},
										{
											"featureType": "poi.sports_complex",
											"stylers": [
												{
													"visibility": "off"
												}
											]
										},
									]
								}}
							>
								{(villaDetail?.latitude && villaDetail?.longitude) && (
									<Marker
										position={{ lat: parseFloat(villaDetail?.latitude), lng: parseFloat(villaDetail?.longitude) }}
									/>
								)}
							</GoogleMap>}
						</div>
						<div className='px-4 px-lg-0'>
							<p className='mb-0 inter-medium h4 mb-4'>{t('villas.detail.distances')}</p>

							<Row className='gy-3'>
								{villaDetail?.distances.map((distance, i) => (
									<Col lg={3} className='d-flex flex-row gap-2' key={i}>
										<span className='inter-regular opacity-75'>{distance?.distance} {distance?.unit}</span>
										<span className='inter-semi-bold'>{distance?.category?.text}</span>
									</Col>
								))}
							</Row>
						</div>
						<div className='d-lg-none px-4 px-lg-0'>
							<p className='joane-bold h5 mt-4 mb-3 text-center'>{t('villas.detail.warranty')}</p>
							<div className='d-flex flex-row justify-content-center gap-3'>
								<div className='border border-1 border-coal-semi-transparent p-1'>
									<div className='d-flex flex-column align-items-center justify-content-center px-2 py-2 text-white etv'>
										<span className='inter-medium h1 mb-0'>ETV</span>
										<span className='inter-regular small'>NÚM: 558</span>
									</div>
								</div>
								<Image src='/img/svg/logo-vacalia.svg' className='vacalia-logo' />
								{/* <div className='border border-1 border-coal bg-secondary p-1 radius'>
									<div className='d-flex flex-column align-items-center justify-content-center radius text-coal tzo-guaranty'>
										<div className='position-relative'>
											<span className='joane-bolder h2 mb-0'>TZO</span>
											<img className="position-absolute home-title-icon-te" src="/img/icons/wheel-icon.svg" alt="iconText" />
										</div>
										<div className='d-flex flex-row align-items-center gap-1'>
											<span className='inter-medium small text-coal'>NÚM: </span>
											<span className='text-primary'>000</span>
										</div>
									</div>
								</div> */}
							</div>
							<div className='mt-3'>
								<p className='text-center inter-semi-bold mb-0'>{t('villas.detail.warranyPartners')}</p>
							</div>
						</div>
					</Col>
					<Col lg={4} className='d-none d-lg-block sticky-container'>
						<StickyBox offsetTop={84} offsetBottom={24} >
							<div className='card-shadow p-4'>
								<div className='d-flex flex-row justify-content-between mb-4'>
									<div className='d-flex flex-row gap-2'>
										<div className='d-flex justify-content-center align-items-center bg-primary align-self-center px-2 py-1 villa-rating'>
											<span className='inter-bold'>{villaDetail?.rating}</span>
										</div>
										<div className='align-self-center'>
											<p className='inter-bold mb-0 lh-sm'>Bien</p>
											<p className='mb-0 lh-sm'>{villaDetail?.rates?.length} {t('villas.detail.reviews')}</p>
										</div>
									</div>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										{(villaBudget && !villaBudget?.error) ? (
											<>
												<p className='inter-bold h3 mb-0'>{villaBudget?.amount}€</p>
												{/* <p className='mb-0 text-end'>/noche</p> */}
											</>
										) : (
											<div className='d-flex flex-column lh-sm'>
												<span className='inter-semi-bold small'>{t('villas.map.prices.from')}</span>
												<p className='mb-0 text-end h3 inter-bold'>{villaDetail?.baseNightPrice}€ <span className='fs-6 inter-semi-bold'>/ {t('villas.map.prices.nights')}</span> </p>
											</div>)}
									</div>
								</div>
								{!villaBudget && <p className='text-center mb-3'>{t('villas.detail.budget.filterClaim')}</p>}
								<div>
									<CheckInOutSelect
										id={"calendar"}
										className={"border-1 border border-coal-semi-transparent"}
										containerClasssName={"villa-input"}
										blockDates={villaDetail?.blockedDates}
									/>
									<GuestSelect
										id={'guest'}
										className={"border-1 border-start border-end border-bottom border-coal-semi-transparent"}
										containerClasssName={"villa-input"}
										outsideTarget={"guest-container"}
									/>
								</div>
								{!villaBudget && <p className='text-center inter-regular small mt-1 text-dark-green mb-0'>{t('villas.filter.minStay')} <span className='inter-semi-bold'>{villaDetail?.baseNightMin + 1 || ""}</span></p>}
								<div>
									{!villaBudget?.error && <FormCheck id='termsDesktop' name='termsDesktop' label={t('villas.landing.termsConditions.label')} className='mt-3 mb-0 inter-regular  user-select-none' checked={acceptTerms} onChange={() => {
										if (acceptTerms) {
											setAcceptTerms(false)
										} else {
											setAcceptTerms(true)
											setModalShow(true)
										}
									}} />}
									<Button disabled={!villaBudget || villaBudget?.error} onClick={() => !acceptTerms ? setDirectBooking(true) : saveAnalyticBudget()} variant='none' className='btn-dark-green-outline inter-medium py-2 text-center text-white text-uppercase mt-4 w-100 booking-btn'>{t('villas.detail.budget.button')}</Button>
									{!villaBudget?.error && <p className='inter-regular text-center small mt-2 mb-0'>{t('villas.detail.budget.claim')}</p>}
								</div>
								{(villaBudget && villaBudget?.error) && <div className='d-flex flex-column gap-1 mt-4'>
									<div className='d-flex flex-row justify-content-center'>
										<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.property_not_available}>
											{t('villas.detail.budget.error.propertyNotAvailable')}
										</Alert>
										<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.max_guests_exceeded}>
											{t('villas.detail.budget.error.maxGuestsExceeded')}
										</Alert>
										<Alert variant={"none"} className='mb-0 text-danger p-0' show={errors.stay_not_met}>
											<p className='mb-0 inter-semi-bold'>{t('villas.detail.budget.error.minStayNotMet.text1')}</p>
											<p className='text-center inter-semi-bold'>{t('villas.detail.budget.error.minStayNotMet.text2')} {(villaBudget.minNights + 1)}</p>
										</Alert>
										<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.invalid_dates}>
											{t('villas.detail.budget.error.invalidDates')}
										</Alert>
										<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.server}>
											{t("register.errors.server")}
										</Alert>
									</div>
								</div>}
								{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-column gap-1 pb-3 mb-3 mt-4 border-1 border-bottom border-coal-semi-transparent'>
									<div className='d-flex flex-row justify-content-between'>
										{(villaBudget && !villaBudget?.error) && (
											<>
												<span className='inter-regular'>{(villaBudget?.amount / totalDays).toFixed(2)}€ X {totalDays} {t('villas.map.prices.nights')}</span>
												<span className='inter-regular'>{villaBudget?.amount}€</span>
											</>
										)}
									</div>
								</div>}
								{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-row justify-content-between'>
									<span className='inter-bold'>{t('villas.detail.budget.total')}</span>
									<span className='inter-bold'>{villaBudget?.amount}€</span>
								</div>}
							</div>
							<p className='joane-bold h5 mt-4 mb-3 text-center'>{t('villas.detail.warranty')}</p>
							<div className='d-flex flex-row justify-content-center gap-4 mb-3'>
								<div className='border border-1 border-coal-semi-transparent p-1'>
									<div className='d-flex flex-column align-items-center justify-content-center px-2 py-2 text-white etv'>
										<span className='inter-medium h1 mb-0'>ETV</span>
										<span className='inter-regular small'>NÚM: 558</span>
									</div>
								</div>
								<Image src='/img/svg/logo-vacalia.svg' className='homerti-guaranty' />

								{/* <div className='border border-1 border-coal bg-secondary p-1 radius'>
									<div className='d-flex flex-column align-items-center justify-content-center radius text-coal tzo-guaranty'>
										<div className='position-relative'>
											<span className='joane-bolder h2 mb-0'>TZO</span>
											<img className="position-absolute home-title-icon-te" src="/img/icons/wheel-icon.svg" alt="iconText" />
										</div>
										<div className='d-flex flex-row align-items-center gap-1'>
											<span className='inter-medium small text-coal'>NÚM: </span>
											<span className='text-primary'>000</span>
										</div>
									</div>
								</div> */}
							</div>
							<div>
								<p className='text-center inter-regular'>{t('villas.detail.warranyPartners')}</p>
							</div>
						</StickyBox>
					</Col>
				</Row>
			</div>
			<TzoSheet title={t('villas.filter.mobileSearcher')} show={sheetShow} close={() => setSheetShow(false)} stickyHead>
				<div className='d-flex flex-column gap-0 p-4 villa-mobile-filters budget-sheet'>
					{/* <div className='d-flex flex-column'>
                            <p className='mb-2 inter-medium'>Destino</p>
                            <DestinationSelect
                                id={"destination-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                outsideTarget={"destination-container-mobile"}
                                noLabel
                                chevron
                            />
                        </div> */}
					<div className='d-flex flex-row justify-content-between mb-3 border-bottom border-1 border-light-coal pb-3'>
						<div className='d-flex flex-row gap-2'>
							<div className='d-flex justify-content-center align-items-center bg-primary align-self-center px-2 py-1 villa-rating'>
								<span className='inter-bold'>{villaDetail?.rating}</span>
							</div>
							<div className='align-self-center'>
								<p className='inter-bold mb-0 lh-sm'>Bien</p>
								<p className='mb-0 lh-sm'>{villaDetail?.rates?.length} {t('villas.detail.reviews')}</p>
							</div>
						</div>
						<div className='d-flex flex-column align-items-center justify-content-center'>
							{(villaBudget && !villaBudget?.error) ? (
								<div>
									<p className='inter-bold h3 mb-0'>{villaBudget?.amount}€</p>
									{/* <p className='mb-0 text-end'>/noche</p> */}
								</div>
							) : (
								<div className='d-flex flex-column lh-sm'>
									<span className='inter-semi-bold small'>{t('villas.map.prices.from')}</span>
									<p className='mb-0 text-end h3 inter-bold'>{villaDetail?.baseNightPrice}€ <span className='fs-6 inter-semi-bold'>/ {t('villas.map.prices.nights')}</span> </p>
								</div>)}
						</div>
					</div>
					{!villaBudget && <p className='text-center mb-3'>{t('villas.detail.budget.filterClaim')}</p>}
					<div>
						<CheckInOutSelect
							id={"calendar-mobile"}
							className={"border-1 border border-coal-semi-transparent"}
							containerClasssName={"villa-input"}
							blockDates={villaDetail?.blockedDates}
							blockReset
						/>
						<GuestSelect
							id={"guest-mobile"}
							className={"border-1 border-start border-end border-bottom border-coal-semi-transparent"}
							containerClasssName={"villa-input"}
							outsideTarget={"guest-container"}
						/>
					</div>
					{!villaBudget && <p className='text-center inter-regular small mt-1 text-dark-green mb-0'>{t('villas.filter.minStay')} <span className='inter-semi-bold'>{villaDetail?.baseNightMin + 1 || ""}</span></p>}
					{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-column gap-1 pt-4 pb-2 mb-1 border-1 border-bottom border-coal-semi-transparent'>
						<div className='d-flex flex-row justify-content-between'>
							{(villaBudget && !villaBudget?.error) && (
								<>
									<span className='inter-regular'>{(villaBudget?.amount / totalDays).toFixed(2)}€ X {totalDays} {t('villas.map.prices.nights')}</span>
									<span className='inter-regular'>{villaBudget?.amount}€</span>
								</>
							)}
						</div>
					</div>}
					{(villaBudget && !villaBudget?.error) && (
						<FormCheck id='termsMobile' label={t('villas.landing.termsConditions.label')} className='mt-3' checked={acceptTerms} onChange={() => {
							if (acceptTerms) {
								setAcceptTerms(false)
							} else {
								setAcceptTerms(true)
								setModalShow(true)
							}
						}} />
					)}
					<div>
						<Button disabled={!villaBudget || villaBudget?.error} onClick={() => !acceptTerms ? setDirectBooking(true) : saveAnalyticBudget()} variant='none' className='btn-dark-green-outline inter-medium py-2 text-center text-white text-uppercase mt-3 w-100 booking-btn'>{t(!villaBudget ? 'villas.detail.budget.disponibility' : 'villas.detail.budget.button')}</Button>
						{villaBudget && <p className='inter-regular text-center small mt-2 mb-0'>{t('villas.detail.budget.claim')}</p>}
					</div>
					{(villaBudget && villaBudget?.error) && <div className='d-flex flex-column gap-1 mt-4'>
						<div className='d-flex flex-row justify-content-center'>
							<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.property_not_available} onClose={() => setErrors({ ...errors, property_not_available: false })}>
								{t('villas.detail.budget.error.propertyNotAvailable')}
							</Alert>
							<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.max_guests_exceeded} onClose={() => setErrors({ ...errors, max_guests_exceeded: false })}>
								{t('villas.detail.budget.error.maxGuestsExceeded')}
							</Alert>
							<Alert variant={"none"} className='mb-0 text-danger p-0' show={errors.stay_not_met} onClose={() => setErrors({ ...errors, stay_not_met: false })}>
								<p className='mb-0 inter-semi-bold'>{t('villas.detail.budget.error.minStayNotMet.text1')}</p>
								<p className='text-center inter-semi-bold'>{t('villas.detail.budget.error.minStayNotMet.text2')} {(villaBudget.minNights + 1)}</p>
							</Alert>
							<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.invalid_dates} onClose={() => setErrors({ ...errors, invalid_dates: false })}>
								{t('villas.detail.budget.error.invalidDates')}
							</Alert>
							<Alert variant={"none"} className='inter-semi-bold mb-0 text-danger p-0' show={errors.server} onClose={() => setErrors({ ...errors, server: false })}>
								{t("register.errors.server")}
							</Alert>
						</div>
					</div>}
				</div>
			</TzoSheet>
			<Modal show={modalShow} centered size='lg' className='villa-terms-modal'>
				<Modal.Body className='d-flex flex-column p-4 p-lg-5'>
					<FontAwesomeIcon icon={"fa-solid fa-xmark"} className='position-absolute top-0 end-0 pe-3 pt-2 cursor-pointer' height={26} onClick={() => setModalShow(false)} />
					<p className='inter-medium h3 mb-4'>{t('villas.landing.termsConditions.title')}</p>
					<div className='content mb-3'>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block1.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block1.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block1.text2')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block1.text3')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block2.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block2.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block2.text2')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block2.text3')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block3.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block3.text1')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block4.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block4.text1')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block5.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block5.text1')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block5.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block5.text1')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block6.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block6.text1')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block7.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block7.text1')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block8.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block8.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block8.text2')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block9.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block9.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block9.text2')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block9.text3')}</p>
						<p className='inter-semi-bold h5'>{t('villas.landing.termsConditions.block10.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block10.text1')}</p>
					</div>
					<FormCheck id='terms2' name='terms2' label={t('villas.landing.termsConditions.label')} className='inter-semi-bold mb-4' checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} />
					<Button variant='none' className='btn-dark-green-outline px-5 shadow-none text-white text-center align-self-center' onClick={() => directBooking ? saveAnalyticBudget() : setModalShow(false)} disabled={!acceptTerms}>{t('register.rightCard.validation.button')}</Button>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default VillaDetail
