import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import SeoHelmet from "../../components/seo-helmet/SeoHelmet";
import ContactCenterModal from "../../components/modals/ContactCenterModal";
import IconListItem from "../../components/home/IconListItem";
import TitleTextListItem from "../../components/home/TitleTextListItem";
import HubspotForm from 'react-hubspot-form'

const Dossier = () => {
    
    const { t, i18n } = useTranslation();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const projectFormLang = {
        es: "7ba6fbe5-e998-4340-b9e6-58bae50d6091",
        en: "60251280-1612-404f-88f6-150f2182f7bc",
        de: "55e53df7-847a-45fa-8906-a19c51498900"
    }


    return (
        <>
            <SeoHelmet title={t('seo.dossier.title')} metaTitle={t('seo.dossier.metaTitle')} metaDescription={t('seo.dossier.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <Container as="section" fluid={true} className="bg-secondary py-5 px-0 px-sm-5">
                <Row className="m-0">
                    <Col xs={12} md={12}>
                        <div className="d-inline-flex position-relative">
                            <h1>
                                <span className="tt-norms-title pe-1 m-0">TRAVEL </span>
                                <span className="joane-title m-0">SLOW</span>
                            </h1>
                            <img className="position-absolute top-0 start-100 home-header-title-icon" src="/img/icons/wheel-icon.svg" alt="iconText" />
                        </div>
                    </Col>
                    <Col xs={12} md={12}>
                        <h1>
                            <span className="tt-norms-title pe-1 m-0">LIVE</span> <span className="joane-title m-0">SLOW</span>
                        </h1>
                    </Col>
                    <Col xs={12} md={12} className="pt-1 d-flex justify-content-start">
                        <img src="/img/svg/poc-a-poc-sm.svg" id="poc-a-poc" alt="" />
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="px-0">
                <Row className="g-0">
                    <Col xs={12} md={6} >
                        <img className="w-100" src="/img/bg-section-2.png" alt="" />
                    </Col>
                    <Col xs={12} md={6} className="p-5">
                        <h1>
                            <span className="tt-norms-bold">{t('joinUs.title1')}</span>
                            <span className="joane">{t('joinUs.title2')}</span>
                        </h1>
                        <div>
                            <p className='tt-norms fw-light mb-3 mt-3'>{t('joinUs.body1')}</p>
                            <p className='tt-norms fw-light'>{t('joinUs.body2')}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="bg-secondary py-5 px-5">
                <Row>
                    <Col sx={12} md={12} className="bg-secondary">
                        <Row className="justify-content-center">
                            <Col sx={12} md={8}>
                                <div className="d-inline-flex position-relative col-12 col-md-8">
                                    <h1>
                                        <span className="tt-norms-bold pe-1">{t('shareWithYou.title1')} </span>
                                        <span className="joane">{t('shareWithYou.title2')}</span>
                                        <img className="position-absolute home-title-icon" src="/img/icons/petal-icon.svg" alt="iconText" id="petal" />
                                    </h1>
                                </div>
                                <div className="col-12 .page-spacing-l">
                                    <p className="text-uppercase">{t('shareWithYou.body')}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="px-0">
                <Row className="g-0">
                    <Col xs={12} md={6} className="text-end p-5 align-self-center">
                        <h2>
                            <span className="tt-norms-bold m-0 pe-2">{t("positiveMovement.title1")} </span>
                            <span className="joane m-0">{t("positiveMovement.title2")}</span>
                        </h2>
                    </Col>
                    <Col xs={12} md={6}>
                        <img className="w-100" src="/img/landscape-v1.png" alt="landsacpe" />
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="bg-secondary py-5">
                <h1 className="text-start ps-3 ps-sm-5 pb-3 m-0 tt-norms-bold">{t('anotherWayMakeThings.title')}</h1>
                <Row>
                    <Col xs={12} md={6} className="px-5">
                        <IconListItem title={t('anotherWayMakeThings.list.gastronomy.title')} icon="/img/icons/gastronomy-icon.png">
                            {t('anotherWayMakeThings.list.gastronomy.body')}
                        </IconListItem>
                        <IconListItem title={t('anotherWayMakeThings.list.holistic.title')} icon="/img/icons/holistic-icon.png">
                            {t('anotherWayMakeThings.list.holistic.body')}
                        </IconListItem>
                        <IconListItem title={t('anotherWayMakeThings.list.society.title')} icon="/img/icons/society-icon.png">
                            {t('anotherWayMakeThings.list.society.body')}
                        </IconListItem>
                        <IconListItem title={t('anotherWayMakeThings.list.responsibleTourism.title')} icon="/img/icons/tourism-icon.png">
                            {t('anotherWayMakeThings.list.responsibleTourism.body')}
                        </IconListItem>
                    </Col>
                    <Col xs={12} md={6} className="px-5">
                        <IconListItem title={t('anotherWayMakeThings.list.culture.title')} icon="/img/icons/culture-icon.png">
                            {t('anotherWayMakeThings.list.culture.body')}
                        </IconListItem>
                        <IconListItem title={t('anotherWayMakeThings.list.sustainability.title')} icon="/img/icons/sustainability-icon.png">
                            {t('anotherWayMakeThings.list.sustainability.body')}
                        </IconListItem>
                        <IconListItem title={t('anotherWayMakeThings.list.sport.title')} icon="/img/icons/sports-icon.png">
                            {t('anotherWayMakeThings.list.sport.body')}
                        </IconListItem>
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="bg-secondary pb-5 px-sm-5 px-5">
                <Row>
                    <Col sx={12} md={6}>
                        <div className="d-inline-flex position-relative mt-5">
                            <h1>
                                <span className="tt-norms-bold pe-1">{t('iconTextColumns.title1')} </span>
                                <span className="joane">{t('iconTextColumns.title2')}</span>
                                <img className="position-absolute home-title-icon" src="/img/icons/sun-icon.svg" id="sun" alt="iconText" />
                            </h1>
                        </div>
                        <p className="tt-norms fw-light">{t('iconTextColumns.text1')}</p>
                        <p className="tt-norms fw-light">{t('iconTextColumns.text2')}</p>
                        <h5 className="tt-norms-bold">{t('iconTextColumns.hashtag')}</h5>
                        <Button variant="secondary" className="mt-3 tt-norms-bold" onClick={handleShow}>{t('iconTextColumns.button')}</Button>
                    </Col>
                    <Col sx={12} md={6} className="pt-4 tp-sm-0">
                        <img src="/img/image3.png" alt="" className="w-100" />
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="bg-secondary px-5">
                <Row className="py-3 px-sm-5 py-sm-5">
                    <Col sx={12} md={4} className="pt-2 pb-2 text-start text-md-center py-sm-5">
                        <div className="d-inline-flex position-relative">
                            <h1 className="text-start">
                                <span className="tt-norms-bold pe-1">{t("whatDrivesUs.title1")}</span>
                                <br />
                                <span className="joane">{t("whatDrivesUs.title2")}</span>
                                <img className="position-absolute home-title-icon" src="/img/icons/snow-icon.svg" alt="iconText" id="snow" />
                            </h1>
                        </div>
                    </Col>
                    <Col sx={12} md={8}>
                        <Row>
                            <Col sx={12} md={12} className="d-flex flex-row justify-content-between flex-wrap pb-0 pb-md-5"> {/*2 blocks per row*/}
                                <Row>
                                    <TitleTextListItem title={t("whatDrivesUs.list.honor.title")}
                                        text={t("whatDrivesUs.list.honor.text")} />
                                    <TitleTextListItem title={t("whatDrivesUs.list.respect.title")}
                                        text={t("whatDrivesUs.list.respect.text")} />
                                </Row>
                            </Col>
                            <Col sx={12} md={12} className="d-flex flex-row justify-content-between flex-wrap"> {/*2 blocks per row*/}
                                <Row>

                                    <TitleTextListItem title={t("whatDrivesUs.list.optimist.title")}
                                        text={t("whatDrivesUs.list.optimist.text")} />
                                    <TitleTextListItem title={t("whatDrivesUs.list.enjoy.title")}
                                        text={t("whatDrivesUs.list.enjoy.text")} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container as="section" fluid={true} className="bg-secondary pb-5 px-5" id="contact">
                <Row className="justify-content-center">
                    <Col sx={12} md={3} className="d-flex responsive-center-item justify-content-end pe-5">
                        <img src="/img/icons/icon5.png" alt="windmill_image" />
                    </Col>
                    <Col sx={12} md={7} className="d-flex flex-column align-items-center justify-content-end">
                        <h1 className="position-relative">
                            <span className="tt-norms-bold pe-1">{t("fillForm.title1")}</span>
                            <span className="joane">{t("fillForm.title2")}</span>
                        </h1>
                        <Button variant="secondary" className="mt-3 tt-norms-bold" onClick={handleShow}>{t('iconTextColumns.button')}</Button>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{t("iconTextColumns.button")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HubspotForm
                        region="eu1"
                        portalId="26078053"
                        formId={projectFormLang[i18n.language]}
                        onSubmit={() => { }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Dossier;
