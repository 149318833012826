import React from 'react'

function FilterRangeIcon({ width, height, active }) {
    
    return (
        <svg id="filter-range" className={active ? "active" : null} width={width} height={height} viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg"
            // onMouseOver={() => {
            //     document.getElementById('filter-range-circle-1')?.setAttribute("cx", 22.4082);
            //     document.getElementById('filter-range-circle-2')?.setAttribute("cx", 7.4082);
            //     document.getElementById('filter-range-circle-3')?.setAttribute("cx", 22.4082);
            // }}
            // onMouseLeave={() => {
            //     document.getElementById('filter-range-circle-1')?.setAttribute("cx", 7.4082);
            //     document.getElementById('filter-range-circle-2')?.setAttribute("cx", 22.4082);
            //     document.getElementById('filter-range-circle-3')?.setAttribute("cx", 7.4082);
            // }}
        >
            <g clipPath="url(#clip0_9836_12873)">
                <line x1="0.908203" y1="5.01514" x2="28.9082" y2="5.01514" stroke="#5A5C69" />
                <line x1="0.908203" y1="15.0151" x2="28.9082" y2="15.0151" stroke="#5A5C69" />
                <line x1="0.908203" y1="25.0151" x2="28.9082" y2="25.0151" stroke="#5A5C69" />
                <circle id="filter-range-circle-1" cx="7.4082" cy="5.01514" r="3" fill="white" stroke="#5A5C69"></circle>
                <circle id="filter-range-circle-2" cx="22.4082" cy="15.0151" r="3" fill="white" stroke="#5A5C69" />
                <circle id="filter-range-circle-3" cx="7.4082" cy="25.0151" r="3" fill="white" stroke="#5A5C69" />
            </g>
            <defs>
                <clipPath id="clip0_9836_12873">
                    <rect width="28" height="28" fill="white" transform="translate(0.908203 0.515137)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FilterRangeIcon