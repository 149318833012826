import React, { useContext } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { FavouritesContext } from '../../Contexts'
import { useEffect } from 'react'
import { useState } from 'react'
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'
import { useTranslation } from 'react-i18next'
import AuthService from '../../services/auth.service'
import TokenService from '../../services/token.service'
import UserService from '../../services/user.service'
import { AppContext } from '../../AppContext'
import Link from '../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router'

const FavoriteLoginModal = () => {

    const { state, update } = useContext(AppContext)
    const { favouriteState: { favoriteModal }, favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const { t, i18n } = useTranslation();
    const location = useLocation();

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

    const [errors, setErrors] = useState({
        password: false,
        email: false,
        server: false
    });

    const closeModal = () => favouriteUpdate({ ...favouriteState, favoriteModal: false })


    const socialLogin = async (provider, data) => {
        setErrors({
            password: false,
            email: false,
            server: false
        });

        try {
            const token = await AuthService.socialLoginToken({ provider, token: data.access_token || data.accessToken })
            await TokenService.setUser(token)
            const user = await UserService.userInfo();
            update({
                ...state,
                user
            });
            closeModal()
        } catch (error) {
            setErrors({ ...errors, server: true });
        }
    }

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 768)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    useEffect(() => {
        if (favoriteModal) {
            favouriteUpdate({
                ...favouriteState,
                favoriteRedirect: location?.pathname
            })
        } else {
            favouriteUpdate({
                ...favouriteState,
                favoriteRedirect: undefined
            })
        }
    }, [favoriteModal])

    return (
        <Modal centered fullscreen={!isDesktop} show={favoriteModal} onHide={() => closeModal()} size={isDesktop ? "md" : "xl"} >
            <Modal.Body className='bg-coal d-flex favorite-modal position-relative p-0'>
                <video autoPlay loop muted playsInline className="radius">
                    <source autoPlay src={"/videos/favorite-login.mp4"} type={"video/mp4"} />
                </video>
                <FontAwesomeIcon width={13} icon={"fa-solid fa-xmark"} className='position-absolute top-0 end-0 text-white close me-3 mt-3 radius px-1 cursor-pointer' onClick={() => closeModal()} />
                <div className='d-flex flex-column justify-content-between flex-grow-1 px-5 py-4 content'>
                    {/* <div className='text-center d-block mb-4'>
                        <p className='tt-norms-bolder h1 text-white text-uppercase mb-0 text-title'>{t('modals.subscribe.favoriteBanner.header.text1')}</p>
                        <p className='tt-norms-bolder h1 text-muted-coal text-uppercase mb-0 text-title'>{t('modals.subscribe.favoriteBanner.header.text2')}</p>
                        <p className='tt-norms-bolder h1 text-white text-uppercase mb-0 text-title'>{t('modals.subscribe.favoriteBanner.header.text3')}</p>
                        <p className='tt-norms-bolder h1 text-muted-coal text-uppercase mb-0 text-title'>{t('modals.subscribe.favoriteBanner.header.text4')}</p>
                        <p className='tt-norms-bolder h1 text-white text-uppercase mb-0 text-title'>{t('modals.subscribe.favoriteBanner.header.text5')}</p>
                        <p className='tt-norms-bolder h1 text-muted-coal text-uppercase mb-0 text-title'>{t('modals.subscribe.favoriteBanner.header.text6')}</p>
                    </div> */}
                    <div className='mb-4 text-center mt-5'>
                        <p className='tt-norms-bolder h1 text-white mb-4 text-subtitle '>{t('modals.subscribe.favoriteBanner.subHeader.text1')} <span className='joane-bolder text-primary text-subtitle'>{t('modals.subscribe.favoriteBanner.subHeader.text2')}</span></p>
                        <p className='tt-norms-bold text-white'>{t('modals.subscribe.favoriteBanner.subHeader.text3')}</p>
                    </div>
                    <div className='d-flex flex-column align-items-center gap-3 pb-4'>
                        <Link href={`${t('pages.register')}`} className={"tzo bg-white radius"}>
                            <Button variant='none' className="d-flex justify-content-center fw-bolder px-4 shadow-sm modal-btn-size radius gap-2 text-nowrap text-coal" onClick={() => closeModal()}>
                                <span className="tt-norms-bolder opacity-85">{t('modals.subscribe.favoriteBanner.buttons.register') + " >"} </span>
                            </Button>
                        </Link>
                        <LoginSocialGoogle
                            isOnlyGetToken
                            client_id={'319399754923-seppuac5mkupbjv2hninb8tqutiael4i.apps.googleusercontent.com'}
                            scope="profile email"
                            onResolve={({ provider, data }) => {
                                socialLogin(provider, data)
                            }}
                            onReject={(err) => {
                                console.error(err)
                            }}
                            className='social-button radius'
                        >
                            <Button variant='none' className="fw-bolder px-4 shadow-sm modal-btn-size radius d-flex flex-row align-items-center justify-content-start gap-2">
                                <div className='google-logo'></div>
                                <span className="tt-norms-semi-bolder opacity-85">{t('modals.subscribe.social.google')}</span>
                            </Button>
                        </LoginSocialGoogle>
                        <LoginSocialFacebook
                            appId={'827246235309311'}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,short_name,email'
                            }
                            onResolve={({ provider, data }) => {
                                socialLogin(provider, data)
                            }}
                            onReject={(err) => {
                                console.error(err)
                            }}
                            className='social-button radius'
                        >
                            <Button variant='none' className="fw-bolder px-4 shadow-sm modal-btn-size .fac radius d-flex flex-row align-items-center justify-content-start gap-2">
                                <div className='facebook-logo'></div>
                                <span className="tt-norms-semi-bolder text-facebook opacity-85 text-nowrap">{t('modals.subscribe.social.facebook')}</span>
                            </Button>
                        </LoginSocialFacebook>
                        <Link href={`${t('pages.login')}`} className={"tzo radius"}>
                            <Button variant='none' className="d-flex align-items-center fw-bolder px-4 shadow-sm modal-btn-size radius gap-2" onClick={() => closeModal()}>
                                {/* <Image width={22} src={'/img/icons/wheel-icon.svg'} className="me-2" /> */}
                                <FontAwesomeIcon height={26} icon="fa-solid fa-envelope" className='text-coal opacity-85' />
                                <span className="tt-norms-semi-bolder opacity-85">{t('modals.subscribe.social.email')}</span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FavoriteLoginModal