import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";

import CategoryService from "../../services/category.service";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { useRef } from "react";
import FavouriteService from "../../services/favourites.service";
import { FavouritesContext } from "../../Contexts";
import FolderService from "../../services/folder.service";
import MobileNav from "../../components/navigation/MobileNav";
import Topbar from "../../components/Navbar";
import HideOnScroll from "../../components/hide-on-scroll/HideOnScroll";
import AuthService from "../../services/auth.service";
import TokenService from "../../services/token.service";

const Main = () => {

    const { t, i18n } = useTranslation();
    const ref = useRef({
        fetchCategories: false,
        fetchFavourites: false,
        fetchMainFolder: false,
    })

    const { state, update } = useContext(AppContext);
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (AuthService.getCurrentUser()) {
            try {
                TokenService.refreshToken()
            } catch (e) {
                navigate(`/${i18n.language}/${t("pages.login")}`)
            }
        }
    }, [navigate]);

    useEffect(() => {
        update({
            ...state,
            currentLanguage: state?.languages.includes(window.location.pathname.split("/")[1]) ? window.location.pathname.split("/")[1] : "es"
        })
    }, [state?.languages])

    useEffect(() => {
        const getCategories = async () => {
            const categories = await CategoryService.getCategories();
            update({
                ...state,
                categories
            });
        }

        if (state.categories.length === 0 && !ref.current.fetchCategories) {
            ref.current.fetchCategories = true;
            getCategories();
        }

    }, [state.categories.length, t, state, update]);

    useEffect(() => {
        const getFavourites = async () => {
            try {
                const favouritesData = await FavouriteService.getFavourites();

                let favouriteIdList = [];

                let articlesList = [];
                let miniGuidesList = [];
                let interestSitesList = [];

                favouritesData.forEach((favourite) => {
                    favouriteIdList.push(favourite?.elementId)

                    if (favourite?.favouriteType === "ARTICLE") {
                        articlesList.push(favourite?.elementId);
                    } else if (favourite?.favouriteType === "MINIGUIDE") {
                        miniGuidesList.push(favourite?.elementId);
                    } else if (favourite?.favouriteType === "INTERESTSITE") {
                        interestSitesList.push(favourite?.elementId);
                    }

                })

                favouriteUpdate({
                    favourites: favouritesData,
                    favouritesList: favouriteIdList,
                    articlesFav: articlesList,
                    miniGuidesFav: miniGuidesList,
                    interestSitesFav: interestSitesList
                })

            } catch (error) {
                console.error(error);
            }
        }

        if (state?.user) {
            ref.current.fetchFavourites = true
            getFavourites();
        } else {
            favouriteUpdate({
                favourites: [],
                favouritesList: [],
                mainFolder: null
            })
        }
    }, [state?.user])

    useEffect(() => {

        const getFolders = async () => {
            try {
                const foldersData = await FolderService.getDetailedFolders()
                const getMainFolder = foldersData?.find(folder => folder?.main === true)

                favouriteUpdate({
                    ...favouriteState,
                    mainFolder: getMainFolder
                })
            } catch (error) {
                console.error(error);
            }
        }

        if (favouriteState.favourites?.length > 0 && !favouriteState?.mainFolder && !ref.current.fetchMainFolder && state?.user) {
            ref.current.fetchFavourites = true
            getFolders();
        }
    }, [favouriteState.favourites, state?.user])

    useEffect(() => {
        if (state.categories?.length === 0) return

        if (state?.mobileViewport === null) {
            update({
                ...state,
                mobileViewport: window.innerWidth <= 425
            })
        }

        let waitResize;

        const checkResize = () => {
            update({
                ...state,
                mobileViewport: window.innerWidth <= 425
            })
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkResize, 100)
        })

        return () => window.removeEventListener('resize', checkResize)
    }, [state.categories])

    return (
        <>
            <SeoHelmet title={t('seo.default.title')} metaTitle={t('seo.default.metaTitle')} metaDescription={t('seo.default.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <ScrollToTop />
            <HideOnScroll />
            <Topbar />
            <Outlet />
            <Footer />
            <MobileNav theme={"dark"} />
        </>
    );
};

export default Main;
