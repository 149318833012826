import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb';
import Link from '../../components/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RegisterService from '../../services/register.service';

const UserValidation = () => {

    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        invalidToken: false
    })

    useEffect(() => {
        const validateEmail = async () => {
            if (searchParams.get('token')) {
                try {
                    await RegisterService.validateEmail({ token: searchParams.get('token') })
                } catch (error) {
                    setErrors({ ...errors, invalidToken: true })
                }
            } else {
                navigate('/');
            }
        }

        validateEmail();
    }, [searchParams])

    return (
        <>
            <SeoHelmet title={t('seo.login.title')} metaTitle={t('seo.login.metaTitle')} metaDescription={t('seo.login.metaDescription')} ogImage={'/img/home-left-1.png'} robots />
            <BreadcrumbPage section={t('breadcrumbs.login')} />
            <div as={'section'} className='login-container px-0 position-relative d-block'>
                <div className='h-100 w-100 background position-absolute top-50 start-50 translate-middle'>
                    <Image src={`/img/login.jpg`} className='' />
                    {/* <video autoPlay loop muted playsInline>
						<source autoPlay src={`/img/promotion/banner-default.mp4`} type={"video/mp4"} />
					</video> */}
                </div>
                <Container className='container-size form py-5'>
                    <Row>
                        <Col lg={6} xl={7} className='position-relative justify-content-center flex-column d-none d-lg-flex'>
                            <div className='h1 mb-3 text-uppercase title text-white d-flex flex-row'>
                                <p className='tt-norms-bold mb-0'>{t('login.title1')}</p>
                                <p className='joane-bold position-relative mb-0 text-logo d-inline-block'>
                                    <span className='position-relative'>{t('login.title2')}</span>
                                    <Image src='/img/icons/wheel-icon.svg' width={62} className='position-absolute top-0 start-100 translate-logo' />
                                </p>
                            </div>
                            <p className='subtitle joane-bold text-white'>{t('login.subtitle')}</p>
                        </Col>
                        <Col xs={12} lg={6} xl={5}>
                            <div className='p-5 radius form-card d-flex flex-column justify-content-center align-items-center'>
                                {!errors?.invalidToken ? (
                                    <>

                                        <h2 className="h3 tt-norms fw-bolder text-center mt-3 mb-0">{t('register.rightCard.validation.title')}</h2>
                                        <h2 className="h3 tt-norms fw-bolder text-center mb-4">{t('register.rightCard.validation.title2')}</h2>
                                        <p className="mt-5 mb-4 tt-norms-bold">{t('register.rightCard.validation.text')}</p>
                                        <Link href={`${t("pages.login")}`}>
                                            <Button className="btn-coal tt-norms-bold px-5 align-self-center radius" type="submit">{t('register.rightCard.validation.button')}</Button>
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal border border-2 border-coal rounded-circle p-1' width={36} height={36} />
                                        <h2 className="h3 tt-norms fw-bolder text-center mt-3 mb-0">{t('register.rightCard.validation.errors.token')}</h2>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default UserValidation