import { Container, Row, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";

const LegalWarning = () => {

    const { t } = useTranslation();

    return (
        <>
            <SeoHelmet title={t('seo.legal.title')} metaTitle={t('seo.legal.metaTitle')} metaDescription={t('seo.legal.metaDescription')} ogImage={'/img/home-left-1.png'}  />
            <Container className="text-break">
                <Row className="text-center pt-5 pb-3">
                    <h2>
                        <span className="tt-norms-title m-0">{t("legal.title1")} </span>
                        <span className="joane-title m-0">{t("legal.title2")} </span>
                        <span className="tt-norms-title m-0">{t("legal.title3")} </span>
                        <span className="joane-title m-0">{t("legal.title4")}</span>
                    </h2>
                </Row>
                <Row className="pb-3">
                    <p>{t("legal.text1")}</p>
                    <p>{t("legal.text2")}</p>
                </Row>
                <Row>
                    <h4>
                        <b>{t("legal.section1.title")}</b>
                    </h4>
                    <p className="mt-2">{t("legal.section1.text1")}</p>
                    <Card>
                        <Card.Body>
                            <Card.Text className="m-0">{t("legal.section1.card.text1")}</Card.Text>
                            <Card.Text>{t("legal.section1.card.text2")}</Card.Text>
                            <Card.Text className="m-0">{t("legal.section1.card.text3")}</Card.Text>
                            <Card.Text className="m-0">{t("legal.section1.card.text4")}</Card.Text>
                            <Card.Text>{t("legal.section1.card.text5")}</Card.Text>
                            <Card.Text>{t("legal.section1.card.text6")}</Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className="pb-5">
                    <h4 className="mt-4">
                        <b>{t("legal.section2.title")}</b>
                    </h4>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block1.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block1.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block2.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block2.text")}</p>
                    <p className="mb-0 mt-2">{t("legal.section2.block2.list.item1")}</p>
                    <p className="mb-0">{t("legal.section2.block2.list.item2")}</p>
                    <p className="mb-0">{t("legal.section2.block2.list.item3")}</p>
                    <p className="mb-0">{t("legal.section2.block2.list.item4")}</p>
                    <p className="mb-0">{t("legal.section2.block2.list.item5")}</p>
                    <p>{t("legal.section2.block2.list.item6")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block3.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block3.text1")}</p>
                    <p>{t("legal.section2.block3.text2")}</p>
                    <p>{t("legal.section2.block3.text3")}</p>
                    <p>{t("legal.section2.block3.text4")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block4.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block4.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block5.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block5.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block6.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block6.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block7.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block7.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block8.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block8.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block9.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block9.text")}</p>

                    <h6 className="pt-2">
                        <b>{t("legal.section2.block10.title")}</b>
                    </h6>
                    <p className="mt-2">{t("legal.section2.block10.text1")}</p>
                    <p>{t("legal.section2.block10.text2")}</p>
                </Row>
            </Container>
        </>
    )
}

export default LegalWarning