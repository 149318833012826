import React, { useRef } from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import useClickOutsideElement from '../../hooks/useClickOutsideElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DestinationSelect({ className, containerClasssName, outsideTarget, noLabel, chevron, id }) {

    const { handleClickOutside } = useClickOutsideElement();

    const [dropOpen, setDropOpen] = useState(false);
    const [selectData, setSelectData] = useState();
    const timeOutRef = useRef();

    const options = [
        {
            id: 1,
            name: "Mallorca"
        },
        {
            id: 2,
            name: "Menorca"
        },
        {
            id: 3,
            name: "ibiza"
        }
    ]

    useEffect(() => {
        const triggerClick = (event) => {
            handleClickOutside(event, outsideTarget, dropOpen, () => setDropOpen(false))
        }

        if (dropOpen) {
            document.addEventListener("mousedown", triggerClick)
        }

        if (!dropOpen) {
            document.getElementById(id)?.classList?.replace("open", "close")
            timeOutRef.current = setTimeout(() => {
                document.getElementById(id).classList.add("d-none")
            }, 225);
        } else {
            document.getElementById(id)?.classList?.remove("d-none")
            timeOutRef.current = setTimeout(() => {
                document.getElementById(id)?.classList?.replace("close", "open")
            }, 10);
        }

        return () => {
            document.removeEventListener("mousedown", triggerClick)
            clearTimeout(timeOutRef.current)
        };
    }, [dropOpen])

    useEffect(() => {
        if (selectData) {
            setDropOpen(false)
        }
    }, [selectData])

    return (
        <div className={`d-flex flex-grow-1 flex-basis-0 position-relative ${containerClasssName}`} id={outsideTarget}>
            <div className={`d-flex flex-row flex-grow-1 justify-content-between position-relative px-3 py-2 cursor-pointer user-select-none ${className} ${dropOpen ? "open" : ""}`} onClick={() => setDropOpen(!dropOpen)}>
                <div className='d-flex flex-column'>
                    {!noLabel && <span className='tt-norms-semi-bolder mb-0'>Destino</span>}
                    <span className='inter-medium text-coal small'>{selectData?.name || "Santa Cruz de Tenerife"}</span>
                </div>
                {chevron && <FontAwesomeIcon icon={`fa-solid ${dropOpen ? "fa-chevron-up" : "fa-chevron-down"}`} className='text-coal' width={12} />}
            </div>
            <div className={`d-flex flex-lg-column position-absolute top-100 w-100 bg-white d-flex shadow-sm options-container close`} id={id}>
                <ul className=''>
                    {options.map((option, i) =>
                        <li key={i} className='select' onClick={() => setSelectData(option)}>
                            <div className='option'>{option?.name}</div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default DestinationSelect