import { useState, useEffect } from "react";
import { Row, Container, Spinner, Col, Button, Image, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ArticleService from "../../services/articles.service";
import BreadcrumbPage from "../../components/breadcrumb/Breadcrumb";
import { useCallback } from "react";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";
import { useParams } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import UserDataService from "../../services/userData.service";
import ContentCards from "../../components/cards/ContentCards";
import ReactPaginate from "react-paginate";
import Link from "../../components/Link";
import HubspotForm from 'react-hubspot-form'
import ContentCardPlaceholder from "../../components/cards/ContentCardPlaceholder";
import NewContentCard from "../../components/cards/NewContentCard";

const Search = () => {

    const { t, i18n } = useTranslation();
    const params = useParams();
    const { promiseInProgress } = usePromiseTracker();

    const [articles, setArticles] = useState([]);
    const [articlesCount, setArticlesCount] = useState(0);
    const [searchText, setSearchText] = useState(params.keyword)
    const [pagination, setPagination] = useState();
    const [totalArticles, setTotalArticles] = useState();
    const orderQuantity = 12;

    const [currentPagination, setCurrentPagination] = useState(1);
    const [orderType, setOrderType] = useState("desc")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const newsletterFormLang = {
        es: "df0c8aaf-2e86-45f3-84a9-379e86068ae6",
        en: "c1a32ac7-ba03-4506-88fa-cb758a65742a",
        de: "ee794b73-fb1b-4004-81ce-fc16b51637e4"
    }


    const getArticles = useCallback(async () => {
        const articlesData = await ArticleService.getArticlesScroll({ length: orderQuantity, offset: (currentPagination - 1), search: params.keyword });
        setArticles(articlesData?.content);
        setPagination(articlesData?.totalPages);
        setTotalArticles(articlesData?.totalElements)
    }, [articles, articlesCount, currentPagination, orderType, params.keyword])

    const filterAscDesc = () => {
        const sortArticles = articles.slice().reverse();
        setArticles(sortArticles)
    }

    useEffect(() => {
        UserDataService.visitGuide();
    }, [])

    useEffect(() => {
        if (searchText !== params.keyword) {
            setArticles([]);
            setSearchText(params.keyword);
            setArticlesCount(0)
        }

        if (articles?.length === 0) {
            getArticles();
        }
    }, [params.keyword])

    useEffect(() => {
        window.scrollTo({ top: document?.getElementById('articles-container')?.offsetTop - 60, behavior: "smooth" })
        if (articles?.length > 0) {
            getArticles();
        }
        setOrderType("desc")
    }, [currentPagination])

    useEffect(() => {
        filterAscDesc();
    }, [orderType])

    return (
        <>
            <SeoHelmet title={t('seo.search.title')} metaTitle={t('seo.search.metaTitle')} metaDescription={t('seo.search.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <BreadcrumbPage search={true} />
            <Container className="py-4" id="articles-container">
                {promiseInProgress && !articles.length ?
                    <div className="d-flex justify-content-between align-items-center align-items-lg-start alig-items mx-2 px-3 border-bottom border-1 border-coal opacity-50 mb-4 mt-2">
                        <p className="mb-2 h4">{t("guide.search.loading")} <span className="tt-norms-bolder">“{params.keyword}”</span></p>
                        <Image src='/img/svg/sort.svg' height={24} className={`text-coal cursor-pointer sort-ico ${orderType === "asc" ? "asc" : ""}`} onClick={() => setOrderType(orderType === "asc" ? "desc" : "asc")} />
                    </div>
                    : articles.length > 0 ?
                        <div className="d-flex justify-content-between align-items-center align-items-lg-start alig-items mx-2 px-3 border-bottom border-1 border-coal opacity-50 mb-4 mt-2">
                            <p className="mb-2 h4">{totalArticles} {t("guide.search.found")} <span className="tt-norms-bolder">“{params.keyword}”</span></p>
                            <Image src='/img/svg/sort.svg' height={24} className={`text-coal cursor-pointer sort-ico ${orderType === "asc" ? "asc" : ""}`} onClick={() => setOrderType(orderType === "asc" ? "desc" : "asc")} />
                        </div>
                        :
                        <div className="d-flex justify-content-between align-items-center align-items-lg-start alig-items mx-2 px-3 border-bottom border-1 border-coal opacity-50 mb-4 mt-2">
                            <p className="mb-2 h4">{t("guide.search.noFound.text1")} <span className="tt-norms-bolder">“{params.keyword}”</span> {t("guide.search.noFound.text2")}</p>
                        </div>
                }
                {articles.length > 0 && (
                    <>
                        <Row className="m-0 mb-5 g-2 gy-4 gy-lg-5 gx-lg-4 d-lg-flex">
                            {articles?.map((article, i) =>
                                <Col xs={12} lg={4} key={i} className="d-flex">
                                    {/* <Link href={`${t("pages.guide")}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className={"text-coal d-flex flex-grow-1"}> */}
                                        <NewContentCard theme={"laurel"} image={article?.img1Url} project={article?.projectTitle} category={article?.categoryTitle} title={`${article?.titleFirstPart || ""} ${article?.titleSecondPart || ""}`.trim()} stamp={article?.stamp?.image} slug={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={article?.id} data={article} />
                                    {/* </Link> */}
                                </Col>
                            )}
                        </Row>
                        <ReactPaginate
                            pageCount={pagination}
                            onPageChange={(event) => {
                                setCurrentPagination(event.selected + 1)
                            }}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName='pagination gap-1'
                            pageClassName='page'
                            previousClassName='page'
                            previousLabel="<"
                            prevRel={"prev"}
                            nextClassName='page'
                            nextLabel=">"
                            nextRel={"next"}
                            breakClassName='page'
                            className="pagination gap-1 mb-5"
                            forcePage={currentPagination - 1}
                        // hrefBuilder={(page) =>
                        //     `${window.origin}/${i18n.language}/${t('pages.explore')}${categoryList.length === 1 ? `/${getCategory(categoryList?.[0])?.slugs?.[i18n.language]}` : ""}${searchParams && `?page=${page}`}`
                        // }
                        />
                    </>
                )}
                <div className='d-flex flex-column justify-content-center align-items-center radius p-5 mb-4 gap-4 card-shadow'>
                    <p className='mb-4 tt-norms-bolder h2 text-coal text-center'>{t('profile.favourites.folders.must.title')}</p>
                    <Button variant='none' className='btn-coal px-4 px-lg-5 border-0 radius shadow-none text-white' onClick={() => handleShow()}>{t('profile.favourites.folders.must.button')}</Button>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{t("miniGuide.suggest.modalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HubspotForm
                        region="eu1"
                        portalId="26078053"
                        formId={newsletterFormLang[i18n.language]}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Search