import React, { useContext } from 'react'
import { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react';
import { VillasContext } from '../../Contexts';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function VillasFeatures({ id, bedrooms, updateBedrooms, beds, updateBeds, baths, updateBaths }) {
    const { t, i18n } = useTranslation();
    const { villasState: { features }, villasState, villasUpdate } = useContext(VillasContext);

    useEffect(() => {
        
        if (features?.bedrooms === bedrooms && features?.beds === beds && features?.baths === baths) return
                
        villasUpdate({
            features: {
                bedrooms: bedrooms,
                beds: beds,
                baths: baths
            }
        })
    }, [bedrooms, beds, baths])

    return (
        <div className='d-flex flex-column gap-3 villa-fatures'>


            <div className='d-flex flex-row justify-content-between align-items-center'>
                <span className='inter-regular'>{t('villas.detail.features.bedrooms')}</span>
                <div className='d-flex flex-row align-items-center gap-3'>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-0 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => bedrooms > 0 && updateBedrooms(bedrooms - 1)}>
                        <FontAwesomeIcon icon={"fa-solid fa-minus"} width={12} className='text-coal' />
                    </Button>
                    <span className='inter-medium'>{bedrooms}</span>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => updateBedrooms(bedrooms + 1)}>
                        <FontAwesomeIcon icon={"fa-solid fa-plus"} width={12} className='text-coal' />
                    </Button>
                </div>
            </div>


            {/* <div className='d-flex flex-row justify-content-between align-items-center'>
                <span className='tt-norms-bold'>Dormitorios</span>
                <div className='d-flex flex-row align-items-center gap-3'>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-0 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => bedrooms > 0 && handleFeature("bedrooms", bedrooms - 1, setBedrooms)}>
                        <FontAwesomeIcon icon={"fa-solid fa-minus"} width={12} className='text-coal' />
                    </Button>
                    <span className='tt-norms-semi-bolder'>{bedrooms}</span>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => handleFeature("bedrooms", bedrooms + 1, setBedrooms)}>
                        <FontAwesomeIcon icon={"fa-solid fa-plus"} width={12} className='text-coal' />
                    </Button>
                </div>
            </div> */}
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <span className='inter-regular'>{t('villas.detail.features.beds')}</span>
                <div className='d-flex flex-row align-items-center gap-3'>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-0 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => beds > 0 && updateBeds(beds - 1)}>
                        <FontAwesomeIcon icon={"fa-solid fa-minus"} width={12} className='text-coal' />
                    </Button>
                    <span className='inter-medium'>{beds}</span>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => updateBeds(beds + 1)}>
                        <FontAwesomeIcon icon={"fa-solid fa-plus"} width={12} className='text-coal' />
                    </Button>
                </div>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <span className='inter-regular'>{t('villas.detail.features.baths')}</span>
                <div className='d-flex flex-row align-items-center gap-3'>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-0 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => baths > 0 && updateBaths(baths - 1)}>
                        <FontAwesomeIcon icon={"fa-solid fa-minus"} width={12} className='text-coal' />
                    </Button>
                    <span className='inter-medium'>{baths}</span>
                    <Button variant='none' className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => updateBaths(baths + 1)}>
                        <FontAwesomeIcon icon={"fa-solid fa-plus"} width={12} className='text-coal' />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default VillasFeatures