import api from './api';

const getInterestSite = (data) => api.get(`interest-site`, data).then(res => res.data);

const getInterestSiteDetail = (slug) => api.get(`interest-site/${slug}`).then(res => res.data);

const getInterestSiteByProject = (id, data) => api.get(`interest-site/project/${id}`, data).then(res => res.data);


const InterestSiteService = {
    getInterestSite,
    getInterestSiteDetail,
    getInterestSiteByProject
}

export default InterestSiteService;