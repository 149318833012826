import api from './api';

const getArticles = (data) => api.get(`article/last`, data).then(res => res.data);

const getArticlesScroll = (data) => api.get(`article/last`, data).then(res => res.data);

const getArticle = (slug, categoryId) => api.get(`/article/${slug}`, {categoryId}).then(res => res.data);

const getArticleByCategory = (categoryId, length, offset, search, dontReadArticleId) => api.get(`/article/last`, {categoryId, length, offset, search, dontReadArticleId}).then(res => res.data);

const getArticleByTags = (tagsId, length, dontReadArticleId) => api.get(`/article/last`, {tagsId, length, dontReadArticleId}).then(res => res.data);

const getArticleByProject = (id, data) => api.get(`/article/project/${id}`, data).then(res => res.data);

const ArticleService = {
    getArticles,
    getArticle,
    getArticlesScroll,
    getArticleByCategory,
    getArticleByTags,
    getArticleByProject
}

export default ArticleService;