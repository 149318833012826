import React from 'react'

function FavoriteNav({ theme }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 25 25" className={`favorite ${theme}`}>
            <path d="M20.9542 3.54191V22.6354L13.037 19.3394L12.8064 19.2433L12.5758 19.3394L4.65859 22.6354V3.54191C4.65859 3.16104 4.79 2.84152 5.07752 2.55405C5.35543 2.27619 5.69251 2.13516 6.13044 2.13516H19.4823C19.9202 2.13516 20.2573 2.27619 20.5352 2.55405C20.8228 2.84152 20.9542 3.16104 20.9542 3.54191Z" strokeWidth="1.65" />
        </svg>
    )
}

export default FavoriteNav