import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Collapse, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Link from '../Link';

function NavbarDropdown({ icon, section, items, active, close }) {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!close) {
            setOpen(false)
        }
    }, [close])

    return (
        <div>
            <div className={`d-flex flex-column border-1 border-bottom border-transparent-coal tt-norms overflow-hidden navbar-dropdown`.trim()}>
                <div className={`py-3 px-3 header cursor-pointer ${open ? "open" : ""}`.trim()} onClick={() => setOpen(prevState => !prevState)}>
                    <div className={`d-flex flex-row justify-content-between`}>
                        <div className='d-flex flex-row align-items-center gap-2'>
                            {icon && <Image src={icon} height={20} />}
                            <span className='text-nowrap inter-regular letter-spacing-1'>{section}</span>
                        </div>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-down" height={14} className={open ? "flip-icon" : ""} />
                    </div>
                </div>
                <Collapse in={open}>
                    <div className={`border-start border-end border-transparent-coal`}>
                        <div className='d-flex flex-column flex-grow-1 border-1'>
                            {items?.map((item, i) =>
                                <Link key={i} href={item?.url} className='px-4 py-2 cursor-pointer item inter-regular '>{item?.name}</Link>
                            )}
                        </div>
                    </div>
                </Collapse>
            </div>
            {/* <div className={`d-flex flex-column gap-3 border-1 border-start border-end border-transparent-coal px-4 ${open ? "d-flex" : "d-none"}`}> */}
        </div>
    )
}

export default NavbarDropdown