import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useContext } from 'react'
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap'
import InterestSiteSlider from '../../components/item-slider/InterestSiteSlider'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons"
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import InterestSiteService from '../../services/interestSite.service';
import { AppContext } from '../../AppContext';
import ArticleService from '../../services/articles.service';
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';
import useHandleFavorite from '../../hooks/useHandleFavorite';
import { FavouritesContext } from '../../Contexts';
import NewContentCard from '../../components/cards/NewContentCard';
import HubspotForm from 'react-hubspot-form'
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

function NewInterestSite() {
    library.add(fas);

    const { t, i18n } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const { state: { categories, user }, state, update } = useContext(AppContext);
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext)
    const { handleFavourite, removeFavourite, isFavorite, isFavoriteActive, setIsFavoriteActive, addingFlag, setAddingFlag } = useHandleFavorite()

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"
    })

    const maxRelatedSites = 5;
    const [userUbication, setUserUbication] = useState();
    const [mainCategory, setMainCategory] = useState();
    const [map, setMap] = useState(null); // map instance
    const onLoadMap = useCallback(setMap, [setMap]);

    const [site, setSite] = useState();
    const [siteTracker, setSiteTracker] = useState(false);
    const [relatedSites, setRelatedSites] = useState();

    const [getCategoryExplore, setGetCategoryExplore] = useState();

    const [relatedSitesTracker, setRelatedSitesTracker] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const newsletterFormLang = {
        es: "df0c8aaf-2e86-45f3-84a9-379e86068ae6",
        en: "c1a32ac7-ba03-4506-88fa-cb758a65742a",
        de: "ee794b73-fb1b-4004-81ce-fc16b51637e4"
    }

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const getSiteDetail = async () => {
        setSiteTracker(true);
        try {
            const data = await InterestSiteService.getInterestSiteDetail(params.slug)
            update({
                ...state,
                detailSlugs: data.slugs
            });

            setSite(data)
        } catch (error) {
            navigate(`/${i18n.language}/${t('pages.explore')}`);
            console.error(error);
        }
        setSiteTracker(false);
    }

    const getSiteRelated = async () => {
        setRelatedSitesTracker(true)
        try {
            let relatedContent = [];
            const dataArticle = await ArticleService.getArticleByProject(site?.projectId, { offset: 0, length: 2 })

            if (dataArticle.length > 0) {
                dataArticle.forEach(data => {
                    relatedContent.push({ ...data, format: "article" })
                });
            }

            if (relatedContent.length < 4) {
                const data = await InterestSiteService.getInterestSiteByProject(site?.projectId, { offset: 0, length: (maxRelatedSites - relatedContent?.length), dontReadInterestSiteId: site?.id })
                data.forEach(data => {
                    relatedContent.push({ ...data, format: "site" })
                });
            }

            setRelatedSites(relatedContent)
        } catch (error) {
            console.error(error);
        }
        setRelatedSitesTracker(false)
    }

    const getCategoryNameObj = (mainCategory, lang) => {
        const cat = categories.find(cat => cat.slugs[i18n.language].toLowerCase() === mainCategory);
        return cat;
    }

    const getCategoryName = (mainCategory, lang) => {
        const cat = categories.find(cat => cat.slugs[i18n.language].toLowerCase() === mainCategory.toLowerCase());
        return cat;
    }

    useEffect(() => {
        if (state.categories.length > 0 && !site && !siteTracker) {
            getSiteDetail();
        }
    }, [state?.categories, navigate, state, site])

    useEffect(() => {
        if (site) {
            setMainCategory(getCategoryName(site?.mainCategory, i18n.language))
        }
    }, [site])

    useEffect(() => {
        if (params.category && !getCategoryExplore) {
            setGetCategoryExplore(getCategoryNameObj(params.category, i18n.language));
        }

        if (categories.length > 0 && site && !relatedSites && !relatedSitesTracker) {
            getSiteRelated();
        }
    }, [categories, params.category, site])

    useEffect(() => {
        if (site && relatedSites) {
            setSite();
            setRelatedSites();
        }

        if (site && site.slugs[i18n.language] !== params.slug) {
            getSiteDetail();
        }
    }, [params.slug])

    useEffect(() => {
        if (site) {
            setIsFavoriteActive(isFavorite("INTERESTSITE", site?.id));
        }
    }, [site])

    return (
        <>
            {site && <SeoHelmet title={site?.name} metaTitle={site?.name} metaDescription={site?.shortDescription} ogImage={'/img/home-image.jpg'} />}
            <main>
                <article>
                    <div className='text-white bg-laurel py-3 d-flex flex-row'>
                        <Container>
                            <div className="content-card custom-fav shadow-none w-auto d-flex flex-row">
                                <div className={`d-flex flex-row align-items-center gap-3 flex-grow-1 cursor-pointer fav-container px-4 px-lg-0 py-2 py-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                    if (user) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if (isFavoriteActive) {
                                            setAddingFlag(true);
                                            removeFavourite("INTERESTSITE", site?.id);
                                        } else {
                                            setAddingFlag(true);
                                            handleFavourite("INTERESTSITE", site?.id);
                                        }
                                    } else {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                    }
                                }}>
                                    <p className='inter-regular text-white h6 mb-0'>{!isFavoriteActive ? t('articles.favorite.add') : t('articles.favorite.remove')}</p>
                                    <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer w-auto `.trim()} />
                                </div>
                            </div>
                        </Container>
                    </div>
                    {site && <Container fluid className='px-0'>
                        <Row className='g-0'>
                            <Col className='header-review position-relative'>
                                <Image fluid src={site?.images?.[0]?.imgUrl || '/img/no-photo.svg'} className='headerReview' />
                                <div className='title-overlay position-absolute top-50 start-50 translate-middle text-white text-center d-flex flex-column justify-content-center'>
                                    <p className='fitzgerald-bold letter-spacing-1 mb-1 px-4'>{site?.name}</p>
                                    {/* <h2 className='h4 tt-norms-bold'>Kilómetro cero y platos de diez</h2> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>}
                    {site && <Container>
                        <Row className='mt-4 mb-2 mt-md-5 mb-md-4 g-0'>
                            <Col>
                                <div className='pb-2 pb-md-4'>
                                    <h1 className='mb-2 inter-regular'>{site?.name}</h1>
                                </div>
                                <p className='inter-regular mb-2'>{site?.shortDescription}</p>
                            </Col>
                        </Row>
                        <Row className='g-0'>
                            <Col className={`d-none d-md-flex gap-3 align-items-center mb-4`}>
                                <div className='d-none d-lg-flex flex-row gap-2 align-items-center tt-norms'>
                                    <span className='inter-regular h3 mb-0'>{site?.mainCategory}</span>
                                </div>
                            </Col>
                        </Row>
                        {site?.images?.length > 1 &&
                            <Row className='g-0 mb-4 site-slider'>
                                <InterestSiteSlider images={site?.images} />
                            </Row>
                        }
                        <aside>
                            {site?.latitude && site?.longitude && <Row className='site-detail-map mb-4 g-0'>
                                <Col className='bg-light'>

                                    <GoogleMap
                                        onLoad={onLoadMap}
                                        mapContainerStyle={containerStyle}
                                        center={(site?.latitude && site?.longitude) ? { lat: site?.latitude, lng: site?.longitude } : userUbication ? userUbication : { lat: 39.620349, lng: 2.905260 }}
                                        zoom={16}
                                        options={{
                                            clickableIcons: false,
                                            disableDefaultUI: true,
                                            fullscreenControl: true,
                                            zoomControl: true,
                                            styles: [
                                                {
                                                    "featureType": "poi.business",
                                                    "stylers": [
                                                        {
                                                            "visibility": "off"
                                                        }
                                                    ]
                                                },
                                                {
                                                    "featureType": "poi.school",
                                                    "stylers": [
                                                        {
                                                            "visibility": "off"
                                                        }
                                                    ]
                                                },
                                                {
                                                    "featureType": "poi.sports_complex",
                                                    "stylers": [
                                                        {
                                                            "visibility": "off"
                                                        }
                                                    ]
                                                },
                                            ]
                                        }}
                                    >

                                        {userUbication &&
                                            <Button variant='none' className='bg-white p-2 text-dark display-on-map position-absolute rounded-1 border-0 shadow d-flex align-items-center ubication-center' onClick={() => {
                                                map.zoom = 17
                                                map.setCenter(userUbication);
                                            }}>
                                                <FontAwesomeIcon icon="fa-solid fa-location-crosshairs" className='text-dark' width={24} height={24} />
                                            </Button>
                                        }
                                        {(site?.latitude && site?.longitude || userUbication) && <Marker position={(site?.latitude && site?.longitude) ? { lat: site?.latitude, lng: site?.longitude } : userUbication}></Marker>}
                                    </GoogleMap>
                                </Col>
                            </Row>}
                            <Row className='g-0'>
                                <Col xs={12}>
                                    {site?.address &&
                                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${site?.latitude},${site?.longitude}`} target={"_blank"} className="text-dark">
                                            <div className='d-flex flex-row align-items-center cursor-pointer gap-3 mb-4 site-info'>
                                                <span className='border border-2 p-1 site-icon align-items-center site-icon radius'>
                                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" className='text-dark icon align-self-center' />
                                                </span>
                                                <p className='mb-0 inter-medium h6'>{site?.address}</p>
                                            </div>
                                        </a>
                                    }
                                    {site?.webSiteUrl &&
                                        <a href={site?.webSiteUrl} className='mb-0 tt-norms-bold h6 text-dark' target={"_blank"}>
                                            <div className='d-flex flex-row align-items-center cursor-pointer gap-3 mb-4 site-info'>
                                                <span className='border border-2 p-1 site-icon align-items-center site-icon radius'>
                                                    <FontAwesomeIcon icon="fa-solid fa-globe" className='text-dark icon align-self-center' />
                                                </span>
                                                <p className='mb-0 inter-medium h6'>{site?.webSiteUrl.split('/')[2] || site?.webSiteUrl}</p>
                                            </div>
                                        </a>
                                    }
                                    {site?.schedule && <div className='d-flex flex-row align-items-center gap-3 mb-4 site-info text-dark'>
                                        <span className='border border-2 p-1 site-icon align-items-center site-icon radius'>
                                            <FontAwesomeIcon icon="fa-solid fa-clock" className='text-dark icon align-self-center' />
                                        </span>
                                        <p className='mb-0 inter-medium h6'>{site?.schedule}</p>
                                    </div>}
                                </Col>
                                <Col xs={12} className={relatedSites?.length > 0 ? 'mb-0' : 'mb-4'}>
                                    <Row className='justify-content-around tt-norms-bold flex-column flex-md-row'>
                                        {site?.phone && <Col md={4} lg={2} className="text-center"><a href={`tel:${site?.phone}`}><Button className='btn-dark-brown-outline mb-4 mb-lg-0 w-100 h-100'>{t('interestSite.buttons.call')}</Button></a></Col>}
                                        {(site?.latitude && site?.longitude) && <Col md={4} lg={2} className="text-center"><a href={`https://www.google.com/maps/dir/?api=1&destination=${site?.latitude},${site?.longitude}`} target="_blank"><Button className='btn-dark-brown-outline mb-4 mb-lg-0 w-100 h-100'>{t('interestSite.buttons.arrive')}</Button></a></Col>}
                                        {site?.bookingUrl && <Col md={4} lg={2} className="text-center"><a href={site?.bookingUrl} target="_blank"><Button className='btn-dark-brown-outline mb-4 mb-lg-0 w-100 h-100'>{t('interestSite.buttons.reserve')}</Button></a></Col>}
                                        {/* {site?.bookingUrl && <Col md={2} className="text-center"><Button variant='dark' className='btn-dark-ligth-outline px-4 py-2 border-1 mb-4 mb-lg-0 w-100 h-100'>{t('interestSite.buttons.article')}</Button></Col>} */}
                                        {/* {site?.bookingUrl && <Col md={2} className="text-center"><Button variant='dark' className='btn-dark-ligth-outline px-4 py-2 border-1 mb-4 mb-lg-0 w-100 h-100'>{t('interestSite.buttons.review')}</Button></Col>} */}
                                    </Row>
                                </Col>
                            </Row>
                        </aside>
                    </Container >}
                </article>
                {
                    relatedSites?.length > 0 && <Container as={"section"} className='py-4 px-0 px-lg-4 py-md-5'>
                        <>
                            <h2 className="h2 tzo-title tt-norms-bold my-4 mb-md-5 pb-2 ms-4 ms-lg-0 text-md-start border-bottom border-coal-semi-transparent">{t('interestSite.relatedContent')}</h2>
                            <Row className='g-2 gy-3 g-lg-3 sites-custom-cards d-none d-lg-flex'>
                                {relatedSites && relatedSites.map((site, i) =>
                                    <Col lg={4} key={site?.id}>
                                        <NewContentCard theme={"laurel"} image={site?.img1Url || site?.images?.[0]?.imgUrl} project={site?.projectTitle} category={site?.categorySlug} title={`${(site?.titleFirstPart || "")} ${site?.titleSecondPart || ""}`.trim() || site?.name} stamp={site?.stamp?.image} slug={site.format === "article" ? `${t('pages.guide')}/${site.categorySlug}/${site.slugs[i18n.language]}` : `${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`} type={site.format === "article" ? "ARTICLE" : "INTERESTSITE"} hideLink id={site?.id} data={site} />
                                    </Col>
                                )}
                                <Col lg={4}>
                                    <div className='d-flex flex-column gap-3 new-content-card h-100 flex-grow-1 cursor-pointer' onClick={() => handleShow()}>
                                        <div className='d-flex position-relative'>
                                            <div className='content-picture d-flex flex-column justify-content-center align-items-center bg-dark-brown p-4'>
                                                <span className='inter-regular text-white fs-2 text-center'>{t('miniGuide.suggest.text1')} <b>It's-a-must</b> {t('miniGuide.suggest.text3')}</span>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column justify-content-end gap-3 flex-grow-1'>
                                            <p className='new-link-laurel align-self-center fs-4'>{t('miniGuide.suggest.add')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Swiper
                                breakpoints={{
                                    768: {
                                        slidesOffsetBefore: 24,
                                        slidesOffsetAfter: 24
                                    }
                                }}
                                slidesPerView={1.225}
                                spaceBetween={12}
                                slidesOffsetBefore={window.innerWidth < 768 ? 24 : 0}
                                slidesOffsetAfter={window.innerWidth < 768 ? 24 : 0}
                                className='w-100 py-3 d-lg-none'
                                shortSwipes
                                longSwipes
                                freeMode
                                modules={[FreeMode]}
                            >
                                {relatedSites.map((site, i) => (
                                    <SwiperSlide key={i}>
                                        <NewContentCard theme={"laurel"} image={site?.img1Url || site?.images?.[0]?.imgUrl} project={site?.projectTitle} category={site?.categorySlug} title={`${(site?.titleFirstPart || "")} ${site?.titleSecondPart || ""}`.trim() || site?.name} stamp={site?.stamp?.image} slug={site.format === "article" ? `${t('pages.guide')}/${site.categorySlug}/${site.slugs[i18n.language]}` : `${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`} type={site.format === "article" ? "ARTICLE" : "INTERESTSITE"} hideLink id={site?.id} data={site} />
                                    </SwiperSlide>
                                ))}
                                <SwiperSlide>
                                    <div className='d-flex flex-column gap-3 new-content-card h-100 flex-grow-1 cursor-pointer' onClick={() => handleShow()}>
                                        <div className='d-flex position-relative'>
                                            <div className='content-picture d-flex flex-column justify-content-center align-items-center bg-dark-brown p-4'>
                                                <span className='inter-regular text-white fs-2 text-center'>{t('miniGuide.suggest.text1')} <b>It's-a-must</b> {t('miniGuide.suggest.text3')}</span>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column justify-content-center gap-3 flex-grow-1'>
                                            <p className='new-link-laurel align-self-center fs-4'>{t('miniGuide.suggest.add')}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </>
                    </Container>
                }
                <Modal show={show} onHide={handleClose} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("miniGuide.suggest.modalTitle")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <HubspotForm
                            region="eu1"
                            portalId="26078053"
                            formId={newsletterFormLang[i18n.language]}
                        />
                    </Modal.Body>
                </Modal>
            </main>
        </>
    )
}

export default NewInterestSite