import React from 'react'

function TzoNav({ theme }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 32 32" className={`tzo ${theme}`}>
			<circle cx="16.3808" cy="16.3364" r="13.2285" />
			<path d="M19.0348 15.6827L18.8393 15.0315L26.2739 8.69887C27.6379 10.4587 28.5317 12.588 28.7971 14.9021L19.0348 15.6827ZM18.7089 17.7058L19.0348 16.9206L28.8017 17.8213C28.5364 20.0661 27.6705 22.1631 26.2971 23.9414L18.7089 17.7012V17.7058ZM16.9445 18.6204L17.6009 18.5557L24.1976 26.1124C22.3494 27.5904 20.1381 28.4911 17.7965 28.7544L16.9492 18.625L16.9445 18.6204ZM15.1755 18.5557L15.6317 18.6204L14.9055 28.759C12.5592 28.4819 10.4224 27.5442 8.66267 26.1539L15.1755 18.5557ZM13.8674 17.0546L14.1932 17.7105L6.50259 23.983C5.12926 22.2093 4.22612 20.0569 3.96542 17.7197L13.8674 17.0592V17.0546ZM13.8674 15.0315L13.8022 15.7474L3.97008 14.8421C4.24474 12.528 5.16651 10.4171 6.52587 8.66653L13.872 15.0268L13.8674 15.0315ZM15.6317 13.7243L15.0405 13.9183L8.72319 6.46329C10.4783 5.10069 12.5964 4.19538 14.9055 3.91824L15.6271 13.7197L15.6317 13.7243ZM17.7825 3.909C20.1567 4.1769 22.3261 5.11455 24.1045 6.51872L17.5916 13.9183L16.9352 13.5257L17.7779 3.90438L17.7825 3.909ZM26.6091 6.42634C23.9602 3.69191 20.4081 2.15842 16.6047 2.09375C16.5302 2.09375 16.4511 2.09375 16.3766 2.09375C8.63008 2.09375 2.26156 8.33397 2.14052 16.1123C2.01483 23.9645 8.30421 30.4542 16.1578 30.5789C16.2323 30.5789 16.3068 30.5789 16.3859 30.5789C24.1324 30.5789 30.5009 24.3387 30.622 16.5603C30.6825 12.7589 29.258 9.15614 26.6091 6.42634Z" />
		</svg>
	)
}

export default TzoNav