import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import PromotionService from '../../services/promotion.service';
import PromotionModal from '../modals/PromotionModal';
import SectionService from '../../services/section.service';
import { AppContext } from '../../AppContext';
import { useContext } from 'react';
import { PromotionsContext } from '../../Contexts';

const Promotions = () => {

    const location = useLocation();
    const { promotionState, promotionsUpdate } = useContext(PromotionsContext);

    const [sectionId, setSectionId] = useState();
    const [promotionsList, setPromotionsList] = useState();
    const [promotionSession, setPromotionSession] = useState();
    const [promotionDevice, setPromotionDevice] = useState();
    const [promotionUser, setPromotionUser] = useState();
    const [sectionList, setSectionList] = useState();
    const [lastLocation, setLastLocation] = useState();

    const [showModal, setShowModal] = useState()
    const closePromotionModal = () => {

        setShowModal(false)
        setTimeout(() => {
            promotionsUpdate({ ...promotionState, promotionModal: false })
        }, 100);
    }

    useEffect(() => {
        const getPromotions = async () => {
            try {
                let session = [];
                let device = [];
                let user = [];

                const data = await PromotionService.getPromotions(sectionId);
                setPromotionsList(data);

                if (data?.length > 0) {
                    data.forEach((promo, i) => {
                        if (promo?.actionType === "SESSION") {
                            session.push(promo);
                        } else if (promo?.actionType === "DEVICE") {
                            device.push(promo);
                        } else if (promo?.actionType === "USER") {
                            user.push(promo);
                        }
                    })

                    setPromotionSession(session);
                    setPromotionDevice(device);
                    setPromotionUser(user)
                } else {
                    promotionsUpdate({ ...promotionState, promotionModal: false })
                }

            } catch (error) {
                console.error(error);
            }
        }

        if (sectionId) {
            getPromotions();
        }
    }, [sectionId])

    useEffect(() => {
        if (promotionsList?.length > 0) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [promotionsList])

    useEffect(() => {
        const getSections = async () => {
            try {
                const data = await SectionService.getSection();

                setSectionList(data);

                if (!location.pathname.split("/")?.[2]) {
                    setSectionId(data.find(section => section.slug === "")?.id);
                } else {
                    setSectionId(data.find(section => section.slug === location.pathname.split("/")?.[2])?.id);
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (lastLocation !== location?.pathname) {
            getSections();
        }
    }, [location])

    useEffect(() => {
        if (!sessionStorage.getItem("sessionPromotions")) {
            let sectionStorage = [];

            if (sectionList?.length > 0) {
                sectionList?.forEach((section) => {
                    sectionStorage.push({
                        id: section?.id,
                        show: true,
                    })
                })

                sessionStorage.setItem("sessionPromotions", JSON.stringify(sectionStorage));
                sessionStorage.setItem("sessionPromotionsShowed", JSON.stringify([]));
            }
        }

        if (!localStorage.getItem("localPromotionsShowed")) {
            let sectionStorage = [];

            if (sectionList?.length > 0) {
                sectionList?.forEach((section) => {
                    sectionStorage.push({
                        id: section?.id,
                        show: true,
                    })
                })

                localStorage.setItem("localPromotionsShowed", JSON.stringify([]));
            }
        }
    }, [sectionList])

    useEffect(() => {
        if (lastLocation !== location?.pathname) {
            setSectionId();
            setPromotionSession();
            setPromotionDevice();
        }
        setLastLocation(location?.pathname)
    }, [location])

    return (
        <>
            {
                promotionsList?.length > 0 && (
                    <PromotionModal show={showModal} close={closePromotionModal} data={promotionsList} session={promotionSession} device={promotionDevice} userPromos={promotionUser} section={sectionId} />
                )
            }
        </>
    )
}

export default Promotions