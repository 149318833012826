import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Image, Placeholder } from 'react-bootstrap'

function VillaCardPlaceholder({ data }) {
	return (
		<div className='villa-card-placeholder card-shadow radius overflow-hidden'>
			<div className='position-relative'>
				<div className='slider'>
					<Placeholder as={"span"} animation="glow" className="h-100 w-100">
						<Placeholder xs={10} className={`h-100 w-100`} />
					</Placeholder>
				</div>
			</div>
			<div className='d-flex flex-row justify-content-between py-2 px-3 border-bottom border-1 border-coal-semi-transparent'>
				<div className='d-flex flex-row gap-3'>
					<Placeholder as={"span"} animation="glow" className="d-flex align-items-center icon" >
						<Placeholder xs={12} className="h-100 radius" />
					</Placeholder>
					<Placeholder as={"span"} animation="glow" className="d-flex align-items-center icon" >
						<Placeholder xs={12} className="h-100 radius" />
					</Placeholder>
					<Placeholder as={"span"} animation="glow" className="d-flex align-items-center icon" >
						<Placeholder xs={12} className="h-100 radius" />
					</Placeholder>
					<Placeholder as={"span"} animation="glow" className="d-flex align-items-center icon" >
						<Placeholder xs={12} className="h-100 radius" />
					</Placeholder>
				</div>
				<div className='d-flex'>
					<Placeholder as={"span"} animation="glow" className="d-flex align-items-center rate" >
						<Placeholder xs={12} className="h-100 radius" />
					</Placeholder>
				</div>
			</div>
			<div className='d-flex flex-column flex-grow-1 justify-content-center py-3 px-3 gap-2'>
				<div className='d-flex'>
					<Placeholder as={"span"} animation="wave" className="d-flex align-items-center w-100 gap-1" >
						<Placeholder xs={4} className="h-100 radius" /> <Placeholder xs={3} className="h-100 radius" /> <Placeholder xs={4} className="h-100 radius" />
					</Placeholder>
				</div>
				<div className='d-flex'>
					<Placeholder as={"span"} animation="wave" className="d-flex align-items-center w-100 gap-1" >
						<Placeholder xs={6} className="h-100 radius" /> <Placeholder xs={5} className="h-100 radius" />
					</Placeholder>
				</div>
				<div className='d-flex'>
					<Placeholder as={"span"} animation="wave" className="d-flex align-items-center w-100 gap-1" >
						<Placeholder xs={3} className="h-100 radius" /> <Placeholder xs={5} className="h-100 radius" /> <Placeholder xs={3} className="h-100 radius" />
					</Placeholder>
				</div>
			</div>
		</div>
	)
}

export default VillaCardPlaceholder