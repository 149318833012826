import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Form, Image, Modal, NavDropdown, Navbar } from 'react-bootstrap'
import Link from './Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarDropdown from './navbar/NavbarDropdown';
import HubspotForm from 'react-hubspot-form'

function MobileTopbar() {

    const { state: { categories, languages, detailSlugs, user }, state, update } = useContext(AppContext);
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [NavOpen, setNavOpen] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [currentSection, setCurrentSection] = useState();

    const [textSearch, setTextSearch] = useState("");
    const [canSearch, setCanSearch] = useState(false);

    const [contact, setContact] = useState(false)
    const handleShowContact = () => setContact(true);
    const handleCloseContact = () => setContact(false)

    const contactFormLang = {
        es: "226dcf0b-edd0-47b0-ba7e-3804b3bff457",
        en: "f78fc2a9-52b6-46bd-b248-ab14dfd321f2",
        de: "d33c40d5-a454-4160-8551-91da653086de"
    }

    const changeLanguage = (lang) => {
        const routes = t("pages", { returnObjects: true });
        const newRoutes = t("pages", { returnObjects: true, lng: lang });
        const currentPath = location.pathname.split("/");

        let newPath = `${window.origin}/${lang}`;

        if (currentPath[2]) {
            const section = Object.keys(routes).find(key => routes[key] === currentPath[2]);

            newPath += `/${newRoutes[section]}`;

            if (section === "guide" && currentPath[3]) {
                categories.forEach(category => {
                    if (category.slugs[i18n.language] === currentPath[3]) {
                        newPath += `/${category.slugs[lang]}`;
                    }
                });

                if (currentPath[4]) {
                    newPath += `/${detailSlugs[lang]}`;
                }
            }

            if ((section === "explore" || section === "miniGuide") && currentPath[3]) {
                categories.forEach(category => {
                    if (category.slugs[i18n.language] === currentPath[3]) {
                        newPath += `/${category.slugs[lang]}`;
                    }
                });

                if (currentPath[4]) {
                    newPath += `/${detailSlugs?.[lang]}`;
                }
            }
        }

        return newPath;
    }

    const searchArticles = () => {
        let scapedString = textSearch.replace("/", "%2F");
        scapedString = scapedString.replace("#", "%23");

        navigate(`/${i18n.language}/${t('pages.guide')}/${t("pages.search")}/${scapedString}`);
    }

    useEffect(() => {
        const section = location.pathname.split('/')[2]
        setCurrentSection(section)
        setNavOpen(false)
    }, [location])

    useEffect(() => {
        if (NavOpen) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
    }, [NavOpen])

    useEffect(() => {
        const getCategoriesList = () => {
            let list = []

            categories.forEach((category) => {
                list.push({
                    name: category?.name,
                    url: `${t("pages.guide")}/${category.slugs[i18n.language]}`
                })
            })

            return list
        }

        if (categories?.length > 0) {
            setCategoriesList(getCategoriesList())
        }
    }, [categories])

    useEffect(() => {
        let search = textSearch.trim()

        if (search.length >= 4) {
            setCanSearch(true)
        } else {
            setCanSearch(false)
        }

    }, [textSearch])

    return (
        <>
            <Navbar fixed='top' className={`d-block d-lg-none mobile-topbar border-bottom border-white ${!NavOpen ? "bg-laurel" : "bg-beige"}`}>
                <Container className='d-flex flex-row gap-2'>
                    {location?.key !== "default" && <div className='d-flex flex-grow-1 nav-section'>
                        {currentSection && <FontAwesomeIcon icon='fa-solid fa-chevron-left' className={`cursor-pointer fa-sm text-black ${!NavOpen && "invert-color"}`} height={18} onClick={() => {
                            navigate(-1)
                        }} />}
                    </div>}
                    <Link>
                        <Navbar.Brand className='tzo-logo'>
                            <Image src="/img/svg/new-logo.svg" className={NavOpen && "invert-color"} />
                        </Navbar.Brand>
                    </Link>
                    <div className='d-flex align-items-center justify-content-end flex-grow-1 nav-section gap-3'>
                        <Button variant='none' className='d-flex align-items-center p-0 m-0 border-0 shadow-none' onClick={() => setNavOpen(prevState => !prevState)}>
                            {!NavOpen && <FontAwesomeIcon icon="fa-solid fa-bars" height={20} className={`text-black ${!NavOpen && "invert-color"}`} />}
                            {NavOpen && <FontAwesomeIcon icon="fa-solid fa-xmark" width={17.5} height={20} className={`text-black ${!NavOpen && "invert-color"}`} />}
                        </Button>
                    </div>
                </Container>
                <div className={`d-flex flex-column d-lg-none nav-content border-1 border-top ${NavOpen ? "open" : ""}`.trim()}>
                    {/* <div className='d-flex flex-row justify-content-between align-items-center px-4 border-1 border-bottom pb-3'>
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" height={20} className='text-coal opacity-75' />
                        <FontAwesomeIcon icon="fa-solid fa-xmark" height={25} className='text-coal opacity-75' onClick={() => setNavOpen(prevState => !prevState)} />
                    </div> */}
                    <div className='d-flex flex-column flex-grow-1 p-4 overflow-auto bg-beige'>
                        <div className='d-flex flex-row justify-content-between align-items-center pb-4 '>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                canSearch && searchArticles();
                            }} className='flex-grow-1'>
                                <div className='d-flex flex-row gap-1 radius p-1 searcher'>
                                    <Button variant='none' className='d-flex justify-content-center align-items-center shadow-none py-0 px-2 m-0 border-0 '>
                                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" height={20} className={`text-coal ${!canSearch ? "opacity-50" : ""}`.trim()} />
                                    </Button>
                                    <Form.Control placeholder='Buscar' className='border-0 shadow-none ps-0 tt-norms-bold' value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
                                </div>
                            </Form>
                            <NavDropdown title={i18n.language.toUpperCase()} id="lang-mobile-dropdown" className="d-flex align-items-center position-relative ps-3 pe-2 text-coal inter-regular">
                                {languages.map(language =>
                                    <NavDropdown.Item key={language} href={changeLanguage(language)}>
                                        {language.toUpperCase()}
                                    </NavDropdown.Item>)
                                }
                            </NavDropdown>
                        </div>
                        <div className='d-flex flex-column'>
                            <NavbarDropdown key={'guide'} icon={"/img/svg/sections/guide.svg"} section={t('navbar.guide')} active={currentSection === t("pages.guide")} close={NavOpen} items={[{ name: t('navbar.tag.guide'), url: t('pages.guide') }, ...categoriesList]} />
                        </div>
                        <Link href={t('pages.explore')} className={`d-flex flex-column border-1 border-bottom border-transparent-coal tt-norms py-3 px-3  header`}>
                            <div className={`d-flex flex-row  align-items-center gap-2`}>
                                <Image src='/img/svg/sections/explore.svg' height={20} />
                                <span className='text-nowrap inter-regular letter-spacing-1'>{t('navbar.explore')}</span>
                            </div>
                        </Link>
                        <Link href={t('pages.miniGuide')} className={`d-flex flex-column border-1 border-bottom border-transparent-coal tt-norms py-3 px-3 header`}>
                            <div className={`d-flex flex-row  align-items-center gap-2`}>
                                <Image src='/img/svg/sections/must.svg' height={20} />
                                <span className='text-nowrap text-uppercase inter-regular letter-spacing-1'>{t('navbar.must')}</span>
                            </div>
                        </Link>
                        <Link href={t('pages.villa')} className={`d-flex flex-row justify-content-between border-1 border-bottom border-transparent-coal tt-norms py-3 px-3 header`}>
                            <div className={`d-flex flex-row  align-items-center gap-2`}>
                                <Image src='/img/svg/sections/villas.svg' height={20} />
                                <span className='text-nowrap inter-regular letter-spacing-1'>{t('navbar.villas')}</span>
                            </div>
                        </Link>
                        <Link href={user ? `${t('pages.profile')}/${t('pages.favourites')}` : `${t("pages.login")}`} className={`d-flex flex-row justify-content-between border-1 border-bottom border-transparent-coal tt-norms py-3 px-3 header`}>
                            <div className={`d-flex flex-row  align-items-center gap-2`}>
                                <Image src='/img/svg/sections/favourite.svg' height={20} />
                                <span className='text-nowrap inter-regular letter-spacing-1'>{t('navbar.favorite')}</span>
                            </div>
                            <span className="bg-primary tt-norms-bolder radius text-black px-2">NEW!</span>
                        </Link>
                        <Link href={user ? `${t('pages.profile')}` : `${t("pages.login")}`} className={`d-flex flex-column border-1 border-bottom border-transparent-coal tt-norms py-3 px-3 header`}>
                            <div className={`d-flex flex-row gap-2`}>
                                <span className='text-nowrap inter-regular letter-spacing-1'>{user ? t('navbar.profile.profile') : t('navbar.login')}</span>
                            </div>
                        </Link>
                        <Link href={t('pages.dossier')} className={`d-flex flex-column border-1 border-bottom border-transparent-coal tt-norms py-3 px-3 header`}>
                            <div className={`d-flex flex-row gap-2`}>
                                <span className='text-nowrap inter-regular letter-spacing-1'>{t('navbar.about')}</span>
                            </div>
                        </Link>
                        <div onClick={() => handleShowContact()} className={`d-flex flex-column border-1 border-bottom border-transparent-coal tt-norms py-3 px-3 header`}>
                            <div className={`d-flex flex-row gap-2`}>
                                <span className='text-nowrap inter-regular letter-spacing-1'>{t('navbar.contact')}</span>
                            </div>
                        </div>
                        <div className='d-flex flex-column flex-grow-1 text-coal justify-content-center align-items-center mt-5'>
                            <p className='mb-0 h5 inter-bold mb-2'>{t('footer.app.title')}</p>
                            <p className='mb-0 inter-regular'>{t('footer.app.text1')}</p>
                            <p className='mb-0 inter-regular'>{t('footer.app.text2')}</p>
                            <div className='d-flex flex-column justify-content-center align-items-center gap-3 mt-4 w-100'>
                                <a href='https://apps.apple.com/es/app/travel-to-zero/id6449782268' target='_blank' className='flex-grow-1'><img alt='Disponible en Google Play' src={`/img/mobile-app/apple-store-badge-${i18n.language}.svg`} className='store-button' /></a>
                                <a href='https://play.google.com/store/apps/details?id=com.proadata.traveltozero' target='_blank' className='flex-grow-1'><img alt='Disponible en Google Play' src={`/img/mobile-app/google-play-badge-${i18n.language}.svg`} className='store-button' /></a>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-2 mt-5'>
                            <div className='d-flex flex-row justify-content-center gap-4'>
                                <a target="_blank" href={`https://www.facebook.com/traveltozero`} className="d-flex flex-row gap-2 align-items-center text-decoration-none rrss" rel="nofollow noreferrer">
                                    <img src="/img/svg/shared-facebook.svg" alt="" />
                                </a>
                                <a target="_blank" href={`https://www.instagram.com/traveltozero`} className="d-flex flex-row gap-2 align-items-center text-decoration-none rrss" rel="nofollow noreferrer">
                                    <img src="/img/svg/shared-instagram.svg" alt="" />
                                </a>
                                <a target="_blank" href={`https://www.linkedin.com/company/traveltozero`} className="d-flex flex-row gap-2 align-items-center text-decoration-none rrss" rel="nofollow noreferrer">
                                    <img src="/img/svg/shared-link.svg" alt="" />
                                </a>
                                <a target="_blank" href={`https://www.pinterest.es/traveltozero`} className="d-flex flex-row gap-2 align-items-center text-decoration-none rrss" rel="nofollow noreferrer">
                                    <img src="/img/svg/shared-pinterest.svg" alt="" />
                                </a>
                            </div>
                            {/* <p className='tt-norms-semi-bolder mb-0 opacity-50 text-center'>{t('footer.follow')}</p> */}
                        </div>
                        <div className='footer mt-5'>
                            <div className='d-flex flex-row align-items-center justify-content-around opacity-50 p-0 inter-regular'>
                                <Link href={`${t('pages.legal')}`} className='small text-nowrap text-decoration-underline text-coal'>· {t("footer.legal")}</Link>
                                <Link href={`${t('pages.cookies')}`} className='small text-nowrap text-decoration-underline text-coal'>· {t("footer.cookies")}</Link>
                                <Link href={`${t('pages.privacy')}`} className='small text-nowrap text-decoration-underline text-coal'>· {t("footer.privacy")}</Link>
                            </div>
                            <p className='mt-3 mb-0 opacity-50 small text-center inter-bold'>copyrights {new Date().getFullYear()} - Travel To Zero</p>
                        </div>
                    </div>
                </div>
            </Navbar>
            <Modal show={contact} onHide={handleCloseContact} size='lg'>
                <Modal.Header closeButton className='border-0 pb-0' />
                <Modal.Body>
                    <HubspotForm
                        region="eu1"
                        portalId="26078053"
                        formId={contactFormLang[i18n.language]}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MobileTopbar