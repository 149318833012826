import React from 'react'

function useClickOutsideElement() {
    const handleClickOutside = (event, outsideTarget, triggerCondition, action, controlTarget) => {
        if (triggerCondition) {
            let clickElement = event?.target
            let parent = event?.target?.parentElement;

            // Avoid run action if original trigger element was called 
            if (controlTarget) {
                if (clickElement?.id !== controlTarget) {
                    let inputCheck = clickElement?.parentElement

                    if (inputCheck?.id === controlTarget) {
                        return
                    }

                    while (inputCheck?.id !== controlTarget && inputCheck) {
                        inputCheck = inputCheck?.parentElement

                        if (inputCheck?.id === controlTarget) {
                            return
                        }
                    }
                } else {
                    return
                }
            }

            // Check event element click is outside target
            if (clickElement?.id === outsideTarget) {
                action();
            }

            // if original click element not the outside target check their parents 
            if (parent?.id === outsideTarget) {
                action();
            } else {
                while (parent?.id !== outsideTarget) {
                    parent = parent?.parentElement;

                    if (!parent && action) {
                        action();
                        return
                    }
                }
            }

        }
    }

    return ({
        handleClickOutside
    })
}

export default useClickOutsideElement