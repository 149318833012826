import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useState } from 'react';

function TzoTouchSheet({ children, open, handleOpen }) {
    const [sheetOpen, setSheetOpen] = useState(false);
    const [sheetHeight, setSheetHeight] = useState(false);
    const [sheetSlideIcon, setSlideIcon] = useState(false)
    const [touchRef, setTouchRef] = useState();
    const [touchStartRef, setTouchStartRef] = useState();

    let reset = null;
    let blockSheetTouch = false;

    const handleStartTouch = (touch) => {
        setTouchRef(touch.clientY);
        setTouchStartRef(touch.clientY);
        setSheetHeight(document.getElementById('sheet').getBoundingClientRect().height)
    }

    const handleSheetTouch = (touch, e) => {

        const sheet = document.getElementById('sheet')

        if (reset) {
            resetTouchRef()
        }

        sheet.style.overflowY = 'hidden'
        sheet.style.height = `${sheetHeight + (touchRef - touch.clientY)}px`

        reset = setTimeout(() => {
            setTouchRef(touch.clientY)
            setSheetHeight(sheetHeight + (touchRef - touch.clientY))
        }, 20);
    }

    const handleEndTouch = (touch) => {
        resetTouchRef()
        const sheet = document.getElementById('sheet')

        if (touchRef > touch.clientY) {
            sheet.classList.replace('close', 'open')
            sheet.classList.add('overflow-hidden')
            sheet.removeAttribute('style')
            setSheetOpen(true);
            setTimeout(() => {
                sheet.classList.remove('overflow-hidden')
            }, 505)
        } else if (touchRef < touch.clientY) {
            sheet.classList.replace('open', 'close')
            sheet.removeAttribute('style')
            setSheetOpen(false);
        } else if (touchStartRef !== touch.clientY) { // check if sheet moved to prevent overlap click event
            if (Array.from(sheet.classList).includes('open')) {
                sheet.classList.replace('open', 'close')
                sheet.removeAttribute('style')
                setSheetOpen(false);
            } else {
                sheet.classList.replace('close', 'open')
                sheet.classList.add('overflow-hidden')
                sheet.removeAttribute('style')
                setSheetOpen(true);
                setTimeout(() => {
                    sheet.classList.remove('overflow-hidden')
                }, 505)
            }
        }

        setTimeout(() => {
            document.getElementsByClassName("sheet-content")[0].removeAttribute("style")
        }, 505);

        sheet.style.height = sheet.getBoundingClientRect().height
    }

    const handleSheetClick = () => {
        const sheet = document.getElementById('sheet')
        if (Array.from(sheet.classList).includes('open')) {
            sheet.classList.replace('open', 'close')
            sheet.removeAttribute('style')
            setSheetOpen(false);
        } else {
            sheet.classList.replace('close', 'open')
            sheet.classList.add('overflow-hidden')
            sheet.removeAttribute('style')
            setSheetOpen(true);
            setTimeout(() => {
                sheet.classList.remove('overflow-hidden')
            }, 505)
        }
    }

    const resetTouchRef = () => {
        clearTimeout(reset)
    }

    useEffect(() => {
        if (handleOpen) {
            handleOpen(sheetOpen)

            setTimeout(() => {
                setSlideIcon(sheetOpen)
            }, 500); // 300ms (transition) + 200ms (ease-in-out)
        }

        setTimeout(() => {
            setSlideIcon(sheetOpen)
        }, 500); // 300ms (transition) + 200ms (ease-in-out)
    }, [sheetOpen])

    useEffect(() => {
        if (open !== sheetOpen) {
            setSheetOpen(open)
        }
    }, [open])

    return (
        <div className={`explore-sheet d-block d-lg-none touch-sheet ${sheetOpen ? "open" : "close"}`} id='sheet'>
            <div className='py-3 d-flex flex-column justify-content-center align-items-center gap-1 drag-sheet'
                onTouchMoveCapture={(e) => {
                    handleSheetTouch(e.changedTouches[0], e)
                }}
                onTouchStartCapture={(e) => {
                    e.stopPropagation()
                    document.body.style.overflowY = 'hidden'
                    document.getElementById('sheet').style.transition = ".0s"
                    handleStartTouch(e.touches[0]);
                }}
                onTouchEnd={(e) => {
                    handleEndTouch(e.changedTouches[0])
                    setTimeout(() => {
                        document.body.style = ""
                    }, 150);
                    setTimeout(() => { blockSheetTouch = false }, 500);
                }}
                onClick={(e) => {
                    handleSheetClick()
                }}
            >
                {!sheetSlideIcon && <FontAwesomeIcon icon="fa-solid fa-arrow-up" width={24} height={24} className='text-black drag-arrow' />}
                <span className='drag-icon'></span>
                {sheetSlideIcon && <FontAwesomeIcon icon="fa-solid fa-arrow-down" width={24} height={24} className='text-black drag-arrow' />}
            </div>
            <hr className='my-0' />
            {children}
        </div>
    )
}

export default TzoTouchSheet