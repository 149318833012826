import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import Link from '../Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FavouritesContext } from '../../Contexts'
import FavouriteService from '../../services/favourites.service'
import { AppContext } from '../../AppContext'
import BookmarkIcon from '../icons/BookmarkIcon'
import { useTranslation } from 'react-i18next'

function NewContentCard({ theme, image, project, category, title, slug, stamp, type, id, noRedirect, hideLink, onClick, data }) {

    const { state: { user }, update } = useContext(AppContext);
    const { favouriteState: { favouritesList, articlesFav, miniGuidesFav, interestSitesFav, favourites, mainFolder, favoriteModal }, favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const { t } = useTranslation();

    const [CardTheme, setCardTheme] = useState(theme || 'laurel')


    const [addingFlag, setAddingFlag] = useState(false)
    const [favoriteTypeList, setFavoriteTypeList] = useState();

    const updateFavouriteContext = async () => {
        const favouritesData = await FavouriteService.getFavourites();
        let favouriteIdList = [];

        let articlesList = [];
        let miniGuidesList = [];
        let interestSitesList = [];

        favouritesData.forEach((favourite) => {
            favouriteIdList.push(favourite?.elementId)

            if (favourite?.favouriteType === "ARTICLE") {
                articlesList.push(favourite?.elementId);
            } else if (favourite?.favouriteType === "MINIGUIDE") {
                miniGuidesList.push(favourite?.elementId);
            } else if (favourite?.favouriteType === "INTERESTSITE") {
                interestSitesList.push(favourite?.elementId);
            }

        })

        favouriteUpdate({
            favourites: favouritesData,
            favouritesList: favouriteIdList,
            articlesFav: articlesList,
            miniGuidesFav: miniGuidesList,
            interestSitesFav: interestSitesList
        })
    }

    const handleFavourite = useCallback(async () => {
        setAddingFlag(true)
        if (type === "MINIGUIDE") {
            try {
                await FavouriteService.createMiniGuideFavourite({
                    value: id || data?.id,
                    folderIds: [mainFolder?.id]
                })

                updateFavouriteContext()
            } catch (error) {
                console.error(error);
                setAddingFlag(false);
            }
        } else if (type === "ARTICLE") {
            try {
                await FavouriteService.createArticleFavourite({
                    value: id || data?.id,
                    folderIds: [mainFolder?.id]
                })

                updateFavouriteContext()
            } catch (error) {
                console.error(error);
                setAddingFlag(false);
            }
        } else if (type === "INTERESTSITE") {
            try {
                await FavouriteService.createInterestSiteFavourite({
                    value: id || data?.id,
                    folderIds: [mainFolder?.id]
                })

                updateFavouriteContext()
            } catch (error) {
                console.error(error);
            }
        }
    })

    const removeFavourite = useCallback(async () => {
        try {
            const favoriteType = type

            const favouriteDetail = favourites.find(favourite => (favourite?.elementId === (id || data?.id) && favoriteType === favourite?.favouriteType))

            await FavouriteService.deleteFavorite((favouriteDetail?.id || data?.id), {
                deleteInOtherFolders: true
            })

            updateFavouriteContext()

            setAddingFlag(false)
        } catch (error) {
            console.error(error);
        }
    })

    useEffect(() => {
        if (type === "ARTICLE") {
            setFavoriteTypeList(articlesFav);
        } else if (type === "MINIGUIDE") {
            setFavoriteTypeList(miniGuidesFav);
        } else if (type === "INTERESTSITE") {
            setFavoriteTypeList(interestSitesFav);
        } else {
            setFavoriteTypeList(favouritesList)
        }
    }, [type, articlesFav, miniGuidesFav, interestSitesFav])

    return (
        <article onClick={() => onClick && onClick()} className='d-flex flex-column flex-grow-1 h-100'>
            <Link href={slug} noRedirect={noRedirect} className={"d-flex flex-column flex-grow-1 h-100"}>
                <div className='d-flex flex-column gap-3 new-content-card flex-grow-1'>
                    <div className='d-flex position-relative'>
                        <Image className='content-picture' src={image || "/img/no-photo.svg"} />
                        {(stamp && (type === 'ARTICLE' && data?.type === 2)) && <Image src={stamp} className='stamp' />}
                        <BookmarkIcon
                            width={40}
                            className={`favorite-icon position-absolute bottom-0 end-0 pb-3 pe-3 ${(favoriteTypeList?.includes(id || data?.id) || addingFlag) ? "text-coal bookmark-active" : "text-beige bookmark"}`}
                            onClick={(e) => {
                                if (user) {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (favoriteTypeList?.includes(id || data?.id)) {
                                        removeFavourite()
                                    } else {
                                        handleFavourite();
                                    }
                                } else {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                }
                            }}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-between gap-3 flex-grow-1'>
                        <div className={`d-flex flex-column mb-3 ${noRedirect ? "gap-2" : "gap-3"}`}>
                            <span className={`inter-medium letter-spacing-2 text-uppercase text-center text-${CardTheme}`}>{category}</span>
                            <p className='inter-regular fs-5 lh-sm letter-spacing-1 text-center clamp-2 mb-0 text-dark-green'>{project && <b className='inter-semi-bold'>{project}.</b>} {title}</p>
                        </div>
                        {!hideLink && <span className={`text-uppercase new-link-${CardTheme} inter-regular letter-spacing-2 mb-1 align-self-center text-uppercase`}>{t('general.components.readMore')}</span>}
                    </div>
                </div>
            </Link>
        </article>
    )
}

export default NewContentCard