import { Circle, GoogleMap, LoadScript, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import React, { useContext, useEffect, useRef } from 'react'
import { useCallback } from 'react';
import { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { AppContext } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import InterestSiteService from '../../services/interestSite.service';
import { useNavigate, useParams, useLocation } from 'react-router';
import Link from '../../components/Link';
import ChargerService from '../../services/charger.service';
import ReactPaginate from 'react-paginate';
import SeoHelmet from '../../components/seo-helmet/SeoHelmet';
import UserDataService from '../../services/userData.service';
import { useSearchParams } from 'react-router-dom';
import { FavouritesContext, PromotionsContext } from '../../Contexts';
import useSwipe from '../../components/horizontal-scroll/useSwipe';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation'
import DesktopSwiper from '../../components/swiper/DesktopSwiper';
import useHandleFavorite from '../../hooks/useHandleFavorite';
import NewContentCard from '../../components/cards/NewContentCard';
import NewContentCardPlaceholder from '../../components/cards/NewContentCardPlaceholder';

const NewExplore = () => {

    const { state: { categories, banners, user, showBottomNav }, state, update } = useContext(AppContext);
    const { promotionState: { promotionModal, promotionTimelapse } } = useContext(PromotionsContext);
    const { favouriteState: { favouritesList, articlesFav, miniGuidesFav, interestSitesFav, favourites, mainFolder, favoriteModal }, favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const { handleFavourite, removeFavourite, isFavorite, isFavoriteActive, setIsFavoriteActive, addingFlag, setAddingFlag } = useHandleFavorite()

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"
    })

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();

    const [map, setMap] = useState(null); // map instance
    const onLoadMap = useCallback(setMap, [setMap]);

    const [sheetOpen, setSheetOpen] = useState(false);
    const [sheetHeight, setSheetHeight] = useState(false);
    const [sheetSlideIcon, setSlideIcon] = useState(false)
    const [touchRef, setTouchRef] = useState();
    const [touchStartRef, setTouchStartRef] = useState();
    const [cardOpen, setCardOpen] = useState(true);
    const [search, setSearch] = useState(false);
    const [chargePoint, setchargePoint] = useState(false);
    const [chargePointDetail, setChargePointDetail] = useState();
    const [category, setCategory] = useState(false);
    const [zone, setZone] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [userUbication, setUserUbication] = useState();
    const [activeMarker, setActiveMarker] = useState(null);
    const [activeMarkerDetail, setActiveMarkerDetail] = useState();
    const [MarkerContentShow, setMarkerContentShow] = useState(1);
    const [chargeWindow, setChargeWindow] = useState(1);
    const [sliderLabel, setSliderLabel] = useState(10);
    const [showPagination, setShowPagination] = useState(false);
    const [hidePlaceholder, setHidePlaceholder] = useState(false);
    const [loadMore, setLoadMore] = useState({
        totalItems: 0,
        itemsToDisplay: 6,
        offset: 1
    })

    const [interestSites, setInterestSites] = useState();
    const [chargePointsList, setChargePointsList] = useState();
    const [chargePointsDisplay, setChargePointsDisplay] = useState();
    const [displaySites, setDisplaySites] = useState();
    const [displayLoadMoreSites, setDisplayLoadMoreSites] = useState()
    const [sheetRefScroll, setSheetRefScroll] = useState()

    const [categoryDetail, setCategoryDetail] = useState();
    const [categoryList, setCategoryList] = useState([]);
    const [searchRange, setSearchRange] = useState(10); // +10km default
    const [titleSearch, setTitleSearch] = useState();
    const [firstLoad, setFirstLoad] = useState(true);
    const [onBoarding, setOnBoarding] = useState(false);
    const [onBoardingText, setOnBoardingText] = useState();
    const [step, setStep] = useState(0);

    const [menuActive, setMenuActive] = useState({
        charger: false,
    })

    const timeOutRef = useRef();
    const swiperDesktopRef = useRef(null);

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)

    const { onTouchEnd, onTouchMove, onTouchStart } = useSwipe();

    const scrollMenuApiRef = useRef({});

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 4,
        numPages: 1
    });

    let reset = null;
    let blockSheetTouch = false;
    const [veAnimation, setVeAnimation] = useState();

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const options = { closeBoxURL: '', enableEventPropagation: true, boxClass: "infoBox card-shadow-explore radius" };

    function handleSearchRange(value) {
        setSearchRange(value)
    }

    const getInterestSites = useCallback(async ({ categoryIds, proximity, title }) => {
        try {
            const sites = await InterestSiteService.getInterestSite({ categoryIds: categoryIds?.toString(), search: title })

            if (categoryIds?.length > 0 && chargePoint) {

                let sitesChargerPoints = chargePointsList && [...chargePointsList]
                let interestSitesChargers = [];

                sites.forEach((site, i) => {
                    if (site?.chargingPoint && site?.chargingPointDTO) {
                        let SelectCharger;

                        interestSitesChargers.push(site)

                        sitesChargerPoints?.forEach((charger) => {
                            if (charger?.id === site?.chargingPointDTO?.id) {
                                SelectCharger = charger;
                            }
                        })

                        if (SelectCharger) {

                            sitesChargerPoints?.splice(sitesChargerPoints?.indexOf(SelectCharger), 1)
                        }
                    }
                })

                setChargePointsDisplay(sitesChargerPoints);
                setInterestSites(proximity || searchRange < 10 ? filterProximity(interestSitesChargers, searchRange) : [...interestSitesChargers]?.reverse())
            } else {
                setInterestSites(proximity || searchRange < 10 ? filterProximity(sites, searchRange) : [...sites]?.reverse())
            }
        } catch (error) {
            console.error(error);
        }
    }, [searchRange, chargePointsList, chargePoint])

    const getChargersPoints = useCallback(async ({ proximity }) => {
        try {
            const chargers = await ChargerService.getCharger();
            setChargePointsList(proximity || searchRange < 10 ? filterProximity(chargers, searchRange) : chargers)
        } catch (error) {
            console.error(error);
        }
    }, [searchRange])

    const resetData = () => {
        setInterestSites();
        setCategoryDetail();
    }

    const loadData = ({ categoryIds, range, title }) => {
        getChargersPoints({ proximity: range < 10 ? true : false });

        if (chargePoint && categoryIds.length === 0 && !chargePointDetail) {
            setHidePlaceholder(true);
            setCardOpen(false)
        } else {
            setHidePlaceholder(false);
            setCardOpen(true)
            getInterestSites({ categoryIds: categoryIds, proximity: range < 10 ? true : false, title: title });
        }
    }

    const findMarker = (category, charger) => {
        const cat = categories.find(cat => cat.name === category);

        if (charger && cat?.multiMarkerUrl) {
            return cat?.multiMarkerUrl
        } else {
            return cat?.markerUrl
        }
    }

    const handleActiveMarker = (marker, isCard, detail, isCharger) => {
        if (marker === activeMarker && !isCard) {
            setActiveMarker(null);
            setActiveMarkerDetail()
            setChargePointDetail();
            setMarkerContentShow(1);

            if (chargePoint && categoryList.length === 0) {
                setCardOpen(false)
            }

            return;
        }

        if (!marker) {
            setActiveMarker(null);
            setActiveMarkerDetail();
            setMarkerContentShow(1);
        } else {
            setActiveMarker(marker);
            setActiveMarkerDetail(detail);


            if (isCharger) {
                setChargePointDetail(detail);
                setMarkerContentShow(2);
            } else {
                setMarkerContentShow(1);
            }
        }
    };

    const handleCategory = (id) => {

        let categoryArray = [...categoryList];

        if (categoryArray.length > 0) {
            let findCat = categoryArray.indexOf(id)
            if (findCat >= 0) {
                categoryArray.splice(findCat, 1)
            } else {
                categoryArray.push(id)
            }
        } else {
            categoryArray.push(id)
        }

        setCategoryList(categoryArray)
    }

    const getCategory = (catId, catName) => {
        let category = null

        if (catId) {
            category = categories.find(cat => cat.id === parseInt(catId));
        } else if (catName) {
            category = categories.find(cat => cat.slugs[i18n.language].toLowerCase() === catName);
        }
        return category;
    }

    const focusMarker = (latitude, longitude, ref, siteDetail) => {
        if (map) {
            const bounds = new window.google.maps.LatLngBounds()
            const mapSize = document.getElementsByClassName('explore-container')?.[0]?.clientWidth;
            const cardSize = document.getElementsByClassName('desktop-card')?.[0]?.clientWidth;
            let customCenterMap = null

            if (mapSize / 2 <= cardSize) {
                customCenterMap = cardSize - 250 // Desktop card - (site card [285px] - 35px [offset])
            } else {
                customCenterMap = ((mapSize / 2) - ((mapSize / 2) - (cardSize + 12))) / 2 // (map center) - (map center - (desktop card + right padding)) / 2 [get center of remain map space]
            }

            bounds.extend({ lat: latitude, lng: longitude })

            map.fitBounds(bounds);
            map.setZoom(isDesktop ? 19 : 17);

            if (!fullScreen) {
                map.panBy(isDesktop ? customCenterMap : 0, isDesktop ? 0 : 90);
            } else if (!isDesktop) {
                map.panBy(!isDesktop && 80, 90); // 90 = sheet min height
            }
        }

        handleActiveMarker(ref, true, siteDetail)
    }
    const filterProximity = useCallback((sites, km) => {
        if ((sites || interestSites) && (userUbication || { lat: 39.620349, lng: 2.905260 })) {

            const distance = (coor1, coor2, userUbication) => {

                const latitude = parseFloat(coor2.latitude) - parseFloat(coor1.latitude);
                const longitude = parseFloat(coor2.longitude) - parseFloat(coor1.longitude);

                return Math.sqrt((latitude * latitude) + (longitude * longitude));
            };

            const sortByDistance = (sites, point) => {
                const sitesList = [...sites];
                let newSiteList = [];

                const sorter = (a, b) => distance(a, point) - distance(b, point);
                sitesList.sort(sorter);

                sitesList.forEach((site) => {
                    if (window?.google?.maps?.geometry?.spherical?.computeDistanceBetween(userUbication, { lat: site.latitude, lng: site.longitude }) <= (km * 1000)) {
                        newSiteList.push(site);
                    }
                })

                return km ? newSiteList : sitesList;
            };

            return sortByDistance(sites || interestSites, { latitude: userUbication?.lat || 39.620349, longitude: userUbication?.lng || 2.905260 });
        }
    }, [interestSites, userUbication])

    const resetTouchRef = () => {
        clearTimeout(reset)
    }

    const handleStartTouch = (touch) => {
        setTouchRef(touch.clientY);
        setTouchStartRef(touch.clientY);
        setSheetHeight(document.getElementById('sheet').getBoundingClientRect().height)

        if (activeMarkerDetail || chargePointDetail) {
            document.getElementsByClassName("sheet-content")[0].style.overflowY = "unset"
        }
    }

    const handleSheetTouch = (touch, e) => {

        const sheet = document.getElementById('sheet')

        if (reset) {
            resetTouchRef()
        }

        sheet.style.overflowY = 'hidden'
        sheet.style.height = `${sheetHeight + (touchRef - touch.clientY)}px`

        reset = setTimeout(() => {
            setTouchRef(touch.clientY)
            setSheetHeight(sheetHeight + (touchRef - touch.clientY))
        }, 20);
    }

    const handleEndTouch = (touch) => {
        resetTouchRef()
        const sheet = document.getElementById('sheet')

        if (touchRef > touch.clientY) {
            sheet.classList.replace('close', 'open')
            sheet.classList.add('overflow-hidden')
            sheet.removeAttribute('style')
            setSheetOpen(true);
            setTimeout(() => {
                sheet.classList.remove('overflow-hidden')
            }, 505)
        } else if (touchRef < touch.clientY) {
            sheet.classList.replace('open', 'close')
            sheet.removeAttribute('style')
            setSheetOpen(false);
        } else if (touchStartRef !== touch.clientY) { // check if sheet moved to prevent overlap click event
            if (Array.from(sheet.classList).includes('open')) {
                sheet.classList.replace('open', 'close')
                sheet.removeAttribute('style')
                setSheetOpen(false);
            } else {
                sheet.classList.replace('close', 'open')
                sheet.classList.add('overflow-hidden')
                sheet.removeAttribute('style')
                setSheetOpen(true);
                setTimeout(() => {
                    sheet.classList.remove('overflow-hidden')
                }, 505)
            }
        }

        setTimeout(() => {
            document.getElementsByClassName("sheet-content")[0].removeAttribute("style")
        }, 505);

        sheet.style.height = sheet.getBoundingClientRect().height
    }

    const handleSheetClick = () => {
        const sheet = document.getElementById('sheet')
        if (Array.from(sheet.classList).includes('open')) {
            sheet.classList.replace('open', 'close')
            sheet.removeAttribute('style')
            setSheetOpen(false);
        } else {
            sheet.classList.replace('close', 'open')
            sheet.classList.add('overflow-hidden')
            sheet.removeAttribute('style')
            setSheetOpen(true);
            setTimeout(() => {
                sheet.classList.remove('overflow-hidden')
            }, 505)
        }
    }

    const exploreOnBoarding = () => {
        const containerEl = document.getElementsByClassName('explore-container')?.[0];
        const search = document.getElementsByClassName('controls-search')?.[0];
        const filters = document.getElementsByClassName('controls-container')?.[1];
        const pointVeFilter = document.getElementById('point-ve');
        const categoriesFilter = document.getElementById('catFilter');
        const zoneFilter = document.getElementById('zoneFilter');
        const fullScreenFilter = document.getElementById('screenFilter');
        const card = document.getElementsByClassName('desktop-card')?.[0];
        const sheet = document.getElementsByClassName('explore-sheet')?.[0];

        if (!onBoarding) {
            setOnBoarding(true);
        }

        switch (step) {
            case 0:
                containerEl?.classList?.add('on-boarding');

                setOnBoardingText({
                    text: t('explore.onBoard.steps.0.text'),
                    button: t('explore.onBoard.steps.0.button'),
                    style: {
                        maxWidth: isDesktop ? "35vw" : "100%",
                        width: !isDesktop && "90vw",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center"
                    }
                })

                break;

            case 1:
                search?.classList?.add('show');
                filters?.classList?.add('show');

                setOnBoardingText({
                    text: t('explore.onBoard.steps.1.text'),
                    button: t('explore.onBoard.steps.1.button'),
                    style: {
                        left: isDesktop ? "160px" : "105px",
                        top: isDesktop ? "175px" : "130px",
                        alignItems: "start"
                    }
                })

                break;

            case 2:
                filters?.classList?.remove('show');
                setSearch(true);

                setOnBoardingText({
                    text: t('explore.onBoard.steps.2.text'),
                    button: t('explore.onBoard.steps.2.button'),
                    style: {
                        left: isDesktop ? "160px" : "105px",
                        top: isDesktop ? "105px" : "84px",
                        alignItems: "start"
                    }
                })
                break;

            // case 3:
            //     search?.classList?.remove('show');
            //     setSearch(false);

            //     filters?.classList?.add("show");
            //     categoriesFilter?.classList?.add("hide");
            //     zoneFilter?.classList?.add("hide");
            //     fullScreenFilter?.classList?.add("hide");

            //     setVeAnimation(setInterval(() => {
            //         if ((Array.from(pointVeFilter?.classList)).includes('active')) {
            //             pointVeFilter?.classList?.remove('active')
            //         } else {
            //             pointVeFilter?.classList?.add('active')
            //         }
            //     }, 1500));

            //     setOnBoardingText({
            //         text: t('explore.onBoard.steps.3.text'),
            //         button: t('explore.onBoard.steps.3.button'),
            //         style: {
            //             left: isDesktop ? "160px" : "105px",
            //             top: isDesktop ? "80px" : "75px"
            //         }
            //     })
            //     break;

            case 3:
                search?.classList?.remove('show');
                setSearch(false);

                filters?.classList?.add("show");
                zoneFilter?.classList?.add("hide");
                pointVeFilter?.classList?.add("hide");
                fullScreenFilter?.classList?.add("hide");

                setCategory(true)

                setOnBoardingText({
                    text: t('explore.onBoard.steps.4.text'),
                    button: t('explore.onBoard.steps.4.button'),
                    style: {
                        left: isDesktop ? "160px" : "105px",
                        top: isDesktop ? "34%" : "32%",
                        alignItems: "start"
                    }
                })
                break;

            case 4:
                if (userUbication) {
                    setCategory(false)
                    setTimeout(() => {
                        categoriesFilter?.classList?.add("hide");
                    }, 25);

                    zoneFilter?.classList?.remove("hide")
                    setZone(true)

                    setOnBoardingText({
                        text: t('explore.onBoard.steps.5.text'),
                        button: t('explore.onBoard.steps.5.button'),
                        style: {
                            left: isDesktop ? "160px" : "105px",
                            top: isDesktop ? "42%" : "37%",
                            alignItems: "start"
                        }
                    })
                } else {
                    setStep(step + 1)
                }
                break;

            // case 5:
            //     setZone(false)
            //     zoneFilter?.classList?.add('hide')

            //     if (!userUbication) {
            //         setCategory(false)
            //         setTimeout(() => {
            //             categoriesFilter?.classList?.add("hide");
            //         }, 5);
            //     }

            //     fullScreenFilter?.classList?.remove('hide');

            //     setOnBoardingText({
            //         text: t('explore.onBoard.steps.6.text'),
            //         button: t('explore.onBoard.steps.6.button'),
            //         style: {
            //             left: isDesktop ? "160px" : "105px",
            //             top: isDesktop ? (userUbication ? "345px" : "258px") : userUbication ? "280px" : "210px"
            //         }
            //     })
            //     break;

            case 5:
                zoneFilter?.classList?.add('hide');
                setZone(false)

                if (!userUbication) {
                    setCategory(false)
                    setTimeout(() => {
                        categoriesFilter?.classList?.add("hide");
                    }, 5);
                }

                card?.classList?.add('show')
                sheet?.classList?.add('show')

                setOnBoardingText({
                    text: t(`explore.onBoard.steps.7.${isDesktop ? "textDesktop" : "textResponsive"}`),
                    button: t('explore.onBoard.steps.7.button'),
                    style: {
                        right: isDesktop && "675px",
                        left: !isDesktop && "50%",
                        bottom: isDesktop ? "50%" : "130px",
                        transform: !isDesktop && "translateX(-50%)",
                        textAlign: !isDesktop && "center",
                        alignItems: !isDesktop ? "center" : "start"
                    }
                })
                break;

            default:
                pointVeFilter.style.transition = "0s"

                Array.from(document.getElementsByClassName('show')).forEach((el) => {
                    el.classList.remove('show')
                })

                containerEl?.classList?.remove('on-boarding');

                setTimeout(() => {
                    pointVeFilter.removeAttribute('style');
                }, 100);

                UserDataService.setExploreOnBoardingShowed();

                setOnBoarding(false);
                break;
        }
    }

    useEffect(() => {
        if (map) {
            map.setCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() })
        }
    }, [search, chargePoint, category, fullScreen])

    useEffect(() => {

        if (map) {

            const bounds = new window.google.maps.LatLngBounds();
            const mapSize = document.getElementsByClassName('explore-container')?.[0]?.clientWidth;
            const cardSize = document.getElementsByClassName('desktop-card')?.[0]?.clientWidth;
            let customCenterMap = null;

            if (interestSites && window.google && (searchRange >= 10 || !searchRange)) {

                interestSites.map((site) => {
                    if (site?.latitude && site?.longitude) {
                        bounds.extend({ lat: site.latitude, lng: site.longitude })
                    }
                })

                if (chargePoint) {
                    chargePointsList?.map((charger) => {
                        if (charger?.latitude && charger?.longitude) {
                            bounds.extend({ lat: charger.latitude, lng: charger.longitude })
                        }
                    })
                }

                // if (userUbication) {
                //     bounds.extend(userUbication)
                // }

                if (interestSites?.length > 0) {
                    map.fitBounds(bounds, (searchRange >= 10 && fullScreen) ? 120 : 40)
                }
            } else if (userUbication && searchRange < 10) {
                if (mapSize / 2 <= cardSize) {
                    customCenterMap = cardSize - 250
                } else {
                    customCenterMap = ((mapSize / 2) - ((mapSize / 2) - (cardSize + 12))) / 2
                }

                bounds.extend({ lat: userUbication?.lat, lng: userUbication?.lng })

                map.fitBounds(bounds);

                if (isDesktop) {
                    if (searchRange === 1) {
                        map.setZoom(15)
                    } else if (searchRange < 4) {
                        map.setZoom(14)
                    } else if (searchRange < 7) {
                        map.setZoom(13)
                    } else if (searchRange < 10) {
                        map.setZoom(12)
                    }
                } else {
                    if (searchRange === 1) {
                        map.setZoom(14)
                    } else if (searchRange < 3) {
                        map.setZoom(13)
                    } else if (searchRange < 6) {
                        map.setZoom(12)
                    } else if (searchRange < 10) {
                        map.setZoom(11)
                    }
                }

                if (!fullScreen && isDesktop) {
                    map.panBy(isDesktop ? customCenterMap : 80, isDesktop ? 0 : 90);
                } else if (!isDesktop) {
                    map.setCenter(userUbication)
                }
            }
        }
    }, [map, interestSites, onLoadMap, chargePoint])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserUbication({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            },
            () => {
                setUserUbication(null) // Denied permission
            }
        );
    }, [])

    useEffect(() => {
        if (state?.range && map) {
            setSearchRange(state?.range)
            window.history.replaceState({}, document.title)
        }
    }, [state?.range, map])

    useEffect(() => {
        resetData();

        if (categories.length > 0) {
            let cat = null;
            resetData()

            if (!firstLoad && displaySites) {
                setPagination({ ...pagination, currentPage: 1 })
            }

            if (params?.category) {
                cat = getCategory(null, params?.category)
            }

            if (categoryList?.length > 1) {
                loadData({ categoryIds: categoryList, range: searchRange, title: titleSearch });
                if (!firstLoad) {
                    navigate(`/${i18n.language}/${t('pages.explore')}${searchParams ? `?${searchParams.toString()}` : ""}`)
                }
            } else if ((categoryList?.length === 1 && !firstLoad) || (firstLoad && cat)) {
                loadData({ categoryIds: categoryList.length > 0 ? categoryList : cat?.id, range: searchRange, title: titleSearch });

                if (!firstLoad) {
                    navigate(`/${i18n.language}/${t('pages.explore')}/${getCategory(categoryList?.[0])?.slugs?.[i18n.language]}${searchParams ? `?${searchParams.toString()}` : ""}`)
                } else {
                    setCategoryList([...categoryList, cat?.id])
                }
            } else {
                loadData({ categoryIds: categoryList, range: searchRange, title: titleSearch });

                navigate(`/${i18n.language}/${t('pages.explore')}${searchParams ? `?${searchParams.toString()}` : ""}`)
            }

            if (firstLoad) {
                setFirstLoad(false)
            }
        }
    }, [categoryList, categories, chargePoint])

    useEffect(() => {
        if (chargePointsList) {
            getChargersPoints({ proximity: searchRange < 10 ? true : false });
        }
    }, [searchRange])

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 992)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    useEffect(() => {
        if (interestSites?.length > 0) {
            setPagination({
                ...pagination,
                totalItems: interestSites?.length,
                numPages: Math.ceil(interestSites?.length / 4),
            });

            setDisplaySites(interestSites.slice(((pagination.currentPage - 1) * 4), pagination.currentPage * 4));

            setLoadMore({
                ...loadMore,
                totalItems: interestSites?.length,
                offset: 1
            })

            setDisplayLoadMoreSites(interestSites.slice(((loadMore.offset - 1) * loadMore.itemsToDisplay), loadMore.offset * loadMore.itemsToDisplay))
        }
    }, [interestSites, isDesktop])

    useEffect(() => {
        const card = document.getElementsByClassName('desktop-card')?.[0]
        const sitesContent = document.getElementById('sites-content');
        const overlay = document.getElementsByClassName('overlay')?.[0]

        if (!fullScreen && interestSites?.length > 0 && displaySites?.length > 0) {
            if (card?.scrollHeight <= card?.clientHeight && sitesContent?.children === 0) {
                overlay?.classList.add("d-none");
            } else {
                overlay?.classList.remove('d-none');
            }
        } else {
            overlay?.classList.add("d-none");
        }
    }, [displaySites])

    useEffect(() => {
        if (categoryList?.length === 1) {
            setCategoryDetail(getCategory(categoryList?.[0]))
        }
    }, [categoryList])

    useEffect(() => {
        if (interestSites) {
            // const div = document.getElementsByClassName('desktop-card')?.[0];

            setDisplaySites(interestSites.slice(((pagination.currentPage - 1) * 4), pagination.currentPage * 4));
            // div.scrollTop = document.getElementById('sites-container').offsetTop - 20
        }
    }, [pagination.currentPage])

    useEffect(() => {
        if (interestSites) {
            let newData = interestSites.slice(((loadMore.offset - 1) * loadMore.itemsToDisplay), loadMore.offset * loadMore.itemsToDisplay)
            setDisplayLoadMoreSites(displayLoadMoreSites.concat(newData))
        }
    }, [loadMore.offset])

    useEffect(() => {
        const div = document.getElementsByClassName('sheet-content')?.[0];

        setTimeout(() => {
            setSlideIcon(sheetOpen)
        }, 500); // 300ms (transition) + 200ms (ease-in-out)

        if (div) {
            if (sheetOpen) {
                div?.classList.remove("overflow-hidden")
            } else {
                div?.classList.add("overflow-hidden");
                setTimeout(() => {
                    div.scrollTop = 0
                }, 450);
            }
        }

    }, [sheetOpen])

    useEffect(() => {
        const div = document.getElementsByClassName('desktop-card')?.[0];
        const overlay = document.getElementsByClassName('overlay')?.[0];

        if (!fullScreen) {
            if (!cardOpen) {
                div.scrollTop = 0;
                overlay?.classList.replace('opacity-100', 'opacity-0');
                div?.classList.add("overflow-hidden");
            } else {
                if (interestSites?.length > 0 && !chargePoint || (interestSites?.length > 0 && chargePoint && categoryList?.length > 0)) {
                    overlay?.classList.replace('opacity-0', 'opacity-100')
                } else {
                    overlay?.classList.add("d-none");
                }
                div?.classList.remove("overflow-hidden");
            }
        }
    }, [cardOpen, fullScreen, isDesktop])

    useEffect(() => {
        if (searchParams.get('page')) {
            setPagination({ ...pagination, currentPage: parseInt(searchParams.get('page')) })
        }
    }, [searchParams])

    useEffect(() => {
        UserDataService.visitExplore();

        if (!UserDataService.isExploreOnBoardingShowed()) {
            setTimeout(() => {
                exploreOnBoarding();
            }, 150);
        }
    }, [])

    useEffect(() => {
        if (onBoarding) {
            exploreOnBoarding();
        }
    }, [step])

    useEffect(() => {
        if (promotionModal && promotionTimelapse > 0) {
            setShowPagination(false);
        }
    }, [location])

    useEffect(() => {
        let reset;

        if (promotionModal) {
            setShowPagination(false)
        } else if (promotionModal === false) {
            setShowPagination(true)
        }

        return () => {
            clearTimeout(reset)
        }
    }, [promotionModal, promotionTimelapse])

    useEffect(() => {
        if (!chargePoint || categoryList?.length === 0) {
            setChargePointsDisplay();
        }
    }, [chargePoint, categoryList])

    useEffect(() => {
        if (activeMarker) {
            scrollMenuApiRef?.current?.scrollToItem?.(scrollMenuApiRef?.current?.getItemByIndex(0), 'auto', 'end', 'nearest')

            if (activeMarkerDetail) {
                setIsFavoriteActive(isFavorite("INTERESTSITE", activeMarkerDetail?.id));
            }
        }
    }, [activeMarkerDetail, activeMarker])


    useEffect(() => {
        if (!activeMarker || !chargePointDetail) {
            document.getElementsByClassName('sheet-content')?.[0]?.scrollTo(0, sheetRefScroll)
            setSheetRefScroll();
        }
    }, [activeMarker, chargePointDetail])

    useEffect(() => {
        if (interestSites && chargePointsList) {
            getInterestSites({ title: titleSearch, proximity: searchRange < 10 ? true : false, categoryIds: categoryList });
        }
    }, [chargePointsList])

    useEffect(() => {
        const contentList = Array.from(document.getElementsByClassName('site-content'))
        const bottomActiontList = Array.from(document.getElementsByClassName('actions-menu'))

        if (!sheetOpen) {
            bottomActiontList?.forEach((el) => {
                setTimeout(() => {
                    el.classList.add("d-none")
                }, 400);
            })
        } else {
            bottomActiontList?.forEach((el) => {
                setTimeout(() => {
                    el.classList.remove("d-none")
                }, 100);
            })
        }
    }, [sheetOpen])

    useEffect(() => {
        if (showBottomNav && categories) {
            update({
                ...state,
                showBottomNav: false
            })
        }
    }, [state])

    return (
        <>
            {/* {(params.category && !fullScreen) && <BreadcrumbPage category={categoryDetail?.name} filter={"exploreCategory"} />}
            {(!params?.category && !fullScreen) && <BreadcrumbPage />} */}
            <SeoHelmet title={categoryDetail ? t(`seo.explore.categories.${categoryDetail?.slugs?.['en']}.title`) : t('seo.explore.title')} metaTitle={categoryDetail ? t(`seo.explore.categories.${categoryDetail?.slugs?.['en']}.metaTitle`) : t('seo.explore.metaTitle')} metaDescription={categoryDetail ? t(`seo.explore.categories.${categoryDetail?.slugs?.['en']}.metaDescription`) : t('seo.explore.metaDescription')} ogImage={'/img/home-image.jpg'} />
            <main>
                <Container as={"article"} fluid className={`bg-light px-0 explore-container ${fullScreen ? "fullscreen" : ""}`.trim()}>
                    {onBoarding &&
                        <div className="position-absolute d-flex flex-column gap-3 show on-boarding-explain" style={onBoardingText?.style}>
                            <span className='tt-norms-bold text-white h5'>{onBoardingText?.text}</span>
                            <Button className={`btn-light-outline-dark px-5 pt-1 mb-1 radius show ${step === 0 ? "align-self-center" : ""}`} onClick={() => setStep(step + 1)}>{onBoardingText?.button}</Button>
                        </div>
                    }
                    <div className={`controls-search controls-container  ${search ? "open" : ""}`.trim()}>
                        <div className='position-relative d-flex flex-row flex-grow-1 shadow-sm radius radius py-1 py-xl-1 px-xl-1 tt-norms-bold cursor-pointer control-wrapper user-select-none'>
                            <div className='d-flex flex-row justify-content-start justify-content-lg-center gap-1 flex-grow-1'>
                                <div className={`d-flex flex-column justify-content-center align-items-center control-icon ${(search && !onBoarding) ? "active tt-norms-semi-bolder" : ""} ${search ? "open" : ""}`.trim()}
                                    onClick={() => {
                                        !onBoarding && setSearch(!search)
                                    }}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='my-1' />
                                    {search && <span className='small inter-medium'>{t('explore.filter.search.title')}</span>}
                                </div>
                                {(search) && <div className="search">
                                    <Form className='d-flex flex-row flex-grow-1 radius overflow-hidden' onSubmit={(e) => {
                                        e.preventDefault();
                                        !onBoarding && getInterestSites({ title: titleSearch, categoryIds: categoryList })
                                    }}>
                                        <Form.Control type="text" className='shadow-none  h-100 text-white' onChange={(e) => setTitleSearch(e.target.value)} value={titleSearch || ""} placeholder={t('explore.filter.search.placeholder')} />
                                        {titleSearch && <Button variant='none' className='d-flex align-items-center shadow-none py-0 m-0 px-2 clear' onClick={() => {
                                            setTitleSearch()
                                            getInterestSites({ title: "", categoryIds: categoryList })
                                        }}>
                                            <FontAwesomeIcon icon={"fa-solid fa-xmark"} className='text-dark-green opacity-50' width={14} />
                                        </Button>}
                                    </Form>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className='controls-container'>
                        <div className="d-flex flex-column gap-1 align-items-start">
                            <div id="point-ve" className={`position-relative d-flex flex-row flex-grow-1 justify-content-center align-items-center shadow-sm radius radius tt-norms-bold py-1 py-xl-1 px-xl-1 charge cursor-pointer user-select-none control-wrapper ${chargePoint ? "active tt-norms-semi-bolder" : ""}`.trim()} onClick={() => {
                                if (!onBoarding) {
                                    setchargePoint(!chargePoint)
                                    chargePoint && handleActiveMarker()
                                    setActiveMarker();
                                    setActiveMarkerDetail();
                                    (chargePoint && chargePointDetail) && setChargePointDetail()
                                    setMenuActive({
                                        ...menuActive,
                                        charger: chargePoint ? false : !menuActive.charger
                                    })
                                }
                            }}>
                                <div className='d-flex flex-column align-items-center justify-content-center justify-content-center radius'>
                                    <div className={`d-flex flex-column align-items-center justify-content-center control-icon ${chargePoint ? "active" : ""} ${menuActive.charger ? "open" : ""}`}>
                                        <Image src='/img/svg/charge-points.svg' className='my-1' />
                                        {menuActive.charger && <span className='small inter-medium'>Point-VE</span>}
                                    </div>
                                </div>
                            </div>
                            <div id='catFilter' className={`position-relative d-flex flex-row flex-grow-1 justify-content-center align-items-center shadow-sm radius radius py-1 py-xl-1 px-xl-1 tt-norms-bold cursor-pointer user-select-none control-wrapper ${category ? "drop-desktop" : ""}`.trim()}>
                                <div className='d-flex flex-column align-items-center justify-content-center radius'>
                                    <div className={`d-flex flex-column align-items-center justify-content-center radius control-icon ${category ? "drop mb-1 tt-norms-semi-bolder open active" : ""}`.trim()} onClick={() => {
                                        if (!onBoarding) {
                                            !category && setZone(false);
                                            setCategory(!category);
                                        }
                                    }}>
                                        <Image src='/img/svg/grid.svg' className='my-1' width={28} />
                                        {category && <span className='small inter-medium'>{t('explore.filter.category')}</span>}
                                    </div>
                                    {(category && categories) && (
                                        <div className='d-flex flex-column gap-1 category-drop pb-1 tt-norms-bold'>
                                            {categories.map((cat, i) =>
                                                <div key={i} className={`d-flex flex-column justify-content-center align-items-center radius category cursor-pointer p-1 py-sm-2 ${categoryList.includes(cat?.id) ? "active" : ""}`.trim()} onClick={() => {
                                                    if (!onBoarding) {
                                                        handleCategory(cat?.id);
                                                        handleActiveMarker();
                                                    }
                                                }}>
                                                    <Image src={cat?.logoUrl} className='my-1' />
                                                    <span className='small inter-medium text-center'>{cat?.name}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {userUbication && <div id='zoneFilter' className='position-relative d-flex flex-row flex-grow-1 justify-content-center align-items-center shadow-sm radius radius py-1 py-xl-1 px-xl-1 tt-norms-bold cursor-pointer user-select-none control-wrapper'>
                                <div className='d-flex flex-column align-items-center justify-content-center justify-content-center radius'>
                                    <div className={`d-flex flex-column align-items-center justify-content-center control-icon ${zone ? "active open" : ""}`} onClick={() => {
                                        if (!onBoarding) {
                                            setZone(!zone);
                                            !zone && setCategory(false)
                                        }
                                    }}>
                                        <FontAwesomeIcon icon="fa-solid fa-location-crosshairs" className='my-1' />
                                        {zone && <span className='small inter-medium'>{t('explore.filter.zone')}</span>}
                                    </div>
                                    {zone && (
                                        <div className='pt-2 d-flex flex-column justify-content-center w-100 justify-content-center align-items-center'>

                                            <div className='slider-container mb-2'>
                                                <Slider
                                                    vertical
                                                    defaultValue={searchRange}
                                                    min={1}
                                                    max={10}
                                                    onAfterChange={(val) => { !onBoarding && handleSearchRange(val) }}
                                                    onChange={(val) => setSliderLabel(val)}
                                                />
                                            </div>
                                            <p className='mb-0 mt-1 inter-regular text-white'>{sliderLabel === 10 && "+"}{sliderLabel}km</p>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div id='screenFilter' className={`position-relative d-flex flex-row flex-grow-1 justify-content-center align-items-center shadow-sm radius radius py-1 py-xl-1 px-xl-1 tt-norms-bold cursor-pointer user-select-none ${fullScreen ? "control-wrapper fullscreen" : "control-wrapper"}`} onClick={() => {
                                if (!onBoarding) {
                                    setFullScreen(!fullScreen)
                                    if (fullScreen) {
                                        document.body.classList.add("spaceNavOverlap")
                                        document.body.classList.remove("overflow-hidden")
                                        if (document.fullscreenElement) {
                                            document.exitFullscreen();
                                        }
                                    } else {
                                        window.scrollTo(0, 0)
                                        document.body.classList.remove("spaceNavOverlap")
                                        document.body.classList.add("overflow-hidden")
                                        document.documentElement.requestFullscreen();
                                    }
                                }
                            }}>
                                <div className='d-flex flex-column align-items-center justify-content-center justify-content-center radius'>
                                    {/* <div className={`d-flex flex-column justify-content-center align-items-center control-icon ${fullScreen ? "active" : ""}`}> */}
                                    <div className="d-flex flex-column justify-content-center align-items-center control-icon">
                                        <FontAwesomeIcon icon={`fa-solid ${!fullScreen ? "fa-expand" : "fa-compress"}`} className='my-1' />
                                        {/* {fullScreen && <span className='small'>{!fullScreen ? t('explore.filter.fullscreen.expand') : t('explore.filter.fullscreen.minimize')}</span>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {onBoarding && <div className='map-overlay' />}
                    {isLoaded && <GoogleMap
                        onLoad={onLoadMap}
                        mapContainerStyle={containerStyle}
                        mapContainerClassName='map-container'
                        center={map ? { lat: map?.getCenter()?.lat(), lng: map?.getCenter()?.lng() } : { lat: 39.620349, lng: 2.905260 }}
                        zoom={10}
                        onDragStart={() => {
                            setMenuActive({
                                charger: false,
                            })
                            setSearch(false);
                            setCategory(false);
                            setZone(false);
                        }}
                        onClick={() => {
                            setMenuActive({
                                charger: false,
                            })
                            setSearch(false);
                            setCategory(false);
                            setZone(false);
                            setActiveMarkerDetail();
                            setActiveMarker()
                        }}
                        options={{
                            clickableIcons: false,
                            disableDefaultUI: true,
                            fullscreenControl: false,
                            scrollwheel: true,
                            gestureHandling: "greedy",
                            styles: [
                                {
                                    "featureType": "poi.business",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi.school",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi.sports_complex",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                            ]
                        }}
                        className="position-relative"
                    >
                        {userUbication && <Marker position={userUbication}></Marker>}
                        {(userUbication && searchRange < 10) && (
                            <Circle
                                center={userUbication}
                                radius={searchRange * 1000}
                                options={{
                                    strokeColor: '#ECE83A',
                                    fillColor: '#2E2A2B',
                                    fillOpacity: '0.4'
                                }}
                            />
                        )}
                        {(interestSites) &&
                            <MarkerClusterer
                                options={{
                                    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                                }}
                                imagePath='/img/icons/wheel-icon.svg'
                                imageExtension='svg'
                                styles={[
                                    {
                                        url: "/img/map/cluster/site-cluster.svg",
                                        width: 52,
                                        height: 52,
                                        textColor: "#2E2A2B",
                                        fontFamily: isDesktop ? "norms-bolder" : "norms-bold",
                                        textSize: 18
                                    }
                                ]}
                            >
                                {(clusterer) =>
                                    <>
                                        {interestSites.map((site, i) => (
                                            ((site?.latitude && site?.longitude) &&
                                                <Marker
                                                    key={i}
                                                    position={{ lat: parseFloat(site?.latitude), lng: parseFloat(site?.longitude) }}
                                                    icon={findMarker(site.mainCategory, site?.chargingPoint)}
                                                    onClick={() => {
                                                        setCardOpen(true)
                                                        setSheetOpen(true)
                                                        setChargePointDetail();
                                                        handleActiveMarker(site.internalReference, false, site)
                                                    }}
                                                    clusterer={clusterer} />
                                            )
                                        ))}
                                    </>
                                }
                            </MarkerClusterer>
                        }
                        {(chargePoint && chargePointsDisplay || chargePoint && chargePointsList) && (
                            <MarkerClusterer
                                options={{
                                    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                                }}
                                imagePath='/img/icons/wheel-icon.svg'
                                imageExtension='svg'
                                styles={[
                                    {
                                        url: "/img/map/cluster/charger-cluster.svg",
                                        width: 52,
                                        height: 52,
                                        textColor: "#2E2A2B",
                                        fontFamily: "norms-bolder",
                                        textSize: 16
                                    }
                                ]}
                            >
                                {(clusterer) =>
                                    <>
                                        {(chargePoint && chargePointsList) &&
                                            (chargePointsDisplay || chargePointsList)?.map((point, i) =>
                                                <Marker
                                                    key={i}
                                                    position={{ lat: parseFloat(point?.latitude), lng: parseFloat(point?.longitude) }}
                                                    icon={`/img/svg/categories/markers/charge-point-marker.svg`}
                                                    onClick={() => {
                                                        timeOutRef.current && clearTimeout(timeOutRef.current)
                                                        handleActiveMarker(point?.id, false, point, true);
                                                        setCardOpen(isDesktop ? (chargePointDetail?.id === point?.id ? false : true) : false)
                                                        setSheetOpen(true)
                                                    }}
                                                    clusterer={clusterer} />
                                            )}
                                    </>
                                }
                            </MarkerClusterer>
                        )}
                    </GoogleMap>}
                    {!fullScreen && (
                        <div className={`desktop-card bg-beige d-none d-lg-flex flex-column ${cardOpen ? "open" : "close"}`} onScroll={(e) => {
                            const card = document.getElementsByClassName('desktop-card')?.[0];
                            const overlay = document.getElementsByClassName('overlay')?.[0];
                            if (cardOpen) {
                                if ((card?.scrollTop / (card?.scrollHeight - card?.clientHeight) >= 0.99) || !(card?.scrollHeight > card?.clientHeight)) {
                                    overlay?.classList.replace('opacity-100', 'opacity-0')
                                } else {
                                    overlay?.classList.add('opacity-0')
                                    overlay?.classList.replace('opacity-0', 'opacity-100')
                                }
                            }
                        }}>
                            <div className='position-relative h-100 content-card'>
                                {(!activeMarkerDetail && !chargePointDetail) && (
                                    <div className='position-sticky top-0 end-0 control'>
                                        {cardOpen ? (
                                            <FontAwesomeIcon icon={faSquareMinus} width={28} height={28} className='position-absolute top-0 end-0 mt-2 me-2' onClick={() => !onBoarding && setCardOpen(false)} />
                                        ) : (
                                            <FontAwesomeIcon icon={faSquarePlus} width={28} height={28} className='position-absolute top-0 end-0 mt-2 me-2' onClick={() => !onBoarding && setCardOpen(true)} />
                                        )}
                                    </div>
                                )}
                                <div className="position-relative desktop-card-container h-100">
                                    {(!activeMarker && !chargePointDetail) && (
                                        <>
                                            <div className='pt-4'>
                                                <div className='px-5'>
                                                    <h1 className='fitzgerald-regular letter-spacing-1 fs-2'>{t('explore.title')}</h1>
                                                    <p className='inter-regular'>{t('explore.description')}</p>
                                                </div>
                                                <hr className='my-4 mx-5' />
                                                {((interestSites?.length > 0 && displaySites?.length > 0 || !interestSites) && !hidePlaceholder) && (
                                                    <div className='px-5 mb-4 sites-content' id='sites-container'>
                                                        <div className='d-flex flex-row align-items-center mb-3'>
                                                            {categoryDetail && <Image src={categoryDetail?.logoUrl} className='category-icon me-2' />}
                                                            <h2 className='fitzgerald-regular letter-spacing-1 mb-0'>{categoryDetail ? categoryDetail?.name : categoryList?.length > 1 ? t('explore.card.search.results') : t('explore.card.search.latest')}</h2>
                                                        </div>
                                                        {!interestSites && !hidePlaceholder ? (
                                                            <Row className='gx-2 gy-3'>
                                                                {[1, 2, 3, 4].map((plcaeholder, i) => (
                                                                    <Col lg={6} key={i}>
                                                                        <NewContentCardPlaceholder hideLink />
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        ) : interestSites?.length > 0 ? (
                                                            <Row className='gx-2 gy-3' id="sites-content">

                                                                {displaySites?.map((site, i) => (
                                                                    (site?.latitude && site?.longitude) && (
                                                                        // <Col lg={6} key={i}>
                                                                        //     <ContentCards
                                                                        //         id={site?.id}
                                                                        //         link={`${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`}
                                                                        //         noRedirect
                                                                        //         size={"lg"}
                                                                        //         category={site?.categoryLogo}
                                                                        //         title={site?.name}
                                                                        //         img={site?.images?.[0]?.imgUrl}
                                                                        //         description={site?.shortDescription}
                                                                        //         type={"INTERESTSITE"}
                                                                        //         optionAction={() => {
                                                                        //             if (!onBoarding) {
                                                                        //                 if (activeMarker === site.internalReference) {
                                                                        //                     navigate(`/${i18n.language}/${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`)
                                                                        //                 }
                                                                        //                 focusMarker(site?.latitude, site?.longitude, site?.internalReference, site)
                                                                        //             }
                                                                        //         }}
                                                                        //         onClick={(e) => {
                                                                        //             if (!onBoarding) {
                                                                        //                 if (activeMarker === site.internalReference) {
                                                                        //                     navigate(`/${i18n.language}/${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`)
                                                                        //                 }
                                                                        //                 focusMarker(site?.latitude, site?.longitude, site?.internalReference, site)
                                                                        //             }
                                                                        //         }}
                                                                        //     />
                                                                        // </Col>
                                                                        <Col xs={6} key={i}>
                                                                            <NewContentCard
                                                                                theme={"laurel"}
                                                                                image={site?.images?.[0]?.imgUrl}
                                                                                category={site?.categorySlug}
                                                                                title={site?.name}
                                                                                slug={`${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`}
                                                                                type={"INTERESTSITE"}
                                                                                id={site?.id}
                                                                                noRedirect
                                                                                hideLink
                                                                                data={site}
                                                                                onClick={(e) => {
                                                                                    if (!onBoarding) {
                                                                                        if (activeMarker === site.internalReference) {
                                                                                            navigate(`/${i18n.language}/${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`)
                                                                                        }
                                                                                        focusMarker(site?.latitude, site?.longitude, site?.internalReference, site)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    )
                                                                ))}
                                                                {showPagination && (
                                                                    isNaN(parseInt(searchParams.get('page'))) ? (
                                                                        <ReactPaginate
                                                                            pageCount={pagination.numPages}
                                                                            onPageChange={(event) => {
                                                                                setPagination({ ...pagination, currentPage: event.selected + 1 })
                                                                                setSearchParams({ ...searchParams, "page": event.selected + 1 })
                                                                            }}
                                                                            pageRangeDisplayed={3}
                                                                            marginPagesDisplayed={1}
                                                                            containerClassName='pagination gap-1'
                                                                            pageClassName='page'
                                                                            previousClassName='page'
                                                                            previousLabel="<"
                                                                            prevRel={"prev"}
                                                                            nextClassName='page'
                                                                            nextLabel=">"
                                                                            nextRel={"next"}
                                                                            breakClassName='page'
                                                                            hrefBuilder={(page) =>
                                                                                `${window.origin}/${i18n.language}/${t('pages.explore')}${categoryList.length === 1 ? `/${getCategory(categoryList?.[0])?.slugs?.[i18n.language]}` : ""}${searchParams && `?page=${page}`}`
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <ReactPaginate
                                                                            pageCount={pagination.numPages}
                                                                            onPageChange={(event) => {
                                                                                setPagination({ ...pagination, currentPage: event.selected + 1 })
                                                                                setSearchParams({ ...searchParams, "page": event.selected + 1 })
                                                                            }}
                                                                            pageRangeDisplayed={3}
                                                                            marginPagesDisplayed={1}
                                                                            containerClassName='pagination gap-1'
                                                                            pageClassName='page'
                                                                            previousClassName='page'
                                                                            previousLabel="<"
                                                                            prevRel={"prev"}
                                                                            nextClassName='page'
                                                                            nextLabel=">"
                                                                            nextRel={"next"}
                                                                            breakClassName='page'
                                                                            initialPage={pagination?.currentPage - 1}
                                                                            hrefBuilder={(page) =>
                                                                                `${window.origin}/${i18n.language}/${t('pages.explore')}${categoryList.length === 1 ? `/${getCategory(categoryList?.[0])?.slugs?.[i18n.language]}` : ""}${searchParams && `?page=${page}`}`
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Row>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                )}
                                                {((interestSites?.length > 0 && displaySites?.length > 0 || !interestSites) && !hidePlaceholder) && <hr className='my-4 opacity-75' />}
                                                <div className='px-5'>
                                                    <p className='mb-3 h2 fitzgerald-regular letter-spacing-1'>{t('explore.card.search.suggested')}</p>
                                                    <div className="sponsor position-relative">
                                                        <Link href={t('pages.promos')} className="h-100 " noRedirect>
                                                            <div className="position-relative rounded-0 border-0 card-video" onClick={() => {
                                                                user ? navigate(`/${i18n.language}/${t('pages.profile')}/${t('pages.favourites')}`) : favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                            }}>
                                                                <video autoPlay loop muted playsInline className="radius">
                                                                    <source autoPlay src={banners?.desktop} type={"video/mp4"} />
                                                                </video>
                                                                <Button variant="none" className="position-absolute start-50 translate-middle-x text-white tt-norms-bold px-5 shadow-none radius">{t('sponsorBanner.button')}</Button>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {cardOpen && <div className='d-flex align-items-center justify-content-center position-sticky overlay opacity-100'>
                                                <div className='mouse'>
                                                    <span className='scroll'></span>
                                                </div>
                                            </div>}
                                        </>
                                    )}
                                    {(activeMarkerDetail && MarkerContentShow === 1) && (
                                        <div className='d-flex flex-column justify-content-between site-detail h-100 position-relative'>
                                            <div className='header'>
                                                <div className='d-flex flex-row justify-content-between align-items-center ps-4 bg-beige'>
                                                    <p className='mb-0 fitzgerald-regular letter-spacing-1 fs-3 clamp-1'>{activeMarkerDetail?.name}</p>
                                                    <div className='d-flex '>
                                                        <div className={`py-4 px-4 border-end border-light-coal cursor-pointer ${MarkerContentShow === 1 ? "shadow-btn-right" : ""}`.trim()} onClick={() => setMarkerContentShow(1)}>
                                                            <Image width={32} src={activeMarkerDetail?.categoryLogo} />
                                                        </div>
                                                        {(chargePoint && activeMarkerDetail?.chargingPointDTO) && <div className={`py-4 px-4 border-end border-light-coal cursor-pointer user-select-none ${MarkerContentShow === 2 ? "shadow-btn-left" : ""}`.trim()} onClick={() => setMarkerContentShow(2)}>
                                                            <Image width={32} src={"/img/svg/ve-icon.svg"} />
                                                        </div>}
                                                        <div className={`py-4 px-4 cursor-pointer d-flex align-items-center`} onClick={() => {
                                                            setActiveMarker();
                                                            setActiveMarkerDetail();
                                                            setMarkerContentShow(1);
                                                        }}>
                                                            <FontAwesomeIcon width={28} height={28} icon={"fa-solid fa-xmark"} className='border border-2 border-coal text-coal opacity-50 radius' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='m-0' />
                                            </div>
                                            <div className='d-flex flex-column flex-grow-1 pt-4 overflow-auto content'>
                                                <p className='mb-0 px-4 inter-regular fs-5'>{activeMarkerDetail?.shortDescription}</p>
                                                <div>
                                                    {(activeMarkerDetail?.images?.length > 1) ? (
                                                        <DesktopSwiper className='w-100 my-4' slidesPerView={1.175} spaceBetween={8} >
                                                            {activeMarkerDetail?.images?.map((img, i) =>
                                                                <SwiperSlide key={i}>
                                                                    <Image src={img?.imgUrl} draggable={false} className='radius w-100 site-image' />
                                                                </SwiperSlide>
                                                            )}
                                                        </DesktopSwiper>
                                                    ) : activeMarkerDetail?.images?.length === 1 && (
                                                        <div className='px-4 my-4'>
                                                            <Image src={activeMarkerDetail?.images?.[0]?.imgUrl} className='radius site-image' />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='actions-menu'>
                                                <hr className='m-0' />
                                                <div className='d-flex flex-row justify-content-between p-3 bg-beige'>
                                                    <div className='d-flex flex-row'>
                                                        <a className='px-4 cursor-pointer' href={`https://www.google.com/maps/dir/?api=1&destination=${activeMarkerDetail?.latitude},${activeMarkerDetail?.longitude}`} target='_blank'>
                                                            <div className='p-2 action'>
                                                                <FontAwesomeIcon icon={"fa fa-diamond-turn-right"} className='text-coal' />
                                                            </div>
                                                        </a>
                                                        <hr className='vertical-separator m-0' />
                                                        {activeMarkerDetail.phone && <a href={`tel:${activeMarkerDetail.phone}`} className='px-4 cursor-pointer'>
                                                            <div className='p-2 action'>
                                                                <FontAwesomeIcon icon={"fa-solid fa-phone"} className='text-coal' />
                                                            </div>
                                                        </a>}
                                                        <hr className='vertical-separator m-0' />
                                                        <div className="content-card custom-fav shadow-none w-auto px-4 cursor-pointer d-flex align-items-center">
                                                            <div className={`fav-container p-1 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                                                if (user) {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    if (isFavoriteActive) {
                                                                        setAddingFlag(true);
                                                                        removeFavourite("INTERESTSITE", activeMarkerDetail?.id);
                                                                    } else {
                                                                        setAddingFlag(true);
                                                                        handleFavourite("INTERESTSITE", activeMarkerDetail?.id);
                                                                    }
                                                                } else {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                                }
                                                            }}>
                                                                <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer`.trim()} />
                                                            </div>
                                                        </div>
                                                        <hr className='vertical-separator m-0' />
                                                    </div>
                                                    <Link href={`${t('pages.explore')}/${activeMarkerDetail?.categorySlug}/${activeMarkerDetail?.slugs?.[i18n.language]}`} className="d-flex see-more align-items-center flex-grow-1 justify-content-center">
                                                        <span className={"text-uppercase new-link-coal inter-regular letter-spacing-2 mb-2"}>{t('general.components.seeMore')}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(chargePointDetail || (activeMarkerDetail && MarkerContentShow === 2)) && (
                                        <div className='d-flex flex-column justify-content-between site-detail h-100 position-relative'>
                                            <div className='header'>
                                                <div className='d-flex flex-row justify-content-between align-items-center ps-4 bg-beige'>
                                                    <p className='mb-0 fitzgerald-regular letter-spacing-1 fs-3 clamp-1'>{t('explore.card.chargePoint.title')}</p>
                                                    <div className='d-flex '>
                                                        {activeMarkerDetail?.chargingPoint && <div className='py-4 px-4 border-end border-light-coal cursor-pointer' onClick={() => setMarkerContentShow(1)}>
                                                            <Image width={32} src={activeMarkerDetail?.categoryLogo} />
                                                        </div>}
                                                        {(chargePoint) && <div className={`py-4 px-4 cursor-pointer user-select-none ${(activeMarkerDetail?.chargingPoint && MarkerContentShow === 2) ? "border-end border-light-coal shadow-btn-left" : "shadow-btn-right"}`.trim()} onClick={() => setMarkerContentShow(2)}>
                                                            <Image width={32} src={"/img/svg/ve-icon.svg"} />
                                                        </div>}
                                                        <div className={`py-4 px-4 cursor-pointer d-flex align-items-center`} onClick={() => {
                                                            setActiveMarker();
                                                            setActiveMarkerDetail();
                                                            setMarkerContentShow(1);

                                                            if (categoryList.length === 0) {
                                                                setSheetOpen(false)
                                                                setCardOpen(false)
                                                                timeOutRef.current = setTimeout(() => {
                                                                    setChargePointDetail();
                                                                }, !isDesktop ? 500 : 300);
                                                            } else {
                                                                setChargePointDetail();
                                                            }
                                                        }}>
                                                            <FontAwesomeIcon width={28} height={28} icon={"fa-solid fa-xmark"} className='border border-2 border-coal text-coal opacity-50 radius' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='m-0' />
                                            </div>
                                            <div className='d-flex flex-column flex-grow-1 pt-4 overflow-auto content'>
                                                <p className='tt-norms-bolder mb-1 px-4 tt-norms h4'>{(chargePointDetail || activeMarkerDetail?.chargingPointDTO)?.name}</p>
                                                <p className='mb-0 px-4 tt-norms-bold tt-norms h6'>{(chargePointDetail || activeMarkerDetail?.chargingPointDTO)?.address}</p>
                                                <div>
                                                    {(chargePointDetail?.images?.length > 1 || activeMarkerDetail?.chargingPointDTO?.images?.length > 1) ? (
                                                        <DesktopSwiper className='w-100 my-4' slidesPerView={1.175} spaceBetween={8} >
                                                            {(chargePointDetail?.images || activeMarkerDetail?.chargingPointDTO?.images)?.map((img, i) =>
                                                                <SwiperSlide key={i}>
                                                                    <Image src={img?.url || img} draggable={false} className='radius w-100 site-image' />
                                                                </SwiperSlide>
                                                            )}
                                                        </DesktopSwiper>
                                                    ) : (chargePointDetail?.images?.length === 1 || activeMarkerDetail?.chargingPointDTO?.images?.length === 1) && (
                                                        <div className='px-4 my-4'>
                                                            <Image src={chargePointDetail?.images?.[0] || activeMarkerDetail?.chargingPointDTO?.images?.[0]} className='radius site-image' />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='charger-table mb-4'>
                                                    {(chargePointDetail?.chargingPointChargerTypes || activeMarkerDetail?.chargingPointDTO?.chargingPointChargerTypes)?.map((charger, i) =>
                                                        <div key={i} className={`d-flex flex-row border-top border-1 border-light-coal ${(chargePointDetail?.chargingPointChargerTypes || activeMarkerDetail?.chargingPointDTO?.chargingPointChargerTypes)?.length - 1 === i ? "border-bottom" : ""}`.trim()}>
                                                            <div className='d-flex tt-norms-bolder mb-0 h5 p-3 border-end border-1 border-light-coal text-center quantity align-items-center justify-content-center'>{charger?.number}</div>
                                                            <div className='d-flex flex-row align-items-center px-3 gap-2'>
                                                                <Image src={charger?.picture} className='charger-ico py-1' />
                                                                <div className='d-flex flex-row align-items-center'>
                                                                    <p className='tt-norms-bolder mb-0'>{charger?.power}</p>
                                                                    <p className='mb-0 tt-norms-bold'>- {charger?.chargerType}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='px-4'>
                                                    {(chargePointDetail?.pointType || activeMarkerDetail?.chargingPointDTO?.pointType) && <p className='tt-norms h6 mb-4'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.type')}</span> {chargePointDetail?.pointType || activeMarkerDetail?.chargingPointDTO?.pointType}</p>}
                                                    {(chargePointDetail?.ubication || activeMarkerDetail?.chargingPointDTO?.ubication) && <p className='tt-norms h6 mb-4'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.ubication')}</span> {chargePointDetail?.ubication || activeMarkerDetail?.chargingPointDTO?.ubication}</p>}
                                                    {(chargePointDetail?.phone || activeMarkerDetail?.chargingPointDTO?.phone) && <p className='tt-norms h6 mb-4'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.phone')}</span> {chargePointDetail?.phone || activeMarkerDetail?.chargingPointDTO?.phone}</p>}
                                                    {chargePointDetail?.siteUrl && <p className='tt-norms h6 mb-4'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.website')}</span> {chargePointDetail?.siteUrl || activeMarkerDetail?.chargingPointDTO?.siteUrl}</p>}
                                                    {chargePointDetail?.chargingPointSchedules && (
                                                        <div className='tt-norms h6 mb-4'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.schedule')}</span>
                                                            <ul className='tt-norms mb-0'>
                                                                {(chargePointDetail?.chargingPointSchedules || activeMarkerDetail?.chargingPointDTO?.chargingPointSchedules)?.map((schedule, i) =>
                                                                    schedule?.allDay ?
                                                                        <li key={i}>{schedule?.dayOfTheWeek}: 24h</li>
                                                                        :
                                                                        <li key={i}>{schedule?.dayOfTheWeek}: {schedule?.start?.split(":")[0]}:{schedule?.start?.split(":")[1]} - {schedule?.end?.split(":")[0]}:{schedule?.end?.split(":")[1]}</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='actions-menu charger'>
                                                <hr className='m-0' />
                                                <div className='d-flex flex-row justify-content-between flex-grow-1'>
                                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${chargePointDetail?.latitude || activeMarkerDetail?.chargingPointDTO?.latitude},${chargePointDetail?.longitude || activeMarkerDetail?.chargingPointDTO?.longitude}`} target='_blank' className='d-flex px-2 cursor-pointer py-2 flex-grow-1 justify-content-center border-light-coal'>
                                                        <div className='p-2 action'>
                                                            <FontAwesomeIcon icon={"fa fa-diamond-turn-right"} className='text-coal' />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {(!fullScreen) && (
                        <div className={`explore-sheet d-block d-lg-none ${sheetOpen ? "open" : "close"}`} id='sheet'>
                            <div className='py-3 d-flex flex-column justify-content-center align-items-center gap-1 bg-beige drag-sheet'
                                onTouchMoveCapture={(e) => {
                                    if (!onBoarding) {
                                        handleSheetTouch(e.changedTouches[0], e)
                                    }
                                }}
                                onTouchStartCapture={(e) => {
                                    if (!onBoarding) {
                                        e.stopPropagation()
                                        document.body.style.overflowY = 'hidden'
                                        document.getElementById('sheet').style.transition = ".0s"
                                        handleStartTouch(e.touches[0]);
                                    }
                                }}
                                onTouchEnd={(e) => {
                                    if (!onBoarding) {
                                        handleEndTouch(e.changedTouches[0])
                                        setTimeout(() => {
                                            document.body.style = ""
                                        }, 150);
                                        setTimeout(() => { blockSheetTouch = false }, 500);
                                    }
                                }}
                                onClick={(e) => {
                                    if (!onBoarding) {
                                        handleSheetClick()
                                    }
                                }}
                            >
                                {!sheetSlideIcon && <FontAwesomeIcon icon="fa-solid fa-arrow-up" width={24} height={24} className='text-black drag-arrow' />}
                                <span className='drag-icon'></span>
                                {sheetSlideIcon && <FontAwesomeIcon icon="fa-solid fa-arrow-down" width={24} height={24} className='text-black drag-arrow' />}
                            </div>
                            <hr className='my-0' />
                            {(!activeMarker && !chargePointDetail) && (
                                <div className='sheet-content bg-beige'>
                                    <Container className='mt-3'>
                                        <p className='fs-3 fitzgerald-bold letter-spacing-1'>{t('explore.title')}</p>
                                        <p className='inter-regular small'>{t('explore.description')}</p>
                                    </Container>
                                    <hr className="opacity-25 my-4" />
                                    {(interestSites?.length > 0 || !interestSites) && !hidePlaceholder && (
                                        <Container>
                                            <div className='d-flex flex-row align-items-center mb-3'>
                                                {categoryDetail && <Image src={categoryDetail?.logoUrl} className='category-icon me-2' />}
                                                <p className='fs-4 fitzgerald-bold letter-spacing-1 mb-0'>{categoryDetail ? categoryDetail?.name : categoryList?.length > 1 ? t('explore.card.search.results') : t('explore.card.search.latest')}</p>
                                            </div>
                                            <Row className='g-3 sites-container'>
                                                {!displayLoadMoreSites && [0].map((val, i) => (
                                                    <Col xs={12} sm={6} key={i} className='radius bg-white h-100 d-flex flex-column'>
                                                        <NewContentCardPlaceholder hideLink />
                                                    </Col>
                                                ))}

                                                {displayLoadMoreSites?.map((site, i) =>
                                                    <Col xs={12} sm={6} key={i} className='flex-column' onClick={() => {
                                                        if (activeMarker === site.internalReference) {
                                                            navigate(`/${i18n.language}/${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`)
                                                        }
                                                        setSheetRefScroll(document.getElementsByClassName('sheet-content')[0].scrollTop)
                                                        focusMarker(site?.latitude, site?.longitude, site?.internalReference);
                                                        handleActiveMarker(site?.id, true, site, false)
                                                        // setSheetOpen(false)
                                                    }}>
                                                        <NewContentCard
                                                            theme={"laurel"}
                                                            image={site?.images?.[0]?.imgUrl}
                                                            category={site?.categorySlug}
                                                            title={site?.name}
                                                            slug={`${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`}
                                                            type={"INTERESTSITE"}
                                                            id={site?.id}
                                                            noRedirect
                                                            hideLink
                                                            data={site}
                                                            onClick={(e) => {
                                                                if (!onBoarding) {
                                                                    if (activeMarker === site.internalReference) {
                                                                        navigate(`/${i18n.language}/${t('pages.explore')}/${site.categorySlug}/${site.slugs[i18n.language]}`)
                                                                    }
                                                                    focusMarker(site?.latitude, site?.longitude, site?.internalReference, site)
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                )}
                                                {displayLoadMoreSites?.length < loadMore.totalItems && (
                                                    <Button variant='none' className='shadow-none d-flex flex-column align-items-center' onClick={() => { setLoadMore({ ...loadMore, offset: loadMore.offset + 1 }) }}>
                                                        <span className='h6 tt-norms-semi-bolder mb-1'>{t("explore.card.loadMore")}</span>
                                                        <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='text-black' width={20} />
                                                    </Button>
                                                )}
                                            </Row>
                                        </Container>
                                    )}
                                    {(interestSites?.length > 0 || !interestSites) && !hidePlaceholder && <hr className="opacity-75 my-4" />}
                                    <Container className='mb-4'>
                                        <p className='fs-4 fitzgerald-bold letter-spacing-1 mb-3'>{t('explore.card.search.suggested')}</p>
                                        <div className="sponsor position-relative">
                                            <Link href={t('pages.promos')} className="h-100" noRedirect>
                                                <div className="position-relative rounded-0 border-0 card-video" onClick={() => {
                                                    user ? navigate(`/${i18n.language}/${t('pages.profile')}/${t('pages.favourites')}`) : favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                }}>
                                                    <video autoPlay loop muted playsInline className="radius">
                                                        <source autoPlay src={banners?.mobile} type={"video/mp4"} />
                                                    </video>
                                                </div>
                                            </Link>
                                        </div>
                                    </Container>
                                </div>
                            )}
                            {(activeMarkerDetail && MarkerContentShow === 1) && (
                                <div className='d-flex flex-column justify-content-between site-detail position-relative'>
                                    <div className='header'>
                                        <div className='d-flex flex-row justify-content-between align-items-center ps-4 bg-beige'>
                                            <p className='mb-0 fitzgerald-bold text-spacing-1 h4 clamp-1'>{activeMarkerDetail?.name}</p>
                                            <div className='d-flex '>
                                                <div className={`d-flex align-items-center border-end border-light-coal item ${MarkerContentShow === 1 ? "shadow-btn-right" : ""}`} onClick={() => setMarkerContentShow(1)}>
                                                    <Image width={30} src={activeMarkerDetail?.categoryLogo} />
                                                </div>
                                                {(chargePoint && activeMarkerDetail?.chargingPointDTO) && <div className={`d-flex align-items-center border-end border-light-coal cursor-pointer user-select-none item ${MarkerContentShow === 2 ? "shadow-btn-left" : ""}`} onClick={() => setMarkerContentShow(2)}>
                                                    <Image width={30} src={"/img/svg/ve-icon.svg"} />
                                                </div>}
                                                <div className={`d-flex align-items-center cursor-pointer item`} onClick={() => {
                                                    setMarkerContentShow(1);
                                                    setSheetOpen(false);

                                                    if (sheetOpen) {
                                                        timeOutRef.current = setTimeout(() => {
                                                            setActiveMarker();
                                                            setActiveMarkerDetail();
                                                            setChargePointDetail();
                                                        }, !isDesktop ? 500 : 300);
                                                    } else {
                                                        setActiveMarker();
                                                        setActiveMarkerDetail();
                                                        setChargePointDetail();
                                                    }
                                                }}>
                                                    <FontAwesomeIcon width={22} height={22} icon={"fa-solid fa-xmark"} className='border border-2 border-coal text-coal opacity-50 radius' />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='m-0' />
                                    </div>
                                    <div className={`flex-column flex-grow-1 pt-4 overflow-auto sheet-content site-content bg-beige`}>
                                        <div>
                                            {(activeMarkerDetail?.images?.length > 1) ? (
                                                <>
                                                    <Swiper
                                                        spaceBetween={8}
                                                        slidesPerView={1.15}
                                                        className='w-100'
                                                        shortSwipes
                                                        longSwipes
                                                        style={{ overflowY: !sheetOpen ? "unset" : "unset" }}
                                                    >
                                                        {activeMarkerDetail?.images?.map((img, i) =>
                                                            <SwiperSlide>
                                                                <Image key={i} src={img?.imgUrl} draggable={false} className='radius w-100' />
                                                            </SwiperSlide>
                                                        )}
                                                    </Swiper>
                                                </>
                                            ) : activeMarkerDetail?.images?.length === 1 && (
                                                <div className='px-4'>
                                                    <Image src={activeMarkerDetail?.images?.[0]?.imgUrl} className='radius site-image' />
                                                </div>
                                            )}
                                        </div>
                                        <p className='mt-4 mb-0 px-4 inter-regular fs-6 pb-4'>{activeMarkerDetail?.shortDescription}</p>
                                    </div>
                                    <div className={`actions-menu`}>
                                        <hr className='m-0' />
                                        <div className='d-flex flex-row justify-content-between p-3 bg-beige'>
                                            <div className='d-flex flex-row'>
                                                <a className='px-3 cursor-pointer d-flex justify-content-center align-items-center' href={`https://www.google.com/maps/dir/?api=1&destination=${activeMarkerDetail?.latitude},${activeMarkerDetail?.longitude}`} target='_blank'>
                                                    <div className='p-1 action'>
                                                        <FontAwesomeIcon icon={"fa fa-diamond-turn-right"} className='text-coal' />
                                                    </div>
                                                </a>
                                                <hr className='vertical-separator m-0' />
                                                <div className="content-card custom-fav shadow-none w-auto px-3 cursor-pointer d-flex align-items-center">
                                                    <div className={`d-flex justify-content-center align-items-center fav-container p-1 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                                        if (user) {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            if (isFavoriteActive) {
                                                                setAddingFlag(true);
                                                                removeFavourite("INTERESTSITE", activeMarkerDetail?.id);
                                                            } else {
                                                                setAddingFlag(true);
                                                                handleFavourite("INTERESTSITE", activeMarkerDetail?.id);
                                                            }
                                                        } else {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                        }
                                                    }}>
                                                        <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer`.trim()} />
                                                    </div>
                                                </div>
                                                <hr className='vertical-separator m-0' />
                                            </div>
                                            <Link href={`${t('pages.explore')}/${activeMarkerDetail?.categorySlug}/${activeMarkerDetail?.slugs?.[i18n.language]}`} className="d-flex see-more align-items-center flex-grow-1 justify-content-center">
                                                <span className={"text-uppercase new-link-coal inter-regular letter-spacing-2"}>{t('general.components.seeMore')}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(chargePointDetail || (activeMarkerDetail && MarkerContentShow === 2)) && (
                                <div className='d-flex flex-column justify-content-between site-detail position-relative'>
                                    <div className='header'>
                                        <div className='d-flex flex-row justify-content-between align-items-center ps-4 bg-beige'>
                                            <p className='mb-0 fitzgerald-bold text-spacing-1 h4 clamp-1'>{t('explore.card.chargePoint.title')}</p>
                                            <div className='d-flex '>
                                                {activeMarkerDetail?.chargingPoint && <div className={`d-flex align-items-center border-end border-light-coal item ${MarkerContentShow === 1 ? "shadow-btn-right" : ""}`.trim()} onClick={() => setMarkerContentShow(1)}>
                                                    <Image width={30} src={activeMarkerDetail?.categoryLogo} />
                                                </div>}
                                                {(chargePoint) && <div className={`d-flex align-items-center border-end border-light-coal cursor-pointer user-select-none item ${MarkerContentShow === 2 ? "shadow-btn-left" : ""}`} onClick={() => setMarkerContentShow(2)}>
                                                    <Image width={30} src={"/img/svg/ve-icon.svg"} />
                                                </div>}
                                                <div className={`d-flex align-items-center cursor-pointer item`} onClick={() => {
                                                    setActiveMarker();
                                                    setActiveMarkerDetail();
                                                    setMarkerContentShow(1);
                                                    setSheetOpen(false)
                                                    setCardOpen(false)

                                                    if (sheetOpen) {
                                                        timeOutRef.current = setTimeout(() => {
                                                            setChargePointDetail();
                                                        }, !isDesktop ? 500 : 300);
                                                    } else {
                                                        setChargePointDetail()
                                                    }
                                                }}>
                                                    <FontAwesomeIcon width={22} height={22} icon={"fa-solid fa-xmark"} className='border border-2 border-coal text-coal opacity-50 radius' />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='m-0' />
                                    </div>
                                    <div className='d-flex flex-column flex-grow-1 pt-4 overflow-auto content'>
                                        <p className='tt-norms-bolder mb-1 px-4 tt-norms h4'>{(chargePointDetail || activeMarkerDetail?.chargingPointDTO)?.name}</p>
                                        <p className='mb-0 px-4 tt-norms-bold tt-norms h6'>{(chargePointDetail || activeMarkerDetail?.chargingPointDTO)?.address}</p>
                                        <div className='d-flex flex-column py-4 sheet-content charger'>
                                            <div className='mb-4'>
                                                {(chargePointDetail?.images?.length > 1 || activeMarkerDetail?.chargingPointDTO?.images?.length > 1) ? (
                                                    <>
                                                        <Swiper
                                                            spaceBetween={8}
                                                            slidesPerView={1.15}
                                                            className='w-100'
                                                            shortSwipes
                                                            longSwipes
                                                            style={{ overflowY: !sheetOpen ? "unset" : "unset" }}
                                                        >
                                                            {(chargePointDetail?.images || activeMarkerDetail?.chargingPointDTO?.images).map((img, i) =>
                                                                <SwiperSlide key={i}>
                                                                    <Image src={img?.url || img} draggable={false} className='radius w-100' />
                                                                </SwiperSlide>
                                                            )}
                                                        </Swiper>
                                                    </>
                                                ) : (chargePointDetail?.images?.length === 1 || activeMarkerDetail?.chargingPointDTO?.images?.length === 1) && (
                                                    <div className='px-4'>
                                                        <Image src={activeMarkerDetail?.images?.[0]?.imgUrl || chargePointDetail?.images?.[0]} className='radius site-image' />
                                                    </div>
                                                )}
                                            </div>
                                            <div className='charger-table mb-4'>
                                                {(chargePointDetail?.chargingPointChargerTypes || activeMarkerDetail?.chargingPointDTO?.chargingPointChargerTypes)?.map((charger, i) =>
                                                    <div key={i} className={`d-flex flex-row border-top border-1 border-light-coal ${(chargePointDetail?.chargingPointChargerTypes || activeMarkerDetail?.chargingPointDTO?.chargingPointChargerTypes)?.length - 1 === i ? "border-bottom" : ""}`.trim()}>
                                                        <div className='d-flex tt-norms-bolder mb-0 h5 p-3 border-end border-1 border-light-coal text-center quantity align-items-center justify-content-center'>{charger?.number}</div>
                                                        <div className='d-flex flex-row align-items-center px-3 gap-2'>
                                                            <Image src={charger?.picture} className='charger-ico py-1' />
                                                            <div className='d-flex flex-row align-items-center'>
                                                                <p className='tt-norms-bolder mb-0'>{charger?.power}</p>
                                                                <p className='mb-0 tt-norms-bold'>- {charger?.chargerType}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='px-4 d-flex flex-column gap-4'>
                                                {(chargePointDetail?.pointType || activeMarkerDetail?.chargingPointDTO?.pointType) && <p className='tt-norms h6 mb-0'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.type')}</span> {chargePointDetail?.pointType || activeMarkerDetail?.chargingPointDTO?.pointType}</p>}
                                                {(chargePointDetail?.ubication || activeMarkerDetail?.chargingPointDTO?.ubication) && <p className='tt-norms h6 mb-0'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.ubication')}</span> {chargePointDetail?.ubication || activeMarkerDetail?.chargingPointDTO?.ubication}</p>}
                                                {(chargePointDetail?.phone || activeMarkerDetail?.chargingPointDTO?.phone) && <p className='tt-norms h6 mb-0'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.phone')}</span> {chargePointDetail?.phone || activeMarkerDetail?.chargingPointDTO?.phone}</p>}
                                                {chargePointDetail?.siteUrl && <p className='tt-norms h6 mb-0'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.website')}</span> {chargePointDetail?.siteUrl || activeMarkerDetail?.chargingPointDTO?.siteUrl}</p>}
                                                {chargePointDetail?.chargingPointSchedules && (
                                                    <div className='tt-norms h6 mb-0'><span className='tt-norms-bolder'>{t('explore.card.chargePoint.schedule')}</span>
                                                        <ul className='tt-norms'>
                                                            {(chargePointDetail?.chargingPointSchedules || activeMarkerDetail?.chargingPointDTO?.chargingPointSchedules)?.map((schedule, i) =>
                                                                schedule?.allDay ?
                                                                    <li key={i}>{schedule?.dayOfTheWeek}: 24h </li>
                                                                    :
                                                                    <li key={i}>{schedule?.dayOfTheWeek}: {schedule?.start?.split(":")[0]}:{schedule?.start?.split(":")[1]} - {schedule?.end?.split(":")[0]}:{schedule?.end?.split(":")[1]}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='actions-menu'>
                                        <hr className='m-0' />
                                        <div className='d-flex flex-row justify-content-between mx-3 h-100'>
                                            <a href={`https://www.google.com/maps/dir/?api=1&destination=${chargePointDetail?.latitude || activeMarkerDetail?.chargingPointDTO?.latitude},${chargePointDetail?.longitude || activeMarkerDetail?.chargingPointDTO?.longitude}`} target='_blank' className='d-flex px-2 cursor-pointer my-2 flex-grow-1 justify-content-center'>
                                                <div className='p-1 action'>
                                                    <FontAwesomeIcon icon={"fa fa-diamond-turn-right"} className='text-coal' />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Container>
            </main>
        </>
    )
}

export default NewExplore
