import React, { Suspense } from 'react';
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { AppProvider } from './AppContext';
import { FavouriteProvider, MiniGuideContext, MiniGuideProvider, NavbarProvider, PromotionsProvider, VillasProvider } from './Contexts';
import LanguageService from './services/language.service';
import i18n from './i18n';
import { HelmetProvider } from 'react-helmet-async';
import UserService from './services/user.service';

const Providers = ({ providers, children }) => {
    const renderProvider = (providers, children) => {
        const [provider, ...restProviders] = providers;

        if (provider) {
            return React.cloneElement(
                provider,
                null,
                renderProvider(restProviders, children)
            )
        }

        return children;
    }

    return renderProvider(providers, children)
}

const init = async () => {

    let languages;
    try {
        languages = await LanguageService.getLanguages();
    } catch(e) {
        console.error(e);
    }

    let userInfo;
    
    if (JSON.parse(localStorage.getItem("user"))) {
        try {
            userInfo = await UserService.userInfo();
        } catch(e) {
            console.error(e);
        }
    }
    
    if (languages && !languages?.find((language) => language?.iso === window.location.pathname.split("/")[1])) {
        window.location.href = "/es"
    }

    const isoList = languages.map(language => language.iso);
    
    i18n.i18nInit(isoList);

    render(
        <React.StrictMode>
            <Providers providers={[
                <AppProvider languages={isoList} user={userInfo} />,
                <MiniGuideProvider />,
                <PromotionsProvider />,
                <FavouriteProvider />,
                <NavbarProvider />,
                <VillasProvider />
            ]}>
                <BrowserRouter>
                    <Suspense fallback={null}>
                        <HelmetProvider>
                            <App languages={isoList} />
                        </HelmetProvider>
                    </Suspense>
                </BrowserRouter>
            </Providers>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

init();

reportWebVitals();
