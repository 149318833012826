import { Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";

const PrivacyPolicy = () => {

    const { t } = useTranslation();

    return (
        <>
            <SeoHelmet title={t('seo.privacy.title')} metaTitle={t('seo.privacy.metaTitle')} metaDescription={t('seo.privacy.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <Container className="text-break">
                <Row className="text-center pt-5 pb-3">
                    <h2>
                        <span className="tt-norms-title m-0">{t("privacyPolicy.title1")} </span>
                        <span className="joane-title m-0">{t("privacyPolicy.title2")} </span>
                    </h2>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("privacyPolicy.section1.title")}</b>
                    </h4>
                    <p>{t("privacyPolicy.section1.text1")}</p>
                    <p>{t("privacyPolicy.section1.text2")}</p>
                    <p>{t("privacyPolicy.section1.text3")}</p>
                    <p>{t("privacyPolicy.section1.text4")}</p>
                    <p>{t("privacyPolicy.section1.text5")}</p>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("privacyPolicy.section2.title")}</b>
                    </h4>
                    <p>{t("privacyPolicy.section2.text1")}</p>
                    <p>{t("privacyPolicy.section2.text2")}</p>
                    <ul className="ps-5">
                        <li>{t("privacyPolicy.section2.list.item1")}</li>
                    </ul>
                    <p>{t("privacyPolicy.section2.text3")}</p>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("privacyPolicy.section3.title")}</b>
                    </h4>
                    <p>{t("privacyPolicy.section3.text1")}</p>
                    <ul className="ps-5">
                        <li>{t("privacyPolicy.section3.list.item1")}</li>
                        <li>{t("privacyPolicy.section3.list.item2")}</li>
                        <li>{t("privacyPolicy.section3.list.item3")}</li>
                        <li>{t("privacyPolicy.section3.list.item4")}</li>
                        <li>{t("privacyPolicy.section3.list.item5")}</li>
                        <li>{t("privacyPolicy.section3.list.item6")}</li>
                        <li>{t("privacyPolicy.section3.list.item7")}</li>
                    </ul>
                    <p>{t("privacyPolicy.section3.text2")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block1.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block1.text1")}</p>
                    <p>{t("privacyPolicy.section3.block1.text2")}</p>
                    <ul className="ps-5">
                        <li>{t("privacyPolicy.section3.block1.list.item1")}</li>
                        <li>{t("privacyPolicy.section3.block1.list.item2")}</li>
                        <li>{t("privacyPolicy.section3.block1.list.item3")}</li>
                        <li>{t("privacyPolicy.section3.block1.list.item4")}</li>
                    </ul>
                    <p>{t("privacyPolicy.section3.block1.text3")}</p>
                    <p>{t("privacyPolicy.section3.block1.text4")}</p>
                    <p>{t("privacyPolicy.section3.block1.text5")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block2.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block2.text1")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block3.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block3.text1")}</p>
                    <p>{t("privacyPolicy.section3.block3.text2")}</p>
                    <p>{t("privacyPolicy.section3.block3.text3")}</p>
                    <p>{t("privacyPolicy.section3.block3.text4")}</p>
                    <p>{t("privacyPolicy.section3.block3.text5")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block4.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block4.text1")}</p>
                    <p>{t("privacyPolicy.section3.block4.text2")}</p>
                    <p>{t("privacyPolicy.section3.block4.text3")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block5.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block5.text1")}</p>
                    <p>{t("privacyPolicy.section3.block5.text2")}</p>
                    <p>{t("privacyPolicy.section3.block5.text3")}</p>
                    <p>{t("privacyPolicy.section3.block5.text4")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block6.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block6.text1")}</p>
                    <p>{t("privacyPolicy.section3.block6.text2")}</p>
                    <p>{t("privacyPolicy.section3.block6.text3")}</p>
                    <ul className="ps-5">
                        <li>{t("privacyPolicy.section3.block6.list.item1")}</li>
                        <li>{t("privacyPolicy.section3.block6.list.item2")}</li>
                        <li>{t("privacyPolicy.section3.block6.list.item3")}</li>
                        <li>{t("privacyPolicy.section3.block6.list.item4")}</li>
                        <li>{t("privacyPolicy.section3.block6.list.item5")}</li>
                        <li>{t("privacyPolicy.section3.block6.list.item6")}</li>
                    </ul>
                    <p>{t("privacyPolicy.section3.block6.text4")}</p>
                    <p>{t("privacyPolicy.section3.block6.text5")}</p>
                    <p>{t("privacyPolicy.section3.block6.text6")}</p>
                    <p>{t("privacyPolicy.section3.block6.text7")}</p>
                    <p>{t("privacyPolicy.section3.block6.text8")}</p>
                    <ul className="ps-5">
                        <li>{t("privacyPolicy.section3.block6.list2.item1")}</li>
                        <li>{t("privacyPolicy.section3.block6.list2.item2")}</li>
                    </ul>
                    <p>{t("privacyPolicy.section3.block6.text9")}</p>

                    <h6>
                        <b>{t("privacyPolicy.section3.block7.title")}</b>
                    </h6>
                    <p>{t("privacyPolicy.section3.block7.text1")}</p>
                    <p>{t("privacyPolicy.section3.block7.text2")}</p>
                    <p>{t("privacyPolicy.section3.block7.text3")}</p>
                    <p>{t("privacyPolicy.section3.block7.text4")}</p>
                    <ul className="ps-5">
                        <li>{t("privacyPolicy.section3.block7.list.item1")}</li>
                        <li>{t("privacyPolicy.section3.block7.list.item2")}</li>
                        <li>{t("privacyPolicy.section3.block7.list.item3")}</li>
                    </ul>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("privacyPolicy.section4.title")}</b>
                    </h4>
                    <p>{t("privacyPolicy.section4.text1")}</p>
                    <p>{t("privacyPolicy.section4.text2")}</p>
                    <p>{t("privacyPolicy.section4.text3")}</p>
                </Row>
                <Row className="pb-3">
                    <h4>
                        <b>{t("privacyPolicy.section5.title")}</b>
                    </h4>
                    <p>{t("privacyPolicy.section5.text")}</p>
                </Row>
                <Row className="pb-5">
                    <h4>
                        <b>{t("privacyPolicy.section6.title")}</b>
                    </h4>
                    <p>{t("privacyPolicy.section6.text1")}</p>
                    <p>{t("privacyPolicy.section6.text2")}</p>
                    <p>{t("privacyPolicy.section6.text3")}</p>
                    <p>{t("privacyPolicy.section6.text4")}</p>
                    <p>{t("privacyPolicy.section6.text5")}</p>
                    <p>{t("privacyPolicy.section6.text6")}</p>
                    <p>{t("privacyPolicy.section6.text7")}</p>
                    <p>{t("privacyPolicy.section6.text8")}</p>
                    <p>{t("privacyPolicy.section6.text9")}</p>
                </Row>
            </Container>
        </>
    )
}
export default PrivacyPolicy