import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Image, Placeholder, Row } from 'react-bootstrap'

const ContentCardPlaceholder = ({ size, data, className }) => {
    return (
        <div className={`radius bg-white d-flex flex-column flex-lg-grow-1 content-card card-placeholder ${className}`}>
            <div className={`d-flex flex-row flex-grow-0 align-items-center justify-content-between py-1 ${size === "sm" ? "px-2" : "px-3"} position-relative`}>
                <Placeholder as={"span"} animation="glow">
                    <Placeholder xs={10} className={`category-${size} radius`} />
                </Placeholder>
                {/* <Placeholder animation="glow">
                    <Placeholder xs={10} size={size === "sm" && "sm"} className={`dropdown-${size} radius`} />
                </Placeholder> */}
            </div>
            <div className='position-relative d-flex flex-grow-0'>
                <Placeholder animation="glow" className="w-100">
                    <Placeholder xs={10} className={`photo-${size}`} />
                </Placeholder>
            </div>
            <Row className='py-2 px-2 align-items-center justify-content-center g-0 align-items-center d-flex flex-grow-1'>
                <Col xs={12} className='pe-0'>
                    <Placeholder as={"span"} animation="wave">
                        <Placeholder xs={7} size={size === "sm" && "sm"} className="radius" /> <Placeholder xs={4} size={size === "sm" && "sm"} className="radius" />  <Placeholder xs={2} size={size === "sm" && "sm"} className="radius" /> <Placeholder xs={6} size={size === "sm" && "sm"} className="radius" />{' '}
                    </Placeholder>
                </Col>
            </Row>
        </div>
    )
}

export default ContentCardPlaceholder