import React from 'react'

function ExploreNav({ theme }) {
    return (
        <svg height="20" viewBox="0 0 20 23" xmlns="http://www.w3.org/2000/svg" className={`explore ${theme}`}>
            <path d="M3.57735 3.87995C7.05595 0.543168 12.7058 0.543167 16.1844 3.87995C19.6489 7.20327 19.6489 12.5807 16.1844 15.904L9.88086 21.9506L3.57735 15.904C0.112783 12.5807 0.112783 7.20327 3.57735 3.87995ZM9.88086 13.0937C11.6865 13.0937 13.1932 11.6835 13.1932 9.892C13.1932 8.10053 11.6865 6.6903 9.88086 6.6903C8.0752 6.6903 6.56851 8.10053 6.56851 9.892C6.56851 11.6835 8.0752 13.0937 9.88086 13.0937Z" />
        </svg>
    )
}

export default ExploreNav