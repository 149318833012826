import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const ArticleSchema = ({ article }) => {

    const { t } = useTranslation();

    var decodeHTML = (html) => {
        let decode = html.replace(/<[^>]*>/g, "").replace(/\n/g, " ");
        var txt = document.createElement('textarea');
        txt.innerHTML = decode;
        return txt.value;
    };

    let imgListOrder = [];

    if (article?.type === 1) {
        if (article?.img4Url && !article?.privateArticle) {
            imgListOrder.push(article?.img4Url)
        }

        if (article?.img2Url && !article?.privateArticle) {
            imgListOrder.push(article?.img2Url)
        }

        if (article?.img3Url && !article?.privateArticle) {
            imgListOrder.push(article?.img3Url)
        }

        if (article?.img1Url) {
            imgListOrder.push(article?.img1Url)
        }
    } else if (article?.type === 2) {
        if (article?.img1Url) {
            imgListOrder.push(article?.img1Url)
        }

        if (article?.img4Url) {
            imgListOrder.push(article?.img4Url)
        }

        if (article?.img2Url) {
            imgListOrder.push(article?.img2Url)
        }

        if (article?.img3Url) {
            imgListOrder.push(article?.img3Url)
        }
    }


    let imgsInfo = [];

    imgListOrder.forEach((img) => {

        const imgData = new Image();

        imgData.src = img;

        imgsInfo.push({
            "@type": "ImageObject",
            url: img,
            width: imgData.naturalWidth > 1200 ? 1200 : imgData.naturalWidth,
            height: imgData.naturalHeight
        })
    });

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON?.stringify({
                    "@context": "https://schema.org",
                    "@type": "NewsArticle",
                    "headline": `${article?.titleFirstPart?.trim()} ${article?.titleSecondPart?.trim()}`.trim(),
                    "description": article?.metaDescription,
                    "datePublished": article?.creationDate.split('T')[0],
                    "dateModified": article?.creationDate.split('T')[0],
                    "articleSection": article?.categoryName,
                    "articleBody": article?.type === 1 ? (article?.privateArticle ? decodeHTML(article?.textBlock1) : decodeHTML(`${article?.textBlock1 || ""} ${article?.textBlock2 || ""} ${article?.textBlock3 || ""} ${article?.textBlock4 || ""}`.trim())) : decodeHTML(`${article?.textBlock1} ${article?.textBlock4}`.trim()),
                    "genre": `${article?.type === 1 ? t('guide.articleType.article') : t('guide.articleType.review')} ${t('guide.schema.genre.text1')} ${article?.categoryName} ${t('guide.schema.genre.text2')} ${article?.interestSites?.[0]?.place || "Mallorca"}`,
                    "about": {
                        "@type": "LocalBusiness",
                        "name": article?.interestSites?.[0]?.name,
                        "url": article?.interestSites?.[0]?.webSiteUrl,
                        "description": article?.interestSites?.[0]?.shortDescription,
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": article?.interestSites?.[0]?.address,
                            "addressLocality": article?.interestSites?.[0]?.place,
                            // "postalCode": "",
                            "addressCountry": "ES"
                        },
                    },
                    "image": imgsInfo,
                    "author": {
                        "@type": "Person",
                        "name": article?.articleAuthor,
                        // "description": ""
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "Travel to Zero",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.traveltozero.com/img/svg/logo-v2.svg",
                            "height": "40",
                            "width": "270"
                        }
                    }
                })}
            </script>
        </Helmet>
    )
}

export default ArticleSchema