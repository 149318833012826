import api from './api';

const getMiniGuide = (data) => {
    return api.get(`mini-guide`, data).then(res => res.data);
}

const getMiniGuideDetail = (slug) => {
    return api.get(`mini-guide/${slug}`).then(res => res.data);
}

const MiniGuideService = {
    getMiniGuide,
    getMiniGuideDetail
}

export default MiniGuideService;