import { Alert, Button, Col, Container, Form, FormGroup, Image, InputGroup, Row } from 'react-bootstrap'
import { AppContext } from '../../AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import AuthService from '../../services/auth.service'
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb'
import FolderService from '../../services/folder.service'
import Link from '../../components/Link'
import ProfileNav from '../../components/profile-nav/ProfileNav'
import React, { useContext, useEffect, useState } from 'react'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'
import TokenService from '../../services/token.service'
import UserService from '../../services/user.service'
import { NavbarContext } from '../../Contexts'

const Profile = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { state: { user, categories }, update, state } = useContext(AppContext);
    const { navbarUpdate } = useContext(NavbarContext)

    const [editMode, setEditMode] = useState(false);
    const [orderType, setOrderType] = useState("DESC");
    const [actionTitle, setActionTitle] = useState("");
    const [success, setSuccess] = useState({
        update: false
    })

    const [errors, setErrors] = useState({
        update: false,
        emailUsed: false
    })

    const [shareErrors, setShareErrors] = useState({
        expiredToken: false,
        invalidToken: false,
        invalidDestinationUser: false,
        server: false
    })

    const { register, handleSubmit, formState, getValues, reset } = useForm({
        mode: "all",
        defaultValues: {
            firstName: user?.firstName,
            surname1: user?.lastName,
            surname2: user?.secondLastName,
            phone: user?.phone,
            email: user?.email,
            postalCode: user?.postalCode,
            address: "",
            comunication: user?.generalNotifications,
            offers: user?.commercialNotifications
        }
    })

    const {
        register: mobileRegister,
        handleSubmit: mobileSubmit,
        formState: mobileFormState,
        reset: mobileReset
    } = useForm({
        mode: "all",
        defaultValues: {
            firstName: user?.firstName,
            surname1: user?.lastName,
            surname2: user?.secondLastName,
            phone: user?.phone,
            email: user?.email,
            postalCode: user?.postalCode,
            address: "",
            comunication: user?.generalNotifications,
            offers: user?.commercialNotifications
        }
    })

    const updateUser = async (data) => {
        setSuccess({ ...success, update: false })
        setErrors({ ...errors, update: false })

        const userData = {
            email: !user?.socialLogin ? data.email : user?.email,
            validateEmailUrl: !user.socialLogin ? `${window.location.origin}/${i18n.language}/${t('pages.validation')}` : null,
            firstName: data.firstName,
            lastName: data.surname1,
            secondLastName: data.surname2,
            phone: data.phone,
            generalNotifications: data?.comunication,
            commercialNotifications: data?.offers
        }

        try {
            await UserService.updateUser(userData)
            const userInfo = await UserService.userInfo()
            update({
                ...state,
                user: userInfo
            });
            setSuccess({ ...success, update: true })
            document.getElementById('edit-container').scrollTo(0, document.getElementById('edit').scrollHeight)
        } catch (error) {
            if (error.response.data.errorCode === 13002) {
                setErrors({ ...errors, emailUsed: true });
            } else {
                setErrors({ ...errors, update: true })
            }
        }
    }

    const [showConfirmation, setShowConfirmation] = useState(false);

    const deleteUser = () => {
        setShowConfirmation(true);
    };

    const confirmDelete = async () => {
        try {
            await UserService.disableAccount();
            setShowConfirmation(false);

            AuthService.logout();
            update({ ...state, user: null })
        } catch (error) {
        }
    };

    const cancelDelete = () => {
        setShowConfirmation(false);
    };

    const shareFolder = async (shareToken) => {
        setShareErrors({
            expiredToken: false,
            invalidToken: false,
            invalidDestinationUser: false,
            server: false
        })

        var data = {
            token: shareToken
        }

        try {
            var response = await FolderService.shareCopyFolder(data);

            if (response.status === 200) {
                TokenService.setShareFolderToken(null);
                navigate(`/${i18n.language}/${t('pages.profile')}/${t('pages.favourites')}`);
            }

        } catch ({ response }) {
            if (response?.data?.errorCode) {
                switch (response.data.errorCode) {
                    case 12061:
                        setShareErrors({
                            expiredToken: true,
                            invalidToken: false,
                            invalidDestinationUser: false,
                            server: false
                        })
                        break;
                    case 12071:
                        setShareErrors({
                            expiredToken: false,
                            invalidToken: true,
                            invalidDestinationUser: false,
                            server: false
                        })
                        break;
                    case 12001:
                        setShareErrors({
                            expiredToken: false,
                            invalidToken: false,
                            invalidDestinationUser: true,
                            server: false
                        })
                        break;
                    default:
                        setShareErrors({ ...shareErrors, server: true });
                }
            }
        }
    }

    useEffect(() => {
        if (!editMode) {
            reset({
                firstName: user?.firstName,
                surname1: user?.lastName,
                surname2: user?.secondLastName,
                phone: user?.phone,
                email: user?.email,
                postalCode: user?.postalCode,
                address: "",
                comunication: user?.generalNotifications,
                offers: user?.commercialNotifications
            });

            mobileReset({
                firstName: user?.firstName,
                surname1: user?.lastName,
                surname2: user?.secondLastName,
                phone: user?.phone,
                email: user?.email,
                postalCode: user?.postalCode,
                address: "",
                comunication: user?.generalNotifications,
                offers: user?.commercialNotifications
            })
            document.getElementById('info').scrollTo(0, 0)
            setActionTitle();
        }
    }, [editMode])

    useEffect(() => {
        if (!user) navigate(`/`)

        var shareToken = TokenService.getShareFolderToken();

        if (shareToken != null) {
            shareFolder(shareToken);
        }

    }, []);

    useEffect(() => {
        if (editMode) {
            setTimeout(() => {
                setEditMode(false);
                setSuccess({ ...success, update: false });
            }, 500);
        }
    }, [user])

    useEffect(() => {
        if (showConfirmation) {
            document.getElementById('info').scrollTo(0, document.getElementById('info').scrollHeight)
        } else {
            document.getElementById('info').scrollTo(0, 0)
        }
    }, [showConfirmation])

    useEffect(() => {
        if (categories) {
            setTimeout(() => {
                update({
                    ...state,
                    shareToken: null
                })
            }, 500);
        }
    }, [categories])

    useEffect(() => {
        navbarUpdate({
            ...state,
            blockNavHide: true
        })
    }, [])

    return (
        <>
            <SeoHelmet title={t('seo.profile.title')} metaTitle={t('seo.profile.metaTitle')} metaDescription={t('seo.profile.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <BreadcrumbPage mobileHide />
            <main>
                <Container as={"section"} className='screen-height'>
                    <div className='d-none d-lg-block py-4 h-100'>
                        <Row className='border border-1 border-light-coal radius h-100 overflow-hidden'>
                            <Col className='d-flex flex-column h-100'>
                                <Row>
                                    <Col lg={4}>
                                        <Row className='border-end border-bottom border-1 border-light-coal'>
                                            <Col lg={6} className='d-flex flex-row tt-norms-bold justify-content-center align-items-center g-0 border-end border-1 border-light-coal'>
                                                <Link href={t('pages.profile')} className="d-flex align-items-center justify-content-center gap-2 text-coal flex-grow-1 py-3">
                                                    <FontAwesomeIcon icon="fa-solid fa-user" width={18} className='text-coal' />
                                                    <span>{t('profile.header.profile')}</span>
                                                </Link>
                                            </Col>
                                            <Col lg={6} className='d-flex flex-row tt-norms-bold justify-content-center align-items-center g-0 opacity-50'>
                                                <Link href={`${t('pages.profile')}/${t('pages.favourites')}`} className="d-flex align-items-center justify-content-center gap-2 text-coal flex-grow-1 py-3" >
                                                    <FontAwesomeIcon icon="fa-solid fa-bookmark" width={14} className='text-coal fav-icon-visual' />
                                                    <span>{t('profile.header.favourites')}</span>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={8} className='border-bottom border-1 border-light-coal position-relative px-0 d-flex align-items-center'>
                                        <div className='d-flex flex-row justify-content-between flex-grow-1 gap-3 px-4'>
                                            <div className='d-flex align-items-center position-relative gap-3'>
                                                {actionTitle && <Button variant='none' className='p-0 shadow-none d-flex flex-row gap-3' onClick={() => {
                                                    setActionTitle()
                                                    setEditMode(false)
                                                }}>
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={20} height={23} className='text-coal cursor-pointer' />
                                                    <span className='tt-norms-bold clamp-1'>{actionTitle}</span>
                                                </Button>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='content-manager'>
                                    <Col lg={4} className={`left border-end border-1 border-light-coal ${editMode ? "" : ""}`.trim()}>
                                        <Row className='flex-column align-items-center d-flex justify-content-center align-content-center h-100'>
                                            {!editMode ? (
                                                <Col className='left py-4 d-flex flex-column flex-grow-1' id='info'>
                                                    <div className='d-flex justify-content-center align-items-center position-relative mb-4'>
                                                        <div className='d-flex justify-content-center align-items-center user-pic mb-4'>
                                                            <FontAwesomeIcon icon="fa-solid fa-user" width={72} className='text-coal' />
                                                        </div>
                                                        {!editMode && <FontAwesomeIcon icon={"fa-solid fa-pen"} width={20} className='position-absolute top-50 end-0 translate-middle-y text-muted me-5 cursor-pointer' onClick={() => {
                                                            setEditMode(true)
                                                            setActionTitle(t('profile.header.titles.edit'))
                                                        }} />}
                                                    </div>
                                                    {/* <div className={`d-flex justify-content-center gap-3 ${!editMode ? "ms-4" : ""}`.trim()}>
                                                <span className='mb-0 tt-norms-bold text-center'>{`${user?.firstName} ${user?.lastName} ${user?.secondLastName}`.trim()}</span>
                                            </div> */}
                                                    <div className='px-5 d-flex flex-column flex-grow-1 justify-content-start'>
                                                        <div>
                                                            {user?.firstName && <Col lg={12} className="mb-5">
                                                                <div className='d-flex flex-row align-items-center gap-3'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-user" width={24} className='text-coal' />
                                                                    <span className='h6 tt-norms-bold mb-0 opacity-75'>{`${user?.firstName} ${user?.lastName} ${user?.secondLastName || ""}`.trim()}</span>
                                                                </div>
                                                            </Col>}
                                                            {user?.email && <Col lg={12} className="mb-5">
                                                                <div className='d-flex flex-row align-items-center gap-3'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-envelope" width={24} className='text-coal' />
                                                                    <span className='h6 tt-norms-bold mb-0 text-break opacity-75'>{user?.email}</span>
                                                                </div>
                                                            </Col>}
                                                            {user?.phone && <Col lg={12} className="mb-5">
                                                                <div className='d-flex flex-row align-items-center gap-3'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-phone" width={24} className='text-coal' />
                                                                    <span className='h6 tt-norms-bold mb-0 opacity-75'>{user?.phone}</span>
                                                                </div>
                                                            </Col>}
                                                            {user?.address && <Col lg={12} className="mb-5">
                                                                <div className='d-flex flex-row align-items-center gap-3'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" width={24} className='text-coal' />
                                                                    <span className='h6 tt-norms-bold mb-0 opacity-75'>{user?.address}</span>
                                                                </div>
                                                            </Col>}
                                                        </div>
                                                        <Col lg={12} className="mb-5">
                                                            <div className='d-flex flex-row align-items-center gap-3'>
                                                                <FontAwesomeIcon icon="fa-solid fa-check" width={24} className='text-coal' />
                                                                <span className='h6 tt-norms-bold mb-0 opacity-75'>{t('profile.profileForm.comunications')} <b>{user?.generalNotifications ? `${t('profile.profileForm.confirm')}` : `${t('profile.profileForm.cancel')}`}</b></span>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                    <Col xs={12} className='text-center'>
                                                        <Button variant="none" className="btn-link text-decoration-none text-muted tt-norms align-self-center shadow-none" onClick={deleteUser}>{t('profile.profileForm.delete')}</Button>
                                                    </Col>
                                                    <Alert show={showConfirmation} variant="transparent" className="mt-4 border-dark radius">
                                                        <Alert.Heading className="mt-3 d-flex justify-content-center text-danger tt-norms-bold">{t('profile.alerts.deleteUser.title')}</Alert.Heading>
                                                        <p className="d-flex justify-content-center text-danger text-center tt-norms">{t('profile.alerts.deleteUser.text')}</p>
                                                        <div className="mt-4 mb-4 d-flex flex-column justify-content-center gap-4 gap-lg-3">
                                                            <Button className="bg-black px-5 mx-5 radius text-white tt-norms-bold" onClick={cancelDelete}>
                                                                {t('profile.alerts.deleteUser.cancel')}
                                                            </Button>
                                                            <Button className="btn-dark-ligth-outline px-5 mx-5 radius shadow-none" onClick={confirmDelete}>
                                                                {t('profile.alerts.deleteUser.delete')}
                                                            </Button>
                                                        </div>
                                                    </Alert>
                                                </Col>
                                            ) : (
                                                <Col className='left' id='edit-container'>
                                                    <Row className='p-4 d-flex align-items-center gx-0' id='edit'>
                                                        <Form className='update d-flex flex-column' onSubmit={handleSubmit(updateUser)}>
                                                            <Row className='gy-4 mb-4'>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.name.label')} <span className='text-danger'>*</span></Form.Label>
                                                                        <Form.Control type="text" {...register("firstName", { required: true })} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.surname1.label')} <span className='text-danger'>*</span></Form.Label>
                                                                        <Form.Control type="text" {...register("surname1", { required: true })} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.surname2.label')}</Form.Label>
                                                                        <Form.Control type="text" {...register("surname2")} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.phone.label')}</Form.Label>
                                                                        <Form.Control type="text" {...register("phone")} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.email.label')} <span className='text-danger'>*</span></Form.Label>
                                                                        <Form.Control type="text" {...register("email", { required: true })} disabled={user?.socialLogin} className='border-1 border-light-coal shadow-none tt-norms py-2 form border-coal' />
                                                                        <small className="text-light-gray tt-norms">{t('profile.profileForm.email.socialAccounts')}</small>
                                                                    </FormGroup>
                                                                </Col>
                                                                <div className='d-flex flex-column gap-4'>
                                                                    <Form.Group>
                                                                        <Form.Check id='comunicationDesktop' key={"comunication"} className="d-block tt-norms" type="checkbox" {...register('comunication')} label={t('profile.profileForm.generalComunications')} />
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <Form.Check id='offersDesktop' key="offers" className="d-block tt-norms" type="checkbox" {...register('offers')} label={t('profile.profileForm.customOffers')} />
                                                                    </Form.Group>
                                                                </div>
                                                            </Row>
                                                            <Button type="submit" variant="none" className='d-flex flex-row gap-2 align-items-center align-self-center px-4 radius btn-coal text-white' disabled={!formState?.isValid}>
                                                                <span>{t('profile.profileForm.save')}</span>
                                                            </Button>
                                                            <Alert variant={"success"} show={success.update} className='mt-4' dismissible onClose={() => setSuccess({ ...success, update: false })}>
                                                                {t('profile.alerts.updateSuccess')}
                                                            </Alert>
                                                            <Alert variant={"danger"} show={errors.update} className='mt-4' dismissible onClose={() => setErrors({ ...errors, update: false })}>
                                                                {t('profile.alerts.updateError')}
                                                            </Alert>
                                                            <Alert variant="danger" show={errors.emailUsed} className='mt-4' dismissible onClose={() => setErrors({ ...errors, emailUsed: false })} >
                                                                {t('profile.alerts.updateErrorEmail')}
                                                            </Alert>
                                                        </Form>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>

                                    </Col>
                                    <Col lg={8} className='px-0'>
                                        <div className='p-4 h-100 d-flex'>
                                            <div className='position-relative d-flex flex-grow-1 align-items-center justify-content-center bg-light-coal radius profile-banner'>
                                                <Image src='/img/banner-profile-background.png' className='position-absolute background radius' />
                                                {/* <Image src='/img/banner-profile-text.png' className='position-absolute text radius' /> */}
                                                <div className='content lh-1 text-white'>
                                                    <p className='title joane-bolder text-center lh-1 mb-2'>{t('profile.banner.title.text1')}</p>
                                                    <p className='title joane-bolder text-center lh-1'>
                                                        <span className='text-primary'>{t('profile.banner.title.text2')} </span>
                                                        <span>{t('profile.banner.title.text3')}</span>
                                                    </p>
                                                    <div className='ps-4'>
                                                        <ul className='text-white tt-norms-semi-bolder lh-lg list ps-5'>
                                                            <li>{t('profile.banner.list.item1')}</li>
                                                            <li>{t('profile.banner.list.item2')}</li>
                                                            <li>{t('profile.banner.list.item3')}</li>
                                                            <li>{t('profile.banner.list.item4')}</li>
                                                            <li>{t('profile.banner.list.item5')}</li>
                                                        </ul>
                                                        <p className='tt-norms text ps-4'>{t('profile.banner.text1')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <Row className='flex-column justify-content-center d-lg-none'>
                        {!editMode && <Col>
                            <ProfileNav profile editProfile={() => setEditMode(true)} />
                        </Col>}
                        <Col className='py-4'>
                            <div className={`d-flex flex-column`.trim()}>
                                {!editMode && (
                                    <>
                                        <div className='position-relative d-flex flex-column align-items-center pb-4 border-bottom border-light-coal border-2'>
                                            <div className='d-flex justify-content-center align-items-center user-pic mb-2'>
                                                <Image src='' />
                                                <FontAwesomeIcon icon="fa-solid fa-user" width={52} className='text-coal' />
                                            </div>
                                            <p className='small mb-0 tt-norms-bold'>{`${user?.firstName} ${user?.lastName} ${user?.secondLastName}`.trim()}</p>
                                            {/* <p className='h5 tt-norms-bolder mb-0 opacity-50'>Profile</p> */}
                                            {editMode && (
                                                <>
                                                    <FontAwesomeIcon icon="fa-solid fa-xmark" width={20} height={23} className='position-absolute top-0 start-0 text-coal cursor-pointer' onClick={() => setEditMode(false)} />
                                                    <Form onSubmit={handleSubmit(updateUser)}>
                                                        <Button type="submit" variant="none" className='p-0 m-0 position-absolute top-0 end-0 text-coal shadow-none'>
                                                            <FontAwesomeIcon icon="fa-solid fa-floppy-disk" className='text-coal cursor-pointer' width={20} />
                                                        </Button>
                                                    </Form>
                                                </>
                                            )}
                                            <FontAwesomeIcon icon="fa-solid fa-pen" width={18} className="text-coal px-3 py-1 cursor-pointer position-absolute bottom-0 end-0 mb-4 opacity-75" onClick={() => setEditMode(true)} />
                                        </div>
                                        <Row className='d-flex flex-column flex-lg-row gap-5 gap-lg-0 gy-lg-5 px-4 pt-4'>
                                            {user?.firstName && <Col lg={6} className='d-flex flex-row align-items-center gap-3'>
                                                <FontAwesomeIcon icon="fa-solid fa-user" width={20} className='text-coal' />
                                                <span className='h6 tt-norms-bold mb-0 opacity-75'>{`${user?.firstName} ${user?.lastName} ${user?.secondLastName}`.trim()}</span>
                                            </Col>}
                                            {user?.email && <Col lg={6} className='d-flex flex-row align-items-center gap-3'>
                                                <FontAwesomeIcon icon="fa-solid fa-envelope" width={20} className='text-coal' />
                                                <span className='h6 tt-norms-bold mb-0 text-break opacity-75'>{user?.email}</span>
                                            </Col>}
                                            {user?.phone && <Col lg={6} className='d-flex flex-row align-items-center gap-3'>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" width={20} className='text-coal' />
                                                <span className='h6 tt-norms-bold mb-0 opacity-75'>{user?.phone}</span>
                                            </Col>}
                                            {user?.address && <Col lg={6} className='d-flex flex-row align-items-center gap-3'>
                                                <FontAwesomeIcon icon="fa-solid fa-location-dot" width={20} className='text-coal' />
                                                <span className='h6 tt-norms-bold mb-0 opacity-75'>{user?.address}</span>
                                            </Col>}
                                            <Col lg={6} className='d-flex flex-row align-items-center gap-3'>
                                                <FontAwesomeIcon icon="fa-solid fa-check" width={20} className='text-coal' />
                                                <span className='h6 tt-norms-bold mb-0 opacity-75'>{t('profile.profileForm.comunications')} <b>{user?.generalNotifications ? "Si" : "NO"}</b></span>
                                            </Col>
                                            <Button variant="none" className="btn-link text-decoration-none text-muted tt-norms align-self-center shadow-none" onClick={deleteUser}>{t('profile.profileForm.delete')}</Button>
                                        </Row>
                                        <Alert show={showConfirmation} variant="transparent" className="mt-5 border-dark radius">
                                            <Alert.Heading className="mt-3 d-flex justify-content-center text-danger tt-norms-bold">{t('profile.alerts.deleteUser.title')}</Alert.Heading>
                                            <p className="d-flex justify-content-center text-danger text-center text-lg-start tt-norms">{t('profile.alerts.deleteUser.text')}</p>
                                            <div className="mt-4 mb-4 d-flex flex-column flex-lg-row justify-content-center gap-4 gap-lg-1">
                                                <Button className="bg-black px-5 mx-5 radius text-white tt-norms-bold" onClick={cancelDelete}>
                                                    {t('profile.alerts.deleteUser.cancel')}
                                                </Button>
                                                <Button className="btn-dark-ligth-outline px-5 mx-5 radius shadow-none" onClick={confirmDelete}>
                                                    {t('profile.alerts.deleteUser.delete')}
                                                </Button>
                                            </div>
                                        </Alert>
                                    </>
                                )}
                                {editMode && (
                                    <>
                                        <Form className='update d-flex flex-column' onSubmit={mobileSubmit(updateUser)}>
                                            <div className='d-flex flex-column gap-4 mb-4'>
                                                <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                                                    <FontAwesomeIcon icon={"fa-solid fa-chevron-left"} width={12} className='text-coal cursor-pointer' onClick={() => setEditMode(false)} />
                                                    <div className='d-flex justify-content-center align-items-center user-pic-edit cursor-pointer'>
                                                        <Image src='' />
                                                        <FontAwesomeIcon icon="fa-solid fa-user" className='text-coal icon' />
                                                    </div>
                                                    {/* <span className='text-coal opacity-75 tt-norms-bold'>Cambiar foto de perfil</span> */}
                                                    <div>
                                                        <FontAwesomeIcon icon={"fa-solid fa-pen"} width={20} className='text-coal cursor-pointer' />
                                                    </div>
                                                </div>
                                                <FormGroup>
                                                    <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.name.label')} <span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control type="text" {...mobileRegister("firstName", { required: true })} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.surname1.label')} <span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control type="text" {...mobileRegister("surname1", { required: true })} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.surname2.label')}</Form.Label>
                                                    <Form.Control type="text" {...mobileRegister("surname2")} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.email.label')} <span className='text-danger'>*</span></Form.Label>
                                                    <Form.Control type="text" {...mobileRegister("email", { required: true })} disabled={user?.socialLogin} className='border-1 border-light-coal shadow-none tt-norms py-2 form border-coal' />
                                                    <small className="text-light-gray tt-norms">{t('profile.profileForm.email.socialAccounts')}</small>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className='tt-norms-bolder mb-0 opacity-50'>{t('profile.profileForm.phone.label')}</Form.Label>
                                                    <Form.Control type="text" {...mobileRegister("phone")} className='border-1 border-light-coal shadow-none tt-norms py-2 form' />
                                                </FormGroup>
                                                <div className='d-flex flex-column gap-4'>
                                                    <Form.Group>
                                                        <Form.Check id='comunication' key={"comunication"} className="d-block tt-norms" type="checkbox" {...mobileRegister('comunication')} label={t('profile.profileForm.generalComunications')} />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Check id='offers' key="offers" className="d-block tt-norms" type="checkbox" {...mobileRegister('offers')} label={t('profile.profileForm.customOffers')} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <Button type="submit" variant="none" className='d-flex flex-row gap-2 align-items-center align-self-center radius btn-coal text-white' disabled={!formState?.isValid}>
                                                <span>{t('profile.profileForm.save')}</span>
                                            </Button>
                                        </Form>
                                        <Alert variant={"success"} show={success.update} className='mt-4' dismissible onClose={() => setSuccess({ ...success, update: false })}>
                                            {t('profile.alerts.updateSuccess')}
                                        </Alert>
                                        <Alert variant={"danger"} show={errors.update} className='mt-4' dismissible onClose={() => setErrors({ ...errors, update: false })}>
                                            {t('profile.alerts.updateError')}
                                        </Alert>
                                        <Alert variant="danger" show={errors.emailUsed} className='mt-4' dismissible onClose={() => setErrors({ ...errors, emailUsed: false })} >
                                            {t('profile.alerts.updateErrorEmail')}
                                        </Alert>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default Profile
