import React, { useCallback, useRef, useState } from 'react'
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VillaCard from '../../components/cards/VillaCard'
import FilterRangeIcon from '../../components/svg-component/FilterRangeIcon'
import DestinationSelect from '../../components/selects/DestinationSelect'
import CheckInOutSelect from '../../components/selects/CheckInOutSelect'
import GuestSelect from '../../components/selects/GuestSelect'
import FilterMapIcon from '../../components/svg-component/FilterMapIcon'
import { useEffect } from 'react'
import Slider from 'rc-slider'
import VillaMobileFilter from '../../components/selects/VillaMobileFilter'
import TzoSheet from '../../components/mobile-sheet/TzoSheet'
import CustomMosaic from '../../components/mosaic/CustomMosaic'
import VillasFeatures from '../../components/villas/VillasFeatures'
import { VillasContext } from '../../Contexts'
import { useContext } from 'react'
import FilterGrid from '../../components/svg-component/FilterGrid'
import VillaDetailedCard from '../../components/cards/VillaDetailedCard'
import FilterList from '../../components/svg-component/FilterList'
import VillasService from '../../services/villas.service'
import ReactPaginate from 'react-paginate'
import VillaCardPlaceholder from '../../components/cards/VillaCardPlaceholder'
import { useForm, useWatch } from 'react-hook-form'
import VillaDetailedCardPlaceholder from '../../components/cards/VillaDetailedCardPlaceholder'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MapMenuIcon from '../../components/svg-component/MapMenuIcon'
import Link from '../../components/Link'
import { AppContext } from '../../AppContext'

const useMapFilter = () => {

    const [mapHoverLabel, setMapHoverLabel] = useState();
    const [mapLabel, setMapLabel] = useState();
    const [zoneId, setZoneId] = useState();

    return {
        mapHoverLabel,
        mapLabel,
        zoneId,
        triggerHoverLabel: setMapHoverLabel,
        triggerMapLabel: setMapLabel,
        triggerZoneId: setZoneId
    }
}

function VillaSearcher() {

    const { villasState: { map, features, equipVilla, filter, layoutCards }, villasState, villasUpdate } = useContext(VillasContext);

    const { mapHoverLabel, mapLabel, zoneId, triggerHoverLabel, triggerMapLabel, triggerZoneId } = useMapFilter();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { register, handleSubmit, reset, formState, getValues, control } = useForm({
        mode: "all",
        // defaultValues: {},
    });

    const [villas, setVillas] = useState();
    const [villaPagination, setVillaPagination] = useState({
        currentPage: 1
    });
    const [currentPage, setCurrentPage] = useState(1);

    const [sheetShow, setSheetShow] = useState();
    const [sideMenu, setSideMenu] = useState(true)
    const [bedrooms, setBedrooms] = useState(features?.bedrooms || 0);
    const [beds, setBeds] = useState(features?.beds || 0);
    const [baths, setBaths] = useState(features?.baths || 0);
    const [equip, setEquip] = useState({
        wifi: equipVilla?.wifi || false,
        barbeque: equipVilla?.barbeque || false,
        television: equipVilla?.television || false,
        garden: equipVilla?.garden || false,
        view: equipVilla?.view || false,
        chargingPoint: equipVilla?.chargingPoint || false,
        pets: equipVilla?.pets || false
    })
    const [searchedGuest, setSearchedGuest] = useState();
    const [layoutType, setLayoutType] = useState(layoutCards || "card")

    const delayRef = useRef();
    const filterTimeout = useRef();

    const getVillas = async () => {
        try {
            let equipments = {}

            if (equip) {
                Object.keys(equip).forEach(propery => {
                    if (equip?.[propery]) {
                        equipments[propery] = true
                    }
                });
            }

            const data = await VillasService.getVillas({
                offset: villaPagination.currentPage - 1,
                length: 6,
                startDate: filter.startDate && handleTimeZone(filter?.startDate),
                endDate: filter.endDate && handleTimeZone(filter?.endDate),
                numBedroom: bedrooms || null,
                numBathroom: baths || null,
                numBed: beds || null,
                guests: filter?.totalGuest || null,
                ...equipments
            })

            setVillas(data.content)

            setVillaPagination({
                ...villaPagination,
                totalElements: data?.totalElements,
                totalPages: Math.ceil(data?.totalElements / 6),
                pageable: data?.pageable
            })
            setSearchedGuest(filter?.totalGuest)

            if (sheetShow) {
                document.getElementsByClassName('mobile-sheet')?.[0]?.classList?.replace("active", "close")

                setTimeout(() => {
                    setSheetShow(false)
                }, 625);
            }

        } catch (error) {

        }
    }

    const handleTimeZone = (date) => {
        const offset = date?.getTimezoneOffset()
        date = new Date(date?.getTime() - (offset * 60 * 1000))
        return date?.toISOString().split('T')[0]
    }

    useEffect(() => {
        villasUpdate({
            features: {
                bedrooms: 0,
                beds: 0,
                baths: 0
            },
            map: {
                hover: undefined,
                active: undefined
            }
        })
    }, [])

    useEffect(() => {
        document.getElementsByClassName("villa-list")?.[0].scrollTo({ top: 0 })

        if (layoutType === "list") {
            setSideMenu(true)
        }
    }, [layoutType])

    useEffect(() => {
        if (searchParams.get('page')) {
            setVillaPagination({
                ...villaPagination,
                currentPage: parseInt(searchParams.get('page'))
            })
        } else {
            navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}?page=1`, { replace: true })
        }
    }, [searchParams])

    useEffect(() => {
        villasUpdate({
            equipVilla: {
                wifi: equip?.wifi,
                barbeque: equip?.barbeque,
                television: equip?.television,
                garden: equip?.garden,
                view: equip?.view,
                chargingPoint: equip?.chargingPoint,
                pets: equip?.pets
            }
        })

    }, [equip])

    useEffect(() => {
        if (!villas && !filterTimeout.current) return

        filterTimeout.current = setTimeout(() => {
            setVillas();

            if (parseInt(villaPagination.currentPage) === 1) {
                getVillas();
            } else {
                navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}?page=1`, { replace: true })
            }
        }, 1000);

        return () => {
            clearTimeout(filterTimeout.current)
        }
    }, [features, equipVilla])

    useEffect(() => {
        if (villaPagination?.currentPage === parseInt(searchParams.get('page'))) {
            getVillas();
        }
    }, [villaPagination?.currentPage, searchParams])

    return (
        <main>
            <BreadcrumbPage />
            <Container className='villa-searcher-container'>
                <div className='villa-searcher d-none d-lg-flex'>
                    <div className='filters g-0'>
                        <div className={`d-flex flex-row justify-content-between align-items-center z-index-2 bg-white options ${sideMenu ? "active" : ""}`.trim()}>
                            {layoutType === "card" && <Button variant='none' className={`shadow-none p-3 border-0 filter-range-button ${!sideMenu ? "flip" : ""}`.trim()} onClick={() => setSideMenu(!sideMenu)}>
                                <FilterRangeIcon width={28} height={28} active={!sideMenu} />
                            </Button>}
                            {layoutType === "card" && <Button variant='none' className='py-3 px-3 shadow-none border-0' onClick={() => setSideMenu(!sideMenu)}>
                                <div variant='none' className={`d-flex align-items-center justify-content-center radius shadow-none border border-1 border-coal-semi-transparent p-1 collapse ${sideMenu ? "active" : ""}`.trim()}>
                                    <FontAwesomeIcon icon={"fa-solid fa-minus"} width={14} />
                                </div>
                            </Button>}
                        </div>
                        <div className='py-3 px-5 shadow-sm z-index-1 list'>
                            <span className='inter-semi-bold text-coal opacity-75'>{villaPagination?.totalElements} {t('villas.searcher.total')}</span>
                            <div className='d-flex flex-row gap-5'>
                                <Button variant='none' className='shadow-none p-0' onClick={() => setLayoutType("card")}>
                                    <FilterGrid width={28} height={28} active={layoutType === "card"} />
                                </Button>
                                <Button variant='none' className='shadow-none p-0' onClick={() => setLayoutType("list")}>
                                    <FilterList width={28} height={28} active={layoutType === "list"} />
                                </Button>
                                <Button variant='none' className='shadow-none p-0'>
                                    <Link href={`${t('pages.villa')}/${t('pages.villaMap')}`}>
                                        <MapMenuIcon width={28} height={28} />
                                    </Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='position-relative d-flex flex-grow-1 align-items-end justify-content-lg-start content'>
                        <div className={`d-flex flex-grow-1 filters ${sideMenu ? "active" : ""}`.trim()}>
                            <Form onSubmit={handleSubmit(() => {
                                document.getElementsByClassName("villa-list")?.[0].scrollTo({ top: 0 })
                                setVillas()
                                parseInt(villaPagination.currentPage) === 1 && getVillas()
                                navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}?page=1`, { replace: true })
                            })}>
                                <div className='d-flex flex-grow-1 px-4 pb-4 h-100 villa-filter-container'>
                                    <div className='d-flex flex-column flex-grow-1 filter-box'>
                                        <div className='d-flex flex-column border-bottom border-1 border-coal-semi-transparent py-lg-3 text-center w-100'>
                                            <p className='inter-bold h5 text-center mb-0'>{t('villas.filter.title')}</p>
                                        </div>
                                        <div className='d-flex flex-column gap-4 flex-grow-1 p-3 custom-scroll filter-content'>
                                            {/* <div className='d-flex flex-column'>
                                                <p className='mb-2 tt-norms-semi-bolder'>Destino</p>
                                                <DestinationSelect
                                                    id={"destination"}
                                                    className={"border border-1 villa-select"}
                                                    containerClasssName={"villa-input"}
                                                    outsideTarget={"destination-container"}
                                                    noLabel
                                                    chevron
                                                />
                                            </div> */}
                                            <div className='d-flex flex-column'>
                                                <p className='mb-2 inter-semi-bold'>{t('villas.filter.checkInOutLabel')}</p>
                                                <CheckInOutSelect
                                                    id={"calendar"}
                                                    className={"border border-1 villa-select"}
                                                    containerClasssName={"villa-input"}
                                                    noLabel
                                                    chevron
                                                />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <p className='mb-2 inter-semi-bold'>{t('villas.filter.guests.title')}</p>
                                                <GuestSelect
                                                    id={'guest'}
                                                    className={"border border-1 villa-select"}
                                                    containerClasssName={"villa-input"}
                                                    outsideTarget={"guest-container"}
                                                    noLabel
                                                    chevron
                                                />
                                            </div>
                                            {/* <div className='d-flex flex-column position-relative filter-map'>
                                                <p className='mb-2 tt-norms-semi-bolder'>Zona</p>
                                                <div className='d-flex justify-content-center'>
                                                    <FilterMapIcon width={"70%"} height={"100%"} hoverZone={triggerHoverLabel} clickZone={triggerMapLabel} triggerZoneId={triggerZoneId} zoneId={zoneId} />
                                                </div>
                                            </div> */}
                                            {/* <div>
                                                <p className='mb-2 tt-norms-semi-bolder'>Precio</p>
                                                <div className='d-flex justify-content-center'>
                                                    <Slider range allowCross={false} defaultValue={[20, 90]} min={0} max={100} pushable={10}
                                                        // onAfterChange={(val) => setPriceRange({min: val?.[0], max: val?.[1]})}
                                                        onChange={(val) => handlePrice(val)}
                                                    />
                                                </div>
                                            </div> */}
                                            <div>
                                                <p className='mb-2 inter-semi-bold mb-3'>{t('villas.filter.features')}</p>
                                                <div className='d-flex flex-column gap-lg-4'>
                                                    <VillasFeatures id={"DESKTOP"} bedrooms={bedrooms} updateBedrooms={setBedrooms} beds={beds} updateBeds={setBeds} baths={baths} updateBaths={setBaths} />
                                                </div>
                                            </div>
                                            <div>
                                                <p className='mb-2 inter-semi-bold'>{t('villas.filter.equipment.title')}</p>
                                                <Row className='gy-2'>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={t('villas.filter.equipment.wifi')}
                                                            className='mb-0'
                                                            id='wifi'
                                                            checked={equip?.wifi}
                                                            onChange={(e) => setEquip({ ...equip, "wifi": e.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={t('villas.filter.equipment.barbecue')}
                                                            className='mb-0'
                                                            id='bbq'
                                                            checked={equip?.barbeque}
                                                            onChange={(e) => setEquip({ ...equip, "barbeque": e.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={"TV"}
                                                            className='mb-0'
                                                            id='tv'
                                                            checked={equip?.television}
                                                            onChange={(e) => setEquip({ ...equip, "television": e.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={t('villas.filter.equipment.garden')}
                                                            className='mb-0'
                                                            id='garden'
                                                            checked={equip?.garden}
                                                            onChange={(e) => setEquip({ ...equip, "garden": e.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={t('villas.filter.equipment.views')}
                                                            className='mb-0'
                                                            id='views'
                                                            checked={equip?.view}
                                                            onChange={(e) => setEquip({ ...equip, "view": e.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={"Point-VE"}
                                                            className='mb-0'
                                                            id='ve'
                                                            checked={equip?.chargingPoint}
                                                            onChange={(e) => setEquip({ ...equip, "chargingPoint": e.target.checked })}
                                                        />
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Check
                                                            label={t('villas.filter.equipment.pets')}
                                                            className='mb-0'
                                                            id='pets'
                                                            checked={equip?.pets}
                                                            onChange={(e) => setEquip({ ...equip, "pets": e.target.checked })}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Button type="submit" variant='none' className='btn-laurel inter-medium'>{t('villas.filter.button')}</Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <Row className={`mx-0 py-3 bg-secondary villa-list custom-scroll ${sideMenu ? "active" : ""}`}>
                            <Col className='d-flex flex-column flex-grow-1'>
                                {layoutType === "card" &&
                                    <Row className='g-2 gy-3 g-lg-4 px-lg-5 py-lg-2 flex-grow-1'>
                                        {villas?.length > 0 && (
                                            villas?.map((villa, i) =>
                                                <Col lg={sideMenu ? 6 : 4} key={i}>
                                                    <VillaCard
                                                        data={villa}
                                                        theme={"laurel"}
                                                    />
                                                </Col>
                                            )
                                        )}

                                        {!villas && (
                                            [1, 2, 3, 4, 5, 6, 8, 9].map((data) =>
                                                <Col lg={sideMenu ? 6 : 4} key={data}>
                                                    <VillaCardPlaceholder />
                                                </Col>
                                            )
                                        )}

                                        {villas?.length === 0 && (
                                            <p className='inter-semi-bold text-center text-coal h5'>{t('villas.searcher.noResult')}</p>
                                        )}
                                    </Row>
                                }
                                {layoutType === "list" &&
                                    <Row className='g-2 gy-3 g-lg-4 px-lg-2 py-lg-2 flex-grow-1'>
                                        {villas?.length > 0 ? (
                                            villas?.map((villa, i) =>
                                                <Col key={i} lg={12}>
                                                    <VillaDetailedCard data={villa} guests={searchedGuest} />
                                                </Col>
                                            )
                                        ) : (
                                            !villas && (
                                                [1, 2, 3, 4, 5, 6, 8, 9].map((data) =>
                                                    <Col lg={12} key={data}>
                                                        <VillaDetailedCardPlaceholder />
                                                    </Col>
                                                )
                                            )
                                        )}
                                    </Row>
                                }

                                {villas?.length > 0 && (
                                    <ReactPaginate
                                        pageCount={villaPagination?.totalPages}
                                        onPageChange={(event) => {
                                            setVillas()
                                            document.getElementsByClassName('villa-list')?.[0].scrollTo({ top: 0 })
                                            setSearchParams({ ...searchParams, "page": event.selected + 1 })
                                            setVillaPagination({ ...villaPagination, currentPage: event.selected + 1 })
                                        }}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        containerClassName='pagination gap-1'
                                        pageClassName='page'
                                        previousClassName='page'
                                        previousLabel="<"
                                        prevRel={"prev"}
                                        nextClassName='page'
                                        nextLabel=">"
                                        nextRel={"next"}
                                        breakClassName='page'
                                        className="pagination gap-1 mt-4"
                                        forcePage={villaPagination.currentPage - 1}
                                        hrefBuilder={(page) =>
                                            `${window.origin}/${i18n.language}/${t('pages.villa')}/${t('pages.villaSearcher')}${searchParams && `?page=${page}`}`
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Row className='villa-searcher g-0 d-none'>
                        <Col lg={3} className='filter'>
                            <div className='d-flex flex-row justify-content-between align-items-center py-3 px-3 z-index-1'>
                                <Button variant='none' className='shadow-none p-0 border-0'>
                                    <FilterRangeIcon width={28} height={28} />
                                </Button>
                                <Button variant='none' className='d-flex align-items-center justify-content-center radius shadow-none border border-1 border-coal-semi-transparent control'>
                                    <FontAwesomeIcon icon={"fa-solid fa-minus"} width={14} />
                                </Button>
                            </div>
                        </Col>
                        <Col lg={9} className='content'>
                            <Row className='d-flex flex-row flex-grow-1 g-2 gy-3 g-lg-3 bg-secondary px-5 py-3 m-0 custom-scroll list'>
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((data, i) =>
                                    <Col lg={6} key={i}>
                                        <VillaCard
                                            theme={"laurel"}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='bg-secondary p-4 villa-searcher d-flex d-lg-none'>
                    <div className='w-100'>
                        <VillaMobileFilter containerClassName={"d-flex align-self-start bg-white"} sheet={sheetShow} action={() => setSheetShow(true)} page={"searcher"} />
                        <Row className={`mx-0 py-3 bg-secondary villa-list custom-scroll gx-0 ${sideMenu ? "active" : ""}`}>
                            <Col xs={12}>
                                <Row className='g-2 gy-3 g-lg-4 px-lg-5 py-lg-4'>
                                    {villas?.length > 0 && (
                                        villas?.map((villa, i) =>
                                            <Col lg={sideMenu ? 6 : 4} key={i}>
                                                <VillaCard
                                                    data={villa}
                                                    theme={"laurel"}
                                                />
                                            </Col>
                                        )
                                    )}

                                    {!villas && (
                                        [1, 2, 3, 4, 5, 6, 8, 9].map((data) =>
                                            <Col lg={sideMenu ? 6 : 4} key={data}>
                                                <VillaCardPlaceholder />
                                            </Col>
                                        )
                                    )}

                                    {villas?.length === 0 && (
                                        <p className='inter-semi-bold text-center text-coal h5'>{t('villas.searcher.noResult')}</p>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        {villas?.length > 0 && (
                            <ReactPaginate
                                pageCount={villaPagination?.totalPages}
                                onPageChange={(event) => {
                                    setVillas()
                                    setSearchParams({ ...searchParams, "page": event.selected + 1 })
                                    window.scrollTo({ top: 0 })
                                    setVillaPagination({ ...villaPagination, currentPage: event.selected + 1 })
                                }}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                containerClassName='pagination gap-1'
                                pageClassName='page'
                                previousClassName='page'
                                previousLabel="<"
                                prevRel={"prev"}
                                nextClassName='page'
                                nextLabel=">"
                                nextRel={"next"}
                                breakClassName='page'
                                className="pagination gap-1 mt-4"
                                forcePage={villaPagination.currentPage - 1}
                                hrefBuilder={(page) =>
                                    `${window.origin}/${i18n.language}/${t('pages.villa')}/${t('pages.villaSearcher')}${searchParams && `?page=${page}`}`
                                }
                            />
                        )}
                    </div>
                </div>
            </Container>
            <TzoSheet title={t('villas.filter.mobileTitle')} show={sheetShow} close={() => setSheetShow(false)} stickyHead>
                <Form onSubmit={handleSubmit(() => {
                    document.getElementsByClassName("villa-list")?.[0].scrollTo({ top: 0 })
                    setVillas()
                    parseInt(villaPagination.currentPage) === 1 && getVillas()
                    navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}?page=1`, { replace: true })
                })}>
                    <div className='d-flex flex-column gap-4 p-4 villa-mobile-filters'>
                        {/* <div className='d-flex flex-column'>
                            <p className='mb-2 tt-norms-semi-bolder'>Destino</p>
                            <DestinationSelect
                                id={"destination-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                outsideTarget={"destination-container-mobile"}
                                noLabel
                                chevron
                            />
                        </div> */}
                        <div className='d-flex flex-column'>
                            <p className='mb-2 inter-semi-bold'>{t('villas.filter.checkInOutLabel')}</p>
                            <CheckInOutSelect
                                id={"calendar-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                noLabel
                                chevron
                            />
                        </div>
                        <div className='d-flex flex-column'>
                            <p className='mb-2 inter-semi-bold'>{t('villas.filter.guests.title')}</p>
                            <GuestSelect
                                id={"guest-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                outsideTarget={"guest-container-mobile"}
                                noLabel
                                chevron
                            />
                        </div>
                        {/* <div>
                            <p className='mb-2 tt-norms-semi-bolder'>Precio</p>
                            <div className='d-flex justify-content-center mb-2'>
                                <Slider range allowCross={false} defaultValue={[20, 90]} min={0} max={100} pushable={10}
                                    // onAfterChange={(val) => setPriceRange({min: val?.[0], max: val?.[1]})}
                                    onChange={(val) => handlePrice(val)}
                                />
                            </div>
                        </div> */}
                        {/* <div>
                            <CustomMosaic />
                        </div> */}
                        <div className='mt-2'>
                            <p className='mb-2 inter-semi-bold'>{t('villas.filter.equipment.title')}</p>
                            <Row className='gy-2'>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.wifi')}
                                        className='mb-0'
                                        id='wifi'
                                        checked={equip?.wifi}
                                        onChange={(e) => setEquip({ ...equip, "wifi": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.barbecue')}
                                        className='mb-0'
                                        id='bbq'
                                        checked={equip?.barbeque}
                                        onChange={(e) => setEquip({ ...equip, "barbeque": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={"TV"}
                                        className='mb-0'
                                        id='tv'
                                        checked={equip?.television}
                                        onChange={(e) => setEquip({ ...equip, "television": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.garden')}
                                        className='mb-0'
                                        id='garden'
                                        checked={equip?.garden}
                                        onChange={(e) => setEquip({ ...equip, "garden": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.views')}
                                        className='mb-0'
                                        id='views'
                                        checked={equip?.view}
                                        onChange={(e) => setEquip({ ...equip, "view": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={"Point-VE"}
                                        className='mb-0'
                                        id='ve'
                                        checked={equip?.chargingPoint}
                                        onChange={(e) => setEquip({ ...equip, "chargingPoint": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.pets')}
                                        className='mb-0'
                                        id='pets'
                                        checked={equip?.pets}
                                        onChange={(e) => setEquip({ ...equip, "pets": e.target.checked })}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <p className='mb-2 inter-semi-bold mb-3'>{t('villas.filter.features')}</p>
                            <VillasFeatures id={"MOBILE"} bedrooms={bedrooms} updateBedrooms={setBedrooms} beds={beds} updateBeds={setBeds} baths={baths} updateBaths={setBaths} />
                        </div>
                        <Button type="submit" variant='none' className='btn-laurel inter-medium'>{t('villas.filter.button')}</Button>
                    </div>
                </Form>
            </TzoSheet>
        </main>
    )
}

export default VillaSearcher