import React from 'react'
import { Button, Col, Image, Placeholder, Row } from 'react-bootstrap'

function VillaDetailedCardPlaceholder() {
    return (
        <div className='d-flex flex-row p-4 bg-white radius card-shadow villa-detailed-card gap-4'>
            <div className={`slider radius overflow-hidden flex-shrink-0`.trim()}>
                <Placeholder as={"span"} animation="glow" className="d-flex align-items-center h-100" >
                    <Placeholder xs={12} className="h-100 radius" />
                </Placeholder>
            </div>
            {/* <Image fluid src={"/img/bestof-home.png"} className='picture radius flex-shrink-1' /> */}
            <Row className='gx-4 flex-grow-1 flex-shrink-1'>
                <Col lg={7} className='d-flex flex-column gap-3 justify-content-between'>
                    <div className='d-flex flex-column gap-2'>
                        <Placeholder as={"span"} animation="wave" size='lg' className="d-flex align-items-center h-100" >
                            <Placeholder xs={12} className="h-100 radius" />
                        </Placeholder>
                        <Placeholder as={"span"} animation="wave" size='sm' className="d-flex align-items-center h-100" >
                            <Placeholder xs={8} className="h-100 radius" />
                        </Placeholder>
                    </div>
                    <div className='d-flex flex-row gap-3'>
                        <Placeholder as={"span"} animation="wave" size='lg' className="d-flex align-items-center h-100 w-100" >
                            <Placeholder xs={10} className="h-100 radius" />
                        </Placeholder>
                    </div>
                    <div className='d-flex flex-column gap-3'>
                        <Placeholder as={"span"} animation="wave" size='sm' className="d-flex align-items-center w-100" >
                            <Placeholder xs={11} size='lg' className="h-100 radius" />
                        </Placeholder>
                        <Placeholder as={"span"} animation="wave" size='sm' className="d-flex align-items-center w-100" >
                            <Placeholder xs={9} className="h-100 radius" />
                        </Placeholder>
                        <Placeholder as={"span"} animation="wave" size='sm' className="d-flex align-items-center w-100" >
                            <Placeholder xs={10} className="h-100 radius" />
                        </Placeholder>
                        {/* <p className='tt-norms-bold mb-0'>3 camas (2 dobles, 1 sofá cama)</p> */}
                    </div>
                </Col>
                <Col lg={5} className='d-flex flex-column justify-content-between gap-3 text-end'>
                    <div className='d-flex flex-row gap-2 justify-content-end'>
                        <Placeholder as={"span"} animation="glow" size='sm' className="d-flex align-items-center rating" >
                            <Placeholder xs={10} className="h-100 radius" />
                        </Placeholder>
                    </div>
                    <div className='d-flex flex-column gap-3'>
                        <div className='d-flex flex-column gap-2 justify-content-end align-items-end'>
                            <Placeholder as={"span"} animation="wave" size='xs' className="d-flex justify-content-end w-100" >
                                <Placeholder xs={9} className="h-100 radius" />
                            </Placeholder>
                            <Placeholder as={"span"} size='lg' animation="wave" className="d-flex justify-content-end w-100" >
                                <Placeholder xs={4} size='lg' className="h-100 radius py-3" />
                            </Placeholder>                               </div>
                        <div className='d-flex flex-column gap-1'>
                            <Placeholder as={"span"} animation="wave" size="xs" className="d-flex justify-content-end w-100" >
                                <Placeholder xs={5} className="h-100 radius" />
                            </Placeholder>
                            <Placeholder as={"span"} animation="wave" size="lg" className="d-flex justify-content-end w-100" >
                                <Placeholder xs={12} className="h-100 radius py-3" />
                            </Placeholder>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default VillaDetailedCardPlaceholder