import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Button, Form, Image } from 'react-bootstrap'
import FilterGrid from '../svg-component/FilterGrid'
import MapMenuIcon from '../svg-component/MapMenuIcon'
import Link from '../Link'
import { useTranslation } from 'react-i18next'
import { VillasContext } from '../../Contexts'
import FilterList from '../svg-component/FilterList'

function VillaMobileFilter({ containerClassName, action, page, sheet }) {
    const { t, i18n } = useTranslation();
    const { villasState: { filter } } = useContext(VillasContext);

    return (
        <div className={`villa-mobile-filter-menu ${containerClassName}`.trim()}>
            <div className='d-flex flex-row align-items-center border border-1 border-coal-semi-transparent radius p-1 flex-grow-1 align-items-center justify-content-center bg-white'>
                {/* <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} width={20} className='text-coal opacity-75 px-2' />
                <Form onSubmit={(e) => { 
                    e.preventDefault();
                }}>
                    <Form.Control placeholder='Buscar alojamiento' className='text-coal text-coal tt-norms-semi-bolder border-0 shadow-none pb-1 ps-0' />
                </Form> */}
                <div className='d-flex flex-column flex-grow-1 lh-sm ps-1 position-relative' onClick={action}>
                    <span className='inter-bold mb-0'>Mallorca</span>
                    <div className='d-flex flex-row gap-1 inter-regular small'>
                        {(filter?.startDate && filter?.endDate) && (
                            <>
                                <span>{filter?.startDate?.getDate()} {filter?.startDate?.toLocaleString('default', { month: 'short' })} - {filter?.endDate?.getDate()} {filter?.endDate?.toLocaleString('default', { month: 'short' })}</span>
                                <span>|</span>
                            </>
                        )}
                        <span>{filter?.totalGuest} {t('villas.filter.guests.title')}</span>
                    </div>
                    {/* <Button variant='none' className='p-0 border-0 flex-grow-1 py-1 shadow-none' onClick={action}>{t('villas.filter.mobileTitle')}</Button>
                     */}
                    <FontAwesomeIcon icon={`fa-solid ${sheet ? "fa-chevron-up" : "fa-chevron-down"}`} width={13} className='text-coal position-absolute top-50 end-0 translate-middle-y pe-2' />
                </div>
                <Link href={`${t('pages.villa')}/${t('pages.villaMap')}`}>
                    <Button variant='none' className='border-start border-1 border-coal-semi-transparent d-flex py-1 px-2 shadow-none border-0'>
                        <MapMenuIcon width={28} height={28} active={page === "map" && true} />
                    </Button>
                </Link>
                <Link href={`${t('pages.villa')}/${t('pages.villaSearcher')}`}>
                    <Button variant='none' className='border-start border-1 border-coal-semi-transparent d-flex py-1 px-2 shadow-none border-0'>
                        <FilterList width={28} height={28} active={page === "searcher" && true} />
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default VillaMobileFilter