import React, { useContext, useRef } from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import useClickOutsideElement from '../../hooks/useClickOutsideElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { VillasContext } from '../../Contexts';
import { useTranslation } from 'react-i18next';


function GuestSelect({ className, containerClasssName, outsideTarget, noLabel, chevron, id }) {

    const { t, i18n } = useTranslation();
    const { villasState: { filter }, villasState, villasUpdate } = useContext(VillasContext)
    const { handleClickOutside } = useClickOutsideElement();

    const timeOutRef = useRef();

    const [dropOpen, setDropOpen] = useState(false);
    // const [guestData, setGuestData] = useState({
    //     adults: {
    //         name: "Adultos",
    //         label: "Adulto/s",
    //         total: 0
    //     },
    //     childrens: {
    //         name: "Niños",
    //         label: "Niño/s",
    //         total: 0
    //     },
    //     babies: {
    //         name: "Bebés",
    //         label: "Bebé/s",
    //         total: 0
    //     }
    // });
    const [totalGuest, setTotalGuest] = useState(filter?.totalGuest)

    // const syncData = () => {
    //     let guestObjCopy = { ...guestData }

    //     Object.keys(guestObjCopy).forEach((guestType) => {
    //         guestObjCopy[guestType].total = filter?.guests?.[guestType]
    //     })

    //     setGuestData(guestObjCopy)
    // }

    // const syncTotal = () => {
    //     setGuestData(guestObjCopy)
    // }

    useEffect(() => {
        const triggerClick = (event) => {
            handleClickOutside(event, outsideTarget, dropOpen, () => setDropOpen(false), 'guestControl')
        }

        if (dropOpen) {
            document.addEventListener("mousedown", triggerClick)
        }

        if (!dropOpen) {
            document.removeEventListener("mousedown", triggerClick)
            document.getElementById(id)?.classList?.replace("open", "close")
            timeOutRef.current = setTimeout(() => {
                document.getElementById(id)?.classList.add("d-none")
            }, 225);
        } else {
            timeOutRef.current = document.getElementById(id)?.classList?.remove("d-none")
            setTimeout(() => {
                document.getElementById(id)?.classList?.replace("close", "open")
            }, 10);
        }

        return () => {
            document.removeEventListener("mousedown", triggerClick)
        };
    }, [dropOpen])

    // useEffect(() => {
    //     setTotalGuest(guestData?.adults.total + guestData?.childrens?.total + guestData?.babies?.total)
    //     villasUpdate({
    //         ...villasState,
    //         filter: {
    //             ...villasState.filter,
    //             totalGuest: guestData?.adults.total + guestData?.childrens?.total + guestData?.babies?.total,
    //             guests: {
    //                 adults: guestData?.adults.total,
    //                 childrens: guestData?.childrens?.total,
    //                 babies: guestData?.babies?.total
    //             }
    //         }
    //     })
    // }, [guestData])

    useEffect(() => {
        if (totalGuest !== filter?.totalGuest) {
            villasUpdate({
                ...villasState,
                filter: {
                    ...villasState.filter,
                    totalGuest: totalGuest,
                }
            })
        }
    }, [totalGuest])

    useEffect(() => {

        // const localTotal = (guestData?.adults.total + guestData?.childrens?.total + guestData?.babies?.total) || 0

        // if (filter?.totalGuest && localTotal !== filter?.totalGuest) {
        //     syncData();
        // }

        if (!totalGuest || totalGuest !== filter?.totalGuest) {
            setTotalGuest(filter?.totalGuest || 1)
        }
    }, [filter])

    return (
        <div className={`d-flex flex-grow-1 position-relative ${containerClasssName}`} id={outsideTarget}>
            <div className={`d-flex flex-row flex-grow-1 justify-content-between px-3 py-2 cursor-pointer user-select-none ${className} ${dropOpen ? "open" : ""}`} onClick={() => setDropOpen(!dropOpen)} id='guestControl'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    {!noLabel && <span className='tt-norms-semi-bolder mb-0'>{t('villas.filter.guests.title')}</span>}
                    <div className='d-flex flex-row gap-2'>
                        {/* {Object.keys(guestData).map((guest, i) => {
                            if (guestData?.[guest].total > 0) {
                                return (
                                    <span key={i} className='inter-medium text-coal small'>
                                        {guestData?.[guest].total > 0 ? ` ${guestData?.[guest]?.total} ${guestData?.[guest]?.label}` : ""}
                                    </span>
                                )
                            }
                        })} */}
                        {/* {(guestData?.["adults"].total === 0 && guestData?.["childrens"].total === 0 && guestData?.["babies"].total === 0) && (
                            <span className='inter-medium text-coal small'>{t('villas.filter.guests.placeholder')}</span>
                        )} */}
                        {totalGuest > 0 && <span className='inter-medium text-coal small'>{totalGuest} {totalGuest > 1 ? "Personas" : "Persona"}</span>}

                        {totalGuest === 0 && <span className='inter-medium text-coal small'>{t('villas.filter.guests.placeholder')}</span>}
                    </div>
                </div>
                {chevron && <FontAwesomeIcon icon={`fa-solid ${dropOpen ? "fa-chevron-up" : "fa-chevron-down"}`} className='text-coal' width={12} />}
            </div>
            <div className={`d-flex flex-lg-column position-absolute top-100 w-100 bg-white d-flex shadow-sm options-container close`} id={id}>
                <ul>
                    {/* {Object.keys(guestData)?.map((guest, i) =>
                        <li key={i}>
                            <div className='d-flex flex-row gap-2 justify-content-between option'>
                                <div>
                                    <p className='tt-norms-bolder mb-0'>{guestData?.[guest]?.label}</p>
                                    <p className='tt-norms-bold opacity-75 mb-0'>13 años o más</p>
                                </div>
                                <div className='d-flex flex-row align-items-center gap-3'>
                                    <Button className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => guestData?.[guest]?.total > 0 && setGuestData(prevState => ({ ...prevState, [guest]: { ...guestData?.[guest], total: guestData?.[guest].total - 1 } }))}>
                                        <FontAwesomeIcon icon={"fa-solid fa-minus"} width={12} className='text-coal' />
                                    </Button>
                                    <span className='tt-norms-semi-bolder'>{guestData?.[guest]?.total}</span>
                                    <Button className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => setGuestData(prevState => ({ ...prevState, [guest]: { ...guestData?.[guest], total: guestData?.[guest].total + 1 } }))}>
                                        <FontAwesomeIcon icon={"fa-solid fa-plus"} width={12} className='text-coal' />
                                    </Button>
                                </div>
                            </div>
                        </li>
                    )} */}
                    <li>
                        <div className='d-flex flex-row gap-2 justify-content-between option py-3'>
                            <div>
                                <p className='tt-norms-bolder mb-0'>Viajeros</p>
                                {/* <p className='tt-norms-bold opacity-75 mb-0'>13 años o más</p> */}
                            </div>
                            <div className='d-flex flex-row align-items-center gap-3'>
                                <Button className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => setTotalGuest(prevState => (prevState - 1) < 1 ? 1 : (prevState - 1))}>
                                    <FontAwesomeIcon icon={"fa-solid fa-minus"} width={12} className='text-coal' />
                                </Button>
                                <span className='tt-norms-semi-bolder'>{totalGuest}</span>
                                <Button className='d-flex align-items-center justify-content-center p-1 border border-1 border-coal opacity-50 radius shadow-none control' onClick={() => setTotalGuest(prevState => prevState + 1)}>
                                    <FontAwesomeIcon icon={"fa-solid fa-plus"} width={12} className='text-coal' />
                                </Button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}


export default GuestSelect