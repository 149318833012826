import api from './api';

const getSection = (data) => {
    return api.get(`/section`, data).then(res => res.data);
}

const SectionService = {
    getSection
}

export default SectionService;