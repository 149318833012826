import React from 'react'

function MapMenuIcon({ width, height, active }) {
	return (
		<svg id="filter-map-icon" className={active ? "active" : null} width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Map">
				<path className='background' d="M6.68959 7.43475L0.855957 1.96572V14.3622L6.68959 19.8312L7.4188 19.102L13.9816 13.633L19.8153 19.102V6.70554L13.9816 1.23651L6.68959 7.43475Z" fill='white' />
				<g id="Vector">
					<path fillRule="evenodd" clipRule="evenodd" d="M13.7474 14.3569L20.5447 20.7088V6.35914L13.9818 0.241638L13.9818 0.291118L6.93286 6.63519L0.126953 0.291119V14.6408L6.68979 20.7583V20.7088L13.7474 14.3569ZM13.9818 13.5945V1.23851L19.8155 6.67629V19.0322L13.9818 13.5945ZM6.68979 7.4055V19.7614L0.856157 14.3237V1.96772L6.68979 7.4055ZM7.41899 7.17872L7.419 19.0715L13.2526 13.8212L13.2526 1.92845L7.41899 7.17872Z" fill="#5A5C69" />
					<path fillRule="evenodd" clipRule="evenodd" d="M13.7474 14.3569L6.68979 20.7088V20.7583L0.126953 14.6408V0.291119L6.93286 6.63519L13.9818 0.291118L13.9818 0.241638L20.5447 6.35914V20.7088L13.7474 14.3569ZM13.9818 1.23851V13.5945L19.8155 19.0322V6.67629L13.9818 1.23851ZM6.68979 19.7614V7.4055L0.856157 1.96772V14.3237L6.68979 19.7614ZM7.419 19.0715L7.41899 7.17872L13.2526 1.92845L13.2526 13.8212L7.419 19.0715Z" fill="#5A5C69" />
				</g>
			</g>
		</svg>

	)
}

export default MapMenuIcon