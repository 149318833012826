import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb'

const Sponsor = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <BreadcrumbPage mobileHide />
            <Container className="px-0 px-md-4 py-4 mb-md-5 mt-md-4 py-md-5 p-md-4 sponsor-landing">
                <h1 className='mb-4 joane-bold px-4 px-md-0'>{t('sponsor.title')}</h1>
                <p className='px-4 px-md-0'>
                    <span className='tt-norms-bold'>{t('sponsor.description.text1')} </span>
                    <span className='tt-norms-bolder'>{t('sponsor.description.text2')} </span>
                    <span className='tt-norms-bold'>{t('sponsor.description.text3')}</span>
                </p>
                <p className='tt-norms-bold mb-4 mb-md-4 px-4 px-md-0'>{t('sponsor.description.text4')}</p>
                <a href={`https://www.proarentacar.com/${i18n.language}/`} target="_blank" className='d-flex d-md-none justify-content-start border-bottom border-dark px-4 px-md-0'>
                    <Image src='/img/svg/rac-logo.svg' className='rac-logo-sponsor text-center mb-4' />
                </a>
                <video autoPlay loop muted playsInline className='mb-4 mt-4 mt-md-0 mb-md-4 px-4 px-md-0'>
                    <source autoPlay src={`/img/sponsor/sponsor-landing.mp4`} type={"video/mp4"} />
                </video>
                <Row className='d-none d-md-flex mx-2 mx-lg-5 justify-content-between'>
                    <Col xs={4} lg={3} className="align-self-center mt-2 mt-lg-3">
                        <a href={`https://www.proarentacar.com/${i18n.language}/booking/vehicle?promotionalCode=TRAVELTOZERO&utm_medium=BANNER&utm_source=TZOW&utm_campaign=TZOERAC`} target="_blank" className='d-flex flex-column tt-norms text-decoration-none text-black'>
                            <span className='border border-dark py-1 tt-norms-bolder text-center mb-1'>TRAVELTOZERO</span>
                            <small className='text-center tt-norms-bold'>{t('sponsor.discountCode')}</small>
                        </a>
                    </Col>
                    <Col xs={4} lg={6} className="text-center">
                        <a href={`https://www.proarentacar.com/${i18n.language}/`} target="_blank">
                            <Image src='/img/svg/rac-logo.svg' className='rac-logo-sponsor' />
                        </a>
                    </Col>
                    <Col xs={4} lg={3} className="mt-md-2 mt-lg-3">
                        <a href={`https://www.proarentacar.com/${i18n.language}/booking/vehicle?promotionalCode=TRAVELTOZERO&utm_medium=BANNER&utm_source=TZOW&utm_campaign=TZOERAC`} target="_blank">
                            <Button variant='none' className='shadow-none btn-solid-blue py-1 w-100 tt-norms-bold'>{t('sponsor.rateButton')}</Button>
                        </a>
                    </Col>
                </Row>
                <Row className='d-flex d-md-none gap-4 g-0 px-4 px-md-0 justify-content-center'>
                    <Col xs={9} className="align-self-center mt-md-3">
                        <a href={`https://www.proarentacar.com/${i18n.language}/booking/vehicle?promotionalCode=TRAVELTOZERO&utm_medium=BANNER&utm_source=TZOW&utm_campaign=TZOERAC`} target="_blank" className='d-flex flex-column tt-norms text-decoration-none text-black'>
                            <span className='border border-dark py-1 tt-norms-bolder text-center mb-1'>TRAVELTOZERO</span>
                            <small className='text-center tt-norms-bold'>{t('sponsor.discountCode')}</small>
                        </a>
                    </Col>
                    <Col xs={9} className="align-self-center">
                        <a href={`https://www.proarentacar.com/${i18n.language}/booking/vehicle?promotionalCode=TRAVELTOZERO&utm_medium=BANNER&utm_source=TZOW&utm_campaign=TZOERAC`} target="_blank">
                            <Button variant='none' className='shadow-none btn-solid-blue py-1 w-100 tt-norms-bold'>{t('sponsor.rateButton')}</Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Sponsor