import { Card, Col, Container, Row, Button, Image } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom";
import Link from "../../components/Link";
import { useEffect } from "react";
import TokenService from "../../services/token.service";
import RegisterService from "../../services/register.service";
import SeoHelmet from "../../components/seo-helmet/SeoHelmet";

const UpdateEmail = () => {

    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('token')) {
            try {
                RegisterService.validateEmail({ token: searchParams.get('token') })
                TokenService.setUser(searchParams.get('token'))
            } catch (error) {
                console.log(error);
            }
        }
    }, [searchParams])

    return (
        <Container fluid className="bg-secondary py-5 px-4 px-sm-5">
            <SeoHelmet title={t('seo.updateEmail.title')} metaTitle={t('seo.updateEmail.metaTitle')} metaDescription={t('seo.updateEmail.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <Row className="justify-content-center align-items-center">
                <Col md={8}>
                    <Card className="shadow-sm">
                        <Row className="g-0">
                            <Col md={6} className="d-none d-lg-block p-5 rounded-start img-login">
                                <div className="mb-5 mx-4 d-flex flex-column justify-content-center">
                                    <h2 className="position-relative h2 text-white tt-norms-thin text-center">Únete a la familia</h2>
                                    <h2 className="position-relative h1 d-inline-block text-white text-center align-self-center">
                                        <span className="tt-norms-bold pe-1 m-0">TRAVELTO</span>
                                        <span className="joane m-0">ZERO</span>
                                        <img className="position-absolute top-0 start-100 login-title-icon" src="/img/icons/wheel-icon.svg" alt="iconText" width={30} height={30} />
                                    </h2>
                                </div>
                                <ul className="loginList text-white py-5 mb-0">
                                    <li className="h5 mb-5 tt-norms-thin">{t('login.leftCard.list.item1')}</li>
                                    <li className="h5 mb-5 tt-norms-thin">{t('login.leftCard.list.item2')}</li>
                                    <li className="h5 mb-5 tt-norms-thin">{t('login.leftCard.list.item3')}</li>
                                    <li className="h5 mb-5 tt-norms-thin">{t('login.leftCard.list.item4')}</li>
                                    <li className="h5 tt-norms-thin">{t('login.leftCard.list.item5')}</li>
                                </ul>
                            </Col>
                            <Col xs={12} lg={6} className="px-4 px-sm-5 py-5 text-center">
                                <Image src="/img/svg/check-validation.svg" width={36} height={36} />
                                <h2 className="h3 tt-norms fw-bolder text-center mt-3 mb-4">{t('register.rightCard.validation.title')}</h2>
                                <p className="mb-5">{t('register.rightCard.validation.text')}</p>

                                <Link href={`${t("pages.login")}`}>
                                    <Button  className="m-auto px-5 fw-bolder" variant="primary" type="submit">{t('register.rightCard.validation.button')}</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default UpdateEmail