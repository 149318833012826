import React, { useContext, useState } from 'react'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'
import { useTranslation } from 'react-i18next';
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb';
import { Alert, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import Link from '../../components/Link';
import { AppContext } from '../../AppContext';
import { useForm } from 'react-hook-form';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TokenService from '../../services/token.service';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RegisterService from '../../services/register.service';
import StickyBox from 'react-sticky-box';

const RecoverPassword = () => {

    library.add(fas);

    const { t, i18n } = useTranslation();
    const { state, update } = useContext(AppContext)
    const navigate = useNavigate();
    const { register, handleSubmit, reset } = useForm({
        mode: "all",
        defaultValues: {}
    })

    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState();
    const [sendConfirm, setSendConfirm] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [registerAccount, setRegisterAccount] = useState(false);
    const [registerConfirm, setRegisterConfirm] = useState(false);

    const [errors, setErrors] = useState({
        server: false,
        password: false,
        registered: false,
    });

    const formSubmit = async (data) => {
        setErrors({ ...errors, server: false, registered: false })
        setErrors({ ...errors, password: false })

        try {
            await UserService.recoverPassword({ email: data.email, validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.recoverPassword')}` })
            setEmail(data.email)
            setSendConfirm(true)
        } catch (error) {
            if (error.response.data.errorCode === 12011) {
                setErrors({ ...errors, registered: true })
                setRegisterAccount(true);
                setEmail(data?.email)
            } else {
                setErrors({ ...errors, server: true })
            }
        }
    }

    const changePassword = async (data) => {
        setErrors({ ...errors, server: false })
        setErrors({ ...errors, password: false })

        if (data.password === data.confirmPassword) {
            try {
                await UserService.resetPassword({ password: data.password, token: searchParams.get('token') })
                reset();
                setPasswordChanged(true);
            } catch (error) {
                setErrors({ ...errors, server: true })
            }
        } else {
            setErrors({ ...errors, password: true })
        }
    }

    const login = async (data) => {

        setErrors({
            login: false,
            server: false
        });

        try {
            await AuthService.login(data.email, data.password);
            const user = await UserService.userInfo();
            update({
                ...state,
                user
            });
            navigate(`/${i18n.language}/`)
        } catch (error) {
            if (error.response.data.errorCode === 12011) {
                setErrors({ ...errors, login: true });
            } else {
                setErrors({ ...errors, server: true });
            }
        }
    }

    const createAccount = async (data) => {
        setErrors({
            password: false,
            email: false,
            server: false
        })

        if (data?.password === data?.confirmPassword) {
            try {
                await RegisterService.sendEmail({
                    email: email,
                    firstName: "",
                    lastName: "",
                    password: data?.password,
                    validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
                })
                setRegisterConfirm(true)
            } catch ({ response }) {
                if (response?.data?.errorCode) {
                    switch (response.data.errorCode) {
                        case 13002:
                            setErrors({ ...errors, email: true });
                            break;
                        default:
                            setErrors({ ...errors, server: true });
                    }
                }
            }
        }
    }

    const resetErrors = () => {
        setErrors({
            password: false,
            email: false,
            server: false
        })
    }

    return (
        <>
            <SeoHelmet title={t('seo.login.title')} metaTitle={t('seo.login.metaTitle')} metaDescription={t('seo.login.metaDescription')} ogImage={'/img/home-left-1.png'} robots />
            {errors?.password &&
                <div className='border-top border-bottom border-dark bg-primary tt-norms px-4 py-1'>
                    {t('register.errors.password')}
                </div>
            }
            <Container fluid className='px-0 bg-beige login-container'>
                <Row className='g-0'>
                    <Col lg={5} className='background-container d-none d-lg-block'>
                        <StickyBox offsetTop={60} className='bg-dark-brown'>
                            <div className='d-flex flex-column gap-4 mx-auto recover-content'>
                                <Image src={`/img/recovery.gif`} className='background ' fluid />
                                <div className='position-absolute top-0 h-100 w-100 d-flex flex-column justify-content-center align-items-start p-5 overlay'>
                                    <Image src='/img/svg/new-logo.svg' className='mb-4 px-5' fluid style={{ width: "630px" }} />
                                    <p className='inter-regular text-white mb-0 fs-2 px-5 mb-0 text-start lh-sm'>{t('recoverPassword.text1')}</p>
                                    <p className='inter-regular text-white mb-0 fs-2 px-5 mb-0 text-start lh-sm'>{t('recoverPassword.text2')}</p>
                                </div>
                            </div>
                        </StickyBox>
                    </Col>
                    <Col xs={12} lg={7} className='d-flex justify-content-center px-4 px-lg-0'>
                        <div className='login-content py-4 py-lg-5 radius form-card d-flex flex-column align-content-center justify-content-center position-relative h-100 vh-100-page'>
                            {registerAccount ? (
                                <div className='position-relative d-flex flex-column justify-content-center h-100'>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="text-black d-block position-absolute top-0 start-0 cursor-pointer d-none d-md-block" width={28} height={28} onClick={() => {
                                        setRegisterAccount(false)
                                        resetErrors()
                                    }} />
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="position-absolute top-0 text-black mb-3 d-block d-md-none mt-0" width={28} height={28} onClick={() => {
                                        setRegisterAccount(false)
                                        resetErrors()
                                    }} />
                                    <h1 className='tt-norms-bold subtitle mb-5'>{t('register.card.button')}</h1>
                                    <div className='bg-primary tt-norms px-4 py-1 h6 radius mb-4'>
                                        {t('recoverPassword.errors.create')}
                                    </div>
                                    <Form onSubmit={handleSubmit(createAccount)} className='d-flex flex-column'>
                                        <Form.Group className='mb-4'>
                                            <Form.Control type="password" placeholder={t('recoverPassword.card.placeholder.password')} {...register("password", { required: true })} required />
                                        </Form.Group>
                                        <Form.Group className='mb-5'>
                                            <Form.Control type="password" placeholder={t('recoverPassword.card.placeholder.repeat')} {...register("confirmPassword", { required: true })} required />
                                        </Form.Group>
                                        <Button type={"submit"} className='btn-coal tt-norms-bold px-5 align-self-start modal-btn-size radius'>{t('register.card.button')}</Button>
                                    </Form>
                                </div>
                            ) : !sendConfirm && !searchParams.get('token') ? (
                                <div className='position-relative d-flex flex-column justify-content-center h-100'>
                                    <Link href={`${t('pages.login')}`}>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="text-black cursor-pointer d-none d-md-block mb-4" width={28} height={28} />
                                    </Link>
                                    <Link href={`${t('pages.login')}`}><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className=" position-absolute top-0 text-black mb-3 d-block d-md-none mt-0" width={28} height={28} /></Link>
                                    <h1 className='fitzgerald-regular fs-1 mb-4 text-center'>{t('recoverPassword.recover.title')}</h1>
                                    <p className='inter-regular mb-4 opacity-50 fs-5 text-center'>{t('recoverPassword.card.recover.subtitle1')} {t('recoverPassword.card.recover.subtitle2')}</p>
                                    <Form onSubmit={handleSubmit(formSubmit)} className='d-flex flex-column'>
                                        <Form.Group className='mb-4'>
                                            <Form.Control type="email" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.email')} {...register("email", {
                                                required: true, pattern: {
                                                    value: /\S+@\S+/
                                                },
                                            })} required />
                                        </Form.Group>
                                        <Button type={"submit"} className='btn-dark-brown-outline inter-regular text-uppercase letter-spacing-1 py-3'>{t('recoverPassword.recover.form.button')}</Button>
                                    </Form>
                                </div>
                            ) : sendConfirm && !searchParams.get('token') ? (
                                <div className='position-relative d-flex flex-column justify-content-center h-100'>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="text-black cursor-pointer d-none d-md-block mb-4" width={28} height={28} onClick={() => {
                                        setSendConfirm(false)
                                        resetErrors()
                                    }} />
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="position-absolute top-0 text-black mb-3 d-block d-md-none mt-0" width={28} height={28} onClick={() => {
                                        setSendConfirm(false)
                                        resetErrors()
                                    }} />
                                    <h1 className='fitzgerald-regular fs-1 mb-4 text-center'>{t('recoverPassword.card.title')}</h1>
                                    <p className='mb-2 inter-medium'>{t('recoverPassword.card.confirm.text1')}</p>
                                    <p className='border-bottom border-dark border-1 mb-4'>
                                        <span className='opacity-75'>{email}</span>
                                    </p>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <p className='inter-medium mb-0'>{t('recoverPassword.card.confirm.text2')}</p>
                                        <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={24} />
                                    </div>
                                </div>
                            ) : searchParams.get('token') && !passwordChanged ? (
                                <div className='position-relative d-flex flex-column justify-content-center h-100'>
                                    <Link href={`${t('pages.recoverPassword')}`}><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="text-black cursor-pointer d-none d-md-block mb-4" width={28} height={28} onClick={() => { resetErrors() }} /></Link>
                                    <Link href={`${t('pages.recoverPassword')}`}><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="position-absolute top-0 text-black mb-3 d-block d-md-none mt-0" width={28} height={28} onClick={() => { resetErrors() }} /></Link>
                                    <h1 className='fitzgerald-regular fs-1 mb-4 text-center'>{t('recoverPassword.card.title')}</h1>
                                    <Form onSubmit={handleSubmit(changePassword)} className='d-flex flex-column'>
                                        <Form.Group className='mb-2'>
                                            <Form.Control type="password" className='inter-regular fs-6' size='lg' placeholder={t('recoverPassword.recover.form.password.label')} {...register("password", { required: true })} required />
                                        </Form.Group>
                                        <Form.Group className='mb-5'>
                                            <Form.Control type="password" className='inter-regular fs-6' size='lg' placeholder={t('recoverPassword.card.placeholder.repeat')} {...register("confirmPassword", { required: true })} required />
                                        </Form.Group>
                                        <Button type={"submit"} className='btn-dark-brown-outline inter-regular text-uppercase letter-spacing-1 py-3'>{t('recoverPassword.card.button')}</Button>
                                    </Form>
                                </div>
                            ) : searchParams.get('token') && passwordChanged && (
                                <div className='position-relative d-flex flex-column justify-content-center h-100'>
                                    <Link href={`${t('pages.login')}`}><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="text-black cursor-pointer d-none d-md-block mb-4" width={28} height={28} /></Link>
                                    <Link href={`${t('pages.login')}`}><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" className="position-absolute top-0 text-black mb-3 d-block d-md-none mt-0" width={28} height={28} /></Link>
                                    <h1 className='fitzgerald-regular fs-1 mb-4 text-center'>{t('recoverPassword.card.login.title')}</h1>
                                    <Form onSubmit={handleSubmit(login)} className='d-flex flex-column'>
                                        <p className='inter-medium mb-3 h5'>{t('recoverPassword.card.login.label')}</p>
                                        <Form.Group className='mb-2'>
                                            <Form.Control type="email" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.email')}  {...register("email", {
                                                required: true, pattern: {
                                                    value: /\S+@\S+/
                                                },
                                            })} required />
                                        </Form.Group>
                                        <Form.Group className='mb-5'>
                                            <Form.Control type="password" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.password')} {...register("password", { required: true })} required />
                                        </Form.Group>
                                        <Button type={"submit"} className='btn-dark-brown-outline inter-regular text-uppercase letter-spacing-1 py-3'>{t('seo.login.title')}</Button>
                                    </Form>
                                </div>
                            )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RecoverPassword