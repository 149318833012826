import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import DesktopSwiper from '../swiper/DesktopSwiper'
import { FreeMode, Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClickOutsideElement from '../../hooks/useClickOutsideElement';

function GalleryZoom({ images, close, activeIndexProp }) {

    const { handleClickOutside } = useClickOutsideElement();

    const sliderRef = useRef(null);


    const [activeImage, setActiveImage] = useState(images?.[activeIndexProp]?.imgUrl);
    const [activeIndex, setActiveIndex] = useState(activeIndexProp);

    const triggerClick = (event) => {
        handleClickOutside(event, document.getElementsByClassName('gallery-zoom')?.[0]?.id, true, () => close())
    }

    const keyboardControl = useCallback((e) => {
        if (e.key === "ArrowRight") {
            if ((activeIndex + 1) < images.length) {
                setActiveIndex(activeIndex + 1);
                setActiveImage(images?.[activeIndex + 1]?.imgUrl)
                sliderRef?.current.swiper.slideTo(activeIndex + 1)
            }
        } else if (e.key === "ArrowLeft") {
            if ((activeIndex - 1) >= 0) {
                setActiveIndex(activeIndex - 1);
                setActiveImage(images?.[activeIndex - 1]?.imgUrl)
                sliderRef?.current.swiper.slideTo(activeIndex - 1)
            }
        }
    }, [activeIndex])

    useEffect(() => {
        document.addEventListener("mousedown", triggerClick)
        document.addEventListener("keydown", keyboardControl)

        return () => {
            document.removeEventListener("mousedown", triggerClick)
            document.removeEventListener("keydown", keyboardControl)
        };
    }, [activeIndex])

    return (
        <div className='gallery-zoom' id='tzo-gallery'>
            <Button variant='none' className='p-0 shadow-none position-absolute top-0 end-0 pt-2 pe-2' onClick={() => close()}>
                <FontAwesomeIcon icon={"fa-solid fa-xmark"} className='text-white' width={28} height={28} />
            </Button>
            <Container className='d-flex flex-column justify-content-center justify-content-lg-start gap-2 py-5 h-100'>
                <div className='main'>
                    <Image src={activeImage} />
                </div>

                <Swiper
                    navigation={true}
                    modules={[Navigation, FreeMode]}
                    slidesPerView={'auto'}
                    spaceBetween={8}
                    className={"slider"}
                    freeMode
                    ref={sliderRef}
                >
                    {images?.map((slide, i) =>
                        <SwiperSlide key={i} onClick={() => {
                            setActiveImage(slide?.imgUrl)
                            setActiveIndex(i)
                        }}>
                            <Image src={slide?.imgUrl} />
                            {activeIndex === i && <div className='active' />}
                        </SwiperSlide>
                    )}
                </Swiper>
            </Container>
        </div>
    )
}

export default GalleryZoom