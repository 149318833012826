import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadcrumbPage from '../breadcrumb/Breadcrumb';
import { AppContext } from "../../AppContext";
import StickyBox from 'react-sticky-box';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddThis from '../addthis/AddThis';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '../Link';
import SeoHelmet from '../seo-helmet/SeoHelmet';
import ArticleSchema from '../schema/ArticleSchema';
import { FavouritesContext, MiniGuideContext } from '../../Contexts';
import CardSlider from '../sliders/CardSlider';
import SubscribeBanner from '../banners/SubscribeBanner';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from '../horizontal-scroll/Arrows';
import useDrag from '../horizontal-scroll/useDrag';
import useSwipe from '../horizontal-scroll/useSwipe';
import { useRef } from 'react';
import ContentCards from '../cards/ContentCards';
import useHandleFavorite from '../../hooks/useHandleFavorite';
import NewContentCard from '../cards/NewContentCard';
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules';
import BookmarkIcon from '../icons/BookmarkIcon';

function NewArticleReview({ article, recomendations }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"
    })

    const { t, i18n } = useTranslation();
    const location = useLocation();

    const { dragStart, dragStop, dragMove, dragging } = useDrag();

    const handleDrag = ({ scrollContainer }) => (ev) => dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
        }
    });

    const { state: { user } } = useContext(AppContext);
    const { miniGuideState: { reviewIndex, articles, format }, miniGuideState, miniGuideUpdate } = useContext(MiniGuideContext)
    const { favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const { handleFavourite, removeFavourite, isFavorite, isFavoriteActive, setIsFavoriteActive, addingFlag, setAddingFlag } = useHandleFavorite()

    const scrollMenuApiRef = useRef();

    const [sliderImages, setSliderImages] = useState();

    const [map, setMap] = useState(null); // map instance
    const [mapMobile, setMapMobile] = useState(null); // map instance mobile
    const onLoadMap = useCallback(setMap, [setMap]);
    const onLoadMapMobile = useCallback(setMapMobile, [setMapMobile]);

    const [currentReview, setCurrentReview] = useState();
    const [prevReview, setPrevReview] = useState();
    const [nextReview, setNextReview] = useState();
    const [relatedReviews, setRelatedReviews] = useState();
    const [centerRanking, setCenterRanking] = useState();

    useEffect(() => {
        if (map && article?.interestSites && window.google) {
            const bounds = new window.google.maps.LatLngBounds()

            article?.interestSites.map((site) => {
                bounds.extend({ lat: site.latitude, lng: site.longitude })
            })

            if (article?.interestSites.length === 1) {
                    map.setZoom(18)
                    map.setCenter({lat: article?.interestSites?.[0].latitude, lng: article?.interestSites?.[0].longitude})
            } else {
                map.fitBounds(bounds, 200)
            }
        }
    }, [map])

    const containerStyle = {
        width: '100%',
        height: '100%'
    };


    useEffect(() => {
        if (!sliderImages) {
            let imageUrls = []
            for (let i = 1; i <= 4; i++) {
                if (article[`img${i}Url`]) {
                    imageUrls.push({ url: article[`img${i}Url`], author: article[`img${i}Author`] })
                }
            }
            imageUrls.splice(0, 1);

            setSliderImages(imageUrls)
        }
    }, [article])

    useEffect(() => {
        if (reviewIndex) {
            miniGuideUpdate({
                ...miniGuideState,
                visitedReview: true
            })
        }
    }, [reviewIndex])

    useEffect(() => {
        if (article && articles) {
            let currentReview
            let displayReviews = [...articles];

            articles.forEach((miniGuideArticle, i) => {
                if (miniGuideArticle?.slugs?.[i18n.language] === article?.slugs?.[i18n.language]) {
                    currentReview = i
                }
            })

            setCurrentReview(currentReview)
            setPrevReview((currentReview - 1) >= 0 && articles?.[currentReview - 1])
            setNextReview((currentReview + 1) < (articles?.length) && articles?.[currentReview + 1])
            displayReviews.splice(currentReview, 1);
            setRelatedReviews(displayReviews)
        }
    }, [articles, article, location])

    useEffect(() => {
        if (article) {
            setIsFavoriteActive(isFavorite("ARTICLE", article?.id));
        }
    }, [article])

    return (
        <>
            <SeoHelmet title={`${article.metaTitle}`} metaTitle={`${article.metaTitle}`} metaDescription={article.metaDescription} ogImageUrl={article.imgListUrl} schemaType={"article"} schemaData={article} />
            <ArticleSchema article={article} />
            {/* <BreadcrumbPage crumbArticle={article?.breadcrumbsTitle} category={article?.categoryName} /> */}
            <main className='bg-beige'>
                {(!isNaN(currentReview) && relatedReviews) ? (
                    format === 2 ? (
                        <div className='d-flex flex-row justify-content-between align-items-center mb-3 article-pagination py-3 mt-4 text-white bg-laurel'>
                            <div className={`d-flex flex-row align-items-center justify-content-between justify-content-lg-start gap-1 position-relative px-4 flex-grow-1 container`}>
                                {prevReview && (
                                    <Link href={`${t('pages.guide')}/${prevReview?.categorySlug}/${prevReview?.slugs?.[i18n.language]}`} className="">
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={11} className='text-white' />
                                    </Link>
                                )}
                                {format === 2 && (
                                    <>
                                        <span className={`tt-norms ${prevReview ? "ps-3" : ""} clamp-1`.trim()}>{`${articles?.[currentReview]?.titleFirstPart} ${articles?.[currentReview]?.titleSecondPart}`.trim()}</span>
                                        <span className='tt-norms px-3'>{currentReview + 1}/{articles?.length}</span>
                                    </>
                                )}
                                {nextReview && (
                                    <Link href={`${t('pages.guide')}/${nextReview?.categorySlug}/${nextReview?.slugs?.[i18n.language]}`} className="">
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" width={11} className='text-white' />
                                    </Link>
                                )}
                                <div className="content-card custom-fav ms-3 shadow-none w-auto">
                                    <div className={`fav-container p-2 p-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                        if (user) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (isFavoriteActive) {
                                                setAddingFlag(true);
                                                removeFavourite("ARTICLE", article?.id);
                                            } else {
                                                setAddingFlag(true);
                                                handleFavourite("ARTICLE", article?.id);
                                            }
                                        } else {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer`.trim()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='text-white bg-laurel py-3 mt-4'>
                                <div className='container d-flex flex-row'>
                                    <Row className='g-0 g-lg-3 d-lg-none flex-grow-1'>
                                        <Col xs={1} lg={"auto"} className='d-flex align-items-center'>
                                            {prevReview && (
                                                <Link href={`${t('pages.guide')}/${prevReview?.categorySlug}/${prevReview?.slugs?.[i18n.language]}`} className="">
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={11} className='text-white' />
                                                </Link>
                                            )}
                                        </Col>
                                        <Col xs={8} lg={"auto"} className='d-flex align-items-center'>
                                            <div className='ranking-pagination'>
                                                <ScrollMenu
                                                    onMouseDown={() => dragStart}
                                                    onMouseUp={() => dragStop}
                                                    onMouseMove={handleDrag}
                                                    scrollContainerClassName={`ranking-pagination ${(centerRanking || scrollMenuApiRef?.current?.isLastItemVisible) ? "d-flex justify-content-center" : ""}`.trim()}
                                                    apiRef={scrollMenuApiRef}
                                                    onUpdate={() => {
                                                        if (centerRanking !== false) {
                                                            setCenterRanking(scrollMenuApiRef?.current?.isLastItemVisible)
                                                        } else if (centerRanking === undefined) {
                                                            setCenterRanking(false)
                                                        }
                                                    }}
                                                >
                                                    {articles.map((article, i) => (
                                                        <Link key={i} href={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className="">
                                                            <div className={`d-flex align-items-center justify-content-center ranking tt-norms-bolder ${currentReview === i ? "active" : ""}`.trim()}>
                                                                {articles?.length - i}
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </ScrollMenu>
                                            </div>
                                        </Col>
                                        <Col xs={1} lg={"auto"} className='d-flex align-items-center justify-content-end'>
                                            {nextReview && (
                                                <Link href={`${t('pages.guide')}/${nextReview?.categorySlug}/${nextReview?.slugs?.[i18n.language]}`} className="">
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" width={11} className='text-white' />
                                                </Link>
                                            )}
                                        </Col>
                                        <Col xs={2}>
                                            <div className="content-card custom-fav d-flex justify-content-center shadow-none">
                                                <div className={`fav-container p-2 p-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`} onClick={(e) => {
                                                    if (user) {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        if (isFavoriteActive) {
                                                            setAddingFlag(true);
                                                            removeFavourite("ARTICLE", article?.id);
                                                        } else {
                                                            setAddingFlag(true);
                                                            handleFavourite("ARTICLE", article?.id);
                                                        }
                                                    } else {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                    }
                                                }}>
                                                    <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover cursor-pointer`.trim()} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='g-0 g-lg-3 d-lg-flex d-none'>
                                        <Col lg={"auto"} className='d-flex align-items-center'>
                                            {prevReview && (
                                                <Link href={`${t('pages.guide')}/${prevReview?.categorySlug}/${prevReview?.slugs?.[i18n.language]}`} className="">
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={11} className='text-white' />
                                                </Link>
                                            )}
                                        </Col>
                                        <Col lg={"auto"} className='d-flex align-items-center'>
                                            <div className='ranking-pagination'>
                                                <ScrollMenu
                                                    onMouseDown={() => dragStart}
                                                    onMouseUp={() => dragStop}
                                                    onMouseMove={handleDrag}
                                                    scrollContainerClassName={`ranking-pagination ${(centerRanking || scrollMenuApiRef?.current?.isLastItemVisible) ? "d-flex justify-content-center" : ""}`.trim()}
                                                    apiRef={scrollMenuApiRef}
                                                    onUpdate={() => {
                                                        if (centerRanking !== false) {
                                                            setCenterRanking(scrollMenuApiRef?.current?.isLastItemVisible)
                                                        } else if (centerRanking === undefined) {
                                                            setCenterRanking(false)
                                                        }
                                                    }}
                                                >
                                                    {articles.map((article, i) => (
                                                        <Link key={i} href={`${t('pages.guide')}/${article?.categorySlug}/${article?.slugs?.[i18n.language]}`} className="">
                                                            <div className={`d-flex align-items-center justify-content-center ranking tt-norms-bolder ${currentReview === i ? "active" : ""}`.trim()}>
                                                                {articles?.length - i}
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </ScrollMenu>
                                            </div>
                                        </Col>
                                        <Col lg={"auto"} className='d-flex align-items-center justify-content-end'>
                                            {nextReview && (
                                                <Link href={`${t('pages.guide')}/${nextReview?.categorySlug}/${nextReview?.slugs?.[i18n.language]}`} className="">
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" width={11} className='text-white' />
                                                </Link>
                                            )}
                                        </Col>
                                        <Col>
                                            <div className="content-card shadow-none">
                                                <div className={`mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`}>
                                                    <BookmarkIcon
                                                        width={18}
                                                        className={`favorite-icon cursor-pointer ${(isFavoriteActive || addingFlag) ? "text-coal bookmark-active" : "text-beige bookmark"}`}
                                                        onClick={(e) => {
                                                            if (user) {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                if (isFavoriteActive) {
                                                                    setAddingFlag(true);
                                                                    removeFavourite("ARTICLE", article?.id);
                                                                } else {
                                                                    setAddingFlag(true);
                                                                    handleFavourite("ARTICLE", article?.id);
                                                                }
                                                            } else {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <div className='text-white bg-laurel py-2 d-flex flex-row'>
                        <Container>
                            <div className="content-card custom-fav w-auto d-flex flex-row shadow-none">
                                <div className={`d-flex flex-row justify-content-between justify-content-lg-start align-items-center gap-3 flex-grow-1 cursor-pointer fav-container px-0 py-2 py-lg-2 mb-0 me-0 radius lg ${isFavoriteActive || addingFlag ? "active" : ""}`}>
                                    <p className='inter-regular text-white h6 mb-0'>{isFavoriteActive ? t('articles.favorite.remove') : t('articles.favorite.add')}</p>
                                    <BookmarkIcon
                                        width={18}
                                        className={`favorite-icon cursor-pointer ${(isFavoriteActive || addingFlag) ? "text-coal bookmark-active" : "text-beige bookmark"}`}
                                        onClick={(e) => {
                                            if (user) {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                if (isFavoriteActive) {
                                                    setAddingFlag(true);
                                                    removeFavourite("ARTICLE", article?.id);
                                                } else {
                                                    setAddingFlag(true);
                                                    handleFavourite("ARTICLE", article?.id);
                                                }
                                            } else {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                favouriteUpdate({ ...favouriteState, favoriteModal: true })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
                <Container>
                    <Row className='py-4 gx-4'>
                        <Col xs={12} lg={4}>
                            <StickyBox offsetTop={84} offsetBottom={24}>
                                <div>
                                    <Image fluid src={article?.img1Url} className="d-lg-none review-head w-100 mb-3 fit-cover" />
                                    <Image fluid src={article?.stamp?.image} className="d-lg-none review-stamp" />
                                </div>
                                <h2 className='h5 inter-regular'>{article?.projectTitle}</h2>
                                <h1 className="fitzgerald-regular h2 mb-2">{`${article?.titleFirstPart} ${article?.titleSecondPart}`.trim()}</h1>
                                {article?.articleShortDescription && (
                                    <p className="inter-medium mb-3">{article?.articleShortDescription}</p>
                                )}
                                <AddThis url={window.location.href} title={article.metaTitle} description={article.articleShortDescription} img={article.img1Url} facebook linkedin whatsapp instagram />
                                {article?.articleAuthor && (
                                    <p className="mb-2 fitzgerald-regular">{t('articles.author')} {article?.articleAuthor}</p>
                                )}
                                {article?.articlePhotographer && (
                                    <p className="mb-2 fitzgerald-regular">{t('articles.photographer')} {article?.articlePhotographer}</p>
                                )}
                                <Swiper
                                    slidesPerView={1.15}
                                    spaceBetween={8}
                                    className='w-100 mb-2 d-lg-none'
                                    shortSwipes
                                    longSwipes
                                    freeMode
                                    modules={[FreeMode]}
                                >
                                    {sliderImages?.map((image, i) => (
                                        <SwiperSlide key={i}>
                                            <Image src={image?.url} className='review-images' />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                {article?.textBlock1 && (
                                    <p className="inter-regular mb-2 text-coal" dangerouslySetInnerHTML={{ __html: article?.textBlock1 }} />
                                )}
                                {article?.textBlock2 && (
                                    <p className="inter-regular mb-2 text-coal" dangerouslySetInnerHTML={{ __html: article?.textBlock2 }} />
                                )}
                            </StickyBox>
                        </Col>
                        <Col lg={8} className='d-none d-lg-block'>
                            <StickyBox offsetTop={84} offsetBottom={24}>
                                <div>
                                    <Image fluid src={article?.img1Url} className="review-head w-100 mb-3 fit-cover" />
                                    <Image fluid src={article?.stamp?.image} className="review-stamp" />
                                </div>
                                <Row className='gx-3 mb-4'>
                                    {sliderImages?.map((image, i) => (
                                        <Col key={i} lg={12 / sliderImages?.length}>
                                            <Image fluid src={image?.url} className="w-100 h-100 fit-cover" />
                                        </Col>
                                    ))}
                                </Row>
                                <aside>
                                    {(article?.interestSites?.length > 0 && isLoaded) && <div className="site-detail-map position-relative">
                                        <GoogleMap
                                            onLoad={onLoadMap}
                                            mapContainerStyle={containerStyle}
                                            options={{
                                                clickableIcons: false,
                                                disableDefaultUI: true,
                                                fullscreenControl: true,
                                                zoomControl: true,
                                                styles: [
                                                    {
                                                        "featureType": "poi.business",
                                                        "stylers": [
                                                            {
                                                                "visibility": "off"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "featureType": "poi.school",
                                                        "stylers": [
                                                            {
                                                                "visibility": "off"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "featureType": "poi.sports_complex",
                                                        "stylers": [
                                                            {
                                                                "visibility": "off"
                                                            }
                                                        ]
                                                    },
                                                ]
                                            }}
                                        >
                                            {article?.interestSites.map((site, i) => (
                                                ((site.latitude && site.longitude) &&
                                                    <Marker
                                                        key={i}
                                                        position={{ lat: parseFloat(site.latitude), lng: parseFloat(site.longitude) }}
                                                    />
                                                )
                                            ))}
                                        </GoogleMap>
                                    </div>}
                                    {article?.interestSites?.length > 0 && (
                                        <>
                                            <Row className="gy-5 mt-0 mb-5 gx-5">
                                                {article?.interestSites.map((site, i) => (
                                                    <Col key={i} xs={12} md={6} xl={article?.interestSites.length === 1 ? 12 : 6} className="site-info-review-container d-flex flex-column justify-content-between">
                                                        <div className="d-flex flex-column gap-4">
                                                            <p className="h2 tzo-title fitzgerald-regular mb-0">{site?.name}</p>
                                                            {site?.name && (site?.latitude && site?.longitude) && (
                                                                <div>
                                                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${site?.latitude},${site?.longitude}`} target={"_blank"} className="text-dark text-decoration-none">
                                                                        <div className='d-flex flex-row align-items-center cursor-pointer gap-3 site-info'>
                                                                            <span className='site-icon align-items-center site-icon'>
                                                                                <FontAwesomeIcon icon="fa-solid fa-location-dot" className='text-dark icon align-self-center' />
                                                                            </span>
                                                                            <p className='mb-0 inter-medium h6'>{site?.address}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {site?.webSiteUrl && (
                                                                <div>
                                                                    <a href={site?.webSiteUrl} className='mb-0 inter-medium h6 text-dark text-decoration-none' target={"_blank"}>
                                                                        <div className='d-flex flex-row align-items-center cursor-pointer gap-3 site-info'>
                                                                            <span className='site-icon align-items-center site-icon'>
                                                                                <FontAwesomeIcon icon="fa-solid fa-globe" className='text-dark icon align-self-center' />
                                                                            </span>
                                                                            <p className='mb-0 inter-medium h6 text-break'>{site?.webSiteUrl?.split('/')[2] || site?.webSiteUrl}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {site?.schedule && (
                                                                <div className='d-flex flex-row align-items-center gap-3 site-info text-dark d-flex flex-grow-0'>
                                                                    <span className='site-icon align-items-center site-icon'>
                                                                        <FontAwesomeIcon icon="fa-solid fa-clock" className='text-dark icon align-self-center' />
                                                                    </span>
                                                                    <p className='mb-0 inter-medium h6'>{site?.schedule}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Link href={`${t('pages.explore')}/${site?.categorySlug}/${site?.slugs?.[i18n.language]}`} className={"align-self-start mt-5"}>
                                                            {/* <Button className="px-5 radius btn-dark-green-outline">{t('miniGuide.card.button')}</Button> */}
                                                            <span className={"text-uppercase new-link-coal inter-regular letter-spacing-2 mb-2"}>{t('general.components.seeMore')}</span>
                                                        </Link>
                                                        {i < article?.interestSites?.length - 1 && <hr className="d-block d-md-none mb-0 mt-5" />}
                                                    </Col>
                                                ))}
                                            </Row>
                                        </>
                                    )}
                                </aside>
                                <p className="fitzgerald-regular mb-4 h3 border-bottom border-coal-semi-transparent">{t('miniGuide.suggestedOptions')}</p>
                                <Row className='gy-5 gx-4 suggest-custom-cards'>
                                    {recomendations?.map((recomendation, i) =>
                                        <Col lg={4} key={recomendation?.id}>
                                            <NewContentCard theme={"coal"} image={recomendation?.listImageUrl} project={recomendation?.projectTitle} category={recomendation?.categoryTitle} title={`${recomendation?.titleFirstPart || ""} ${recomendation?.titleSecondPart || ""}`.trim()} stamp={recomendation?.stamp?.image} slug={`${t('pages.guide')}/${recomendation?.categorySlug}/${recomendation?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={recomendation?.id} data={recomendation} />
                                        </Col>
                                    )}
                                </Row>
                            </StickyBox>
                        </Col>
                    </Row>
                </Container>
                <section className='pb-4 d-lg-none'>
                    <p className="fitzgerald-regular mb-4 h2 border-bottom border-coal-semi-transparent pe-4 ms-4">{t('miniGuide.suggestedOptions')}</p>
                    {recomendations?.length > 0 &&
                        <Swiper
                            slidesPerView={1.15}
                            spaceBetween={8}
                            className='w-100 mb-2 d-lg-none'
                            slidesOffsetBefore={window.innerWidth < 768 ? 24 : 0}
                            slidesOffsetAfter={window.innerWidth < 768 ? 24 : 0}
                            shortSwipes
                            longSwipes
                            freeMode
                            modules={[FreeMode]}
                        >
                            {recomendations?.map((recomendation, i) => (
                                <SwiperSlide key={i}>
                                    <NewContentCard theme={"laurel"} image={recomendation?.listImageUrl} project={recomendation?.projectTitle} category={recomendation?.categoryTitle} title={`${recomendation?.titleFirstPart || ""} ${recomendation?.titleSecondPart || ""}`.trim()} stamp={recomendation?.stamp?.image} slug={`${t('pages.guide')}/${recomendation?.categorySlug}/${recomendation?.slugs?.[i18n.language]}`} type={"ARTICLE"} id={recomendation?.id} data={recomendation} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </section>
            </main>
        </>
    )
}

export default NewArticleReview
