import api from './api';

const createFolder = (data) => api.post("user-folder", data).then(res => res.data);

const updateFolder = (id, data) => api.put(`user-folder/${id}`, data).then(res => res.data);

const getDetailedFolders = (data) => api.get("user-folder", {
    folderIds: data?.folderIds
}).then(res => res.data);

const getSimpleFolders = (data) => api.get("user-folder/simple", data).then(res => res.data);

const deleteFolder = (id, data) => api.delete(`user-folder/${id}?deleteInOtherFolders=${data?.deleteInOtherFolders}`).then(res => res.data);

const shareCopyFolder = (data) => api.post("share/copy", data).then(res => res);

const shareContent = (data) => api.post("share/encrypt-token", data).then(res => res);

const FolderService = {
    createFolder,
    updateFolder,
    getDetailedFolders,
    getSimpleFolders,
    deleteFolder,
    shareCopyFolder,
    shareContent
}

export default FolderService;