import React from 'react'
import { Placeholder } from 'react-bootstrap'

function NewContentCardPlaceholder({ hideLink }) {
    return (
        <div className='d-flex flex-column text-center gap-3 flex-grow-1 new-content-card'>
            <Placeholder as="span" animation="glow">
                <Placeholder xs={12} className="content-picture" />
            </Placeholder>
            <Placeholder as="span" animation="wave">
                <Placeholder xs={5} className="" />
            </Placeholder>
            <Placeholder as="span" animation="wave" className="mb-3" >
                <Placeholder xs={4} size='lg' /> <Placeholder xs={7} size='lg' />
            </Placeholder>
            {!hideLink && <Placeholder as="span" animation="wave" >
                <Placeholder xs={3} />
            </Placeholder>}
        </div>
    )
}

export default NewContentCardPlaceholder