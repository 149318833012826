import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, FormCheck, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Link from '../Link';
import FavouriteService from '../../services/favourites.service';
import { FavouritesContext } from '../../Contexts';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';

const ContentCards = ({ size, data, id, title, img, category, stamp, hideTop, select, onSelect, selected, type, options, optionAction, onClick, refreshFavourites, link, description, noRedirect, className }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { state: { user }, update } = useContext(AppContext);

    const { favouriteState: { favouritesList, articlesFav, miniGuidesFav, interestSitesFav, favourites, mainFolder, favoriteModal }, favouriteState, favouriteUpdate } = useContext(FavouritesContext)

    const [dropdown, setDropdown] = useState(false)
    const [addingFlag, setAddingFlag] = useState(false)
    const [favoriteTypeList, setFavoriteTypeList] = useState();

    const updateFavouriteContext = async () => {
        const favouritesData = await FavouriteService.getFavourites();
        let favouriteIdList = [];

        let articlesList = [];
        let miniGuidesList = [];
        let interestSitesList = [];

        favouritesData.forEach((favourite) => {
            favouriteIdList.push(favourite?.elementId)

            if (favourite?.favouriteType === "ARTICLE") {
                articlesList.push(favourite?.elementId);
            } else if (favourite?.favouriteType === "MINIGUIDE") {
                miniGuidesList.push(favourite?.elementId);
            } else if (favourite?.favouriteType === "INTERESTSITE") {
                interestSitesList.push(favourite?.elementId);
            }

        })

        favouriteUpdate({
            favourites: favouritesData,
            favouritesList: favouriteIdList,
            articlesFav: articlesList,
            miniGuidesFav: miniGuidesList,
            interestSitesFav: interestSitesList
        })
    }

    const handleFavourite = useCallback(async () => {
        setAddingFlag(true)
        if (type === "MINIGUIDE") {
            try {
                await FavouriteService.createMiniGuideFavourite({
                    value: id || data?.id,
                    folderIds: [mainFolder?.id]
                })

                updateFavouriteContext()
            } catch (error) {
                console.error(error);
                setAddingFlag(false);
            }
        } else if (type === "ARTICLE") {
            try {
                await FavouriteService.createArticleFavourite({
                    value: id || data?.id,
                    folderIds: [mainFolder?.id]
                })

                updateFavouriteContext()
            } catch (error) {
                console.error(error);
                setAddingFlag(false);
            }
        } else if (type === "INTERESTSITE") {
            try {
                await FavouriteService.createInterestSiteFavourite({
                    value: id || data?.id,
                    folderIds: [mainFolder?.id]
                })

                updateFavouriteContext()
            } catch (error) {
                console.error(error);
            }
        }
    })

    const removeFavourite = useCallback(async () => {
        try {
            const favoriteType = type

            const favouriteDetail = favourites.find(favourite => (favourite?.elementId === (id || data?.id) && favoriteType === favourite?.favouriteType))

            await FavouriteService.deleteFavorite((favouriteDetail?.id || data?.id), {
                deleteInOtherFolders: true
            })

            updateFavouriteContext()

            if (refreshFavourites) {
                refreshFavourites();
            }

            setAddingFlag(false)
        } catch (error) {
            console.error(error);
        }
    })

    useEffect(() => {
        if (type === "ARTICLE") {
            setFavoriteTypeList(articlesFav);
        } else if (type === "MINIGUIDE") {
            setFavoriteTypeList(miniGuidesFav);
        } else if (type === "INTERESTSITE") {
            setFavoriteTypeList(interestSitesFav);
        } else {
            setFavoriteTypeList(favouritesList)
        }
    }, [type, articlesFav, miniGuidesFav, interestSitesFav])

    return (
        <article className={`content-card radius bg-white d-flex flex-column flex-lg-grow-1 overflow-hidden cursor-pointer h-100 ${select ? "user-select-none" : ""} ${className}`.trim()} onClick={() => {
            if (select) {
                // setSelected(!selected)
                onSelect();
            } else if (onClick) {
                onClick()
            }
        }}>
            {!hideTop && <div className={`d-flex flex-row flex-grow-0 align-items-center justify-content-between py-1 ${size === "sm" ? "px-2" : "px-3"} px-lg-3 position-relative top-card`} onClick={(e) => {
                if (optionAction) {
                    e.stopPropagation();
                    e.preventDefault();
                    optionAction();
                }
            }}>
                <Image src={category || data?.categoryLogoImageUrl} className={size === "sm" ? "category-sm" : "category-lg"} />
                {options && <FontAwesomeIcon icon="fa-solid fa-ellipsis" className='text-coal cursor-pointer' height={size === "sm" ? 22 : 26} width={size === "sm" ? 22 : 26} />}
            </div>}
            {!link || (link && select) ? (
                <>
                    <div className='position-relative d-flex flex-grow-0'>
                        {select && <FormCheck className='position-absolute top-0 end-0 mt-1 me-1' checked={selected || false} onClick={() => onSelect()} readOnly />}
                        {stamp && <Image src={stamp} className='position-absolute stamp' />}
                        <Image src={img || data?.mainPicture || '/img/no-photo.svg'} className={`photo-${size} ${select ? "select" : ""}`.trim()} draggable={select && false} />
                        {type !== "ACCOMMODATION" && <div className={`position-absolute bottom-0 end-0 fav-container ${size} p-2 radius shadow-sm ${(favoriteTypeList?.includes(id || data?.id) || addingFlag) ? "active" : ""}`.trim()} onClick={(e) => {
                            if (user) {
                                e.stopPropagation();
                                e.preventDefault();
                                if (favoriteTypeList?.includes(id || data?.id)) {
                                    removeFavourite()
                                } else {
                                    handleFavourite();
                                }
                            } else {
                                e.stopPropagation();
                                e.preventDefault();
                                favouriteUpdate({...favouriteState, favoriteModal: true})
                            }
                        }}>
                            <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover`.trim()} />
                        </div>}
                    </div>
                    <Row className={`py-2 ${size === "sm" ? "px-2" : "px-3"} px-lg-3 align-items-center g-0 align-items-center d-flex flex-grow-1 bottom-card`}>
                        <Col xs={12} className='pe-0'>
                            <p className={`mb-0 tt-norms-semi-bolder clamp-2 lh-sm ${size === "sm" ? "card-title-sm" : ""} ${description ? "mb-1" : ""}`.trim()}>{title || data?.title}</p>
                            {description && <p className='mb-0 tt-norms clamp-2 lh-sm small'>{description}</p>}
                        </Col>
                        {/* <Col xs={2} className={`d-flex align-items-center justify-content-end position-relative`}>
                        </Col> */}
                    </Row>
                </>
            ) : (
                <Link href={link} noRedirect={noRedirect ? true : false} className={"d-flex flex-column flex-grow-1"}>
                    <div className='position-relative d-flex flex-grow-0'>
                        {select && <FormCheck className='position-absolute top-0 end-0 mt-1 me-1' checked={selected || false} onClick={() => onSelect()} readOnly />}
                        <Image src={img || data?.mainPicture || '/img/no-photo.svg'} className={`photo-${size} ${select ? "select-card" : ""}`.trim()} draggable={select && false} />
                        <div className={`position-absolute bottom-0 end-0 fav-container p-2 p-lg-2 radius shadow-sm ${size} ${(favoriteTypeList?.includes(id || data?.id) || addingFlag) ? "active" : ""}`.trim()} onClick={(e) => {
                            if (user) {
                                e.stopPropagation();
                                e.preventDefault();
                                if (favoriteTypeList?.includes(id || data?.id)) {
                                    removeFavourite()
                                } else {
                                    handleFavourite();
                                }
                            } else {
                                e.stopPropagation();
                                e.preventDefault();
                                favouriteUpdate({...favouriteState, favoriteModal: true})
                            }
                        }}>
                            <FontAwesomeIcon icon={"fa-solid fa-bookmark"} className={`text-coal fav-icon hover`.trim()} />
                        </div>
                    </div>
                    <Row className={`py-2 ${size === "sm" ? "px-2" : "px-3"} px-lg-3 align-items-center g-0 align-items-center d-flex flex-grow-1 bottom-card`}>
                        <Col xs={12} className='pe-0'>
                            <p className={`mb-0 text-coal tt-norms-semi-bolder clamp-2 lh-sm ${size === "sm" ? "card-title-sm" : ""} ${description ? "mb-1" : ""}`.trim()}>{title || data?.title}</p>
                            {description && <p className='mb-0 text-coal tt-norms clamp-2 lh-sm small'>{description}</p>}
                        </Col>
                        {/* <Col xs={2} className={`d-flex align-items-center justify-content-end position-relative`}>

                        </Col> */}
                    </Row>
                </Link>
            )}
        </article>
    )
}

export default ContentCards
