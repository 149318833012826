import api from './api';

const saveAnalyticBudget = (data) => api.post("analytic/budget", data).then(res => res);

const saveAnonymousAnalyticBudget = (data) => api.post("analytic/anonymous/budget", data).then(res => res);

const AnalyticService = {
    saveAnalyticBudget,
    saveAnonymousAnalyticBudget
}

export default AnalyticService;