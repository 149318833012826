import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Link = ({ children, href, className, target, params, disabled, id, draggable, noRedirect }) => {

    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const handleClick = (e) => {
        e.preventDefault();
        if (!noRedirect) {
            if (!target && !disabled) {
                if (href) {
                    navigate(`/${i18n.language}/${href}`, { state: { params: params } })
                } else {
                    navigate(`/${i18n.language}`)
                }
            } else if (!disabled) {
                window.open(`${window.origin}/${i18n.language}${href ? `/${href}` : ""}`.trim(), '_blank')
            }
        }
    }

    return (
        <a id={id} href={`${window.origin}/${i18n.language}${href ? `/${href}` : ""}`.trim()} onClick={handleClick} className={`text-decoration-none ${className ? className : ""}`.trim()} draggable={draggable}>{children}</a>
    )
}

export default Link
