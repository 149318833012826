
const IconListItem = ({icon, title, children}) => {
    return (
        <div className="px-0 py-3 px-sm-5 py-sm-4">
            <div className="d-flex justify-content-start">
                <img src={icon} alt="" className="mb-3 responsive-icon"/>
            </div>
            <h3 className="joane">{title}</h3>
            <p className="tt-norms fw-light">{children}</p>
        </div>
    );
}

export default IconListItem;