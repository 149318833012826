import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { AppContext } from "../../AppContext";

const SeoHelmet = ({ title, metaTitle, metaDescription, ogImage, ogImageUrl, ogUrl, robots, siteName, noSuffix }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const { state: { languages, detailSlugs, categories } } = useContext(AppContext);
    const currentPath = location.pathname.split("/");
    const routes = t("pages", { returnObjects: true });
    const section = Object.keys(routes).find(key => routes[key] === currentPath[2]);

    let hrefLang = [];

    const generateHrefLang = (url, section, categories, slugs) => {
        const urlHreflang = [];

        languages.forEach(lang => {
            let langPath = window.location.origin
            const langRoutes = t("pages", { returnObjects: true, lng: lang });

            url.forEach((path, index) => {
                if (index === 1) { // language code
                    langPath += `/${lang}`
                }

                if (index === 2 && section) { // section
                    langPath += `/${langRoutes[section]}`
                }

                if (index === 3 && categories) {
                    categories.forEach((category) => {
                        if (category.slugs[i18n.language] === url[index]) {
                            langPath += `/${category.slugs[lang]}`
                        }
                    })
                }

                if (index === 4 && slugs) {
                    langPath += `/${slugs?.[lang]}`
                }

            })

            urlHreflang.push({ lang: lang, href: langPath })
        });

        hrefLang = urlHreflang;
    }

    if (["guide", "explore", "miniGuide"].includes(section)) {
        if (currentPath?.[3] && currentPath?.[4] && categories.length > 0 && detailSlugs) {
            generateHrefLang(currentPath, section, categories, detailSlugs);
        } else if (currentPath?.[3] && categories.length > 0) {
            generateHrefLang(currentPath, section, categories);
        } else {
            generateHrefLang(currentPath, section);
        }
    } else {
        generateHrefLang(currentPath, section);
    }

    return (
        <Helmet prioritizeSeoTags>
            <title>{`${title}${!noSuffix && title !== "TraveltoZero" ? " | TraveltoZero" : ""}`}</title>
            <meta name="title" content={`${metaTitle}${metaTitle !== "TraveltoZero" ? " | TraveltoZero" : ""}`} />
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={`${metaTitle}${metaTitle !== "TraveltoZero" ? " | TraveltoZero" : ""}`} />
            <meta property="og:description" content={metaDescription} />
            {ogImage || ogImageUrl && <meta property="og:image" content={ogImageUrl || window.location.origin + ogImage} />}
            {ogUrl && <meta property="og:url" content={ogUrl} />}
            {siteName && <meta property="og:site_name" content={siteName} />}
            {robots && <meta name="robots" content="noindex" />}
            {robots && <meta name="robots" content="nofollow" />}
            {hrefLang.map((url, i) => <link key={i} rel="alternate" hreflang={url?.lang} href={url?.href} />)}
        </Helmet>
    )
}

export default SeoHelmet