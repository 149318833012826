import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function InterestSiteSlider({ images }) {

    let imageList = [...images];
    imageList.splice(0, 1)

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 576)
    const [selectedImage, setSelectedImage] = useState({ img: imageList?.[0]?.imgUrl, id: 1 } || null);
    const [blockSlide, setBlockSlide] = useState({ x: 0, y: 0 })

    const carouselRef = useRef();

    const slideStart = (event) => {
        setBlockSlide({
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
        })
    }

    const slideBlock = (event) => {
        let touchDrag = 50

        if ((event.touches[0].clientX - blockSlide.x) > touchDrag || (event.touches[0].clientX - blockSlide.x) < -50) {
            document.getElementsByTagName('body')[0].style.overflowY = "hidden"
        }
    }

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 576)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    return (
        <>
            <Row as={"aside"} className='d-none d-md-flex h-100 gx-0'>
                {imageList.length === 1
                    &&
                    <Image fluid src={selectedImage?.img} className={`slider-large-img px-0 d-none d-sm-block shadow-sm mb-4`} />

                }

                {imageList.length > 1 &&
                    <>
                        <Col className="h-100 pe-3 w-auto">
                            {imageList.length >= 1 &&
                                <Image fluid src={selectedImage?.img} className={`slider-large-img px-0 d-none d-sm-block shadow-sm ${imageList.length >= 1 ? 'mb-4' : 'mb-0'}`} />
                            }
                        </Col>
                        <Col md="auto" className="overflow-auto h-100 gx-2 list slider-scroll">
                            {imageList.map((img, i) =>
                                <div key={i} className={`interestSlideImage ${i < imageList.length - 1 ? "mb-3" : "mb-0"}`} id={i + 1}>
                                    <div className={`${selectedImage?.id === i + 1 ? "d-none d-sm-block" : "d-none"} position-absolute w-100 h-100 overlay`} />
                                    <Image draggable={false} fluid src={img.imgUrl} className="cursor-pointer card-shadow" onClick={() => { isDesktop && setSelectedImage({ img: img.imgUrl, id: i + 1 }) }} />
                                </div>
                            )}
                        </Col>
                    </>
                }
            </Row>
            <aside className={`px-0 ${imageList.length >= 1 ? 'd-block d-md-none' : ""}`}>
                {imageList.length >= 1 && <div
                    onTouchStart={(e) => {
                        slideStart(e)
                    }}

                    onTouchMove={(e) => {
                        slideBlock(e)
                    }}
                    onTouchEnd={(e) => document.getElementsByTagName('body')[0].style = ""}
                >
                    <Carousel
                        removeArrowOnDeviceType={["mobile", "tablet"]}
                        additionalTransfrom={0}
                        arrows
                        className=""
                        containerClass="px-0 interestSlideImage"
                        dotListClass=""
                        focusOnSelect={false}
                        itemClass="px-1"
                        keyBoardControl
                        minimumTouchDrag={50}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        partialVisible
                        swipeable={true}
                        draggable={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 768
                                },
                                items: 5,
                                partialVisibilityGutter: 0,
                            },
                            mobile: {
                                breakpoint: {
                                    max: 576,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: imageList.length === 1 ? 0 : 40,
                            },
                            tablet: {
                                breakpoint: {
                                    max: 992,
                                    min: 576
                                },
                                items: 2,
                                partialVisibilityGutter: 20
                            }
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        ref={carouselRef}
                    >
                        {imageList.map((img, i) =>
                            <div key={i} className='interestSlideImage' id={i + 1}>
                                {/* <div className={`${selectedImage?.id === i + 1 ? "d-none d-sm-block" : "d-none"} position-absolute w-100 h-100 overlay`} /> */}
                                <Image draggable={false} fluid src={img.imgUrl} className="cursor-pointer" onClick={() => { isDesktop && setSelectedImage({ img: img.imgUrl, id: i + 1 }) }} />
                            </div>
                        )}
                    </Carousel>
                </div>}
            </aside>
        </>
    )
}

export default InterestSiteSlider