import React from 'react'

function FilterList({ width, height, active, onClick }) {
    return (
        <svg id="filter-list" className={active ? "active" : null} width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <g clipPath="url(#clip0_9836_12895)">
                <path d="M0.5 0.5H7.5V7.5H0.5V0.5Z" fill='white' stroke="#5A5C69" strokeWidth="0.729204" />
                <path d="M7.5 0.5H27.5V7.5H7.5V0.5Z" fill='white' stroke="#5A5C69" strokeWidth="0.729204" />
                <path d="M0.5 10.5H7.5V17.5H0.5V10.5Z"fill='white'  stroke="#5A5C69" strokeWidth="0.729204" />
                <path d="M7.5 10.5H27.5V17.5H7.5V10.5Z" fill='white' stroke="#5A5C69" strokeWidth="0.729204" />
                <path d="M0.5 20.5H7.5V27.5H0.5V20.5Z" fill='white' stroke="#5A5C69" strokeWidth="0.729204" />
                <path d="M7.5 20.5H27.5V27.5H7.5V20.5Z" fill='white' stroke="#5A5C69" strokeWidth="0.729204" />
            </g>
            <defs>
                <clipPath id="clip0_9836_12895">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default FilterList