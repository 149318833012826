import api from './api';

const userInfo = (data) => api.get('/user', data).then(res => res.data);

const updateUser = (data) => api.put(`/user/update-information`, data).then(res => res.data);

const recoverPassword = (data) => api.post('/auth/send-recover-email', data).then(res => res.data);

const resetPassword = (data) => api.post('/auth/reset-password', data).then(res => res.data);

const updateEmail = (data) => api.post('/user/validate-new-email', data).then(res => res.data);

const disableAccount = (data) => api.post('/user/disable').then(res => res.data);

const UserService = {
    userInfo,
    updateUser,
    recoverPassword,
    resetPassword,
    updateEmail,
    disableAccount
}

export default UserService;