import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';


const MobileSheet = ({ children, title, show, close, stickyHead, returnShow, returnAction }) => {

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 992)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    useEffect(() => {
        if (show && !isDesktop) {
            document.body.style.overflow = "hidden"
        } else {
            setTimeout(() => {
                document.body.removeAttribute('style');
                document.getElementsByClassName('sheet-container')?.[0]?.scrollTo(0, 0)
            }, 625);
        }
    }, [show, isDesktop])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event?.target?.className?.includes?.("mobile-sheet")) {
                close()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return (
        <div className={`d-flex d-lg-none mobile-sheet ${show ? "active" : ""}`.trim()}>
            <div className='sheet-container'>
                <div className={`d-flex justify-content-between py-3 px-3 header ${stickyHead ? "sticky" : "position-relative"} ${returnShow ? "flex-row" : "flex-row-reverse"}`.trim()}>
                    {returnShow && <FontAwesomeIcon icon={"fa-solid fa-chevron-left"} width={12} className='text-coal control' onClick={() => returnAction()} />}
                    <span className='w-100 px-5 h6 tt-norms-bold text-muted text-center mb-0 flex-grow-1 position-absolute top-50 start-50 translate-middle'>{title || "Lorem ipsum"}</span>
                    <FontAwesomeIcon width={22} height={22} icon={"fa-solid fa-xmark"} className='border border-2 border-coal text-coal radius control opacity-50' onClick={() => close()} />
                </div>
                <div className='position-relative d-flex flex-column content'>
                    <div className='d-flex flex-column flex-grow-1'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileSheet