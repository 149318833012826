import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Link from '../Link'
import { useTranslation } from 'react-i18next';
import { Form, Image, InputGroup } from 'react-bootstrap';

const ProfileNav = ({ profile, favorite, editProfile, folderDetail, returnFolderDetail, filter, order, orderAction }) => {

    const { t, i18n } = useTranslation();
    
    const [inputContent, setInputContent] = useState()

    return (
        <>
            <div className='profile-nav py-4 d-lg-none'>
                <div className={`d-flex flex-row sections gap-md-3 gap-lg-0 ${profile ? "justify-content-center" : "justify-content-between justify-content-md-center"} justify-content-lg-between`}>
                    {favorite &&
                        <Form onSubmit={e => {
                            e.preventDefault();
                            filter(inputContent);
                        }}>
                            <InputGroup size='sm'>
                                <InputGroup.Text className="rounded-start p-0 ps-2 pe-1"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" width={16} height={16} /></InputGroup.Text>
                                <Form.Control className='px-1 tt-norms' placeholder='Buscar...' onChange={(e) => setInputContent(e.target.value)} />
                            </InputGroup>
                        </Form>
                    }
                    {profile && <hr className='separator' />}
                    <Link href={`${t('pages.profile')}/${t('pages.favourites')}`} className="d-flex align-items-center">
                        <FontAwesomeIcon icon="fa-solid fa-bookmark" width={18} className={`text-coal px-lg-0 icon py-1 ${profile ? "px-3 opacity-50" : ""} ${favorite ? "active" : ""}`.trim()} onClick={() => {
                            folderDetail && returnFolderDetail()
                        }} />
                    </Link>
                    <hr className='separator' />
                    <Link href={t('pages.profile')} className="d-flex align-items-center">
                        <FontAwesomeIcon icon="fa-solid fa-user" width={18} className={`text-coal px-lg-0 icon py-1 ${profile ? "px-3" : " opacity-50"} ${profile ? "active" : ""}`.trim()} />
                    </Link>
                    {favorite && (
                        <>
                            <hr className='separator' />
                            <Image src='/img/svg/sort.svg' width={20} className={`text-coal cursor-pointer sort-ico ${order === "ASC" ? "asc" : ""}`} onClick={() => orderAction(order === "ASC" ? "DESC" : "ASC")} />
                            {/* <hr className='separator' />
                            <FontAwesomeIcon icon="fa-solid fa-table-cells-large" height={20} className={`text-coal icon px-3 py-1`.trim()} /> */}
                        </>
                    )}
                    <hr className='separator' />
                </div>
            </div>
        </>
    )
}

export default ProfileNav