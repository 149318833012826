import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Image, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UseDynamicTextColor from '../../hooks/UseDynamicTextColor';

const FolderCard = ({ create, name, total, image, folderDetail, editFolder, onClick, placeholder, color , active, hideOptions }) => {

    const { t, i18n } = useTranslation();
    const { getTextColor } = UseDynamicTextColor();

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)
    const [dropdown, setDropdown] = useState(false)
    const [mainColor, setMainColor] = useState(color)
    const [textColor, setTextColor] = useState();
    const [useCustomColor, setUseCustomColor] = useState();
    const [parentCard, setParentCard] = useState();

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const customColor = (event) => {
        let parent = event?.target?.parentElement;

        while (!parent?.className?.includes?.("folder-card")) {
            parent = parent?.parentElement;
        }

        setUseCustomColor(true)
        setParentCard(parent)
    }

    useEffect(() => {
        if (!color) {
            setMainColor(getRandomColor())
        }
    }, [])

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 992)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    useEffect(() => {
        if (parentCard && !useCustomColor && !active) {
            parentCard.removeAttribute("style")
        }
    }, [useCustomColor])

    useEffect(() => {
        if (mainColor) {
            setTextColor(getTextColor(mainColor))
        }
    }, [mainColor])

    return (
        <>
            {placeholder ? (
                <div className='d-flex flex-column radius overflow-hidden card-shadow flex-grow-1 folder-card-placeholder' onClick={() => onClick()}>
                    <div className='header position-relative'>
                        <Placeholder as={"span"} animation="glow" className="header-img">
                            <Placeholder xs={10} className="h-100 w-100" />
                        </Placeholder>
                    </div>
                    <div className='p-2'>
                        <Placeholder as={"p"} animation="wave" className="mb-0 flex-grow-1">
                            <Placeholder xs={2} size='xs' className="radius" /> <Placeholder xs={9} size='xs' className="radius" />
                        </Placeholder>
                    </div>
                </div>
            ) : (

                !create ? (
                    <div className='folder-card radius overflow-hidden card-shadow flex-grow-1 cursor-pointer'
                        onMouseEnter={(e) => customColor(e)}
                        onMouseLeave={() => setUseCustomColor(false)}
                        onClick={() => folderDetail()}
                        style={{ boxShadow: (useCustomColor || active) && `0px 0px 0px 5px ${mainColor}` }}
                    >
                        <div className='header position-relative' style={{backgroundColor: `${mainColor}`}}>
                            {/* <Image fluid src={image || "/img/no-photo.svg"} /> */}
                            <div className='overlay d-flex align-items-center justify-content-center p-4 text-center' style={{backgroundColor: `${mainColor}`}}>
                                <p className='tt-norms-bolder h6 mb-0' style={{color: `${textColor}`}}>{name}</p>
                                { !hideOptions && <FontAwesomeIcon icon="fa-solid fa-ellipsis" height={22} className='position-absolute top-0 end-0 cursor-pointer px-2 pb-2' style={{color: `${textColor}`}} onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    editFolder()
                                }} /> }
                            </div>
                        </div>
                        <div className='p-2'>
                            {/* <p className='tt-norms-bolder small mb-0'>Mallorca</p> */}
                            <p className='tt-norms-bolder small mb-0'>{total} {t('profile.favourites.folders.content')}</p>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex flex-column folder-card radius overflow-hidden card-shadow flex-grow-1 cursor-pointer' onClick={() => onClick()}>
                        <div className='header position-relative'>
                            {/* <FontAwesomeIcon icon="fa-solid fa-plus" height={82} className='position-absolute top-50 start-50 translate-middle text-muted ' /> */}
                            <img src="/img/svg/plus-thin.svg" alt="plus-ico" className='position-absolute top-50 start-50 translate-middle text-muted ' height={32} width={32} />
                            {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis" height={24} className='position-absolute top-0 end-0 me-2' /> */}
                        </div>
                        <div className='p-2 d-flex align-items-center justify-content-center flex-grow-1'>
                            <p className='tt-norms-bolder mb-0 text-center text-muted small'>{t('profile.favourites.folders.createFolder.createButton')}</p>
                        </div>
                    </div>
                )
            )}
        </>
    )
}

export default FolderCard