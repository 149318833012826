import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';

function CustomMosaic() {
	const { t, i18n } = useTranslation();

	const [mosaicItems, setMosaicItems] = useState();
	const [mosaicItemsColumn, setMosaicItemsColumn] = useState();

	const [mosaicData, setMosaicData] = useState([
		{name: "test1"},
		{name: "test2"},
		{name: "test3"},
		{name: "test4"},
		{name: "test5"},
		{name: "test6"}
	])

	const getLayout = (index) => {
		switch (index) {
			case 1:
				return "grid-1";

			case 2:
				return "grid-2";

			case 3:
				return "grid-3";

			case 4:
				return "grid-4";

			case 5:
				return "grid-5";

			case 6:
				return "grid-6";

			case "half-1":
				return "grid-half-1";

			case "half-2":
				return "grid-half-2";

			case "single":
				return "grid-single";

			default:
				break;
		}
	}

	useEffect(() => {
		let mosaicList = []
		let offset = 1

		let column1 = [];
		let column2 = [];

		if (mosaicData.length > 0) {
			let mosaicRowsList = [];
			let mosaicRow = [];

			mosaicData.forEach((cat, i) => {

				mosaicRow.push(cat);

				if (mosaicRow.length === 3) {
					mosaicRowsList.push(mosaicRow)
					mosaicRow = [];
				} else if (i === mosaicData?.length - 1) {
					mosaicRowsList.push(mosaicRow)
				}
			})

			mosaicRowsList.forEach((row) => {
				row.forEach((item, i) => {
					mosaicList.push({
						id: item?.id,
						name: item?.name,
						slug: item?.slugs?.[i18n.language],
						layout: getLayout(row?.length === 3 ? offset : row?.length === 2 ? `half-${i + 1}` : "single")
					})

					if (offset === 6) {
						offset = 1
					} else {
						offset++
					}
				})

			})

			offset = 1

			mosaicData.forEach((cat, i) => {

				if (i % 2) {
					column2.push({
						id: cat?.id,
						name: cat?.name,
						slug: cat?.slugs?.[i18n.language],
						layout: getLayout(offset)
					})
				} else {
					column1.push({
						id: cat?.id,
						name: cat?.name,
						slug: cat?.slugs?.[i18n.language],
						layout: getLayout(offset)
					})
				}

				if (offset === 6) {
					offset = 1
				} else {
					offset++
				}
			});

			setMosaicItemsColumn({ columnLeft: column1, columnRight: column2 })
			setMosaicItems(mosaicList)
		}
	}, [mosaicData])

	return (
		<>
			<div className='mosaic'>
				<div className='d-none mosaic-grid'>
					{mosaicItems?.map((item, i) =>
						<div key={i} className={`position-relative radius mosaic-item bg-coal radius overflow-hidden ${item.layout}`}>
							{/* <Image src={categoryData?.[item?.slug]?.[0]?.listImageUrl} />
							<div className='d-flex flex-column position-relative justify-content-between py-3 px-4 text-end overlay'>
								<span>{categoryData?.[item?.slug]?.[0]?.listImageUrl}</span>
								<p className='tt-norms-bolder text-white text-uppercase mb-0'>{item.name}</p>
								<p className='text-white mb-0'><span className='tt-norms-bolder'>{categoryData?.[item?.slug]?.length}</span> Resultados</p>
							</div> */}
						</div>
					)}
				</div>
				<Row className='d-flex g-2 d-lg-none mosaic-columns'>
					<Col xs={6} className='d-flex flex-column gap-2 column'>
						{mosaicItemsColumn?.columnLeft?.map((item, i) =>
							<div key={i} className={`d-flex flex-column position-relative justify-content-between radius mosaic-item bg-coal text-end radius overflow-hidden ${item.layout}`}>
								{/* <div className='flex-grow-1'>
									<Image src={categoryData?.[item?.slug]?.[0]?.listImageUrl} />
									<div className='d-flex flex-column position-relative justify-content-between py-3 px-3 text-end overlay'>
										<p className='tt-norms-bolder text-white text-uppercase mb-0 small text-nowrap'>{item.name}</p>
										<p className='tt-norms-bold text-white mb-0 subtitle'><span className='tt-norms-bolder p'>{categoryData?.[item?.slug]?.length}</span> Resultados</p>
									</div>
								</div> */}
							</div>
						)}
					</Col>
					<Col xs={6} className='d-flex flex-column gap-2 column'>
						{mosaicItemsColumn?.columnRight?.map((item, i) =>
							<div key={i} className={`d-flex flex-column position-relative justify-content-between radius mosaic-item bg-coal text-end radius overflow-hidden ${item.layout}`}>
								{/* <div className='flex-grow-1'>
									<Image src={categoryData?.[item?.slug]?.[0]?.listImageUrl} />
									<div className='d-flex flex-column position-relative justify-content-between py-3 px-3 text-end overlay'>
										<p className='tt-norms-bolder text-white text-uppercase mb-0 small white text-nowrap'>{item.name}</p>
										<p className='tt-norms-bold text-white mb-0 subtitle'><span className='tt-norms-bolder p'>{categoryData?.[item?.slug]?.length}</span> Resultados</p>
									</div>
								</div> */}
							</div>
						)}
					</Col>
				</Row>
			</div>
		</>
	)
}

export default CustomMosaic