import api from './api';

const getFavourites = (data) => api.get("user-favourite", data).then(res => res.data);

const createArticleFavourite = (data) => api.post("user-favourite/article", data).then(res => res.data);

const createMiniGuideFavourite = (data) => api.post("user-favourite/mini-guide", data).then(res => res.data);

const createInterestSiteFavourite = (data) => api.post("user-favourite/interest-site", data).then(res => res.data);

const updateFavourite = (id, data) => api.put(`user-favourite/${id}`, data).then(res => res.data);

const deleteFavorite = (id, data) => api.delete(`user-favourite/${id}?deleteInOtherFolders=${data?.deleteInOtherFolders}`, data).then(res => res.data)

const FavouriteService = {
    getFavourites,
    createArticleFavourite,
    createMiniGuideFavourite,
    createInterestSiteFavourite,
    updateFavourite,
    deleteFavorite
}


export default FavouriteService;