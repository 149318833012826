import api from "./api";

const getCategories = () => api.get(`/category`).then(response => response.data);

const getCategoriesDetail = (slug) => api.get(`/category/${slug}`).then(response => response.data);

const CategoryService = {
    getCategories,
    getCategoriesDetail
}

export default CategoryService;