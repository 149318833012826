import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { PromotionsContext } from '../../Contexts';

const PromotionModal = ({ show, close, data, session, device, userPromos, section }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { state: { user } } = useContext(AppContext);
    const { promotionState: { promotionTimelapse }, promotionState, promotionsUpdate } = useContext(PromotionsContext);

    const [promotionShow, setPromotionShow] = useState()
    const [showModal, setShowModal] = useState();

    useEffect(() => {
        if (promotionShow?.length > 0 && !promotionTimelapse) {
            setPromotionShow();
            setShowModal();
        }
    }, [location])

    useEffect(() => {
        promotionsUpdate({
            ...promotionState,
            promotionTimelapse: null
        })
    }, [showModal])

    useEffect(() => {
        let resetTimeLapseStorage;
        let clearModalShow;
        let selectedPromotion;

        if (data) {
            let promotions = [];
            let sessionPromotions = JSON.parse(sessionStorage.getItem("sessionPromotions"));
            const sessionSectionShow = sessionPromotions?.find(sessionSection => sessionSection.id === section)?.show

            if (session && device && userPromos) {
                if (sessionSectionShow && user && userPromos?.length > 0) {
                    let localPromotionsNames = JSON.parse(localStorage.getItem("localPromotionsShowed"));

                    userPromos.forEach((promo) => {
                        if (!localPromotionsNames?.includes(promo.name)) {
                            if (promotions.length < 1) {
                                localPromotionsNames?.push(promo.name);
                                promotions?.push(promo);
                                selectedPromotion = promo;
                            }
                        }
                    });

                    if (selectedPromotion) {
                        resetTimeLapseStorage = setTimeout(() => {

                            sessionPromotions[sessionPromotions.map(section => section?.id).indexOf(section)].show = false;

                            sessionStorage.setItem("sessionPromotions", JSON.stringify(sessionPromotions));
                            localStorage.setItem("localPromotionsShowed", JSON.stringify(localPromotionsNames));

                        }, selectedPromotion?.timelapse * 1000)
                    }
                }

                if (sessionSectionShow && session?.length > 0) {
                    let sessionPromotionsNames = JSON.parse(sessionStorage.getItem("sessionPromotionsShowed"))

                    if (session?.length > 0) {
                        session?.forEach((promo) => {
                            if (!sessionPromotionsNames.includes(promo.name)) {
                                if (promotions.length < 1) {
                                    sessionPromotionsNames.push(promo.name)
                                    promotions?.push(promo);
                                    selectedPromotion = promo;
                                }
                            }
                        })

                        if (selectedPromotion) {
                            resetTimeLapseStorage = setTimeout(() => {
                                sessionPromotions[sessionPromotions.map(section => section?.id).indexOf(section)].show = false;
                                sessionStorage.setItem("sessionPromotions", JSON.stringify(sessionPromotions));
                                sessionStorage.setItem("sessionPromotionsShowed", JSON.stringify(sessionPromotionsNames));
                            }, selectedPromotion?.timelapse * 1000);
                        }
                    }

                }


                if (device && sessionSectionShow && !selectedPromotion) {
                    let localPromotionsNames = JSON.parse(localStorage.getItem("localPromotionsShowed"));

                    device.forEach((promo) => {
                        if (!localPromotionsNames?.includes(promo.name)) {
                            if (promotions.length < 1) {
                                localPromotionsNames?.push(promo.name);
                                promotions?.push(promo);
                                selectedPromotion = promo;
                            }
                        }
                    });

                    if (selectedPromotion) {
                        resetTimeLapseStorage = setTimeout(() => {
                            sessionPromotions[sessionPromotions.map(section => section?.id).indexOf(section)].show = false;
                            sessionStorage.setItem("sessionPromotions", JSON.stringify(sessionPromotions));
                            localStorage.setItem("localPromotionsShowed", JSON.stringify(localPromotionsNames));
                        }, selectedPromotion?.timelapse * 1000);
                    }
                }

                if (selectedPromotion) {
                    clearModalShow = setTimeout(() => {
                        promotionsUpdate({ ...promotionState, promotionModal: true });
                        setPromotionShow(promotions);
                        setShowModal(true);
                    }, selectedPromotion?.timelapse * 1000);
                } else {
                    clearModalShow = setTimeout(() => {
                        promotionsUpdate({ ...promotionState, promotionModal: false });
                    }, 1000);
                }
            }
        }

        return () => {
            clearTimeout(clearModalShow);
            clearTimeout(resetTimeLapseStorage);
            selectedPromotion = null
            promotionsUpdate({ ...promotionState, promotionModal: null });
        }

    }, [session, device, userPromos, section])

    return (
        <>
            {showModal && promotionShow?.map((promotion, i) => {
                return (
                    <Modal key={i} className='promotion-modal radius' centered show={show} backdrop="static" size='lg'>
                        <a href={promotion?.promotionContent?.buttonContentUrl} className={"text-coal text-decoration-none"}>
                            <Modal.Body className='px-4 py-5 p-md-5' onClick={close}>
                                <Button variant='none' className='shadow-none position-absolute close p-0' onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    close();
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" width={16} />
                                </Button>
                                <div className='d-flex flex-column justify-content-center align-items-center gap-4'>
                                    {
                                        promotion?.promotionContent?.multimediaContentUrl?.includes("jpeg") || promotion?.promotionContent?.multimediaContentUrl?.includes("png") ? (
                                            <Image src={promotion?.promotionContent?.multimediaContentUrl} className='radius' />
                                        ) : (
                                            <video autoPlay loop muted playsInline className='radius'>
                                                <source autoPlay src={promotion?.promotionContent?.multimediaContentUrl} type={"video/mp4"} />
                                            </video>
                                        )
                                    }
                                    <p className='text-center tt-norms-bolder title mb-0'>{promotion?.promotionContent?.title}</p>
                                    <div className='d-flex flex-column gap-3'>
                                        <p className='text-center tt-norms-bold content mb-0'>{promotion?.promotionContent?.description}</p>
                                    </div>
                                    <Button className='btn-dark-green-outline-white px-5 radius' onClick={close}>{t('home.promotion.button')}</Button>
                                </div>
                            </Modal.Body>
                        </a>
                    </Modal>
                )
            })}
        </>
    )
}

export default PromotionModal