import React from 'react'
import { Image, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import HubspotForm from 'react-hubspot-form'
import Link from './Link';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [show, setShow] = useState(false);
	const [contact, setContact] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true);

	const handleCloseContact = () => setContact(false)
	const handleShowContact = () => setContact(true);

	const newsletterFormLang = {
		es: "7e9cacf1-5001-4357-b1f6-fd2f6f9e910e",
		en: "22093985-7dbd-4927-bd94-dab2bb1217a3",
		de: "994aabc2-9f24-4ee7-a77f-9e48ff813079"
	}

	const contactFormLang = {
		es: "226dcf0b-edd0-47b0-ba7e-3804b3bff457",
		en: "f78fc2a9-52b6-46bd-b248-ab14dfd321f2",
		de: "d33c40d5-a454-4160-8551-91da653086de"
	}


	return (
		<>
			<footer className='d-none d-lg-block bg-dark-green'>
				<div className='d-flex flex-column flex-lg-row container-fluid container-md container-size justify-content-between px-lg-0 py-5'>
					<div className='d-flex flex-column justify-content-center justify-content-lg-start align-items-center'>
						<Image src="/img/svg/new-logo.svg" className='logo' />
						{/* <div className='d-flex flex-row gap-2'>
							<Image src='/img/svg/footprint.svg' className='icon' />
							<div className='d-flex align-items-center'>
								<p className='tt-norms-bold text-white h5 mb-0'>{t('footer.footprint.text1')}</p>
							</div>
						</div> */}
					</div>
					<div className='separator-transparent my-4 my-lg-0 mx-lg-4' />
					<div className='d-none d-lg-flex flex-column'>
						<p className='inter-medium text-primary h5 mb-3 text-uppercase letter-spacing-1'>{t('footer.about')}</p>
						<p className='inter-regular letter-spacing-1 mb-3 cursor-pointer link' onClick={handleShowContact}>{t('footer.contact')}</p>
						<Link href={t('pages.dossier')} className='inter-regular letter-spacing-1 mb-3 link'>{t("navbar.dossier")}</Link>
						<p className='inter-regular letter-spacing-1 mb-3 cursor-pointer link' onClick={handleShow}>{t("footer.newsletters")}</p>
					</div>
					<div className='d-flex d-lg-none flex-column-reverse justify-content-center justify-content-md-around flex-lg-column gap-1'>
						<div className='d-flex flex-column justify-content-center align-items-center gap-3 mb-5'>
							<p className='inter-light text-primary text-uppercase letter-spacing-1 h5 mb-0'>{t('footer.follow')}</p>
							<a target="_blank" href={`https://www.facebook.com/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none link" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-facebook.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 link'>Facebook</p>
							</a>
							<a target="_blank" href={`https://www.instagram.com/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none link" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-instagram.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 link'>Instagram</p>
							</a>
							<a target="_blank" href={`https://www.linkedin.com/company/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none link" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-link.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 link'>Linkedin</p>
							</a>
							<a target="_blank" href={`https://www.pinterest.es/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none link" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-pinterest.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 link'>Pinterest</p>
							</a>
						</div>
						<div className='separator-transparent-vertical mx-2 my-2' />
						<div className='d-flex d-lg-none flex-column justify-content-center align-items-center'>
							<p className='inter-light text-primary text-uppercase h5 mb-3'>{t('footer.about')}</p>
							<p className='text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 mb-3 cursor-pointer link' onClick={handleShowContact}>{t('footer.contact')}</p>
							<Link href={t('pages.dossier')} className='text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 mb-3 link'>{t("navbar.dossier")}</Link>
							<p className='text-white inter-light letter-spacing-1 text-uppercase letter-spacing-1 mb-3 cursor-pointer link' onClick={handleShow}>{t("footer.newsletters")}</p>
						</div>
					</div>
					<div className='d-flex d-lg-none flex-column text-white text-center'>
						<p className='mb-0 h5 inter-semi-bold mb-2 letter-spacing-1'>{t('footer.app.title')}</p>
						<p className='mb-0 inter-light letter-spacing-1 h6 lh-lg'>{t('footer.app.text1')}</p>
						<p className='mb-0 inter-light letter-spacing-1 h6 lh-lg'>{t('footer.app.text2')}</p>
						<div className='d-flex flex-column gap-4 mt-4 justify-content-center'>
							<a href='https://apps.apple.com/es/app/travel-to-zero/id6449782268' target='_blank'><img alt='Disponible en Google Play' src={`/img/mobile-app/apple-store-badge-${i18n.language}.svg`} className='store-button' /></a>
							<a href='https://play.google.com/store/apps/details?id=com.proadata.traveltozero' target='_blank'><img alt='Disponible en Google Play' src={`/img/mobile-app/google-play-badge-${i18n.language}.png`} className='store-button' /></a>
						</div>
					</div>
					<div className='d-none d-lg-block separator-transparent my-5 my-lg-0 mx-lg-4' />
					<div className='d-none d-lg-flex flex-row justify-content-between justify-content-md-around justify-content-lg-between flex-lg-column gap-1'>
						<div className='d-flex flex-column gap-3'>
							<p className='inter-medium text-primary text-uppercase letter-spacing-1 h5 mb-0'>{t('footer.follow')}</p>
							<a target="_blank" href={`https://www.facebook.com/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-facebook.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 inter-regular letter-spacing-1 link'>Facebook</p>
							</a>
							<a target="_blank" href={`https://www.instagram.com/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-instagram.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 inter-regular letter-spacing-1 link'>Instagram</p>
							</a>
							<a target="_blank" href={`https://www.linkedin.com/company/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-link.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 inter-regular letter-spacing-1 link'>Linkedin</p>
							</a>
							<a target="_blank" href={`https://www.pinterest.es/traveltozero`} className="me-2 d-flex flex-row gap-2 align-items-center text-decoration-none" rel="nofollow noreferrer">
								{/* <img src="/img/svg/shared-pinterest.svg" alt="" className="invert-color" /> */}
								<p className='mb-0 inter-regular letter-spacing-1 link'>Pinterest</p>
							</a>
						</div>
						<div className='separator-transparent-vertical mx-2' />
						<div className='d-flex d-lg-none flex-column'>
							<p className='tt-norms-bolder text-white h5 mb-3'>{t('footer.about')}</p>
							<p className='tt-norms mb-3 cursor-pointer link' onClick={handleShowContact}>{t('footer.contact')}</p>
							<Link href={t('pages.dossier')} className='tt-norms mb-3 link'>{t("navbar.dossier")}</Link>
							<p className='tt-norms mb-3 cursor-pointer link' onClick={handleShow}>{t("footer.newsletters")}</p>
						</div>
					</div>
					<div className='separator-transparent my-4 my-lg-0 mx-lg-4' />
					<div className='d-none d-lg-flex flex-column text-white'>
						<p className='mb-0 h5 inter-semi-bold mb-2 letter-spacing-1'>{t('footer.app.title')}</p>
						<p className='mb-0 h5 inter-light letter-spacing-1'>{t('footer.app.text1')}</p>
						<p className='mb-0 h5 inter-light letter-spacing-1'>{t('footer.app.text2')}</p>
						<div className='d-flex flex-row gap-2 mt-4'>
							<a href='https://apps.apple.com/es/app/travel-to-zero/id6449782268' target='_blank'><img alt='Disponible en Google Play' src={`/img/mobile-app/apple-store-badge-${i18n.language}.svg`} className='store-button' /></a>
							<a href='https://play.google.com/store/apps/details?id=com.proadata.traveltozero' target='_blank'><img alt='Disponible en Google Play' src={`/img/mobile-app/google-play-badge-${i18n.language}.svg`} className='store-button' /></a>
						</div>
					</div>
					<div className='d-flex d-lg-none flex-column text-center gap-1'>
						<p className='inter-light letter-spacing-1 text-white mb-0'>© 2022 - {new Date().getFullYear()} Travel To Zero</p>
						<div className='d-flex flex-column py-2 gap-3'>
							<p className='inter-light letter-spacing-1 mb-0 cursor-pointer link' onClick={() => navigate(`/${i18n.language}/${t('pages.cookies')}`)}>{t("footer.cookies")}</p>
							<p className='inter-light letter-spacing-1 mb-0 cursor-pointer link' onClick={() => navigate(`/${i18n.language}/${t('pages.privacy')}`)}>{t("footer.privacy")}</p>
							<p className='inter-light letter-spacing-1 mb-0 cursor-pointer link' onClick={() => navigate(`/${i18n.language}/${t('pages.legal')}`)}>{t("footer.legal")}</p>
						</div>
					</div>
				</div>
				<div className='d-none d-lg-flex flex-column justify-content-around align-items-center justify-content-center'>
					<p className='inter-light letter-spacing-1 text-white mb-0 small'>© 2022 - {new Date().getFullYear()} Travel To Zero</p>
					<div className='d-flex flex-row py-2 gap-2'>
						<p className='inter-light letter-spacing-1 mb-0 cursor-pointer small link' onClick={() => navigate(`/${i18n.language}/${t('pages.cookies')}`)}>{t("footer.cookies")}</p>
						<div className='separator my-1' />
						<p className='inter-light letter-spacing-1 mb-0 cursor-pointer small link' onClick={() => navigate(`/${i18n.language}/${t('pages.privacy')}`)}>{t("footer.privacy")}</p>
						<div className='separator my-1' />
						<p className='inter-light letter-spacing-1 mb-0 cursor-pointer small link' onClick={() => navigate(`/${i18n.language}/${t('pages.legal')}`)}>{t("footer.legal")}</p>
					</div>
				</div>
			</footer>
			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>{t("footer.newsletters")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<HubspotForm
						region="eu1"
						portalId="26078053"
						formId={newsletterFormLang[i18n.language]}
					/>
				</Modal.Body>
			</Modal>
			<Modal show={contact} onHide={handleCloseContact} size='lg'>
				<Modal.Header closeButton className='border-0 pb-0' />
				<Modal.Body>
					<HubspotForm
						region="eu1"
						portalId="26078053"
						formId={contactFormLang[i18n.language]}
					/>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Footer