import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import GalleryZoom from './GalleryZoom'
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

function Gallery({ images }) {

	const [showZoom, setShowZoom] = useState();
	const [activeIndex, setActiveIndex] = useState();

	useEffect(() => {
		if (showZoom) {
			document.body.classList.add('overflow-hidden')
		} else {
			document.body.classList.remove('overflow-hidden')
		}
	}, [showZoom])

	return (
		<>
			<div className='tzo-gallery-container position-relative'>
				<div className='tzo-gallery d-none d-lg-flex'>
					<div className='main'>
						<div className='position-relative overflow-hidden cursor-pointer' onClick={() => {
							setShowZoom(true)
							setActiveIndex(0)
						}}>
							<Image src={images?.[0].imgUrl} />
							<div className='overlay'>
								<FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" className="text-white opacity-75" width={32} height={32} />
							</div>
						</div>
					</div>
					<div className='grid'>
						{[images?.[1].imgUrl, images?.[2].imgUrl, images?.[3].imgUrl, images?.[4].imgUrl].map((data, i) =>
							<div className='position-relative overflow-hidden cursor-pointer' key={i} onClick={() => {
								setShowZoom(true)
								setActiveIndex(i + 1)
							}}>
								<Image src={data} />
								<div className={`overlay ${(i === 3 && images?.length > 5) ? "active" : ""}`.trim()}>
									{!(i === 3 && images?.length > 5) && <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" className="text-white opacity-75" width={32} height={32} />}
									{(i === 3 && images?.length > 5) && <span className='tt-norms-bold h3 text-white opacity-75 mb-0'>{images?.length - 5}+</span>}
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='d-block d-lg-none'>
					<Swiper
						modules={[FreeMode]}
						breakpoints={{
							768: {
								slidesPerView: 2.5
							}
						}}
						slidesPerView={1.225}
						spaceBetween={12}
						slidesOffsetBefore={24}
						slidesOffsetAfter={24}
						freeMode
						className="py-2 d-lg-none"
					>
						{[images?.[0].imgUrl, images?.[1].imgUrl, images?.[2].imgUrl, images?.[3].imgUrl, images?.[4].imgUrl].map((data, i) => (
							<SwiperSlide key={i}>
								<div className='position-relative overflow-hidden cursor-pointer h-100' onClick={() => {
									setShowZoom(true)
									setActiveIndex(0)
								}}>
									<Image src={data} />
									<div className={`overlay ${(i === 4 && images?.length > 5) ? "active" : ""}`.trim()}>
										{!(i === 4 && images?.length > 5) && <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" className="text-white opacity-75" width={32} height={32} />}
										{(i === 4 && images?.length > 5) && <span className='tt-norms-bold h1 text-white opacity-75 mb-0'>{images?.length - 5}+</span>}
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
			{showZoom && <GalleryZoom images={images} close={() => setShowZoom(false)} activeIndexProp={activeIndex} />}

		</>
	)
}

export default Gallery