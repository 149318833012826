import api from './api';

const sendEmail = (data) => api.post(`/auth/sign-up`, data).then(res => res.data);

const validateEmail = (token) => api.post(`/auth/validate-email`, token).then(res => res.data);

const reSendEmail = (data) => api.post(`/auth/send-validation-email`, data).then(res => res.data);

const RegisterService = {
    sendEmail,
    validateEmail,
    reSendEmail
}

export default RegisterService;