import api from './api';

const getHeadline = (data) => {
    return api.get(`/home-headline`, data).then(res => res.data);
}

const HomeHeadlineService = {
    getHeadline
}

export default HomeHeadlineService;