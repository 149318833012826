import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { VillasContext } from '../../Contexts';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import React, { useContext } from 'react'

function CheckInOutSelect({ className, containerClasssName, noLabel, chevron, id, size, blockDates, blockReset }) {

    const { t, i18n } = useTranslation();
    const { villasState: { filter }, villasState, villasUpdate } = useContext(VillasContext)

    const [dropOpen, setDropOpen] = useState(false);
    const [selectData, setSelectData] = useState();
    const [value, onChange] = useState();
    const [delayTrigger, setDelayTrigger] = useState(false);
    const [blockedDatesList, setBlockedDatesList] = useState();
    const [maxDate, setMaxDate] = useState();

    const handleInputDate = (dates) => {
        let startDate = new Date(dates?.[0].getFullYear(), dates?.[0].getMonth(), dates?.[0].getDate());
        let endDate = new Date(dates?.[1].getFullYear(), dates?.[1].getMonth(), dates?.[1].getDate());

        onChange([startDate, endDate]);
    }

    const syncDates = () => {
        if (value?.[0] || value?.[1]) {
            if (value?.[0] !== filter?.startDate && value?.[1] !== filter?.endDate) {
                onChange([filter?.startDate, filter?.endDate])
            } else if (value?.[1] !== filter?.endDate) {
                onChange([value?.[0], filter?.endDate])
            } else if (value?.[0] !== filter?.startDate) {
                onChange([filter?.startDate, value?.[1]])
            }
        } else if (!value?.[0] && !value?.[1] && filter?.startDate && filter?.endDate) {
            onChange([filter?.startDate, filter?.endDate])
        }
    }

    const getDaysArray = function (start, end, format) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            // arr.push(new Date(dt));
            if (format === "shortDate") {
                arr.push(`${dt?.getFullYear()}-${dt?.getMonth()}-${dt?.getDate()}`)
            } else if ("time") {
                arr.push(new Date(dt).getTime());
            } else {
                arr.push(new Date(dt));
            }
        }
        return arr;
    };

    const checkDates = (dates, block) => {
        const found = block?.some(blocked => dates.includes(new Date(blocked).getTime()))

        return found
    }

    useEffect(() => {

        if (!dropOpen) {
            document.getElementById(id)?.classList?.replace("open", "close")
            setTimeout(() => {
                document.getElementById(id)?.classList.add("d-none")
            }, 225);
        } else {
            document.getElementById(id)?.classList?.remove("d-none")
            setTimeout(() => {
                document.getElementById(id)?.classList?.replace("close", "open")
            }, 10);
        }

    }, [dropOpen])

    useEffect(() => {
        if (value?.length > 0 && value?.[0]?.getTime() !== value?.[1]?.getTime()) {
            let betweenDates;

            if (value?.length === 2) {
                betweenDates = getDaysArray(value?.[0], value?.[1], "time")

                if (blockDates && checkDates(betweenDates, blockedDatesList)) {
                    onChange([value?.[1], value?.[1]])

                    villasUpdate({
                        ...villasState, filter: {
                            ...villasState.filter,
                            startDate: value?.[1],
                            endDate: value?.[1]
                        }
                    })
                } else {
                    if (value?.[0]?.getTime() !== filter?.startDate?.getTime() || value?.[1]?.getTime() !== filter?.endDate?.getTime()) {
                        villasUpdate({
                            ...villasState, filter: {
                                ...villasState.filter,
                                startDate: value?.[0],
                                endDate: value?.[1]
                            }
                        })
                    }
                }
            }
        } else {
            if (!filter) {
                villasUpdate({
                    ...villasState, filter: {
                        ...villasState.filter,
                        startDate: null,
                        endDate: null
                    }
                })
            } else if (value?.length === 0) {
                villasUpdate({
                    ...villasState, filter: {
                        ...villasState.filter,
                        startDate: null,
                        endDate: null
                    }
                })
            }
        }
    }, [value])

    useEffect(() => {
        if ((value?.[0] && !filter?.startDate) || (value?.[1] && !filter?.endDate)) {
            onChange([])
        } else if (value?.[0]?.getTime() !== filter?.startDate?.getTime() || value?.[1]?.getTime() !== filter?.endDate?.getTime()) {
            syncDates();
        }
    }, [filter])

    useEffect(() => {
        if (!value) {
            syncDates();
        }
    }, [])

    useEffect(() => {
        if (blockDates) {
            let parseDates = [];

            blockDates?.forEach((date) => {
                let dateType = new Date(date)
                parseDates?.push(new Date(dateType?.getFullYear(), dateType?.getMonth(), dateType?.getDate()))
            })

            setBlockedDatesList(parseDates)
        } else {
            setBlockedDatesList();
        }
    }, [blockDates])

    useEffect(() => {
        const currentDate = new Date();

        currentDate.setDate(currentDate.getDate() + (365 * 2))

        setMaxDate(currentDate)
    }, [])

    return (
        <div className={`d-flex flex-grow-1 position-relative ${containerClasssName}`} id='checkInOut'>
            <div className={`d-flex flex-row flex-grow-1 justify-content-between px-3 py-2 cursor-pointer user-select-none flex-grow-1 ${className} ${dropOpen ? "open" : ""}`} onClick={() => {
                !delayTrigger && setDropOpen(true)
            }}>
                <div className='d-flex flex-column'>
                    {!noLabel && <span className='tt-norms-semi-bolder mb-0'>{t('villas.filter.checkInOutLabel')}</span>}
                    <div className='d-flex flex-row flex-grow-1 gap-3 small align-items-center justify-content-center'>
                        {value?.length > 0 ? (
                            <>
                                <span className='inter-medium text-coal'>{value?.[0]?.toLocaleDateString()}</span>
                                <span className='inter-medium text-coal'>{value?.[1]?.toLocaleDateString()}</span>
                            </>
                        ) : (
                            <span className='inter-medium text-coal'>{t('villas.filter.checkInOutPlaceholder')}</span>
                        )}
                    </div>
                </div>
                <div className='d-flex flex-row align-items-center gap-3'>
                    {chevron && <FontAwesomeIcon icon={`fa-solid ${dropOpen ? "fa-chevron-up" : "fa-chevron-down"}`} className='text-coal' width={12} />}
                    {(value?.length > 0 && !blockReset) && <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={12} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onChange([])
                    }} />}
                </div>
            </div>
            <div id={id} className='options-container calendar close'>
                <DateRangePicker
                    tileDisabled={({ date, view }) =>
                        (view === 'month') && // Block day tiles only
                        blockedDatesList?.some(disabledDate => {
                            return (
                                date.getFullYear() === disabledDate.getFullYear() &&
                                date.getMonth() === disabledDate.getMonth() &&
                                date.getDate() === disabledDate.getDate()
                            )
                        })
                    }
                    onChange={(data) => handleInputDate(data)}
                    value={value}
                    selectRange={true}
                    isOpen={dropOpen ? true : false}
                    minDate={new Date()}
                    maxDate={maxDate}
                    shouldCloseCalendar={({ reason }) => {
                        setDelayTrigger(true);
                        setDropOpen(false)
                        setTimeout(() => {
                            setDelayTrigger(false);
                        }, 125);
                    }} />
            </div>
        </div>
    )
}

export default CheckInOutSelect