import { Alert, Button, Col, Container, Form, FormCheck, FormControl, FormLabel, Image, InputGroup, Row } from 'react-bootstrap'
import { FavouritesContext } from '../../Contexts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadcrumbPage from '../../components/breadcrumb/Breadcrumb'
import ContentCardPlaceholder from '../../components/cards/ContentCardPlaceholder'
import ContentCards from '../../components/cards/ContentCards'
import FavouriteService from '../../services/favourites.service'
import FolderCard from '../../components/cards/FolderCard'
import FolderService from '../../services/folder.service'
import Link from '../../components/Link'
import MobileSheet from '../../components/mobile-sheet/MobileSheet'
import ProfileNav from '../../components/profile-nav/ProfileNav'
import React, { useContext, useRef } from 'react'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'
import UseDynamicTextColor from '../../hooks/UseDynamicTextColor';

const Folder = () => {

    const { favouriteState: { mainFolder, dataFolderPersist, folderEditPersist }, favouriteState, favouriteUpdate, } = useContext(FavouritesContext)

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { getTextColor } = UseDynamicTextColor();

    const [mainFolderData, setMainFolderData] = useState(mainFolder)
    const [favourites, setFavourites] = useState();
    const [folders, setFolders] = useState();
    const [show, setShow] = useState(false);
    const [folderDetail, setFolderDetail] = useState();
    const [favouritesList, setFavouritesList] = useState();
    const [editContent, setEditContent] = useState();
    const [editOption, setEditOption] = useState();
    const [editType, setEditType] = useState();
    const [newFolder, setNewFolder] = useState();
    const [sheetTitle, setSheetTitle] = useState("");
    const [actionTitle, setActionTitle] = useState("")
    const [folderFilter, setFolderFilter] = useState()
    const [inputContent, setInputContent] = useState()
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)
    const [showReturn, setShowReturn] = useState(false)
    const [orderType, setOrderType] = useState("DESC")
    const [actionTitleList, setActionTitleList] = useState([])
    const [closeMenu, setCloseMenu] = useState(false);
    const [showFavoritesEditFolder, setShowFavoritesEditFolder] = useState();

    const [selectedContent, setSelectedContent] = useState([]);
    const [folderName, setFolderName] = useState();
    const [folderColor, setFolderColor] = useState()
    const [deleteFlag, setDeleteFlag] = useState()

    const [emailShare, setEmailShare] = useState();
    const [shareDescription, setShareDescription] = useState();
    const [isEmailSend, seIsEmailSend] = useState(false)

    const [success, setSuccess] = useState({
        create: true,
        delete: false
    });

    const [errors, setErrors] = useState({
        create: false,
        name: false,
        delete: false
    });

    let colorDelayReset;
    const [delayCreate, setDelayCreate] = useState();

    const getFolders = useCallback(async () => {
        try {
            const data = await FolderService.getDetailedFolders();

            setFolders(data);
        } catch (error) {
            console.error(error);
        }
    })

    const getFavourites = useCallback(async () => {
        try {
            const data = await FavouriteService.getFavourites()

            setFavourites(data);
        } catch (error) {
            console.error(error);
        }
    }, [FavouriteService, favourites])

    const updateFolder = useCallback(async (main, device) => {
        setSuccess({ ...success, create: false, delete: false })
        setErrors({ ...errors, create: false, name: false, delete: false })

        try {
            // if (main) { // Main folder reset
            //     let mainFavourites = []

            //     mainFolder?.favourites?.forEach((favourite) => {
            //         mainFavourites.push(favourite?.id)
            //     })

            //     await FolderService.updateFolder(mainFolder?.id, {
            //         name: mainFolder?.name,
            //         favouriteIds: mainFavourites,
            //         main: false
            //     })
            // }

            await FolderService.updateFolder((editContent?.id || folderDetail?.id), {
                name: folderName,
                favouriteIds: selectedContent,
                color: folderColor || editContent?.color || folderDetail?.color || "#ECE83A"
            })

            if (!isDesktop) {
                setFolders();
                getFolders();
            } else {
                setCloseMenu(true)
                getFolders();
            }

            setFolderColor(document.getElementById(`folderColor${device}`)?.value)
            getFavourites();
            setSuccess({ ...success, create: true })
        } catch (error) {
            console.error(error);
        }
    }, [editContent, selectedContent, folderName, folderColor, success, errors, actionTitleList])

    const createFolder = useCallback(async () => {
        setSuccess({ ...success, create: false })
        setErrors({ ...errors, create: false, name: false })

        if (folderName) {
            try {
                await FolderService.createFolder({
                    name: folderName,
                    favouriteIds: selectedContent,
                    main: folders?.length <= 0 ? true : false,
                    color: folderColor || "#ECE83A"
                })

                if (folders?.length === 0) {
                    setFolders(undefined)
                    setNewFolder(false)
                }

                setSuccess({ ...success, create: true })

                setFolders();
                getFolders();

                setFolderName();
                setFolderColor();
                setSelectedContent([]);

                window.scrollTo(0, 0)

                setDelayCreate(setTimeout(() => {
                    setShow(false)
                    setTimeout(() => {
                        setNewFolder(false)
                        setActionTitleList([])
                        setActionTitle();
                        setSuccess({ ...success, create: false })
                    }, 650);
                }, 250));
            } catch (error) {
                console.error(error);
                setErrors({ ...errors, create: true })
            }
        } else {
            setErrors({ ...errors, name: true })
        }
    }, [folderName, selectedContent, folderName, folderColor, success, errors])

    const deleteFolder = useCallback(async (id) => {
        setSuccess({ ...success, create: false, delete: false })
        setErrors({ ...errors, create: false, name: false, delete: false })
        let reset;

        try {
            await FolderService.deleteFolder(id, {
                deleteInOtherFolders: deleteFlag
            })

            setSuccess({ ...success, delete: true })

            setFolders();
            getFolders();

            if (deleteFlag) {
                getFavourites()
            }

            setTimeout(() => {
                setShow(false)
            }, 250);

            if (folderDetail?.id === id) {
                setFolderDetail();
                setEditContent();
                setEditType();
                if (!isDesktop) {
                    setTimeout(() => {
                        setEditOption();
                    }, 625);
                } else {
                    setEditOption();
                }
            } else {
                if (isDesktop) {
                    setEditOption();
                    setEditType();
                    setEditContent();
                } else {
                    setTimeout(() => {
                        setEditOption();
                        setEditType();
                        setEditContent();
                    }, 625);
                }
            }

            setActionTitle();
            setActionTitleList([]);
            setShowReturn(false)

            setSuccess({ ...success, delete: false })
        } catch (error) {
            console.error(error);
            setErrors({ ...errors, delete: true })
        }

        return () => {
            clearTimeout(reset)
        }
    }, [success, errors, deleteFlag])

    const updateFavourite = useCallback(async (id) => {
        setSuccess({ ...success, create: false, delete: false })
        setErrors({ ...errors, create: false, name: false, delete: false })

        try {
            await FavouriteService.updateFavourite(id, {
                folderIds: selectedContent
            })

            if (!selectedContent?.includes(folderDetail?.id) && !folderDetail?.allFavourites && folderDetail) {
                setShow(false)
                closeCard();
            }

            // setTimeout(() => {
            //     // closeCard()
            //     setSuccess({ ...success, create: false, delete: false })
            // }, 500);

            getFolders();
            setSuccess({ ...success, create: true })
            document.getElementsByClassName('sheet-container')?.[0].scroll({ top: 0, behavior: 'smooth' })
        } catch (error) {
            console.error(error);
        }
    }, [selectedContent, editOption, actionTitleList])

    const deleteFavorite = useCallback(async (id) => {
        try {
            await FavouriteService.deleteFavorite((id), {
                deleteInOtherFolders: true
            })

            setShow(false)
            getFolders();
            getFavourites();
            updateFavouriteContext();
            setEditContent();
            setEditType("folder")
            setEditOption()
            setShowReturn(false)
            setSuccess({ ...success, delete: true })
        } catch (error) {
            console.error(error);
        }

        return () => {

        }
    }, [deleteFlag])

    const shareFolder = async (folderId, favorite) => {
        try {
            FolderService.shareContent({
                email: emailShare,
                itemId: folderId,
                favourite: favorite
            })

            setSuccess({ ...success, create: true })

            if (!isDesktop) {
                setTimeout(() => {
                    setShow(false)
                    setTimeout(() => {
                        closeCard()
                        setSuccess({ ...success, create: false })
                        setEmailShare();
                    }, 650);
                }, 500)
            } else {
                setTimeout(() => {
                    closeCard()
                    setSuccess({ ...success, create: false })
                    setEmailShare();
                }, 850);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleContentSelect = (select) => {

        let contentArray = [...selectedContent];

        if (contentArray.length > 0) {
            let findContent = contentArray.indexOf(select)
            if (findContent >= 0) {
                contentArray.splice(findContent, 1)
            } else {
                contentArray.push(select)
            }
        } else {
            contentArray.push(select)
        }

        setSelectedContent(contentArray)
    }

    const handleFolderSelect = (select) => {
        let contentArray = [...selectedContent];

        if (contentArray.length > 0) {
            let findContent = contentArray.indexOf(select)
            if (findContent >= 0) {
                contentArray.splice(findContent, 1)
            } else {
                contentArray.push(select)
            }
        } else {
            contentArray.push(select)
        }

        setSelectedContent(contentArray)
    }

    const filterFolderFavourites = useCallback((name) => {
        if (name) {
            const folderFavs = (editType === "folder" && editOption === "edit") ? favourites : (editType === "folder" && !editOption) ? favouritesList : favourites;

            let filteredFavs = []

            folderFavs.forEach((fav) => {
                if ((fav?.title?.toLowerCase()).includes(name.toLowerCase())) {
                    filteredFavs.push(fav)
                }
            })

            setFolderFilter(filteredFavs)
        } else {
            if (folderFilter) {
                setFolderFilter();
            }
        }

    }, [folderFilter, folderDetail, favourites, favouritesList])

    const updateFavouriteContext = async () => {
        const favouritesData = await FavouriteService.getFavourites();

        let favouriteIdList = [];

        favouritesData.forEach((favourite) => {
            favouriteIdList.push(favourite?.elementId)
        })

        favouriteUpdate({
            favourites: favouritesData,
            favouritesList: favouriteIdList
        })
    }

    const closeCard = (closeAll) => {
        setFolderFilter();
        clearTimeout(delayCreate)
        setDelayCreate();

        if (closeAll) {
            setNewFolder(false);
            setFolderDetail();
            setEditContent();
            setEditOption();
            setEditType();
            setShow(false);
            setFavouritesList();
            setActionTitle();
            setActionTitleList([]);

            favouriteUpdate({
                ...favouriteState,
                dataFolderPersist: null,
                folderEditPersist: false
            })
        } else {

            if (!folderDetail) {
                if (editContent && editOption) {
                    setEditOption();
                    setShowReturn(false)

                    let list = [...actionTitleList]
                    list?.splice(list?.length - 1, 1)

                    setActionTitleList(list)
                    setActionTitle(list?.[list.length - 1])
                }
            } else if (!folderDetail && editOption) {
                setShowReturn(false)
            }

            if (folderDetail && editContent) {
                if (editOption) {
                    setEditOption();
                    if (showFavoritesEditFolder) {
                        setShowReturn(false);
                    }
                } else {
                    setEditContent();
                    setEditType("folder");
                    setShowReturn(false);
                    setShow(false);
                    favouriteUpdate({
                        ...favouriteState,
                        folderEditPersist: false
                    })
                }
            }

            if (folderDetail && !editContent) {
                setEditOption();
                setShowReturn(false);
            }

            if (isDesktop) {
                let list = [...actionTitleList]
                list?.splice(list?.length - 1, 1)

                setActionTitleList(list)
                setActionTitle(list?.[list.length - 1])
            } else if (!isDesktop) {
                let list = [...actionTitleList]
                if (folderDetail && editContent) {
                    setActionTitleList(list?.splice(list?.length - 1, 1))
                } else if (folderDetail && !editContent) {
                    setActionTitle(list?.[0])
                }
            }
        }
        setSuccess({ ...success, create: false, delete: false })
        setErrors({ ...errors, create: false, name: false })
    }

    const handleColor = (color) => {
        if (colorDelayReset) {
            clearTimeout(colorDelayReset)
        }

        colorDelayReset = setTimeout(() => {
            setFolderColor(color)
        }, 100);
    }

    const filterAscDesc = () => {
        setFavouritesList(favouritesList?.slice()?.reverse())
        setFavourites(favourites?.slice()?.reverse())
        setFolderFilter(folderFilter?.slice()?.reverse())
        setMainFolderData({
            ...mainFolderData,
            favourites: mainFolderData?.favourites?.reverse()
        })

        document.getElementsByClassName('right')?.[0].scroll({ top: 0 })
    }

    useEffect(() => {
        getFolders()
        getFavourites();
    }, [])

    useEffect(() => {
        let waitResize;

        const checkDesktop = () => {
            setIsDesktop(window.innerWidth >= 992)
        }

        window.addEventListener('resize', () => {
            clearTimeout(waitResize);
            waitResize = setTimeout(checkDesktop, 100)
        })

        return () => window.removeEventListener('resize', checkDesktop)
    }, [isDesktop])

    useEffect(() => {
        let clear;
        let selectdContentArray = [];

        if (folderDetail && editContent) {
            favouriteUpdate({
                ...favouriteState,
                dataFolderPersist: folderDetail?.id,
                folderEditPersist: true
            })
        }

        if (folderDetail && editContent && editOption) {
            setShowReturn(true);
        }

        if (!editContent && !isDesktop) {
            clear = setTimeout(() => {
                setEditOption();
            }, [675])
        } else if (!editContent) {
            setEditOption();

        }

        if (editContent) {
            if (!isDesktop) {
                if (!folderDetail) {
                    editContent?.favourites?.forEach(article => {
                        selectdContentArray.push(article?.id)
                    });

                    setFolderName(editContent?.name)
                    setSelectedContent(selectdContentArray)
                } else if (folderDetail) {
                    folders?.forEach((folder) => {
                        folder?.favourites.forEach((favourite) => {
                            if (favourite?.id === editContent?.id) {
                                selectdContentArray.push(folder?.id)
                            }
                        })
                    })

                    setSelectedContent(selectdContentArray)
                }
            } else if (isDesktop) {
                if (editType === "favourite") {
                    folders?.forEach((folder) => {
                        folder?.favourites.forEach((favourite) => {
                            if (favourite?.id === editContent?.id) {
                                selectdContentArray.push(folder?.id)
                            }
                        })
                    })

                    setSelectedContent(selectdContentArray)
                } else if (editType === "folder") {
                    editContent?.favourites?.forEach(article => {
                        selectdContentArray.push(article?.id)
                    });

                    setFolderName(editContent?.name)
                    setSelectedContent(selectdContentArray)
                }
            }
        }

        return () => {
            clearTimeout(clear)
        }
    }, [editContent])

    useEffect(() => {
        let clear;

        if (!show) {
            clear = setTimeout(() => {
                if (!isDesktop) {
                    setEditContent();
                    setEditOption();
                    setNewFolder();
                    setSuccess({ ...success, create: false, delete: false })
                    setErrors({ ...errors, create: false, name: false })
                }
            }, 675)
        } else {
            // setEditOption();
        }

        return () => {
            clearTimeout(clear)
        }
    }, [show])

    useEffect(() => {
        if (show && deleteFlag !== undefined) {
            setDeleteFlag(undefined)
        }

        if (editOption === "edit" && folderFilter) {
            setFolderFilter();
        }
    }, [editOption])

    useEffect(() => {
        if (!folderDetail) {
            setEditContent();
            setFolderName();
            setFolderColor();
            setSelectedContent([]);
        }
    }, [newFolder])

    useEffect(() => {
        if (folders) {
            const main = folders?.findIndex(folder => folder.main === true)

            if (folders?.[main]) {
                setMainFolderData({
                    ...folders?.[main],
                    favourites: folders?.[main]?.favourites?.reverse()
                })
            }

            if (dataFolderPersist) {
                folders?.forEach((folder) => {
                    if (folder?.id === dataFolderPersist) {
                        setFolderDetail(folder)
                        setEditOption();
                        setShowReturn(false)
                        !folderEditPersist && setActionTitle(folder.name)

                        if (folderEditPersist) {
                            setEditContent(folder);
                            setEditType("folder");
                            setActionTitleList([folder?.name, t('profile.favourites.folders.menu.folder')]);
                            setActionTitle(t('profile.favourites.folders.menu.folder'));
                            setShowReturn(true)
                        }
                    }
                })
            }

            if (editContent && !folderDetail) {
                if (editType === "folder") {
                    setEditContent(folders.find(folder => folder.id === editContent?.id))
                } else if (editType === "favourite") {
                    setEditContent(favourites.find(fav => fav.id === editContent?.id))
                }
                setEditOption();
                closeCard();
                setSuccess({ ...success, create: false, delete: false })
            }

            if (editContent && folderDetail && !folderDetail?.allFavourites) {
                const updatedFolder = folders.find(folder => folder.id === folderDetail?.id);
                const activeFavourite = updatedFolder?.favourites.find(favourite => favourite.id === editContent?.id);

                setFolderDetail({
                    ...updatedFolder,
                    favourites: updatedFolder?.favourites
                })

                if (!activeFavourite) {
                    setEditType("folder");
                    setShowReturn(false)
                    setActionTitle(updatedFolder?.name);
                } else {
                    setEditContent(activeFavourite)
                    setEditOption()
                }
            }

            if (!editContent && folderDetail) {
                const updatedFolder = folders.find(folder => folder.id === folderDetail?.id)

                setFolderDetail({
                    ...updatedFolder,
                    favourites: updatedFolder?.favourites
                })
            }
        }
    }, [folders])

    useEffect(() => {
        if (success.create || errors.create) {
            setTimeout(() => {
                setSuccess({ ...success, create: false, delete: false })
                setErrors({ ...errors, create: false, name: false, delete: false })
            }, 5000);
        }
    }, [success.create, errors.create])

    useEffect(() => {
        window.scrollTo(0, 0)
        setOrderType("DESC");

        if (folderDetail) {
            favouriteUpdate({
                ...favouriteState,
                dataFolderPersist: folderDetail?.id
            })
        }

        if (!folderDetail && folderFilter) {
            setFolderFilter()
        }

        if (folderDetail && folderFilter) {
            setFolderFilter();
        }

        if (folderDetail?.favourites?.length > 0) {
            setFavouritesList(folderDetail?.main ? folderDetail?.favourites : folderDetail?.favourites?.slice()?.reverse())
            setFolderFilter()
        } else if (folderDetail?.favourites?.length === 0) {
            setFavouritesList([]);
        } else {
            setFavouritesList();
        }

        if (closeMenu) {
            setSuccess({ ...success, create: false, delete: false })
            closeCard();
            setCloseMenu(false)
        }
    }, [folderDetail])

    useEffect(() => {
        let selectdContentArray = [];

        if (editOption && folderDetail) {
            setShowReturn(true)
        }

        if (editOption === "edit") {
            if (folderDetail) {
                folderDetail?.favourites?.forEach(article => {
                    selectdContentArray.push(article?.id)
                });

                setFolderName(folderDetail?.name)
                setSelectedContent(selectdContentArray)
            } else if (folderDetail) {
                folders?.forEach((folder) => {
                    folder?.favourites.forEach((favourite) => {
                        if (favourite?.id === folderDetail?.id) {
                            selectdContentArray.push(folder?.id)
                        }
                    })
                })

                setSelectedContent(selectdContentArray)
            }
        }
    }, [editOption])

    useEffect(() => {
        filterAscDesc();
    }, [orderType])

    useEffect(() => {
        if (isDesktop && folderDetail?.allFavourites) {
            setFolderDetail();
            setActionTitle();
            setActionTitleList([])

            if (editContent) {
                setEditContent();
                setShow(false);
                setShowReturn(false)
            }
        }

        if (!isDesktop && showFavoritesEditFolder) {
            setFolderDetail()
        }
    }, [isDesktop])

    useEffect(() => {
        if (actionTitle && !actionTitleList?.includes(actionTitle)) {
            let list = [...actionTitleList]
            list.push(actionTitle)
            setActionTitleList(list)
        }
    }, [actionTitle])

    useEffect(() => {
        if (!delayCreate) {
            setShow(false)
            setTimeout(() => {
                setNewFolder(false)
                setSuccess({ ...success, create: false })
            }, 675);
        }
    }, [delayCreate])

    useEffect(() => {
        setMainFolderData({
            ...mainFolder,
            favourites: mainFolder?.favourites?.reverse()
        })
    }, [mainFolder])

    useEffect(() => {
        if (favourites && favourites?.length !== favouriteState?.favourites?.length) {
            let favouriteIdList = [];

            let articlesList = [];
            let miniGuidesList = [];
            let interestSitesList = [];

            favourites.forEach((favourite) => {
                favouriteIdList.push(favourite?.elementId)

                if (favourite?.favouriteType === "ARTICLE") {
                    articlesList.push(favourite?.elementId);
                } else if (favourite?.favouriteType === "MINIGUIDE") {
                    miniGuidesList.push(favourite?.elementId);
                } else if (favourite?.favouriteType === "INTERESTSITE") {
                    interestSitesList.push(favourite?.elementId);
                }
            })

            favouriteUpdate({
                favourites: favourites,
                favouritesList: favouriteIdList,
                articlesFav: articlesList,
                miniGuidesFav: miniGuidesList,
                interestSitesFav: interestSitesList
            })
        }
    }, [favourites])

    return (
        <>
            <SeoHelmet title={t('seo.profile.title')} metaTitle={t('seo.profile.metaTitle')} metaDescription={t('seo.profile.metaDescription')} ogImage={'/img/home-left-1.png'} />
            <BreadcrumbPage mobileHide />
            <Container className='screen-height'>
                {(folderDetail && !isDesktop) && <ProfileNav favorite folderDetail={folderDetail} returnFolderDetail={() => setFolderDetail()} filter={filterFolderFavourites} order={orderType} orderAction={() => setOrderType(orderType === "ASC" ? "DESC" : "ASC")} />}

                <div className='d-none d-lg-block py-4 h-100'>
                    <Row className='border border-1 border-light-coal radius h-100 overflow-hidden'>
                        <Col className='d-flex flex-column h-100'>
                            <Row>
                                <Col lg={4}>
                                    <Row className='border-end border-bottom border-1 border-light-coal'>
                                        <Col lg={6} className='d-flex flex-row tt-norms-bold justify-content-center align-items-center g-0 border-end border-1 border-light-coal'>
                                            <Link href={t('pages.profile')} className="d-flex align-items-center justify-content-center gap-2 text-coal flex-grow-1 py-3 opacity-50">
                                                <FontAwesomeIcon icon="fa-solid fa-user" width={18} className='text-coal' />
                                                <span>{t('profile.header.profile')}</span>
                                            </Link>
                                        </Col>
                                        <Col lg={6} className='d-flex flex-row tt-norms-bold justify-content-center align-items-center g-0' onClick={() => {
                                            setFolderDetail();
                                            setEditOption();
                                            setEditType();
                                            closeCard(true)
                                        }}>
                                            <Link href={`${t('pages.profile')}/${t('pages.favourites')}`} className="d-flex align-items-center justify-content-center gap-2 text-coal flex-grow-1 py-3" >
                                                <FontAwesomeIcon icon="fa-solid fa-bookmark" width={14} className='text-coal fav-icon-visual hover active' />
                                                <span>{t('profile.header.favourites')}</span>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={8} className='border-bottom border-1 border-light-coal d-flex align-items-center'>
                                    <div className='d-flex flex-row justify-content-between px-5 flex-grow-1 gap-3'>
                                        <div className='d-flex align-items-center position-relative gap-3'>
                                            {((newFolder || folderDetail || editContent) && showReturn) && (
                                                <Button variant='none' className='p-0 shadow-none d-flex flex-row gap-3' onClick={() => closeCard(false)}>
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={20} height={23} className='text-coal cursor-pointer' />
                                                    <span className='tt-norms-bold clamp-1'>{actionTitle || t('profile.favourites.folders.sheetTitle.allFavourites')}</span>
                                                </Button>
                                            )}
                                            {((newFolder || folderDetail || editContent) && !showReturn) && ( // Close All
                                                <Button variant='none' className='p-0 shadow-none d-flex flex-row gap-3' onClick={() => closeCard(true)}>
                                                    <FontAwesomeIcon icon="fa-solid fa-chevron-left" width={20} height={23} className='text-coal cursor-pointer' />
                                                    <span className='tt-norms-bold clamp-1'>{actionTitle || t('profile.favourites.folders.sheetTitle.allFavourites')}</span>
                                                </Button>
                                            )}
                                            {!newFolder && !folderDetail && !editContent && <span className='tt-norms-bold clamp-1 user-select-none'>{t('profile.favourites.folders.sheetTitle.allFavourites')}</span>}
                                        </div>
                                        <div className='d-flex align-items-center position-relative gap-3'>
                                            <Form className='favourite-search' onSubmit={e => {
                                                e.preventDefault();
                                                filterFolderFavourites(inputContent);
                                            }}>
                                                <InputGroup className='overflow-hidden'>
                                                    <InputGroup.Text className="rounded-start p-0 ps-2 pe-1 bg-none"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" width={18} height={18} /></InputGroup.Text>
                                                    <Form.Control className='px-1 tt-norms' placeholder='Buscar...' onChange={(e) => setInputContent(e.target.value)} />
                                                </InputGroup>
                                            </Form>
                                            <Image src='/img/svg/sort.svg' height={24} className={`text-coal cursor-pointer sort-ico ${orderType === "ASC" ? "asc" : ""}`} onClick={() => setOrderType(orderType === "ASC" ? "DESC" : "ASC")} />
                                            {/* {(newFolder || folderDetail || editContent) && <FontAwesomeIcon icon="fa-solid fa-xmark" width={25} height={28} className='me-2 position-absolute top-50 end-0 translate-middle-y text-coal cursor-pointer' onClick={() => closeCard(true)} />} */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='content-manager'>
                                <Col md={4} className={`border-end border-1 border-light-coal left ${(folders?.length === 0 && !newFolder) ? "g-0" : ""}`.trim()}>
                                    {(folders === undefined && !newFolder) && (
                                        <Row className='g-3 py-4'>
                                            {[1, 2, 3, 4, 5, 6].map((data) =>
                                                <Col key={data} lg={6} className='d-flex'>
                                                    <FolderCard placeholder />
                                                </Col>
                                            )}
                                        </Row>
                                    )}
                                    {(!newFolder && !editContent && folders?.length > 0) && (
                                        <Row className='g-3 py-4'>
                                            <>
                                                {folders?.length > 0 && folders?.map((folder, i) => {
                                                    if (folder?.main) {
                                                        return (
                                                            <Col key={i} lg={6} className='d-flex'>
                                                                <FolderCard name={t('profile.favourites.favourites.all.text')} total={folder?.favourites?.length} image={folder?.favourites?.[folder?.favourites?.length - 1]?.imgUrl} color={folder?.color || "#ECE83A"} active={!folderDetail || folderDetail?.id === folder?.id} hideOptions
                                                                    folderDetail={() => {
                                                                        closeCard(true)
                                                                    }}
                                                                />
                                                            </Col>
                                                        )
                                                    }
                                                }
                                                )}
                                                {folders?.length > 0 && folders?.map((folder, i) => {
                                                    if (!folder?.main) {
                                                        return (
                                                            <Col key={i} lg={6} className='d-flex'>
                                                                <FolderCard name={folder?.name} total={folder?.favourites?.length} image={folder?.favourites?.[folder?.favourites?.length - 1]?.imgUrl} color={folder?.color} active={folderDetail?.id === folder?.id}
                                                                    folderDetail={() => {
                                                                        if (!(folderDetail?.id === folder?.id)) {
                                                                            setFolderDetail(folder)
                                                                            setEditOption();
                                                                            setShowReturn(false)
                                                                            setActionTitle(folder.name)
                                                                        } else {
                                                                            closeCard(true)
                                                                        }
                                                                    }}
                                                                    editFolder={() => {
                                                                        setEditContent(folder)
                                                                        setEditType("folder")
                                                                        setShow(true)
                                                                        setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                                                                        setActionTitle(!folderDetail ? folder?.name : `${t('profile.favourites.folders.menu.folder')}`)
                                                                        setEditOption()
                                                                        setShowReturn(folderDetail ? true : false)
                                                                        setFolderDetail(isDesktop && folder)
                                                                        setEditContent(folder)
                                                                        setShowFavoritesEditFolder(!folderDetail ? true : false)
                                                                    }} />
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                                {folders?.length > 0 && <Col lg={6} className='d-flex'>
                                                    <FolderCard create onClick={() => {
                                                        window.scrollTo(0, 0)
                                                        document?.getElementsByClassName('right')?.[0].scroll({ top: 0 })
                                                        setNewFolder(true)
                                                        setShow(true)
                                                        setSheetTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                                        setActionTitleList([])
                                                        setActionTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                                    }} />
                                                </Col>}

                                            </>
                                        </Row>
                                    )}
                                    {(folders?.length === 0 && !newFolder) && (
                                        <Row className='g-0'>
                                            <Col className='text-center py-5'>
                                                <div className='mb-4'>
                                                    <p className='mb-0 h5'>{t('profile.favourites.folders.newFolder.text1')}</p>
                                                    <p className='tt-norms-bolder h5'>{t('profile.favourites.folders.newFolder.text2')}</p>
                                                </div>
                                                <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' onClick={() => {
                                                    setNewFolder(true)
                                                    setShow(true)
                                                    setSheetTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                                    setActionTitleList([])
                                                    setActionTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                                }}>{t('profile.favourites.folders.newFolder.button')}</Button>
                                            </Col>
                                            <hr className='mt-0' />
                                        </Row>
                                    )}
                                    {(editContent && editType === "folder") && (
                                        <Row className='h-100 mx-2 pt-4'>
                                            <div className='desktop-folder-card px-0 d-flex flex-column flex-grow-1 h-100 overflow-hidden'>
                                                <div className='py-4 px-3' style={{ backgroundColor: `${folderDetail?.color || editContent?.color}`, color: (folderDetail?.color || editContent?.color) && `${getTextColor(folderDetail?.color || editContent?.color)}` }}>
                                                    <p className='mb-0 h5 tt-norms-bolder mb-3'>{folderDetail?.name || editContent?.name}</p>
                                                    <p className='h6 tt-norms mb-0'>{favouritesList?.length || editContent?.favourites?.length} {t('profile.favourites.folders.content')}</p>
                                                </div>
                                                <hr className='my-0' />
                                                {!editOption && <div>
                                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                                        setEditOption("edit")
                                                        setShowReturn(true)
                                                        setActionTitle(t('profile.favourites.folders.menu.edit'))
                                                    }}>
                                                        <FontAwesomeIcon icon="fa-solid fa-pencil" width={20} className='text-coal' />
                                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.menu.edit')}</span>
                                                    </div>
                                                    <hr className='my-0' />
                                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                                        setSheetTitle(`${t('profile.favourites.folders.sheetTitle.shareFolder')}`)
                                                        setActionTitle(`${t('profile.favourites.folders.sheetTitle.shareFolder')}`)
                                                        setEditOption("share")
                                                        setShowReturn(true)
                                                    }}>
                                                        <FontAwesomeIcon icon="fa-solid fa-paper-plane" width={20} className='text-coal' />
                                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.sheetTitle.shareFolder')}</span>
                                                    </div>
                                                    <hr className='my-0' />
                                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                                        setSheetTitle(`${t('profile.favourites.folders.menu.delete')}`)
                                                        setActionTitle(`${t('profile.favourites.folders.menu.delete')}`)
                                                        setShowReturn(true)
                                                        setEditOption("delete")
                                                    }}>
                                                        <FontAwesomeIcon icon="fa-solid fa-trash-can" height={20} className='text-coal' />
                                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.menu.delete')}</span>
                                                    </div>
                                                    <hr className='my-0' />
                                                </div>}
                                                {editOption === "edit" && (
                                                    <div className='overflow-auto'>
                                                        <div className='py-4 d-flex flex-column gap-3 px-4'>
                                                            <p className='tt-norms-bold text-coal mb-0'>{t('profile.favourites.folders.editFolder.step1')}</p>
                                                            <p className='tt-norms-bold text-coal mb-0'>{t('profile.favourites.folders.editFolder.step2')}</p>
                                                            <div className='d-flex flex-row align-items-center gap-3'>
                                                                <Form.Label className='text-coal tt-norms-bolder mb-0 opacity-75' htmlFor="folderColor">{t('profile.favourites.folders.editFolder.color')}</Form.Label>
                                                                <Form.Control
                                                                    type="color"
                                                                    id="folderColorDesktop"
                                                                    value={folderColor || folderColor || folderDetail?.color || editContent?.color || ""}
                                                                    onChange={(e) => handleColor(e.target.value)}
                                                                    title=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.editFolder.name')}</FormLabel>
                                                                <FormControl placeholder={editContent?.name} value={folderName || ""} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setFolderName(e.target.value)}></FormControl>
                                                            </div>
                                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!folderName} onClick={() => {
                                                                updateFolder()
                                                            }}>{t('profile.profileForm.save')}</Button>
                                                            <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                                <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                                <p className='mb-0 tt-norms-bolder'>
                                                                    {t('profile.favourites.folders.alerts.edit.success')} </p>
                                                            </Alert>
                                                            <Alert variant="none" className='d-flex flex-row align-items-center gap-3 px-0 py-0 mb-0' show={errors.create} onClose={() => setErrors({ ...errors, name: false })}>
                                                                <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                                                <p className='mb-0 tt-norms-bolder'>
                                                                    {t('profile.favourites.folders.alerts.edit.error')} </p>
                                                            </Alert>
                                                            <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={errors.name} onClose={() => setErrors({ ...errors, name: false })}>
                                                                <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                                                <p className='mb-0 tt-norms-bolder'>
                                                                    {t('profile.favourites.folders.alerts.edit.name')} </p>
                                                            </Alert>
                                                        </div>
                                                        <hr className='mt-0 mb-4' />
                                                        {(!folderDetail || folderDetail && showFavoritesEditFolder) && <div className='px-3 overflow-auto py-1'>
                                                            <Row className='g-2 gy-3 g-lg-3 mb-4'>
                                                                {favourites?.map((data, i) => (
                                                                    <Col key={i} xs={6} lg={6} className='d-flex'>
                                                                        {/* <Link href={`${t('pages.miniGuide')}/${data?.categorySlug}/${data?.slug?.[i18n.language]}`} > */}
                                                                        <div className={"text-coal d-flex flex-grow-1"}>
                                                                            <ContentCards type={data?.favouriteType} size="sm" id={data?.elementId} title={data?.title} category={""} img={data?.imgUrl} hideTop select onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)} />
                                                                        </div>
                                                                        {/* </Link> */}
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </div>}
                                                    </div>
                                                )}
                                                {editOption === "delete" && (
                                                    <>
                                                        <p className='tt-norms-bolder mb-0 px-4 mt-4'>{t('profile.favourites.folders.deleteFolder.info')}</p>
                                                        <hr className='my-4' />
                                                        <div className='px-4'>
                                                            <div className='d-flex flex-column gap-3 mb-4'>
                                                                <FormCheck type='radio' value={deleteFlag} label={t('profile.favourites.folders.deleteFolder.deleteFolder')} id="delete-folder" name='delete' className='form-radio' onChange={() => setDeleteFlag(false)} />
                                                                <FormCheck type='radio' value={!deleteFlag && true} label={t('profile.favourites.folders.deleteFolder.deleteAll')} id="delete-all" name='delete' className='form-radio' onChange={() => setDeleteFlag(true)} />
                                                            </div>
                                                            <div className='d-flex flex-column gap-3'>
                                                                <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' onClick={() => {
                                                                    setEditOption();
                                                                    setDeleteFlag();
                                                                }}>{t('profile.favourites.folders.deleteFolder.cancel')}</Button>
                                                                <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={deleteFlag === undefined} onClick={() => deleteFolder(editContent?.id || folderDetail?.id)}>{t('profile.favourites.folders.deleteFolder.delete')}</Button>
                                                                <Alert variant={"success"} className='mb-0' show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                                                                    <p>{t('profile.favourites.folders.alerts.delete.success')}</p>
                                                                    <p>{t('profile.favourites.folders.alerts.delete.delayInfo')}</p>
                                                                </Alert>
                                                                <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                                    {t('profile.favourites.folders.alerts.delete.error')} </Alert>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {editOption === "share" && (
                                                    <>
                                                        {!isEmailSend ? (
                                                            <>
                                                                <p className='tt-norms-bolder mb-0 px-4 mt-4'>{t('profile.favourites.folders.shareFolder.text')}</p>
                                                                <hr className='my-4' />
                                                                <Form className='d-flex flex-column gap-4 px-4' onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    shareFolder(editContent.id)
                                                                }}>
                                                                    <div className='d-flex flex-column gap-3'>
                                                                        <div>
                                                                            <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.shareFolder.placeholderEmail')}</FormLabel>
                                                                            <FormControl type='email' placeholder={"hola@traveltrozero.com"} value={emailShare || ""} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setEmailShare(e.target.value)}></FormControl>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex flex-column gap-3'>
                                                                        <Button type='submit' variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!emailShare}>{t('profile.favourites.folders.shareFolder.button')}</Button>
                                                                        <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                                            <p className='mb-0 tt-norms-bolder'>{t('profile.favourites.folders.alerts.share.success')}</p>
                                                                        </Alert>
                                                                        <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                                            {t('profile.favourites.folders.alerts.delete.error')}
                                                                        </Alert>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </Row>
                                    )}
                                    {newFolder &&
                                        <Row>
                                            <div className='py-5 d-flex flex-column gap-3 px-5'>
                                                <p className='tt-norms-bold text-coal mb-0 h6'>{t('profile.favourites.folders.createFolder.step1')}</p>
                                                <p className='tt-norms-bold text-coal mb-0 h6'>{t('profile.favourites.folders.createFolder.step2')}</p>
                                                <div className='d-flex flex-row align-items-center gap-3'>
                                                    <Form.Label className='text-coal tt-norms-bolder mb-0 opacity-75' htmlFor="folderColor">{t('profile.favourites.folders.createFolder.color')}</Form.Label>
                                                    <Form.Control
                                                        type="color"
                                                        id="folderColorDesktop"
                                                        value={folderColor || folderColor || folderDetail?.color || editContent?.color || "#ECE83A"}
                                                        title=""
                                                        onChange={(e) => handleColor(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.createFolder.name')}</FormLabel>
                                                    <FormControl placeholder={editContent?.name} value={folderName || ''} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setFolderName(e.target.value)}></FormControl>
                                                </div>
                                                <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!folderName} onClick={() => {
                                                    createFolder()
                                                }}>{t('profile.favourites.folders.newFolder.button')}</Button>
                                                <Alert variant="none" className='d-flex flex-column gap-3 px-0 py-0 mb-0 mt-3' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                    <div className='d-flex flex-row gap-2 justify-content-center'>
                                                        <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                        <p className='mb-0 tt-norms-bolder'>{t('profile.favourites.folders.alerts.create.success')}</p>
                                                    </div>
                                                    <small className='tt-norms-bold text-center opacity-50'>{t('profile.favourites.folders.alerts.create.delayInfo')}</small>
                                                </Alert>
                                                <Alert variant="none" className='d-flex flex-row align-items-center gap-3 px-0 py-0 mb-0 mt-3' show={errors.create} onClose={() => setErrors({ ...errors, create: false })}>
                                                    <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                                    <p className='mb-0 tt-norms-bolder'>
                                                        {t('profile.favourites.folders.alerts.create.error')} </p>
                                                </Alert>
                                                <Alert variant="none" className='d-flex flex-row align-items-center gap-3 px-0 py-0 mb-0 mt-3' show={errors.name} onClose={() => setErrors({ ...errors, name: false })}>
                                                    <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                                    <p className='mb-0 tt-norms-bolder'>
                                                        {t('profile.favourites.folders.alerts.create.name')} </p>
                                                </Alert>
                                            </div>
                                        </Row>
                                    }
                                    {(editContent && editType === "favourite") && <Row className='h-100 mx-2 pt-4'>
                                        <div className='desktop-folder-card px-0 d-flex flex-column flex-grow-1 h-100 overflow-hidden'>
                                            <div className='head content position-relative'>
                                                <div className='d-flex flex-grow-1 overlay py-4 px-3 d-flex flex-column align-items-center justify-content-center position-relative'>
                                                    <p className='mb-0 h4 tt-norms-bolder mb-1 text-white text-center'>{editContent?.title}</p>
                                                </div>
                                                <Image src={editContent?.imgUrl || "/img/no-photo.svg"} className='position-absolute top-50 start-50 translate-middle' />
                                            </div>
                                            <hr className='my-0' />
                                            {!editOption && <div>
                                                <div className=' px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                                    setEditOption("addFolder")
                                                    setActionTitle(t('profile.favourites.favourites.menu.add'))
                                                    setShowReturn(true);
                                                }}>
                                                    <FontAwesomeIcon icon="fa-solid fa-add" width={20} className='text-coal' />
                                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.add')}</span>
                                                </div>

                                                <hr className='my-0' />
                                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                                    setEditOption("share")
                                                    setActionTitle(t('profile.favourites.favourites.menu.share'))
                                                    setShowReturn(true);
                                                }}>
                                                    <FontAwesomeIcon icon="fa-solid fa-paper-plane" width={20} className='text-coal' />
                                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.share')}</span>
                                                </div>
                                                <hr className='my-0' />
                                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                                    setEditOption("deleteFolder")
                                                    setActionTitle(t('profile.favourites.favourites.menu.delete'))
                                                    setShowReturn(true);
                                                }}>
                                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" height={20} className='text-coal' />
                                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.delete')}</span>
                                                </div>
                                                <hr className='my-0' />
                                            </div>}
                                            {editOption === "addFolder" && (
                                                <>
                                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => setEditOption("addFolder")}>
                                                        <FontAwesomeIcon icon="fa-solid fa-add" width={20} className='text-coal' />
                                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.add')}</span>
                                                    </div>
                                                    <hr className='my-0' />
                                                    <Alert variant="none" className='d-flex flex-row gap-3 px-3 py-3 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                        <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                        <p className='mb-0 tt-norms-bolder'>
                                                            {t('profile.favourites.folders.alerts.edit.success')} </p>
                                                    </Alert>
                                                    {success.create && <hr className='my-0' />}
                                                    <div className='py-4 px-3 overflow-auto'>
                                                        <p className='mb-4 tt-norms-bolder'>{folders?.length > 1 ? t('profile.favourites.favourites.addFavourite.info') : t('profile.favourites.favourites.addFavourite.noFolder')}</p>
                                                        {folders?.length > 1 && (
                                                            <div className='d-flex flex-column gap-3 folder-select'>
                                                                {folders?.map((folder, i) => {
                                                                    if (!folder?.main) {
                                                                        return (
                                                                            <div key={i} className='d-flex flex-row align-items-center border border-1 border-light-coal flex-grow-1 radius overflow-hidden' style={{ backgroundColor: `${folder?.color}` }} onClick={() => handleFolderSelect(folder?.id)}>
                                                                                <div className='d-flex p-3 h-100'>
                                                                                    <FormCheck checked={selectedContent?.includes(folder?.id) || ""} className='check-border' readOnly />
                                                                                </div>
                                                                                <div className='d-flex flex-grow-1 px-2 py-3 folder-select'>
                                                                                    <p className='mb-0'>{folder?.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                                {selectedContent?.length === 0 && <p className='mb-0 tt-norms-bolder small text-center'>{t('profile.favourites.favourites.addFavourite.warning')}</p>}
                                                                <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={selectedContent?.length === 0} onClick={() => updateFavourite(editContent?.id)}>{t('profile.favourites.favourites.addFavourite.button')}</Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {editOption === "share" && (
                                                <>
                                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option'>
                                                        <FontAwesomeIcon icon="fa-solid fa-paper-plane" width={20} className='text-coal' />
                                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.share')}</span>
                                                    </div>
                                                    <hr className='my-0' />
                                                    {!isEmailSend ? (
                                                        <>
                                                            <p className='tt-norms-bolder mb-0 px-4 mt-4'>{t('profile.favourites.folders.shareFolder.text')}</p>
                                                            <hr className='my-4' />
                                                            <Form className='d-flex flex-column gap-4 px-4' onSubmit={(e) => {
                                                                e.preventDefault();
                                                                shareFolder(editContent.id, true)
                                                            }}>
                                                                <div className='d-flex flex-column gap-3'>
                                                                    <div>
                                                                        <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.shareFolder.placeholderEmail')}</FormLabel>
                                                                        <FormControl type='email' placeholder={"hola@traveltrozero.com"} value={emailShare || ""} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setEmailShare(e.target.value)}></FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex flex-column gap-3'>
                                                                    <Button type='submit' variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!emailShare}>{t('profile.favourites.folders.shareFolder.button')}</Button>
                                                                    <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                                        <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                                        <p className='mb-0 tt-norms-bolder'>{t('profile.favourites.folders.alerts.share.success')}</p>
                                                                    </Alert>
                                                                    <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                                        {t('profile.favourites.folders.alerts.delete.error')}
                                                                    </Alert>
                                                                </div>
                                                            </Form>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                            {editOption === "deleteFolder" && (
                                                <>
                                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option'>
                                                        <FontAwesomeIcon icon="fa-solid fa-trash-can" height={20} className='text-coal' />
                                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.delete')}</span>
                                                    </div>
                                                    <hr className='my-0' />
                                                    <p className='mb-0 py-4 px-3 tt-norms-bolder'>{t('profile.favourites.favourites.deleteFavourite.info')}</p>
                                                    <hr className='my-0' />
                                                    <div className='py-4 px-3'>
                                                        <div className='d-flex flex-column gap-3 mb-4'>
                                                            <FormCheck type='radio' value={!deleteFlag && true} label={t('profile.favourites.favourites.deleteFavourite.label')} id='delete-fav' name='delete' className='form-radio' onChange={() => setDeleteFlag(true)} />
                                                        </div>
                                                        <div className='d-flex flex-column gap-3'>
                                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' onClick={() => {
                                                                setEditOption();
                                                                setDeleteFlag(undefined);
                                                            }}>{t('profile.favourites.folders.deleteFolder.cancel')}</Button>
                                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={deleteFlag === undefined} onClick={() => deleteFavorite(editContent?.id)}>{t('profile.favourites.folders.deleteFolder.delete')}</Button>
                                                            <Alert variant={"success"} className='mb-0' show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                                                                <p>{t('profile.favourites.folders.alerts.delete.success')}</p>
                                                                <p>{t('profile.favourites.folders.alerts.delete.delayInfo')}</p>
                                                            </Alert>
                                                            <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                                {t('profile.favourites.folders.alerts.delete.error')} </Alert>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Row>}
                                </Col>
                                <Col lg={8} className='flex-row right'>
                                    {favourites === undefined && (
                                        <Row className='g-3 px-5 py-4'>
                                            {[1, 2, 3, 4, 5, 6].map((i) => (
                                                <Col key={i} xs={6} md={4} className='d-flex'>
                                                    <div className={"text-coal d-flex flex-grow-1"}>
                                                        <ContentCardPlaceholder key={i} size="lg" />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                    {(!folderDetail && (mainFolderData || favourites)) && (
                                        <Row className='g-3 px-5 py-4'>
                                            {(folderFilter || mainFolderData?.favourites || favourites)?.map((data, i) => (
                                                <Col key={i} xs={6} md={4} className='d-flex'>
                                                    <div className={"text-coal d-flex flex-grow-1"}>
                                                        <ContentCards
                                                            type={data?.favouriteType}
                                                            size="lg"
                                                            id={data?.elementId}
                                                            title={data?.title}
                                                            category={data?.categoryImage}
                                                            img={data?.imgUrl} hideTop={newFolder}
                                                            select={newFolder}
                                                            onSelect={() => handleContentSelect(data?.id)}
                                                            selected={selectedContent?.includes(data?.id)}
                                                            onClick={() => {
                                                                setEditContent(data)
                                                                setEditType("favourite")
                                                                setShow(false)
                                                                setEditOption();
                                                                setActionTitle(data?.title)
                                                            }}
                                                            options={!newFolder}
                                                            optionAction={() => {
                                                                setEditContent(data)
                                                                setEditType("favourite")
                                                                setShow(false)
                                                                setEditOption();
                                                                setActionTitleList([])
                                                                setShowReturn(false)
                                                                setActionTitle(data?.title)
                                                            }}
                                                            refreshFavourites={() => {
                                                                getFavourites()
                                                                getFolders();
                                                            }}
                                                            link={`${data?.favouriteType === "ARTICLE" ? t('pages.guide') : data?.favouriteType === "MINIGUIDE" ? t('pages.miniGuide') : t('pages.explore')}/${data?.categorySlug}/${data?.slug}`}
                                                        />
                                                    </div>
                                                </Col>
                                            ))}
                                            {folderFilter?.length === 0 && (
                                                <p className='tt-norms-semi-bolder text-center opacity-75 h5'>{t('profile.favourites.favourites.noFavourites')}</p>
                                            )}
                                        </Row>
                                    )}
                                    {(folderDetail && editOption !== "edit") && (<Row className='g-3 px-5 py-4'>
                                        {folderFilter === undefined && favouritesList === undefined ? (
                                            [1, 2, 3, 4, 5, 6].map((i) => (
                                                <Col key={i} xs={6} md={4} className='d-flex'>
                                                    <div className={"text-coal d-flex flex-grow-1"}>
                                                        <ContentCardPlaceholder key={i} size="lg" />
                                                    </div>
                                                </Col>
                                            ))
                                        ) : (
                                            (folderFilter || favouritesList)?.map((favourite, i) =>
                                                <Col key={i} md={4}>
                                                    <ContentCards
                                                        type={favourite?.favouriteType}
                                                        key={i}
                                                        size="lg"
                                                        id={favourite?.elementId}
                                                        title={favourite?.title}
                                                        category={favourite?.categoryImage}
                                                        img={favourite?.imgUrl}
                                                        hideTop={editOption === "edit"}
                                                        select={editOption === "edit" && !showFavoritesEditFolder}
                                                        onSelect={() => handleContentSelect(favourite?.id)}
                                                        selected={selectedContent?.includes(favourite?.id)}
                                                        options
                                                        optionAction={() => {
                                                            setEditContent(favourite)
                                                            setEditType("favourite")
                                                            setShow(true)
                                                            setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setEditOption();
                                                            setShowReturn(true);
                                                            setActionTitle(favourite?.title)
                                                            actionTitleList?.length > 1 && setActionTitleList([actionTitleList?.[0]])
                                                        }}
                                                        onClick={() => {
                                                            setEditContent(favourite)
                                                            setEditType("favourite")
                                                            setShow(true)
                                                            setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setEditOption();
                                                            setShowReturn(true);
                                                        }}
                                                        refreshFavourites={() => {
                                                            getFavourites();
                                                            getFolders();
                                                        }}
                                                        link={`${favourite?.favouriteType === "ARTICLE" ? t('pages.guide') : favourite?.favouriteType === "MINIGUIDE" ? t('pages.miniGuide') : t('pages.explore')}/${favourite?.categorySlug}/${favourite?.slug}`}
                                                    // onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)}
                                                    />
                                                </Col>
                                            )
                                        )}
                                    </Row>)}
                                    {(folderDetail && editOption === "edit") && (<Row className='g-3 px-5 py-4'>
                                        {(folderFilter || favouritesList)?.map((favourite, i) => (
                                            <Col key={i} md={4}>
                                                <ContentCards
                                                    type={favourite?.favouriteType}
                                                    key={i}
                                                    size="lg"
                                                    id={favourite?.elementId}
                                                    title={favourite?.title}
                                                    category={favourite?.categoryImage}
                                                    img={favourite?.imgUrl}
                                                    hideTop={editOption === "edit"}
                                                    select={editOption === "edit" && !showFavoritesEditFolder}
                                                    onSelect={() => handleContentSelect(favourite?.id)}
                                                    selected={selectedContent?.includes(favourite?.id)}
                                                    options
                                                    optionAction={() => {
                                                        setEditContent(favourite)
                                                        setActionTitle(favourite?.title)
                                                    }}
                                                    refreshFavourites={() => {
                                                        getFavourites();
                                                        getFolders();
                                                    }}
                                                    link={`${favourite?.favouriteType === "ARTICLE" ? t('pages.guide') : favourite?.favouriteType === "MINIGUIDE" ? t('pages.miniGuide') : t('pages.explore')}/${favourite?.categorySlug}/${favourite?.slug}`}
                                                // onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)}
                                                />
                                            </Col>
                                        ))}
                                    </Row>)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div >

                {!folderDetail && (
                    <div className='my-4 mb-lg-5 d-block d-lg-none'>
                        {folders?.length <= 0 && <div className='d-flex flex-column justify-content-center align-items-center radius py-4 px-5 card-shadow mb-4'>
                            <p className='mb-4 tt-norms-bolder h6 text-center'>{t('profile.favourites.folders.newFolder.text1')} {t('profile.favourites.folders.newFolder.text2')}</p>
                            <Button variant='none' className='btn-coal text-white px-4 text-white radius' onClick={() => {
                                setShow(true)
                                setSheetTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                setActionTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                setNewFolder(true)
                            }}>{t('profile.favourites.folders.newFolder.button')}</Button>
                        </div>}
                        <Row className='g-2 gy-3'>
                            {folders === undefined && (
                                [1, 2, 3, 4, 5, 6].map((data) =>
                                    <Col key={data} xs={6} md={4} className='d-flex'>
                                        <FolderCard placeholder />
                                    </Col>
                                )
                            )}
                            {/* {(folders?.length > 0 && favourites?.length > 0) && <Col xs={6} md={4} className='d-flex'>
                                <div className='folder-card radius overflow-hidden card-shadow flex-grow-1' onClick={() => setFolderDetail({
                                    name: "Favoritos",
                                    favourites: favourites.slice()?.reverse(),
                                    allFavourites: true
                                })}>
                                    <div className='header position-relative bg-primary'>
                                        <div className='overlay d-flex align-items-center justify-content-center p-4 text-center text-coal'>
                                            <p className='tt-norms-bolder h6'>{t('profile.favourites.favourites.all.title')}</p>
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <p className='tt-norms-bolder small mb-0'>{t('profile.favourites.favourites.all.text')}</p>
                                        <p className='tt-norms-bolder small mb-0'>{favourites?.length} {t('profile.favourites.folders.content')}</p>
                                    </div>
                                </div>
                            </Col>} */}
                            {folders?.length > 0 && folders?.map((folder, i) => {
                                if (folder?.main) {
                                    return (
                                        <Col key={i} xs={6} md={4} className='d-flex'>
                                            <FolderCard name={t('profile.favourites.favourites.all.text')} total={favourites?.length} image={folder?.favourites?.[folder?.favourites?.length - 1]?.imgUrl} color={folder?.color || "#ECE83A"} hideOptions
                                                folderDetail={() => {
                                                    setFolderDetail(folder)
                                                    setEditType("folder")
                                                    setEditOption();
                                                    setActionTitle(folder.name)
                                                }}
                                                editFolder={() => {
                                                    setEditContent(folder)
                                                    setEditType("folder")
                                                    setShow(true)
                                                    setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                                                    setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                                                    setEditOption()
                                                }} />
                                        </Col>
                                    )
                                }
                            })}
                            {folders?.length > 0 && folders?.map((folder, i) => {
                                if (!folder?.main) {
                                    return (
                                        <Col key={i} xs={6} md={4} className='d-flex'>
                                            <FolderCard name={folder?.name} total={folder?.favourites?.length} image={folder?.favourites?.[folder?.favourites?.length - 1]?.imgUrl} color={folder?.color}
                                                folderDetail={() => {
                                                    setFolderDetail(folder)
                                                    setEditType("folder")
                                                    setEditOption();
                                                    setActionTitle(folder.name)
                                                }}
                                                editFolder={() => {
                                                    setEditContent(folder)
                                                    setEditType("folder")
                                                    setShow(true)
                                                    setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                                                    setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                                                    setEditOption()
                                                }} />
                                        </Col>
                                    )
                                }
                            })}
                            {folders?.length > 0 && <Col xs={6} md={4} className='d-flex'>
                                <FolderCard create onClick={() => {
                                    setNewFolder(true)
                                    setShow(true)
                                    setSheetTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                    setActionTitle(t('profile.favourites.folders.sheetTitle.createFolder'))
                                }} />
                            </Col>}
                            {folders?.length <= 0 && favourites?.map((data, i) => (
                                <Col key={i} xs={6} md={4} className='d-flex'>
                                    <div className={"text-coal d-flex flex-grow-1"}>
                                        <ContentCards type={data?.favouriteType} size="sm" title={data?.title} category={""} img={data?.imgUrl} />
                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </div>
                )
                }
                {
                    folderDetail && (
                        <div className='d-lg-none'>
                            <div className='mb-4 position-relative'>
                                <FontAwesomeIcon icon={"fa-solid fa-chevron-left"} className='d-flex position-absolute top-50 start-0 translate-middle-y text-coal cursor-pointer' width={12} onClick={() => {
                                    setFolderDetail();
                                    setEditOption();
                                    setEditType();
                                    closeCard(true)
                                }} />
                                <p className='h6 tt-norms-bolder px-4 text-center mb-0'>{!folderDetail?.main ? folderDetail?.name : t('profile.favourites.favourites.all.text')}</p>
                            </div>
                            <Row className='g-2 gy-3 mb-4'>
                                {folderFilter === undefined && favouritesList === undefined ? (
                                    [1, 2, 3, 4, 5, 6].map((i) => (
                                        <Col key={i} xs={6} md={4} className='d-flex'>
                                            <div className={"text-coal d-flex flex-grow-1"}>
                                                <ContentCardPlaceholder key={i} size="sm" />
                                            </div>
                                        </Col>
                                    ))
                                ) : (
                                    folderFilter ? (
                                        folderFilter?.length > 0 ? (
                                            folderFilter?.map((favourite, i) =>
                                                <Col key={i} xs={6} md={4}>
                                                    <ContentCards
                                                        type={favourite?.favouriteType}
                                                        size="sm"
                                                        id={favourite?.elementId}
                                                        title={favourite?.title}
                                                        category={favourite?.categoryImage}
                                                        img={favourite?.imgUrl}
                                                        options
                                                        optionAction={() => {
                                                            setEditContent(favourite)
                                                            setShow(true)
                                                            setEditType("favourite")
                                                            setActionTitle(favourite?.title)
                                                        }}
                                                        refreshFavourites={() => {
                                                            getFavourites();
                                                            getFolders();
                                                        }}
                                                        link={`${favourite?.favouriteType === "ARTICLE" ? t('pages.guide') : favourite?.favouriteType === "MINIGUIDE" ? t('pages.miniGuide') : t('pages.explore')}/${favourite?.categorySlug}/${favourite?.slug}`}
                                                    // onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)}
                                                    />
                                                </Col>
                                            )
                                        ) : (
                                            <p className='tt-norms-semi-bolder text-center opacity-75'>{t('profile.favourites.favourites.noFavourites')}</p>
                                        )
                                    ) : (
                                        favouritesList?.length > 0 ? (
                                            favouritesList?.map((favourite, i) => (
                                                <Col key={i} xs={6} md={4}>
                                                    <ContentCards
                                                        type={favourite?.favouriteType}
                                                        key={i}
                                                        size="sm"
                                                        id={favourite?.elementId}
                                                        title={favourite?.title}
                                                        category={favourite?.categoryImage}
                                                        img={favourite?.imgUrl}
                                                        options
                                                        optionAction={() => {
                                                            setEditContent(favourite)
                                                            setShow(true)
                                                            setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setEditType("favourite")
                                                            setEditOption()
                                                            setShowReturn(true)
                                                            setActionTitle(favourite?.title)
                                                        }}
                                                        onClick={() => {
                                                            setEditContent(favourite)
                                                            setShow(true)
                                                            setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                                                            setEditType("favourite")
                                                            setEditOption()
                                                            setShowReturn(true)
                                                        }}
                                                        refreshFavourites={() => {
                                                            getFavourites();
                                                            getFolders();
                                                        }}
                                                        link={`${favourite?.favouriteType === "ARTICLE" ? t('pages.guide') : favourite?.favouriteType === "MINIGUIDE" ? t('pages.miniGuide') : t('pages.explore')}/${favourite?.categorySlug}/${favourite?.slug}`}
                                                    // onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)}
                                                    />
                                                </Col>
                                            ))) : favouritesList?.length === 0 && (
                                                <p className='tt-norms-semi-bolder h6 text-center opacity-75'>{t('profile.favourites.favourites.noResults')}</p>
                                            )
                                    )
                                )}
                            </Row>
                        </div>
                    )
                }
            </Container >
            {/* Mobile sheet for folders management */}
            {
                (!folderDetail || folderDetail && showFavoritesEditFolder) && <MobileSheet title={sheetTitle} stickyHead show={show} returnShow={editOption}
                    close={() => {
                        setShow(false)
                        setEditType();
                        setTimeout(() => {
                            setEditOption();
                        }, 625);
                        setShowReturn(false)
                        clearTimeout(delayCreate)
                        setDelayCreate()
                    }}
                    returnAction={() => {
                        setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                        setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFolder')}`)
                        setEditOption();
                        closeCard();
                    }}
                >
                    {editContent && (
                        <div className='folder-card px-0 mx-3 mt-4 d-flex flex-column flex-grow-1 container-lg mx-lg-auto'>
                            <div className='py-4 px-3 head' style={{ backgroundColor: `${editContent?.color || folderDetail?.color}`, color: (folderDetail?.color || editContent?.color) && `${getTextColor(folderDetail?.color || editContent?.color)}` }}>
                                <p className='mb-0 h5 tt-norms-bolder mb-1'>{editContent?.name}</p>
                                <p className='h6 tt-norms mb-0'>{editContent?.favourites?.length} {t('profile.favourites.folders.content')}</p>
                            </div>
                            <hr className='my-0' />
                            {!editOption && <div>
                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                    setSheetTitle(t('profile.favourites.folders.sheetTitle.editContent'))
                                    setActionTitle(t('profile.favourites.folders.sheetTitle.editContent'))
                                    setEditOption("edit")
                                    setShowReturn(true)
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-pencil" width={20} className='text-coal' />
                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.menu.edit')}</span>
                                </div>
                                <hr className='my-0' />
                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                    setSheetTitle(t('profile.favourites.folders.sheetTitle.shareFolder'))
                                    setActionTitle(t('profile.favourites.folders.sheetTitle.shareFolder'))
                                    setEditOption("share")
                                    setShowReturn(true)
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-paper-plane" width={20} className='text-coal' />
                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.menu.share')}</span>
                                </div>
                                <hr className='my-0' />
                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                    setSheetTitle("Eliminar carpeta")
                                    setActionTitle("Eliminar carpeta")
                                    setEditOption("delete")
                                    setShowReturn(true)
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" height={20} className='text-coal' />
                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.menu.delete')}</span>
                                </div>
                                <hr className='my-0' />
                                {/* {!editContent?.main && ( // Main Covert Option
                                <>
                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => setEditOption("main")}>
                                        <FontAwesomeIcon icon="fa-solid fa-crown" height={20} className='text-coal' />
                                        <span className='tt-norms-bold h6 mb-0'>Convertir en principal</span>
                                    </div>
                                    <hr className='my-0' />
                                </>
                            )} */}
                            </div>}
                            {editOption === "edit" && (
                                <>
                                    <div className='py-4 d-flex flex-column gap-3 px-4'>
                                        <p className='tt-norms-bold text-coal mb-0'>{t('profile.favourites.folders.editFolder.step1')}</p>
                                        <p className='tt-norms-bold text-coal mb-0'>{t('profile.favourites.folders.editFolder.step2')}</p>
                                        <div className='d-flex flex-row align-items-center gap-3'>
                                            <Form.Label className='text-coal tt-norms-bolder mb-0 opacity-75' htmlFor="folderColor">{t('profile.favourites.folders.editFolder.color')}</Form.Label>
                                            <Form.Control
                                                type="color"
                                                id="folderColorMobile"
                                                value={folderColor || folderColor || folderDetail?.color || editContent?.color || ""}
                                                title=""
                                                onChange={(e) => handleColor(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.editFolder.name')}</FormLabel>
                                            <FormControl placeholder={editContent?.name} value={folderName || ""} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setFolderName(e.target.value)}></FormControl>
                                        </div>
                                        <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!folderName} onClick={() => {
                                            updateFolder()
                                        }}>{t('profile.favourites.folders.editFolder.button')}</Button>
                                        <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                            <p className='mb-0 tt-norms-bolder'>
                                                {t('profile.favourites.folders.alerts.edit.success')} </p>
                                        </Alert>
                                        <Alert variant="none" className='d-flex flex-row align-items-center gap-3 px-0 py-0 mb-0' show={errors.create} onClose={() => setErrors({ ...errors, name: false })}>
                                            <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                            <p className='mb-0 tt-norms-bolder'>
                                                {t('profile.favourites.folders.alerts.create.error')} </p>
                                        </Alert>
                                        <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={errors.name} onClose={() => setErrors({ ...errors, name: false })}>
                                            <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                            <p className='mb-0 tt-norms-bolder'>
                                                {t('profile.favourites.folders.alerts.edit.name')} </p>
                                        </Alert>
                                    </div>
                                    <hr className='mt-0 mb-4' />
                                    <div className='px-3'>
                                        <Row className='g-2 gy-3 g-lg-3 mb-4'>
                                            {favourites?.map((data, i) => (
                                                <Col key={i} xs={6} md={4} className='d-flex'>
                                                    {/* <Link href={`${t('pages.miniGuide')}/${data?.categorySlug}/${data?.slug?.[i18n.language]}`} > */}
                                                    <div className={"text-coal d-flex flex-grow-1"}>
                                                        <ContentCards type={data?.favouriteType} size="sm" id={data?.elementId} title={data?.title} category={""} img={data?.imgUrl} hideTop select onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)} />
                                                    </div>
                                                    {/* </Link> */}
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </>
                            )}
                            {editOption === "delete" && (
                                <>
                                    <p className='tt-norms-bolder mb-0 px-4 mt-4'>{t('profile.favourites.folders.deleteFolder.info')}</p>
                                    <hr className='my-4' />
                                    <div className='px-4'>
                                        <div className='d-flex flex-column gap-3 mb-4'>
                                            <FormCheck type='radio' value={deleteFlag} label={t('profile.favourites.folders.deleteFolder.deleteFolder')} id="folder" name='delete' className='form-radio' onChange={() => setDeleteFlag(false)} />
                                            <FormCheck type='radio' value={!deleteFlag && true} label={t('profile.favourites.folders.deleteFolder.deleteAll')} id="folder-favs" name='delete' className='form-radio' onChange={() => setDeleteFlag(true)} />
                                        </div>
                                        <div className='d-flex flex-column gap-3'>
                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' onClick={() => {
                                                setEditOption();
                                                setDeleteFlag(undefined);
                                            }}>{t('profile.favourites.folders.deleteFolder.cancel')}</Button>
                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={deleteFlag === undefined} onClick={() => deleteFolder(editContent?.id)}>{t('profile.favourites.folders.deleteFolder.delete')}</Button>
                                            <Alert variant={"success"} className='mb-0' show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                                                <p>{t('profile.favourites.folders.alerts.delete.success')}</p>
                                                <p>{t('profile.favourites.folders.alerts.delete.delayInfo')}</p>
                                            </Alert>
                                            <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                {t('profile.favourites.folders.alerts.delete.error')}
                                            </Alert>
                                        </div>
                                    </div>

                                </>
                            )}
                            {editOption === "share" && (
                                <>
                                    <p className='tt-norms-bolder mb-0 px-4 mt-4'>{t('profile.favourites.folders.shareFolder.text')}</p>
                                    <hr className='my-4' />
                                    <Form className='d-flex flex-column gap-4 px-4' onSubmit={(e) => {
                                        e.preventDefault();
                                        shareFolder(editContent.id)
                                    }}>
                                        <div className='d-flex flex-column gap-3'>
                                            <div>
                                                <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.shareFolder.placeholderEmail')}</FormLabel>
                                                <FormControl type='email' placeholder={"hola@traveltrozero.com"} value={emailShare || ""} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setEmailShare(e.target.value)}></FormControl>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column gap-3'>
                                            <Button type='submit' variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!emailShare}>{t('profile.favourites.folders.shareFolder.button')}</Button>
                                            <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                <p className='mb-0 tt-norms-bolder'>{t('profile.favourites.folders.alerts.share.success')}</p>
                                            </Alert>
                                            <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                {t('profile.favourites.folders.alerts.delete.error')}
                                            </Alert>
                                        </div>
                                    </Form>
                                </>
                            )}
                            {/* {editOption === "main" && ( // Main folder edit screen
                            <>
                                <p className='tt-norms-bolder mb-0 px-4 mt-4'>¿Quieres convertir esta carpeta en la principa?</p>
                                <p className='tt-norms-bold mb-0 px-4 mt-4'>La carpeta <b>{mainFolder?.name}</b> dejará de serlo</p>
                                <hr className='my-4' />
                                <div className='px-4'>
                                    <div className='d-flex flex-column gap-3'>
                                        <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' onClick={() => {
                                            updateFolder(true);
                                        }}>Convertir</Button>
                                        <Alert variant={"success"} className='mb-0' show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                                            <p>¡Carpeta borrada correctamente!</p>
                                            <p>Esta pantalla se cerrará en unos segundos</p>
                                        </Alert>
                                        <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                            Error al borrar la carpeta
                                        </Alert>
                                    </div>
                                </div>

                            </>
                        )} */}
                        </div>
                    )}
                    {newFolder && (
                        <>
                            <Form className='' onSubmit={() => createFolder()}>
                                <div className='d-lg-none py-4 d-flex flex-column gap-3 px-4'>
                                    <p className='tt-norms-bold text-coal mb-0'>{t('profile.favourites.folders.createFolder.step1')}</p>
                                    <p className='tt-norms-bold text-coal mb-0'>{t('profile.favourites.folders.createFolder.step2')}</p>
                                    <div className='d-flex flex-row align-items-center gap-3'>
                                        <Form.Label className='text-coal tt-norms-bolder mb-0 opacity-75' htmlFor="folderColor">{t('profile.favourites.folders.createFolder.color')}</Form.Label>
                                        <Form.Control
                                            type="color"
                                            id="folderColorMobile"
                                            value={folderColor || folderColor || folderDetail?.color || editContent?.color || "#ECE83A"}
                                            title=""
                                            onChange={(e) => handleColor(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.createFolder.name')}</FormLabel>
                                        <FormControl placeholder={editContent?.name} value={folderName || ''} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setFolderName(e.target.value)}></FormControl>
                                    </div>
                                    <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!folderName} onClick={() => {
                                        createFolder()
                                    }}>{t('profile.favourites.folders.createFolder.createButton')}</Button>
                                    <Alert variant="none" className='d-flex flex-column align-items-center justify-content-center px-0 py-0 mb-0 mt-3 me-3' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                        <div className='d-flex flex-row gap-3 mb-2'>
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                            <p className='mb-0 tt-norms-bolder'>
                                                {t('profile.favourites.folders.alerts.create.success')}
                                            </p>
                                        </div>
                                        <small className='tt-norms-bold text-center opacity-50'>{t('profile.favourites.folders.alerts.create.delayInfo')}</small>
                                    </Alert>
                                    <Alert variant="none" className='d-flex flex-row align-items-center justify-content-center gap-3 px-0 py-0 mb-0 mt-3 me-3' show={errors.create} onClose={() => setErrors({ ...errors, create: false })}>
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                        <p className='mb-0 tt-norms-bolder'>
                                            {t('profile.favourites.folders.alerts.create.error')}
                                        </p>
                                    </Alert>
                                    <Alert variant="none" className='d-flex flex-row align-items-center justify-content-center gap-3 px-0 py-0 mb-0 mt-3 me-3' show={errors.name} onClose={() => setErrors({ ...errors, name: false })}>
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" className='text-coal' width={15} />
                                        <p className='mb-0 tt-norms-bolder'>
                                            {t('profile.favourites.folders.alerts.create.name')}
                                        </p>
                                    </Alert>
                                </div>
                            </Form>
                            <hr className='mt-0 mb-4' />
                            <div className='px-3'>
                                <Row className='g-2 gy-3 g-lg-3 mb-4'>
                                    {favourites?.map((data, i) => (
                                        <Col key={i} xs={6} md={4} className='d-flex'>
                                            <div className={"text-coal d-flex flex-grow-1"}>
                                                <ContentCards type={data?.favouriteType} size="sm" id={data?.elementId} title={data?.title} category={""} img={data?.imgUrl} hideTop select onSelect={() => handleContentSelect(data?.id)} selected={selectedContent?.includes(data?.id)} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </>
                    )}
                </MobileSheet>
            }
            {
                folderDetail && (
                    <MobileSheet
                        title={sheetTitle}
                        stickyHead show={show}
                        close={() => {
                            setShow(false)
                            setEditType("folder")
                            setTimeout(() => {
                                setEditOption();
                            }, 625);
                            setShowReturn(false)
                            clearTimeout(delayCreate)
                            setDelayCreate()
                            actionTitleList?.length > 1 && setActionTitleList([actionTitleList?.[0]])
                            actionTitleList?.length > 1 && setActionTitle(folderDetail?.name)
                        }}
                        returnShow={editOption}
                        returnAction={() => {
                            setSheetTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                            setActionTitle(`${t('profile.favourites.folders.sheetTitle.editFavourites')}`)
                            setEditOption("")
                            closeCard()
                        }}>
                        <div className='folder-card px-0 mx-3 mt-4 d-flex flex-column flex-grow-1 container-lg mx-lg-auto overflow-hidden'>
                            <div className='head content position-relative'>
                                <div className='d-flex flex-grow-1 overlay py-4 px-3 d-flex flex-column align-items-center justify-content-center position-relative'>
                                    <p className='mb-0 h2 tt-norms-bolder mb-1 text-white'>{editContent?.title}</p>
                                </div>
                                <Image src={editContent?.imgUrl || "/img/no-photo.svg"} className='position-absolute top-50 start-50 translate-middle' />
                            </div>
                            <hr className='my-0' />
                            {!editOption && <div>
                                <div className=' px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                    setSheetTitle(`${t('profile.favourites.folders.sheetTitle.addContent')}`)
                                    setActionTitle(`${t('profile.favourites.folders.sheetTitle.addContent')}`)
                                    setEditOption("addFolder")
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-add" width={20} className='text-coal' />
                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.folders.sheetTitle.addContent')}</span>
                                </div>

                                <hr className='my-0' />
                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                    setSheetTitle("Compartir favorito")
                                    setActionTitle("Compartir favorito")
                                    setEditOption("share")
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-paper-plane" width={20} className='text-coal' />
                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.share')}</span>
                                </div>
                                <hr className='my-0' />
                                <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => {
                                    setSheetTitle("Eliminar favorito")
                                    setActionTitle("Eliminar favorito")
                                    setEditOption("deleteFolder")
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" height={20} className='text-coal' />
                                    <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.delete')}</span>
                                </div>
                                <hr className='my-0' />
                            </div>}
                            {editOption === "addFolder" && (
                                <>
                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option' onClick={() => setEditOption("addFolder")}>
                                        <FontAwesomeIcon icon="fa-solid fa-add" width={20} className='text-coal' />
                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.add')}</span>
                                    </div>
                                    <hr className='my-0' />
                                    <Alert variant="none" className='d-flex flex-row gap-3 px-3 py-3 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                        <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                        <p className='mb-0 tt-norms-bolder'>
                                            {t('profile.favourites.folders.alerts.edit.success')}
                                        </p>
                                    </Alert>
                                    {success.create && <hr className='my-0' />}
                                    <div className='py-4 px-3'>
                                        <p className='mb-4 tt-norms-bolder'>{folders?.length > 1 ? t('profile.favourites.favourites.addFavourite.info') : t('profile.favourites.favourites.addFavourite.noFolder')}</p>
                                        <div className='d-flex flex-column gap-3 folder-select'>
                                            {folders?.map((folder, i) => {
                                                if (!folder?.main) {
                                                    return (
                                                        <div key={i} className='d-flex flex-row align-items-center border border-1 border-light-coal flex-grow-1 radius overflow-hidden' style={{ backgroundColor: `${folder?.color}` }} onClick={() => handleFolderSelect(folder?.id)}>
                                                            <div className='d-flex p-3 h-100'>
                                                                <FormCheck checked={selectedContent?.includes(folder?.id) || ""} className='check-border' readOnly />
                                                            </div>
                                                            <div className='d-flex flex-grow-1 px-2 py-3 folder-select'>
                                                                <p className='mb-0'>{folder?.name}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                            {selectedContent?.length === 0 && <p className='mb-0 tt-norms-bolder small text-center'>{t('profile.favourites.favourites.addFavourite.warning')}</p>}
                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={selectedContent?.length === 0} onClick={() => updateFavourite(editContent?.id)}>{t('profile.favourites.favourites.addFavourite.button')}</Button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {editOption === "share" && (
                                <>
                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option'>
                                        <FontAwesomeIcon icon="fa-solid fa-paper-plane" width={20} className='text-coal' />
                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.share')}</span>
                                    </div>
                                    <hr className='my-0' />
                                    {!isEmailSend ? (
                                        <>
                                            <p className='tt-norms-bolder mb-0 px-4 mt-4'>{t('profile.favourites.folders.shareFolder.text')}</p>
                                            <hr className='my-4' />
                                            <Form className='d-flex flex-column gap-4 px-4' onSubmit={(e) => {
                                                e.preventDefault();
                                                shareFolder(editContent.id, true)
                                            }}>
                                                <div className='d-flex flex-column gap-3'>
                                                    <div>
                                                        <FormLabel className='text-coal tt-norms-bolder mb-0 opacity-75'>{t('profile.favourites.folders.shareFolder.placeholderEmail')}</FormLabel>
                                                        <FormControl type='email' placeholder={"hola@traveltrozero.com"} value={emailShare || ""} className='border-1 border-light-coal shadow-none tt-norms py-2' onChange={(e) => setEmailShare(e.target.value)}></FormControl>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column gap-3'>
                                                    <Button type='submit' variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={!emailShare}>{t('profile.favourites.folders.shareFolder.button')}</Button>
                                                    <Alert variant="none" className='d-flex flex-row gap-3 px-0 py-0 mb-0' show={success.create} onClose={() => setSuccess({ ...success, create: false })}>
                                                        <FontAwesomeIcon icon="fa-solid fa-check" className='text-coal' width={20} />
                                                        <p className='mb-0 tt-norms-bolder'>{t('profile.favourites.folders.alerts.share.success')}</p>
                                                    </Alert>
                                                    <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                        {t('profile.favourites.folders.alerts.delete.error')}
                                                    </Alert>
                                                </div>
                                            </Form>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            {editOption === "deleteFolder" && (
                                <>
                                    <div className='px-3 d-flex flex-row gap-3 align-items-center py-3 option'>
                                        <FontAwesomeIcon icon="fa-solid fa-trash-can" height={20} className='text-coal' />
                                        <span className='tt-norms-bold h6 mb-0'>{t('profile.favourites.favourites.menu.delete')}</span>
                                    </div>
                                    <hr className='my-0' />
                                    <p className='mb-0 py-4 px-3 tt-norms-bolder'>{t('profile.favourites.favourites.deleteFavourite.info')}</p>
                                    <hr className='my-0' />
                                    <div className='py-4 px-3'>
                                        <div className='d-flex flex-column gap-3 mb-4'>
                                            <FormCheck type='radio' value={!deleteFlag && true} label={t('profile.favourites.favourites.deleteFavourite.label')} id='folder-content' name='delete' className='form-radio' onChange={() => setDeleteFlag(true)} />
                                        </div>
                                        <div className='d-flex flex-column gap-3'>
                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' onClick={() => {
                                                setEditOption();
                                                setDeleteFlag(undefined);
                                            }}>{t('profile.favourites.folders.deleteFolder.cancel')}</Button>
                                            <Button variant='none' className='tt-norms-bold btn-coal text-white shadow-none radius align-self-center px-4 sheet-button' disabled={deleteFlag === undefined} onClick={() => deleteFavorite(editContent?.id)}>{t('profile.favourites.folders.deleteFolder.delete')}</Button>
                                            <Alert variant={"success"} className='mb-0' show={success.delete} dismissible onClose={() => setSuccess({ ...success, delete: false })}>
                                                <p>{t('profile.favourites.folders.alerts.delete.success')}</p>
                                                <p>{t('profile.favourites.folders.alerts.delete.delayInfo')}</p>
                                            </Alert>
                                            <Alert variant={"danger"} className='mb-0' show={errors.delete} dismissible onClose={() => setErrors({ ...errors, delete: false })}>
                                                {t('profile.favourites.folders.alerts.delete.error')}
                                            </Alert>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </MobileSheet>
                )
            }
        </>
    )
}

export default Folder