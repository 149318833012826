import React, { useContext, useState } from 'react'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Image, Row, Form, Alert } from "react-bootstrap"
import { useSearchParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { FavouritesContext } from '../../Contexts';
import { useForm } from 'react-hook-form';
import Link from '../../components/Link';
import { LoginSocialApple, LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import RegisterService from '../../services/register.service';
import AuthService from '../../services/auth.service';
import TokenService from '../../services/token.service';
import UserService from '../../services/user.service';
import UserDataService from '../../services/userData.service';
import StickyBox from 'react-sticky-box';

function NewRegister() {
    const params = useSearchParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const { state: { shareToken, user, categories }, state, update } = useContext(AppContext)
    const { favouriteState: { favoriteRedirect }, favouriteState, favouriteUpdate } = useContext(FavouritesContext);

    const { register, handleSubmit, reset, resetField } = useForm({
        mode: "all",
        defaultValues: {}
    })

    const [email, setEmail] = useState();
    const [sendConfirm, setSendConfirm] = useState(false);

    const [errors, setErrors] = useState({
        login: false,
        server: false
    });

    const formSubmit = async (data) => {
        setErrors({
            password: false,
            email: false,
            server: false
        })

        const registerData = {
            firstName: data.name || data.email.split("@")?.[0],
            lastName: data.surname1,
            secondLastName: data.surname2,
            email: data.email,
            password: data.password,
            generalNotifications: data.comunication,
            commercialNotifications: data.offers,
            validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
        }

        if (data.password === data.confirmPassword) {
            try {
                await RegisterService.sendEmail(registerData)
                setEmail(data.email)
                setSendConfirm(true)
                reset()
            } catch ({ response }) {
                if (response?.data?.errorCode) {
                    switch (response.data.errorCode) {
                        case 13002:
                            setErrors({
                                password: false,
                                email: true,
                                server: false
                            })
                            reset();
                            break;
                        default:
                            setErrors({ ...errors, server: true });
                    }
                }
            }
        } else {
            setErrors({
                password: true,
                email: false,
                server: false
            })
        }
    }

    const socialLogin = async (provider, data) => {
        setErrors({
            server: false
        });
        try {
            const token = await AuthService.socialLoginToken({
                provider,
                token: data.access_token || data.accessToken || data.authorization.id_token,
                firstName: data.user !== undefined ? data.user.name.firstName : null,
                lastName: data.user !== undefined ? data.user.name.lastName : null
            })
            await TokenService.setUser(token)
            const user = await UserService.userInfo();
            const userShareToken = TokenService.getShareFolderToken();

            update({
                ...state,
                user
            });
            await UserDataService.updateUserInLogin();

            if (shareToken && !userShareToken) {
                let encodedParam = encodeURIComponent(shareToken);
                await TokenService.setShareFolderToken(encodedParam);
            }

            if (i18n.language !== UserDataService.getLanguagePreference()) {
                window.location.href = `${window.origin}/${UserDataService.getLanguagePreference()}/${shareToken ? t('pages.profile') : ""}`;
            } else {
                navigate(favoriteRedirect || `/${i18n.language}/${(shareToken || userShareToken) ? t('pages.profile') : ""}`)
            }
        } catch (error) {
            setErrors({ ...errors, server: true });
        }
    }

    const reSendMail = async () => {
        try {
            await RegisterService.reSendEmail({
                email,
                validationUrl: `${window.location.origin}/${i18n.language}/${t('pages.validation')}`
            })
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <SeoHelmet title={t('seo.login.title')} metaTitle={t('seo.login.metaTitle')} metaDescription={t('seo.login.metaDescription')} ogImage={'/img/home-left-1.png'} robots />
            <main>
                <Container fluid className='px-0 bg-beige login-container'>
                    <Row className='g-0'>
                        <Col lg={5} className='background-container d-none d-lg-block'>
                            <StickyBox offsetTop={60} className='bg-dark-brown'>
                                <div className='d-flex flex-column align-items-start gap-4 justify-content-center mx-auto register-content w-75'>
                                    <Image src='/img/svg/new-logo.svg' className='mb-4 align-self-start' width={350} />
                                    <h1 className='fitzgerald-regular fs-1 text-white'>{t('login.leftCard.title1')}</h1>
                                    <div className='d-flex flex-column gap-3'>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content1.bold')}</span> {t('register.leftCard.advantages.content1.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content2.bold')}</span> {t('register.leftCard.advantages.content2.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content3.bold')}</span> {t('register.leftCard.advantages.content3.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content4.bold')}</span> {t('register.leftCard.advantages.content4.text')}</p>
                                        <p className='mb-0 fs-4 inter-medium text-white'><span className='text-primary'>{t('register.leftCard.advantages.content5.bold')}</span> {t('register.leftCard.advantages.content5.text')}</p>
                                    </div>
                                    <p className='mb-0 fs-4 inter-regular text-white'>{t('register.leftCard.subtitle')}</p>
                                </div>
                            </StickyBox>
                        </Col>
                        <Col xs={12} lg={7} className='d-flex justify-content-center px-4 px-lg-0'>
                            <div className='d-flex flex-column align-items-center justify-content-center h-100 login-content py-4'>
                                {!sendConfirm ? (
                                    <>
                                        <h1 className='fitzgerald-regular fs-1 mb-4'>{t('register.card.title1')} {t('register.card.title2')}</h1>
                                        <Form onSubmit={handleSubmit(formSubmit)} className='d-flex flex-column'>
                                            <div className='d-flex flex-column gap-2'>
                                                <Form.Group>
                                                    <Form.Control type="email" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.email')} {...register("email", {
                                                        required: true, pattern: {
                                                            value: /\S+@\S+/
                                                        },
                                                    })} required />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control type="password" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.password')} {...register("password", { required: true })} required />
                                                </Form.Group>
                                                <Form.Group className='mb-4'>
                                                    <Form.Control type="password" className='inter-regular fs-6' size='lg' placeholder={t('modals.subscribe.form.confirm')} {...register("confirmPassword", { required: true })} required />
                                                </Form.Group>
                                                <Button type='submit' className='btn-dark-brown-outline inter-regular text-uppercase letter-spacing-1 py-3 mb-3'>{t('register.card.button')}</Button>
                                                {errors?.email &&
                                                    <Alert variant='danger' className='text-center'>{t('register.errors.email')}</Alert>
                                                }
                                                {errors?.password &&
                                                    <Alert variant='danger' className='text-center'>{t('register.errors.password')}</Alert>
                                                }
                                                <div className='d-flex flex-column w-100 mb-4'>
                                                    <p className='mb-0 inter-regular text-coal opacity-50 fs-5 mb-4 text-center'>{t('login.card.socialText')}</p>
                                                    <LoginSocialGoogle
                                                        isOnlyGetToken
                                                        client_id={'319399754923-seppuac5mkupbjv2hninb8tqutiael4i.apps.googleusercontent.com'}
                                                        scope="profile email"
                                                        onResolve={({ provider, data }) => {
                                                            socialLogin(provider, data)
                                                        }}
                                                        onReject={(err) => {
                                                            console.error(err)
                                                        }}
                                                        className='mb-2 w-100'
                                                    >
                                                        <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center'>
                                                            <div className='google-logo logo me-2'></div>
                                                            <span className="small radius opacity-75">{t('modals.subscribe.social.google')}</span>
                                                        </Button>
                                                    </LoginSocialGoogle>
                                                    <LoginSocialFacebook
                                                        appId={'827246235309311'}
                                                        fieldsProfile={
                                                            'id,first_name,last_name,middle_name,name,short_name,email'
                                                        }
                                                        onResolve={({ provider, data }) => {
                                                            socialLogin(provider, data)
                                                        }}
                                                        onReject={(err) => {
                                                            console.error(err)
                                                        }}
                                                        className='mb-2 w-100'
                                                    >
                                                        <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center'>
                                                            <div className='facebook-logo me-2'></div>
                                                            <span className="tt-norms-semi-bolder small radius opacity-75">{t('modals.subscribe.social.facebook')}</span>
                                                        </Button>
                                                    </LoginSocialFacebook>
                                                    <LoginSocialApple
                                                        client_id={'com.proadata.traveltozero.signin'}
                                                        scope={'name email'}
                                                        onResolve={({ provider, data }) => {
                                                            socialLogin(provider, data)
                                                        }}
                                                        redirect_uri={`${window.origin}/${UserDataService.getLanguagePreference()}/login`}
                                                        onReject={(err) => {
                                                            console.error(err)
                                                        }}
                                                        className='w-100'
                                                    >
                                                        <Button variant='none' className='d-flex flex-row justify-content-center border-1 border-dark-brown inter-semi-bold py-3 shadow-none w-100 text-center'>
                                                            <div className='apple-logo me-2'></div>
                                                            <span className="small radius opacity-75">{t('modals.subscribe.social.apple')}</span>
                                                        </Button>
                                                    </LoginSocialApple>
                                                </div>

                                                <Form.Group className="d-flex justify-content-center align-self-start gap-2 justify-content-start" controlId="comunication">
                                                    <Form.Check id='comunication' className='d-flex flex-row align-items-end align-self-center user-select-none mb-1 laurel-checkbox lg' type="checkbox" {...register('comunication')} />
                                                    <label for={"comunication"} className='inter-regular text-dark-brown user-select-none align-self-center'>{t('register.card.generalComunications')}</label>
                                                </Form.Group>

                                                <Form.Group className="d-flex justify-content-center align-self-start gap-2 justify-content-start mb-2" controlId="offers">
                                                    <Form.Check id='offers' className='d-flex flex-row align-items-end align-self-center gap-2 inter-regular text-dark-brown user-select-none mb-1 laurel-checkbox lg' type="checkbox" {...register('offers')} />
                                                    <label for={"offers"} className='inter-regular text-dark-brown user-select-none align-self-center'>{t('register.card.customOffers')}</label>
                                                </Form.Group>

                                                <p className="mb-0 inter-regular text-coal opacity-75">{t('register.card.textInformation1')} <Link href={`${t('pages.privacy')}`} target={"_blank"} className="text-black text-decoration-underline">{t('register.card.textInformation2')}</Link></p>
                                            </div>
                                        </Form>
                                    </>
                                ) : (
                                    <>
                                        <h1 className='fitzgerald-regular fs-1 mb-4'>{t('register.card.confirm.title')}</h1>
                                        <p className="inter-regular mb-2">{t('register.card.confirm.text1')} <b>{email}</b> {t('register.card.confirm.text2')}</p>
                                        <p className="inter-regular mb-0 align-self-start">{t('register.card.confirm.text3')}</p>
                                        <p className="inter-medium text-center mt-5 mb-4">{t('register.card.confirm.text4')}</p>
                                        <div className="text-center">
                                            <Button className="btn-dark-brown-outline inter-medium px-5 radius" variant="primary" onClick={() => reSendMail()}>
                                                {t('register.card.confirm.button')}
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main >
        </>
    )
}

export default NewRegister