import React from 'react'

function MustNav({ theme }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 25 25" className={`must ${theme}`}>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0003 12.9177L12.3831 19.1649L7.77413 12.9577V23.3359H1.38086V1.33594H6.74317L12.3858 8.96822L18.0859 1.33594H23.3809V23.3359H17.0003V12.9177Z" />
        </svg>
    )
}

export default MustNav