import api from './api';

const getVillas = (data) => api.get(`villas`, data).then(res => res.data);

const getVillaDetail = (slug, data) => api.get(`villas/${slug}`, data).then(res => res.data)

const getBudget = (id, data) => api.get(`villas/budget/${id}`, data).then(res => res.data)

const VillasService = {
    getVillas,
    getVillaDetail,
    getBudget
}

export default VillasService;