import React, { useCallback, useContext, useEffect } from 'react'
import { useMap, APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps'
import APP_CONF from '../../scripts/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useRef } from 'react';
import FilterRangeIcon from '../../components/svg-component/FilterRangeIcon';
import { Alert, Button, Col, Collapse, Container, Form, FormCheck, Image, Modal, Pagination, Row } from 'react-bootstrap';
import DestinationSelect from '../../components/selects/DestinationSelect';
import GuestSelect from '../../components/selects/GuestSelect';
import CheckInOutSelect from '../../components/selects/CheckInOutSelect';
import VillasFeatures from '../../components/villas/VillasFeatures';
import FilterGrid from '../../components/svg-component/FilterGrid';
import FilterList from '../../components/svg-component/FilterList';
import VillaCard from '../../components/cards/VillaCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import MapMenuIcon from '../../components/svg-component/MapMenuIcon';
import TzoTouchSheet from '../../components/mobile-sheet/TzoTouchSheet';
import VillaMobileFilter from '../../components/selects/VillaMobileFilter';
import TzoSheet from '../../components/mobile-sheet/TzoSheet';
import Link from '../../components/Link';
import { useTranslation } from 'react-i18next';
import VillasService from '../../services/villas.service';
import { VillasContext } from '../../Contexts';
import { useForm } from 'react-hook-form';
import VillaCardPlaceholder from '../../components/cards/VillaCardPlaceholder';
import { AppContext } from '../../AppContext';
import ReactPaginate from 'react-paginate';
import AuthService from '../../services/auth.service';
import AnalyticService from '../../services/analytic.service';

const MapWrapper = ({ data, updateBounds, villaStates, isDesktop }) => {
	const map = useMap();

	const [isTilesLoaded, setIsTilesLoaded] = useState(false)
	const onMapChange = useRef()
	const [mapBounds, setMapBounds] = useState();

	const mapFilterTrigger = () => {
		clearTimeout(onMapChange.current)
		onMapChange.current = setTimeout(() => {
			setMapBounds(map.getBounds())
		}, 750);
	}

	useEffect(() => {
		if (mapBounds) {
			const boundPadding = paddedBounds(map, isDesktop ? 45 : 105, isDesktop ? 45 : 150, isDesktop ? 75 : 60, isDesktop ? (75 + 520) : 60);

			updateBounds({
				ne: {
					lat: boundPadding.getNorthEast().lat(),
					lng: boundPadding.getNorthEast().lng(),
				},
				sw: {
					lat: boundPadding.getSouthWest().lat(),
					lng: boundPadding.getSouthWest().lng()
				},
			})
		}
	}, [mapBounds])

	return (
		<Map
			mapId={"bf51a910020fa25a"}
			defaultCenter={{ lat: 39.570230, lng: 2.650172 }}
			defaultZoom={10}
			gestureHandling={"greedy"}
			disableDefaultUI={true}
			onTilesLoaded={() => setIsTilesLoaded(true)}
			onZoomChanged={() => mapFilterTrigger()}
			onDragstart={() => clearTimeout(onMapChange.current)}
			onDragend={() => mapFilterTrigger()}
			minZoom={4}
		>
			{isTilesLoaded && <MarkersHandler2 data={data} villaStatesUtils={villaStates} isDesktop={isDesktop} />}
		</Map>
	)
}

const MarkersHandler2 = ({ data, villaStatesUtils }) => {
	const { villasState: { filter } } = useContext(VillasContext);
	const { t } = useTranslation();

	const handleMarkerClick = (marker) => {
		if (villaStatesUtils?.villaDetail?.id === marker?.id) {
			villaStatesUtils?.setVillaDetail()
			villaStatesUtils?.showTouchSheet(false)
		} else {
			villaStatesUtils?.setVillaDetail(marker)
			villaStatesUtils?.setDesktopBooking()
			villaStatesUtils?.showTouchSheet(true)
		}
	}

	return (
		<>
			{data?.map((marker, i) => (
				<AdvancedMarker
					className={`price-marker ${(filter?.startDate && filter?.endDate) ? "extend" : ""}`.trim()}
					position={{ lat: marker.latitude, lng: marker.longitude }}
					key={i}
					onClick={() => {
						handleMarkerClick(marker)
					}}
				>
					{marker?.nightPrice ? (
						<div className='d-flex flex-column lh-sm'>
							{(filter?.startDate && filter?.endDate) && <span className='inter-medium float-tag'>{t('villas.map.prices.from')}</span>}
							<span className='inter-semi-bold h6 mb-0'>{(!filter?.startDate && !filter?.endDate) && `${t('villas.map.prices.from')}`} {marker?.nightPrice}€{filter?.startDate && filter?.endDate && ` / ${t('villas.map.prices.nights')}`}</span>
						</div>
					) : (
						<span className='inter-medium h6'>{marker?.title}</span>
					)}
				</AdvancedMarker>
			))}
		</>
	)
}

function paddedBounds(map, npad, spad, epad, wpad) {
	let SW = map.getBounds().getSouthWest();
	let NE = map.getBounds().getNorthEast();
	let topRight = map.getProjection().fromLatLngToPoint(NE);
	let bottomLeft = map.getProjection().fromLatLngToPoint(SW);
	let scale = Math.pow(2, map.getZoom());

	let SWtopoint = map.getProjection().fromLatLngToPoint(SW);
	let SWpoint = new window.google.maps.Point(((SWtopoint.x - bottomLeft.x) * scale) + wpad, ((SWtopoint.y - topRight.y) * scale) - spad);
	let SWworld = new window.google.maps.Point(SWpoint.x / scale + bottomLeft.x, SWpoint.y / scale + topRight.y);
	let pt1 = map.getProjection().fromPointToLatLng(SWworld);

	let NEtopoint = map.getProjection().fromLatLngToPoint(NE);
	let NEpoint = new window.google.maps.Point(((NEtopoint.x - bottomLeft.x) * scale) - epad, ((NEtopoint.y - topRight.y) * scale) + npad);
	let NEworld = new window.google.maps.Point(NEpoint.x / scale + bottomLeft.x, NEpoint.y / scale + topRight.y);
	let pt2 = map.getProjection().fromPointToLatLng(NEworld);

	return new window.google.maps.LatLngBounds(pt1, pt2);
}

const sortByProximity = ((villas, villaDetail, quantity) => {

	const distance = (coor1, coor2, userUbication) => {

		const latitude = parseFloat(coor2.latitude) - parseFloat(coor1.latitude);
		const longitude = parseFloat(coor2.longitude) - parseFloat(coor1.longitude);

		return Math.sqrt((latitude * latitude) + (longitude * longitude));
	};

	const sortByDistance = (villas, point) => {
		const villasList = [...villas];
		let newVillasList = [];

		const sorter = (a, b) => distance(a, point) - distance(b, point);
		villasList.sort(sorter);

		villasList.forEach((villa) => {
			if (quantity && newVillasList?.length === quantity) return

			if (window?.google?.maps?.geometry?.spherical?.computeDistanceBetween({ lat: villaDetail?.latitude, lng: villaDetail?.longitude }, { lat: villa.latitude, lng: villa.longitude }) <= (99999999 * 1000) && villa?.id !== villaDetail?.id) { // 9999*... => search km
				newVillasList.push(villa);
			}
		})

		return newVillasList?.length > 0 ? newVillasList : villasList;
	};

	return sortByDistance(villas, { latitude: villaDetail?.latitude, longitude: villaDetail?.longitude });

})

function VillaMap() {

	const { register, handleSubmit, reset, formState, getValues, control } = useForm({
		mode: "all",
		// defaultValues: {},
	});

	const { t } = useTranslation();
	const { villasState: { map, features, equipVilla, filter, layoutCards }, villasState, villasUpdate } = useContext(VillasContext);
	const { state: { categories, showBottomNav }, state, update } = useContext(AppContext);
	let windowOpen = window.open; // Avoid popup blocker

	const [isMapLoaded, setIsMapLoaded] = useState(false);

	const [villas, setVillas] = useState();
	const [villaList, setVillaList] = useState();
	const [villaFilterList, setVillaFilterList] = useState();
	const [villaPagination, setVillaPagination] = useState({
		currentPage: 1
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [mapBounds, setMapBounds] = useState();
	const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992)
	const [errors, setErrors] = useState({
		stay_not_met: false,
		max_guests_exceeded: false,
		property_not_available: false,
		invalid_dates: false,
		server: false
	});

	const [searchedGuest, setSearchedGuest] = useState();
	const [equip, setEquip] = useState({
		wifi: equipVilla?.wifi || false,
		barbeque: equipVilla?.barbeque || false,
		television: equipVilla?.television || false,
		garden: equipVilla?.garden || false,
		view: equipVilla?.view || false,
		chargingPoint: equipVilla?.chargingPoint || false,
		pets: equipVilla?.pets || false
	})
	const [selectedEquip, setSelectedEquip] = useState();

	const [expand, setExpand] = useState(false);
	const [villaDetail, setVillaDetail] = useState();
	const [proximityVillas, setProximityVillas] = useState();
	const [cardScrollPosition, setCardScrollPosition] = useState();
	const [sheetScrollPosition, setSheetScrollPosition] = useState();
	const [sheetShow, setSheetShow] = useState();
	const [budgetSheetShow, setBudgetSheetShow] = useState(false);
	const [touchSheetOpen, shetTouchSheetOpen] = useState(false);

	const detailedGalleryRef = useRef(null);
	const villaProximitySliderRef = useRef(null);
	const detailedGalleryMobileRef = useRef(null);
	const villaProximitySliderMobileRef = useRef(null);

	const [villaExtendedDetail, setVillaExtendedDetail] = useState()
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [villaBudget, setVillaBudget] = useState();
	const [totalDays, setTotalDays] = useState();
	const [localFilter, setLocalFilter] = useState()
	const [modalShow, setModalShow] = useState(false);
	const [desktopBooking, setDesktopBooking] = useState();
	const [acceptTerms, setAcceptTerms] = useState(false);

	const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 })
	const [bedrooms, setBedrooms] = useState(features?.bedrooms || 0);
	const [beds, setBeds] = useState(features?.beds || 0);
	const [baths, setBaths] = useState(features?.baths || 0);

	const delayRef = useRef();
	const filterTimeout = useRef();

	const getVillas = async () => {
		try {
			let equipments = {}

			if (equip) {
				Object.keys(equip).forEach(propery => {
					if (equip?.[propery]) {
						equipments[propery] = true
					}
				});
			}

			setSelectedEquip(equipments)

			const data = await VillasService.getVillas({
				length: 20,
				offset: villaPagination.currentPage - 1,
				startDate: filter.startDate && handleTimeZone(filter?.startDate),
				endDate: filter.endDate && handleTimeZone(filter?.endDate),
				numBedroom: bedrooms || null,
				numBathroom: baths || null,
				numBed: beds || null,
				guests: filter?.totalGuest || null,
				minLong: mapBounds?.sw?.lng || null,
				maxLong: mapBounds?.ne?.lng || null,
				minLat: mapBounds?.sw?.lat || null,
				maxLat: mapBounds?.ne?.lat || null,
				...equipments
			})

			setVillas(data.content)
			setVillaList(data.content)

			setVillaPagination({
				...villaPagination,
				totalElements: data?.totalElements,
				totalPages: Math.ceil(data?.totalElements / 20),
				pageable: data?.pageable
			})
			setSearchedGuest(filter?.totalGuest)

			if (sheetShow) {
				document.getElementsByClassName('mobile-sheet')?.[0]?.classList?.replace("active", "close")

				setTimeout(() => {
					setSheetShow(false)
				}, 625);
			}

		} catch (error) {

		}
	}

	const getVillaDetail = async (slug) => {
		try {
			const data = await VillasService.getVillaDetail(slug)
			setVillaExtendedDetail(data)
		} catch (error) {
			console.error(error);
		}
	}

	const getVillaBudget = async () => {
		setErrors({
			stay_not_met: false,
			max_guests_exceeded: false,
			property_not_available: false,
			invalid_dates: false,
			server: false
		})

		try {
			const data = await VillasService.getBudget(villaDetail?.id, {
				guests: filter?.totalGuest,
				startDate: filter.startDate ? handleTimeZone(filter?.startDate) : handleTimeZone(new Date()),
				endDate: filter.endDate ? handleTimeZone(filter?.endDate) : handleTimeZone(new Date())
			})

			setVillaBudget(data);
			setTotalDays(datediff(filter?.startDate, filter?.endDate))

			if (data?.errorCode) {
				switch (data.errorCode) {
					case "017":
						setErrors({
							stay_not_met: true,
							max_guests_exceeded: false,
							property_not_available: false,
							invalid_dates: false,
							server: false
						});
						break;
					case "016":
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: true,
							property_not_available: false,
							invalid_dates: false,
							server: false
						});
						break;
					case "015":
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: false,
							property_not_available: true,
							invalid_dates: false,
							server: false
						});
						break;
					case "008":
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: false,
							property_not_available: false,
							invalid_dates: true,
							server: false
						});
						break;
					default:
						setErrors({
							stay_not_met: false,
							max_guests_exceeded: false,
							property_not_available: false,
							invalid_dates: false,
							server: true
						});
				}
			}
		} catch (error) {

		}
	}

	const saveAnalyticBudget = async () => {
		try {
			var user = AuthService.getCurrentUser();
			if (user ? true : false) {
				await AnalyticService.saveAnalyticBudget(
					{
						accommodationId: villaDetail?.id,
						guests: filter?.totalGuest,
						dateFrom: handleTimeZone(filter?.startDate),
						dateTo: handleTimeZone(filter?.endDate),
						amount: villaBudget?.amount
					}
				)
			} else {
				await AnalyticService.saveAnonymousAnalyticBudget(
					{
						accommodationId: villaDetail?.id,
						guests: filter?.totalGuest,
						dateFrom: handleTimeZone(filter?.startDate),
						dateTo: handleTimeZone(filter?.endDate),
						amount: villaBudget?.amount
					}
				)
			}

			setModalShow(false)
			// windowOpen().location = villaBudget?.bookingUrl
			window.location.assign(villaBudget?.bookingUrl)
		} catch (error) {
		}
	}


	function datediff(first, second) {
		return Math.round((second - first) / (1000 * 60 * 60 * 24));
	}

	const handleTimeZone = (date) => {
		const offset = date?.getTimezoneOffset()
		date = new Date(date?.getTime() - (offset * 60 * 1000))
		return date?.toISOString().split('T')[0]
	}

	const automatizeBooking = () => {
		setModalShow(false)
		saveAnalyticBudget()
	}

	const villaSort = (data) => {
		setVillas();

		let sortData = [...data]

		// Features

		sortData = sortData?.filter((villa) => villa?.numBedroom >= features?.bedrooms)
		sortData = sortData?.filter((villa) => villa?.numBathroom >= features?.baths)
		sortData = sortData?.filter((villa) => villa?.totalBed >= features?.beds)

		// Equipment

		if (equipVilla?.wifi) sortData = sortData?.filter((villa) => villa?.wifi === true)
		if (equipVilla?.barbeque) sortData = sortData?.filter((villa) => villa?.barbecue === true)
		if (equipVilla?.television) sortData = sortData?.filter((villa) => villa?.television === true)
		if (equipVilla?.garden) sortData = sortData?.filter((villa) => villa?.garden === true)
		if (equipVilla?.view) sortData = sortData?.filter((villa) => villa?.seaView === true)
		if (equipVilla?.chargingPoint) sortData = sortData?.filter((villa) => villa?.chargingPoint === true)
		if (equipVilla?.pets) sortData = sortData?.filter((villa) => villa?.pet?.type === "ALLOWED")

		return sortData
	}

	const handlePrice = useCallback((price) => {
		clearTimeout(delayRef.current)

		delayRef.current = setTimeout(() => {
			setPriceRange({ min: price?.[0], max: price?.[1] })
		}, 25);
	}, [delayRef, priceRange])

	useEffect(() => {
		let waitResize;

		const checkDesktop = () => {
			setIsDesktop(window.innerWidth >= 992)
		}

		window.addEventListener('resize', () => {
			clearTimeout(waitResize);
			waitResize = setTimeout(checkDesktop, 100)
		})

		return () => window.removeEventListener('resize', checkDesktop)
	}, [isDesktop])

	useEffect(() => {
		if (villaDetail) {
			const mapSize = document.getElementsByClassName('villa-map')?.[0]?.clientWidth;
			const cardSize = document.getElementsByClassName('map-menu')?.[0]?.clientWidth;

			const bounds = new window.google.maps.LatLngBounds()
			let customCenterMap = ((mapSize / 2) - ((mapSize / 2) - (cardSize + 32))) / 2 // (map center) - (map center - (desktop card + left padding)) / 2 [get center of remain map space]

			bounds.extend({ lat: villaDetail?.latitude, lng: villaDetail?.longitude })

			setProximityVillas(sortByProximity(villaList, villaDetail, 6));
		} else if (villaList) {
			const bounds = new window.google.maps.LatLngBounds()

			villaList.forEach((marker) => {
				bounds.extend({ lat: marker?.latitude || marker?.lat, lng: marker?.longitude || marker?.lng })
			})

			const cardSize = document.getElementsByClassName('map-menu')?.[0]?.clientWidth;

			// map.fitBounds(bounds)
		}
	}, [villaDetail])

	useEffect(() => {
		if (villaDetail) {
			if (filter?.startDate && filter?.endDate) {
				getVillaBudget();
			}

			getVillaDetail(villaDetail?.slug)
		}

		if (!villaDetail && cardScrollPosition) {
			document.getElementsByClassName('villa-content')?.[0].scrollTo({ top: cardScrollPosition })
		} else if (!villaDetail && sheetScrollPosition) {
			document.getElementsByClassName('sheet-content')?.[0].scrollTo({ top: sheetScrollPosition })
		} else if (villaDetail) {
			document.getElementsByClassName('villa-content')?.[0].scrollTo({ top: 0 })
			document.getElementsByClassName('sheet-content')?.[0].scrollTo({ top: 0 })

			setTimeout(() => {
				detailedGalleryRef?.current?.swiper?.slideTo(0)
				villaProximitySliderRef?.current?.swiper?.slideTo(0)
				detailedGalleryMobileRef?.current?.swiper?.slideTo(0)
				villaProximitySliderMobileRef?.current?.swiper?.slideTo(0)
			}, 100);
		} else {
			document.getElementsByClassName('villa-content')?.[0].scrollTo({ top: 0 })
			document.getElementsByClassName('sheet-content')?.[0].scrollTo({ top: 0 })
		}
	}, [villaDetail])

	useEffect(() => {
		getVillas();
	}, [])

	useEffect(() => {
		villasUpdate({
			equipVilla: {
				wifi: equip?.wifi,
				barbeque: equip?.barbeque,
				television: equip?.television,
				garden: equip?.garden,
				view: equip?.view,
				chargingPoint: equip?.chargingPoint,
				pets: equip?.pets
			}
		})

	}, [equip])

	useEffect(() => {
		if (!villas && !filterTimeout.current) return

		filterTimeout.current = setTimeout(() => {
			setCardScrollPosition();
			setVillas()
		}, 1000);

		return () => {
			clearTimeout(filterTimeout.current)
		}
	}, [features, equipVilla])

	useEffect(() => {
		if (villaPagination?.currentPage && villas) {
			setVillaList();
		}
	}, [villaPagination?.currentPage])

	useEffect(() => {
		if (!villaList) {
			document.getElementsByClassName("villa-content")?.[0].scrollTo({ top: 0, behavior: 'instant' })
			document.getElementsByClassName("sheet-content")?.[0].scrollTo({ top: 0, behavior: 'instant' })
			getVillas();
		}
	}, [villaList])

	useEffect(() => {
		if (!mapBounds) return

		if (villaPagination?.currentPage !== 1) {
			setVillaPagination({
				...villaPagination,
				currentPage: 1
			})
		} else {
			getVillas();
		}
	}, [mapBounds])

	useEffect(() => {
		if (((localFilter?.startDate?.getTime() !== filter?.startDate?.getTime() || localFilter?.endDate?.getTime() !== filter?.endDate?.getTime()) || localFilter?.totalGuest !== filter?.totalGuest) && villaDetail) {
			if (filter?.startDate && filter?.endDate && filter?.totalGuest && filter?.totalGuest) {
				filterTimeout.current = setTimeout(() => {
					getVillaBudget();
				}, 1000);
			}
		} else if ((!filter?.startDate || !filter?.endDate)) {
			setVillaBudget();
		}

		filterTimeout.current = setTimeout(() => {
			getVillas();
		}, 1000);

		setLocalFilter({
			...filter
		})

		return () => {
			clearTimeout(filterTimeout.current)
		}
	}, [filter])

	useEffect(() => {
		if (showBottomNav && categories) {
			update({
				...state,
				showBottomNav: false
			})
		}
	}, [state])

	return (
		<main className='villa-map'>
			<div className='d-none d-lg-flex flex-row map-menu border border-light-coal overflow-hidden card-shadow'>
				<div className={`filter-menu d-flex flex-column flex-grow-1 h-100 ${expand ? "active" : ""}`.trim()}>
					<div className={`d-flex flex-row justify-content-between align-items-center z-index-2 bg-white options`.trim()}>
						<Button variant='none' className={`shadow-none p-3 border-0 filter-range-button`.trim()} onClick={() => setExpand(!expand)}>
							<FilterRangeIcon width={28} height={28} active={expand} />
						</Button>
						<Button variant='none' className='py-3 px-3 shadow-none border-0' onClick={() => setExpand(!expand)}>
							<div variant='none' className={`d-flex align-items-center justify-content-center shadow-none border border-1 border-light-coal p-1 collapse ${expand ? "active" : ""}`.trim()}>
								<FontAwesomeIcon icon={"fa-solid fa-minus"} width={14} />
							</div>
						</Button>
					</div>
					<Form onSubmit={handleSubmit(() => {
						document.getElementsByClassName("villa-content")?.[0].scrollTo({ top: 0 })
						setVillaDetail();
						setVillaExtendedDetail()
						// parseInt(currentPage) === 1 ? getVillas() : setCurrentPage(1)
						getVillas()
					})}>
						<div className={`d-flex flex-grow-1 px-4 pb-4 overflow-hidden villa-filter-container h-100`.trim()}>
							<div className='d-flex flex-column flex-grow-1 filter-box'>
								<div className='d-flex flex-column border-bottom border-1 border-light-coal py-lg-3 text-center w-100'>
									<p className='inter-bold h5 text-center mb-0'>{t('villas.filter.title')}</p>
								</div>
								<div className='d-flex flex-column gap-4 flex-grow-1 p-3 custom-scroll filter-content'>
									{/* <div className='d-flex flex-column'>
										<p className='mb-2 inter-medium'>Destino</p>
										<DestinationSelect
											id={"destination"}
											className={"border border-1 villa-select"}
											containerClasssName={"villa-input"}
											outsideTarget={"destination-container"}
											noLabel
											chevron
										/>
									</div> */}
									<div className='d-flex flex-column'>
										<p className='mb-2 inter-semi-bold'>{t('villas.filter.checkInOutLabel')}</p>
										<CheckInOutSelect
											id={"calendar"}
											className={"border border-1 villa-select"}
											containerClasssName={"villa-input"}
											blockDates={villaExtendedDetail?.blockedDates}
											noLabel
											chevron
										/>
									</div>
									<div className='d-flex flex-column'>
										<p className='mb-2 inter-semi-bold'>{t('villas.filter.guests.title')}</p>
										<GuestSelect
											id={'guest'}
											className={"border border-1 villa-select"}
											containerClasssName={"villa-input"}
											outsideTarget={"guest-container"}
											noLabel
											chevron
										/>
									</div>
									{/* <div className='d-flex flex-column position-relative filter-map'>
										<p className='mb-2 inter-medium'>Zona</p>
										<div className='d-flex justify-content-center'>
											<FilterMapIcon width={"70%"} height={"100%"} hoverZone={triggerHoverLabel} clickZone={triggerMapLabel} triggerZoneId={triggerZoneId} zoneId={zoneId} />
										</div>
									</div> */}
									{/* <div>
										<p className='mb-2 inter-medium'>Precio</p>
										<div className='d-flex justify-content-center'>
											<Slider range allowCross={false} defaultValue={[20, 90]} min={0} max={100} pushable={10}
												// onAfterChange={(val) => setPriceRange({min: val?.[0], max: val?.[1]})}
												onChange={(val) => handlePrice(val)}
											// onAfterChange={(val) => handlePrice(val)}
											/>
										</div>
									</div> */}
									<div className='mt-3'>
										<p className='mb-2 inter-semi-bold'>{t('villas.filter.equipment.title')}</p>
										<Row className='gy-2'>
											<Col xs={6}>
												<Form.Check
													label={t('villas.filter.equipment.wifi')}
													className='mb-0'
													id='wifi'
													checked={equip?.wifi}
													onChange={(e) => setEquip({ ...equip, "wifi": e.target.checked })}
												/>
											</Col>
											<Col xs={6}>
												<Form.Check
													label={t('villas.filter.equipment.barbecue')}
													className='mb-0'
													id='bbq'
													checked={equip?.barbeque}
													onChange={(e) => setEquip({ ...equip, "barbeque": e.target.checked })}
												/>
											</Col>
											<Col xs={6}>
												<Form.Check
													label={"TV"}
													className='mb-0'
													id='tv'
													checked={equip?.television}
													onChange={(e) => setEquip({ ...equip, "television": e.target.checked })}
												/>
											</Col>
											<Col xs={6}>
												<Form.Check
													label={t('villas.filter.equipment.garden')}
													className='mb-0'
													id='garden'
													checked={equip?.garden}
													onChange={(e) => setEquip({ ...equip, "garden": e.target.checked })}
												/>
											</Col>
											<Col xs={6}>
												<Form.Check
													label={t('villas.filter.equipment.views')}
													className='mb-0'
													id='views'
													checked={equip?.view}
													onChange={(e) => setEquip({ ...equip, "view": e.target.checked })}
												/>
											</Col>
											<Col xs={6}>
												<Form.Check
													label={"Point-VE"}
													className='mb-0'
													id='ve'
													checked={equip?.chargingPoint}
													onChange={(e) => setEquip({ ...equip, "chargingPoint": e.target.checked })}
												/>
											</Col>
											<Col xs={6}>
												<Form.Check
													label={t('villas.filter.equipment.pets')}
													className='mb-0'
													id='pets'
													checked={equip?.pets}
													onChange={(e) => setEquip({ ...equip, "pets": e.target.checked })}
												/>
											</Col>
										</Row>
									</div>
									<div>
										<p className='mb-2 inter-semi-bold mb-3'>{t('villas.filter.features')}</p>
										<div className='d-flex flex-column gap-lg-4'>
											<VillasFeatures id={"DESKTOP"} bedrooms={bedrooms} updateBedrooms={setBedrooms} beds={beds} updateBeds={setBeds} baths={baths} updateBaths={setBaths} />
										</div>
									</div>
									<Button type="submit" variant='none' className='btn-laurel inter-regular'>{t('villas.filter.button')}</Button>
								</div>
							</div>
						</div>
					</Form>
				</div>
				<div className='content-menu'>
					<div className='nav-menu'>
						<div className='d-flex flex-row justify-content-center gap-4'>
							<Button variant='none' className='shadow-none p-3 border-0'>
								<Link href={`${t('pages.villa')}/${t('pages.villaSearcher')}`}>
									<FilterGrid width={28} height={28} onClick={() => {
										villasUpdate({
											...villasState,
											layoutCards: "card"
										})
									}} />
								</Link>
							</Button>
							<Button variant='none' className='shadow-none p-3 border-0'>
								<Link href={`${t('pages.villa')}/${t('pages.villaSearcher')}`}>
									<FilterList width={28} height={28} onClick={() => {
										villasUpdate({
											...villasState,
											layoutCards: "list"
										})
									}} />
								</Link>
							</Button>
							<Button variant='none' className='shadow-none p-3 border-0'>
								<MapMenuIcon width={28} height={28} active />
							</Button>
						</div>
					</div>
					<div className={`villa-content custom-scroll ${!villaDetail ? "bg-secondary" : ""}`.trim()}>
						{!villaDetail && (
							<div className='p-3'>
								<p className='text-coal inter-medium opacity-75 mb-4 mt-1'>{villaPagination?.totalElements} {t('villas.searcher.total')}</p>
								<Row className='g-2 gy-3 g-lg-4 mb-4'>
									{villaList?.length > 0 && (
										villaList?.map((card, i) =>
											<Col xs={12} key={i}>
												<VillaCard data={card} theme={"laurel"} noRedirect cardAction={() => {
													setCardScrollPosition(document.getElementsByClassName('villa-content')[0].scrollTop)
													setVillaDetail(card)
												}} />
											</Col>
										)
									)}
									{!villaList && (
										[1, 2, 3, 4, 5, 6, 8, 9].map((data) =>
											<Col key={data}>
												<VillaCardPlaceholder />
											</Col>
										)
									)}

								</Row>
								<ReactPaginate
									pageCount={villaPagination?.totalPages}
									onPageChange={(event) => {
										setVillaPagination({ ...villaPagination, currentPage: event.selected + 1 })
									}}
									pageRangeDisplayed={3}
									marginPagesDisplayed={1}
									containerClassName='pagination gap-1'
									pageClassName='page'
									previousClassName='page'
									previousLabel="<"
									prevRel={"prev"}
									nextClassName='page'
									nextLabel=">"
									nextRel={"next"}
									breakClassName='page'
									forcePage={villaPagination?.currentPage - 1}
								// hrefBuilder={(page) =>
								// 	`${window.origin}/${i18n.language}/${t('pages.explore')}${categoryList.length === 1 ? `/${getCategory(categoryList?.[0])?.slugs?.[i18n.language]}` : ""}${searchParams && `?page=${page}`}`
								// }
								/>
							</div>
						)}
						{(villaDetail && !desktopBooking) && (
							<div className='villa-detail d-flex flex-column flex-grow-1 gap-4 pt-4'>
								<Button variant='none' className='d-flex flex-row flex-grow-1 gap-3 align-items-center shadow-none border-0 px-3 py-0' onClick={() => {
									setVillaDetail()
									setVillaExtendedDetail()
								}}>
									<FontAwesomeIcon icon={"fa-solid fa-chevron-left"} className='text-coal opacity-75 ' height={18} />
									<p className='mb-0 inter-medium text-coal opacity-75'>{villaExtendedDetail?.title}</p>
								</Button>
								<div>
									{proximityVillas?.length >= 1 ? (
										<Swiper
											navigation
											modules={[Navigation]}
											spaceBetween={8}
											slidesPerView={1.25}
											slidesOffsetBefore={16}
											slidesOffsetAfter={16}
											className={'d-none d-lg-block villa-content-slider content-swiper'}
											ref={detailedGalleryRef}
										>
											{villaDetail?.images?.map((image, i) => (
												<SwiperSlide key={i}>
													<Image src={image} />
												</SwiperSlide>
											))}
										</Swiper>
									) : (
										<></>
									)}
								</div>
								{/* {villaDetail?.nightPrice && <div className='py-2 px-3 card-shadow d-flex flex-row gap-5 align-items-center justify-content-between'>
									<div>
										<p className='inter-medium text-coal mb-0'>{villaDetail?.nightPrice}€ / {t('villas.map.prices.from')}</p>
										{(filter?.startDate && filter?.endDate) && <p className='inter-medium text-coal mb-0'>{filter?.startDate?.getDate()} {filter?.startDate.toLocaleString('default', { month: 'short' })} - {filter?.endDate?.getDate()} {filter?.endDate.toLocaleString('default', { month: 'short' })}</p>}
									</div>
									<Button variant='none' className='d-flex justify-content-center flex-grow-1 btn-coal inter-medium radius text-white tzo-hover'>{t('villas.map.villaDetail.reserve')}</Button>
								</div>} */}
								<div className='px-3 d-flex flex-column gap-4'>
									<div>
										<p className='mb-4 inter-medium h4'>{t('villas.map.villaDetail.features')}</p>
										<div>
											<Row className='g-3'>
												<Col lg={6} className='d-flex flex-row gap-3'>
													<Image src={"/img/svg/guests.svg"} width={28} />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.capacity} {t('villas.detail.features.capacity')}</span>
												</Col>
												{villaDetail?.numBedroom && <Col lg={6} className='d-flex flex-row gap-3'>
													<Image src={"/img/svg/door-closed.svg"} width={28} />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.numBedroom} {t('villas.detail.features.bedrooms')}</span>
												</Col>}
												<Col lg={6} className='d-flex flex-row gap-3'>
													<Image src={"/img/svg/beds.svg"} width={28} />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.totalBed} {t('villas.detail.features.beds')}</span>
												</Col>
												{villaDetail?.numBathroom && <Col lg={6} className='d-flex flex-row gap-3'>
													<Image src={"/img/svg/baths.svg"} width={28} />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.numBathroom} {t('villas.detail.features.baths')}</span>
												</Col>}
												{villaDetail?.chargingPoint && <Col lg={6} className='d-flex flex-row gap-3'>
													<Image src={"/img/svg/car-ve.svg"} width={28} />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.car} {t('villas.detail.features.chargerPoint')}</span>
												</Col>}
												<Col lg={6} className='d-flex flex-row gap-3'>
													<Image src={"/img/svg/plot-area.svg"} width={28} />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.plotArea}</span>
												</Col>
												<Col lg={6} className='d-flex flex-row gap-3'>
													<FontAwesomeIcon icon={"fa-solid fa-home"} width={28} className='text-coal' />
													<span className='inter-medium text-coal small'>{villaExtendedDetail?.houseArea}</span>
												</Col>
											</Row>
										</div>
									</div>
									<div className='mx-4 mx-lg-0' id='description'>
										<p className='mb-4 inter-medium h4'>{t('villas.map.villaDetail.about')}</p>
										<p className='inter-regular text-coal mb-0 villa-description'>{showFullDescription ? villaExtendedDetail?.description : villaExtendedDetail?.description?.split(/\r\n|\r|\n/)?.[0]}</p>
										<Button variant='none' className='shadow-none border-0 p-0 inter-medium opacity-75' onClick={() => setShowFullDescription(!showFullDescription)}>{!showFullDescription ? `${t('villas.detail.about.more')}` : `${t('villas.detail.about.less')}`}</Button>
									</div>
									<div>
										<p className='mb-4 inter-medium h4'>{t('villas.detail.services.title')}</p>
										<Row className='g-3'>

											{villaExtendedDetail?.privatePool && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/water-ladder.svg' />
												{t('villas.detail.services.pool')}
											</Col>}
											{villaExtendedDetail?.airConditioning && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/air-conditioner.svg' />
												{t('villas.detail.services.airConditioning')}
											</Col>}
											{villaExtendedDetail?.barbecue && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/grill.svg' />
												{t('villas.detail.services.barbecue')}
											</Col>}
											{villaExtendedDetail?.chargingPoint && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/charging-station.svg' />
												{t('villas.detail.services.chargingPoint')}
											</Col>}
											{villaExtendedDetail?.dryer && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/dryer.svg' />
												{t('villas.detail.services.dryer')}
											</Col>}
											{(villaExtendedDetail?.gasOven || villaExtendedDetail?.electricOven) && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/oven.svg' />
												{t('villas.detail.services.oven')}
											</Col>}
											{villaExtendedDetail?.television && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/tv.svg' />
												{t('villas.detail.services.television')}
											</Col>}
											{villaExtendedDetail?.wifi && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/wifi.svg' />
												{t('villas.detail.services.wifi')}
											</Col>}
											{villaExtendedDetail?.washingMachine && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<Image src='/img/svg/villas-equip/washing-machine.svg' />
												{t('villas.detail.services.washingMachine')}
											</Col>}
											{(villaExtendedDetail && villaExtendedDetail?.pet?.type !== "NOTALLOWED") && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
												<FontAwesomeIcon icon="fa-solid fa-paw" height={19} />
												{t('villas.detail.services.pets')}
											</Col>}
										</Row>
									</div>
									<div className='px-4 px-lg-0'>
										<p className='mb-0 inter-medium h4 mb-4'>{t('villas.detail.distances')}</p>

										<Row className='gy-3'>
											{villaExtendedDetail?.distances.map((distance, i) => (
												<Col xs={12} className='d-flex flex-row justify-content-between gap-2' key={i}>
													<span className='inter-semi-bold'>{distance?.category?.text}</span>
													<span className='inter-regular opacity-75'>{distance?.distance} {distance?.unit}</span>
												</Col>
											))}
										</Row>
									</div>
									<div>
										<div className='d-flex flex-row justify-content-center gap-4 mb-3'>
											<div className='border border-1 border-coal-semi-transparent p-1'>
												<div className='d-flex flex-column align-items-center justify-content-center px-2 py-2 text-white etv'>
													<span className='inter-medium h1 mb-0'>ETV</span>
													<span className='inter-regular small'>NÚM: 558</span>
												</div>
											</div>
											<Image src='/img/svg/logo-vacalia.svg' className='homerti-guaranty' />
										</div>
										<div>
											<p className='text-center inter-regular'>{t('villas.detail.warranyPartners')}</p>
										</div>
									</div>
								</div>
								{/* <div>
									{proximityVillas?.length > 1 ? (
										<Swiper
											spaceBetween={8}
											slidesPerView={proximityVillas?.length > 1 && 1.2}
											slidesOffsetBefore={16}
											slidesOffsetAfter={16}
											className={'d-none d-lg-block content-swiper pb-1'}
											freeMode={true}
											ref={villaProximitySliderRef}
										>
											{proximityVillas?.length > 0 && (
												proximityVillas?.map((card, i) => {
													return (
														<SwiperSlide key={i}>
															<VillaCard theme={"laurel"} data={card} noRedirect cardAction={() => {
																setCardScrollPosition(document.getElementsByClassName('villa-content')[0].scrollTop)
																setVillaDetail(card)
															}} />
														</SwiperSlide>
													)
												})
											)}
										</Swiper>
									) : (
										proximityVillas?.length === 1 && proximityVillas?.[0]?.id !== villaDetail?.id) && (
										<div className='px-3'>
											<VillaCard theme={"laurel"} data={proximityVillas?.[0]} noRedirect cardAction={() => {
												setSheetScrollPosition(document.getElementsByClassName('sheet-content')[0].scrollTop)
												setVillaDetail(proximityVillas?.[0])
											}} />
										</div>
									)}
								</div> */}
								<div className='villa-budget sticky card-shadow bg-white'>
									<div className='h-100 d-flex flex-row sticky-price py-2 px-4'>
										{(!villaBudget?.amount || !filter?.startDate || !filter?.endDate) ? (
											<>
												<div className='d-flex flex-column gap-0'>
													<span className='inter-medium small'>{t('villas.detail.budget.from')}</span>
													<div className='inter-bold'>
														<span className='h5'>{villaDetail?.nightPrice}€</span> <span className='small'>/ {t('villas.detail.budget.nights')}</span>
													</div>
												</div>
												<div className='vertical-separator mx-3' />
												<Button variant='none' className='d-flex flex-grow-1 btn-dark-green-outline align-self-center justify-content-center text-white shadow-none' onClick={() => setDesktopBooking(true)}>{t('villas.detail.budget.disponibility')}</Button>
											</>
										) : (
											<>
												<div className='d-flex flex-row gap-2'>
													<div className='d-flex flex-column gap-0'>
														<span className='inter-bold'>{(villaBudget?.amount / totalDays).toFixed(0)}€ / {t('villas.detail.budget.nights')}</span>
														<span className='inter-medium'>{filter?.startDate?.getDate()} {filter?.startDate?.toLocaleString('default', { month: 'short' })} - {filter?.endDate?.getDate()} {filter?.endDate?.toLocaleString('default', { month: 'short' })}</span>
													</div>
													<FontAwesomeIcon icon={"fa-solid fa-pencil"} height={18} className='text-coal ps-2 pe-3 opacity-75 cursor-pointer' onClick={() => setDesktopBooking(true)} />
												</div>
												<div className='vertical-separator' />
												<Button variant='none' className='d-flex flex-grow-1 btn-dark-green-outline align-self-center justify-content-center text-white shadow-none ms-3 booking-btn' onClick={() => {
													if (acceptTerms) {
														saveAnalyticBudget()
													} else {
														setModalShow(true)
													}
												}}>{t('villas.detail.budget.button')}</Button>
											</>
										)}
									</div>
								</div>
							</div>
						)}
						{desktopBooking && (
							<div className='d-flex flex-column flex-grow-1 pt-4'>
								<div className='d-flex flex-row gap-2 px-3 pb-4'>
									<Button variant='none' className='d-flex flex-row text-coal gap-3 align-items-center shadow-none p-0 border-0 m-0 opacity-75' onClick={() => setDesktopBooking(false)}>
										<FontAwesomeIcon icon={"fa-solid fa-chevron-left"} className='text-coal' height={18} />
										<span className='inter-medium'>{villaDetail?.title}</span>
									</Button>
								</div>
								<div className='d-flex flex-row justify-content-between mb-3 border-bottom border-1 border-light-coal pb-3 px-3'>
									<div className='d-flex flex-row gap-2'>
										<div className='d-flex justify-content-center align-items-center bg-primary align-self-center px-2 py-1 villa-rating'>
											<span className='inter-bold'>{villaDetail?.rating}</span>
										</div>
										<div className='align-self-center'>
											<p className='inter-bold mb-0 lh-sm'>Bien</p>
											<p className='mb-0 lh-sm'>{villaDetail?.rates?.length} {t('villas.detail.reviews')}</p>
										</div>
									</div>
									<div className='d-flex flex-column align-items-center justify-content-center align-items-end'>
										{(villaBudget && !villaBudget?.error) ? (
											<div>

												<p className='inter-bold h3 mb-0'>{villaBudget?.amount}€</p>
												{/* <p className='mb-0 text-end'>/noche</p> */}
											</div>
										) : (
											<p className='inter-bold h4 mb-0 text-end'>{villaDetail?.nightPrice}€ <span className='fs-6 inter-medium'>/ {t('villas.map.prices.nights')}</span></p>
										)}
									</div>
								</div>
								{!villaBudget && <p className='text-center mb-3 px-3'>{t('villas.detail.budget.filterClaim')}</p>}
								<div className='px-3'>
									<CheckInOutSelect
										id={"calendar-mobile"}
										className={"border-1 border border-coal-semi-transparent"}
										containerClasssName={"villa-input"}
										blockDates={villaExtendedDetail?.blockedDates}
										blockReset
									/>
									<GuestSelect
										id={"guest-mobile"}
										className={"border-1 border-start border-end border-bottom border-coal-semi-transparent"}
										containerClasssName={"villa-input"}
										outsideTarget={"guest-container"}
									/>
								</div>
								{!villaBudget && <p className='text-center inter-regular small mt-1 text-dark-green mb-0'>{t('villas.filter.minStay')} <span className='inter-semi-bold'>{villaExtendedDetail?.baseNightMin + 1 || ""}</span></p>}
								{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-column gap-1 pt-4 pb-2 mb-1 border-1 border-bottom border-coal-semi-transparent mx-4'>
									<div className='d-flex flex-row justify-content-between'>
										{(villaBudget && !villaBudget?.error) && (
											<>
												<span className='inter-regular'>{(villaBudget?.amount / totalDays).toFixed(2)}€ X {totalDays} {t('villas.map.prices.nights')}</span>
												<span className='inter-regular'>{villaBudget?.amount}€</span>
											</>
										)}
									</div>
								</div>}
								{(villaBudget && !villaBudget?.error) && (
									<FormCheck id='termsM' name='termsM' label={t('villas.landing.termsConditions.label')} className='mt-3 inter-regular px-3 mx-4' checked={acceptTerms} onChange={() => {
										if (acceptTerms) {
											setAcceptTerms(false)
										} else {
											setAcceptTerms(true)
											setModalShow(true)
										}
									}} />
								)}
								<div className='px-3'>
									<Button disabled={!villaBudget || villaBudget?.error || !acceptTerms} onClick={() => saveAnalyticBudget()} variant='none' className='btn-dark-green-outline inter-medium py-2 text-center text-white text-uppercase mt-4 w-100 booking-btn'>{t(!villaBudget ? 'villas.detail.budget.disponibility' : 'villas.detail.budget.button')}</Button>
									{villaBudget && <p className='inter-regular text-center small mt-2 mb-0'>{t('villas.detail.budget.claim')}</p>}
								</div>
								{(villaBudget && villaBudget?.error) && (
									<div className='d-flex flex-column gap-1 mt-4 px-3'>
										<div className='d-flex flex-row justify-content-center'>
											<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.property_not_available}>
												{t('villas.detail.budget.error.propertyNotAvailable')}
											</Alert>
											<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.max_guests_exceeded}>
												{t('villas.detail.budget.error.maxGuestsExceeded')}
											</Alert>
											<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.stay_not_met}>
												<p className='mb-0 inter-medium'>{t('villas.detail.budget.error.minStayNotMet.text1')}</p>
												<p className='text-center inter-medium'>{t('villas.detail.budget.error.minStayNotMet.text2')} {(villaBudget.minNights + 1)}</p>
											</Alert>
											<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.invalid_dates}>
												{t('villas.detail.budget.error.invalidDates')}
											</Alert>
											<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.server}>
												{t("register.errors.server")}
											</Alert>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<VillaMobileFilter containerClassName={"d-flex d-lg-none align-self-start position-absolute top-start flex-grow-1 w-100 px-4 py-3 z-index-1"} sheet={sheetShow} action={() => setSheetShow(true)} page={"map"} />
			<APIProvider
				apiKey={"AIzaSyCtQXQpnTRWSAowAvd4m-jW5Td6KR4uCtY"}
				libraries={["marker", "geometry"]}
				onLoad={() => setIsMapLoaded(true)}
			>
				{isMapLoaded && (
					<MapWrapper
						data={villaList}
						updateBounds={setMapBounds}
						villaStates={{
							villaDetail: villaDetail,
							setVillaDetail: setVillaDetail,
							setProximityVillas: setProximityVillas,
							showTouchSheet: shetTouchSheetOpen,
							setDesktopBooking: setDesktopBooking
						}}
						isDesktop={isDesktop}
					/>
				)}
			</APIProvider>
			<TzoTouchSheet open={touchSheetOpen} handleOpen={shetTouchSheetOpen}>
				<div className={`sheet-content ${villaDetail ? "price" : ""}`.trim()}>
					{!villaDetail && (
						<Container className='py-3'>
							<p className='text-coal inter-bold opacity-75 mb-4 mt-1 text-center'>{villaPagination?.totalElements} {t('villas.searcher.total')}</p>
							<Row className='g-2 gy-3 g-lg-4'>
								{villaList?.length > 0 && (
									villaList?.map((card, i) =>
										<Col xs={12} key={i}>
											<VillaCard theme={"laurel"} data={card} noRedirect cardAction={() => {
												setSheetScrollPosition(document.getElementsByClassName('sheet-content')[0].scrollTop)
												setVillaDetail(card)
											}} />
										</Col>
									)
								)}
							</Row>
							<ReactPaginate
								pageCount={villaPagination?.totalPages}
								onPageChange={(event) => {
									setVillaPagination({ ...villaPagination, currentPage: event.selected + 1 })
								}}
								pageRangeDisplayed={1}
								marginPagesDisplayed={1}
								containerClassName='pagination gap-1 mt-4'
								pageClassName='page'
								previousClassName='page'
								previousLabel="<"
								prevRel={"prev"}
								nextClassName='page'
								nextLabel=">"
								nextRel={"next"}
								breakClassName='page'
								forcePage={villaPagination?.currentPage - 1}
							// hrefBuilder={(page) =>
							// 	`${window.origin}/${i18n.language}/${t('pages.explore')}${categoryList.length === 1 ? `/${getCategory(categoryList?.[0])?.slugs?.[i18n.language]}` : ""}${searchParams && `?page=${page}`}`
							// }
							/>
						</Container>
					)}
					{villaDetail && (
						<div className='d-flex flex-column flex-grow-1 gap-4 py-4'>
							<Button variant='none' className='d-flex flex-row flex-grow-1 gap-4 align-items-center shadow-none border-0 px-4 py-0' onClick={() => {
								setVillaDetail()
								setVillaExtendedDetail()
							}}>
								<FontAwesomeIcon icon={"fa-solid fa-chevron-left"} className='text-coal opacity-75 ' height={18} />
								<p className='mb-0 inter-medium text-coal opacity-75'>{villaDetail?.title}</p>
							</Button>
							<div>
								<Swiper
									spaceBetween={8}
									slidesPerView={1.25}
									slidesOffsetBefore={24}
									slidesOffsetAfter={24}
									className={'d-block villa-slider content-swiper'}
									freeMode
									ref={detailedGalleryMobileRef}
									modules={[FreeMode]}
								>
									{villaDetail?.images?.map((image, i) => (
										<SwiperSlide key={i}>
											<Image src={image} />
										</SwiperSlide>
									))}
								</Swiper>
							</div>
							{/* <div className='py-2 px-3 card-shadow d-flex flex-row gap-5 align-items-center justify-content-between px-4'>
								<div>
									<p className='inter-medium text-coal mb-0'>{(!filter?.startDate && !filter?.endDate) && `${t('villas.map.prices.from')}`} {villaDetail?.nightPrice}€{filter?.startDate && filter?.endDate && ` / ${t('villas.map.prices.nights')}`}</p>
									{(filter?.startDate && filter?.endDate) && <p className='inter-medium text-coal mb-0'>{filter?.startDate?.getDate()} {filter?.startDate.toLocaleString('default', { month: 'short' })} - {filter?.endDate?.getDate()} {filter?.endDate.toLocaleString('default', { month: 'short' })}</p>}
								</div>
								<Button variant='none' className='d-flex justify-content-center flex-grow-1 btn-coal inter-medium radius text-white tzo-hover'>{t('villas.map.villaDetail.reserve')}</Button>
							</div> */}
							<div className='px-4 d-flex flex-column gap-4'>
								<div>
									<p className='mb-4 inter-medium h4'>{t('villas.map.villaDetail.features')}</p>
									<div>
										<Row className='gy-4'>
											<Col xs={6} className='d-flex flex-row gap-3'>
												<Image src={"/img/svg/guests.svg"} width={28} />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.capacity} {t('villas.detail.features.capacity')}</span>
											</Col>
											{villaDetail?.numBedroom && <Col xs={6} className='d-flex flex-row gap-3'>
												<Image src={"/img/svg/door-closed.svg"} width={28} />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.numBedroom} {t('villas.detail.features.bedrooms')}</span>
											</Col>}
											<Col xs={6} className='d-flex flex-row gap-3'>
												<Image src={"/img/svg/beds.svg"} width={28} />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.totalBed} {t('villas.detail.features.beds')}</span>
											</Col>
											{villaDetail?.numBathroom && <Col xs={6} className='d-flex flex-row gap-3'>
												<Image src={"/img/svg/baths.svg"} width={28} />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.numBathroom} {t('villas.detail.features.baths')}</span>
											</Col>}
											{villaDetail?.chargingPoint && <Col xs={6} className='d-flex flex-row gap-3'>
												<Image src={"/img/svg/car-ve.svg"} width={28} />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.car} {t('villas.detail.features.chargerPoint')}</span>
											</Col>}
											<Col xs={6} className='d-flex flex-row gap-3'>
												<Image src={"/img/svg/plot-area.svg"} width={28} />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.plotArea}</span>
											</Col>
											<Col xs={6} className='d-flex flex-row gap-3'>
												<FontAwesomeIcon icon={"fa-solid fa-home"} width={28} className='text-coal' />
												<span className='inter-medium text-coal small'>{villaExtendedDetail?.houseArea}</span>
											</Col>
										</Row>
									</div>
								</div>
								<div id='description'>
									<p className='mb-4 inter-medium h4'>{t('villas.map.villaDetail.about')}</p>
									<p className='inter-regular text-coal mb-0 villa-description'>{showFullDescription ? villaExtendedDetail?.description : villaExtendedDetail?.description?.split(/\r\n|\r|\n/)?.[0]}</p>
									<Button variant='none' className='shadow-none border-0 p-0 inter-medium opacity-75' onClick={() => setShowFullDescription(!showFullDescription)}>{!showFullDescription ? `${t('villas.detail.about.more')}` : `${t('villas.detail.about.less')}`}</Button>
								</div>
								<div>
									<p className='mb-4 inter-medium h4'>{t('villas.detail.services.title')}</p>
									<Row className='gy-4'>

										{villaExtendedDetail?.privatePool && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/water-ladder.svg' />
											{t('villas.detail.services.pool')}
										</Col>}
										{villaExtendedDetail?.airConditioning && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/air-conditioner.svg' />
											{t('villas.detail.services.airConditioning')}
										</Col>}
										{villaExtendedDetail?.barbecue && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/grill.svg' />
											{t('villas.detail.services.barbecue')}
										</Col>}
										{villaExtendedDetail?.chargingPoint && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/charging-station.svg' />
											{t('villas.detail.services.chargingPoint')}
										</Col>}
										{villaExtendedDetail?.dryer && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/dryer.svg' />
											{t('villas.detail.services.dryer')}
										</Col>}
										{(villaExtendedDetail?.gasOven || villaExtendedDetail?.electricOven) && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/oven.svg' />
											{t('villas.detail.services.oven')}
										</Col>}
										{villaExtendedDetail?.television && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/tv.svg' />
											{t('villas.detail.services.television')}
										</Col>}
										{villaExtendedDetail?.wifi && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/wifi.svg' />
											{t('villas.detail.services.wifi')}
										</Col>}
										{villaExtendedDetail?.washingMachine && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<Image src='/img/svg/villas-equip/washing-machine.svg' />
											{t('villas.detail.services.washingMachine')}
										</Col>}
										{(villaExtendedDetail && villaExtendedDetail?.pet?.type !== "NOTALLOWED") && <Col xs={6} className='d-flex flex-row align-items-center gap-2 inter-regular'>
											<FontAwesomeIcon icon="fa-solid fa-paw" height={19} />
											{t('villas.detail.services.pets')}
										</Col>}
									</Row>
								</div>
								<div>
									<p className='mb-0 inter-medium h4 mb-4'>{t('villas.detail.distances')}</p>
									<Row className='gy-3'>
										{villaExtendedDetail?.distances.map((distance, i) => (
											<Col xs={12} className='d-flex flex-row justify-content-between gap-2' key={i}>
												<span className='inter-semi-bold'>{distance?.category?.text}</span>
												<span className='inter-regular opacity-75'>{distance?.distance} {distance?.unit}</span>
											</Col>
										))}
									</Row>
								</div>
								<div>
									<div className='d-flex flex-row justify-content-center gap-3'>
										<div className='border border-1 border-coal-semi-transparent radius p-1'>
											<div className='d-flex flex-column align-items-center justify-content-center radius px-2 py-2 text-white etv'>
												<span className='inter-medium h1 mb-0'>ETV</span>
												<span className='inter-regular small'>NÚM: 558</span>
											</div>
										</div>
										<Image src='/img/svg/logo-vacalia.svg' className='vacalia-logo' />
									</div>
									<div className='mt-3'>
										<p className='text-center inter-tergular mb-0'>{t('villas.detail.warranyPartners')}</p>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{(villaDetail) && <div className={`villa-budget sheet sticky card-shadow bg-white ${touchSheetOpen ? "open" : ""}`}>
					<div className={`h-100 d-flex flex-row sticky-price py-2 px-4`.trim()}>
						{(!villaBudget || !filter?.startDate || !filter?.endDate) ? (
							<>
								<div className='d-flex flex-column gap-0'>
									<span className='inter-medium small'>{t('villas.detail.budget.from')}</span>
									<div className='inter-bold'>
										<span className='h5'>{villaDetail?.nightPrice}€</span> <span className='small'>/ {t('villas.detail.budget.nights')}</span>
									</div>
								</div>
								<div className='vertical-separator mx-3' />
								<Button variant='none' className='d-flex flex-grow-1 btn-dark-green-outline align-self-center justify-content-center text-white shadow-none' onClick={() => setBudgetSheetShow(true)}>{t('villas.detail.budget.disponibility')}</Button>
							</>
						) : (
							<>
								<div className='d-flex flex-row gap-2'>
									<div className='d-flex flex-column gap-0'>
										<span className='inter-bold'>{(villaBudget?.amount / totalDays).toFixed(0)}€ / {t('villas.detail.budget.nights')}</span>
										<span className='inter-medium'>{filter?.startDate?.getDate()} {filter?.startDate?.toLocaleString('default', { month: 'short' })} - {filter?.endDate?.getDate()} {filter?.endDate?.toLocaleString('default', { month: 'short' })}</span>
									</div>
									<FontAwesomeIcon icon={"fa-solid fa-pencil"} height={18} className='text-coal ps-2 pe-3 opacity-75' onClick={() => setBudgetSheetShow(true)} />
								</div>
								<div className='vertical-separator' />
								<Button variant='none' className='d-flex flex-grow-1 btn-dark-green-outline align-self-center justify-content-center text-white shadow-none ms-3' onClick={() => {
									if (acceptTerms) {
										saveAnalyticBudget()
									} else {
										setModalShow(true)
									}
								}}>{t('villas.detail.budget.button')}</Button>
							</>
						)}
					</div>
				</div>}
			</TzoTouchSheet>
			<TzoSheet title={t('villas.filter.mobileTitle')} show={sheetShow} close={() => setSheetShow(false)} stickyHead>
				<Form onSubmit={handleSubmit(() => {
					document.getElementsByClassName("villa-content")?.[0].scrollTo({ top: 0 })
					setVillaDetail();
					setVillaExtendedDetail()
					// parseInt(currentPage) === 1 ? getVillas() : setCurrentPage(1)
					getVillas()
				})}>
					<div className='d-flex flex-column gap-4 p-4 villa-mobile-filters'>
						{/* <div className='d-flex flex-column'>
							<p className='mb-2 inter-medium'>Destino</p>
							<DestinationSelect
								id={"destination-mobile"}
								className={"border border-1 villa-select"}
								containerClasssName={"villa-input"}
								outsideTarget={"destination-container-mobile"}
								noLabel
								chevron
							/>
						</div> */}
						<div className='d-flex flex-column'>
							<p className='mb-2 inter-medium'>{t('villas.filter.checkInOutLabel')}</p>
							<CheckInOutSelect
								id={"calendar-mobile"}
								className={"border border-1 villa-select"}
								containerClasssName={"villa-input"}
								blockDates={villaExtendedDetail?.blockedDates}
								noLabel
								chevron
							/>
						</div>
						<div className='d-flex flex-column'>
							<p className='mb-2 inter-medium'>{t('villas.filter.guests.title')}</p>
							<GuestSelect
								id={"guest-mobile"}
								className={"border border-1 villa-select"}
								containerClasssName={"villa-input"}
								outsideTarget={"guest-container-mobile"}
								noLabel
								chevron
							/>
						</div>
						{/* <div>
							<p className='mb-2 inter-medium'>Precio</p>
							<div className='d-flex justify-content-center mb-2'>
								<Slider range allowCross={false} defaultValue={[20, 90]} min={0} max={100} pushable={10}
									// onAfterChange={(val) => setPriceRange({min: val?.[0], max: val?.[1]})}
									onChange={(val) => handlePrice(val)}
								/>
							</div>
						</div> */}
						{/* <div>
							<CustomMosaic />
						</div> */}
						<div className='mt-3'>
							<p className='mb-2 inter-medium'>{t('villas.filter.equipment.title')}</p>
							<Row className='gy-2'>
								<Col xs={6}>
									<Form.Check
										label={t('villas.filter.equipment.wifi')}
										className='mb-0'
										id='wifi'
										checked={equip?.wifi}
										onChange={(e) => setEquip({ ...equip, "wifi": e.target.checked })}
									/>
								</Col>
								<Col xs={6}>
									<Form.Check
										label={t('villas.filter.equipment.barbecue')}
										className='mb-0'
										id='bbq'
										checked={equip?.barbeque}
										onChange={(e) => setEquip({ ...equip, "barbeque": e.target.checked })}
									/>
								</Col>
								<Col xs={6}>
									<Form.Check
										label={"TV"}
										className='mb-0'
										id='tv'
										checked={equip?.television}
										onChange={(e) => setEquip({ ...equip, "television": e.target.checked })}
									/>
								</Col>
								<Col xs={6}>
									<Form.Check
										label={t('villas.filter.equipment.garden')}
										className='mb-0'
										id='garden'
										checked={equip?.garden}
										onChange={(e) => setEquip({ ...equip, "garden": e.target.checked })}
									/>
								</Col>
								<Col xs={6}>
									<Form.Check
										label={t('villas.filter.equipment.views')}
										className='mb-0'
										id='views'
										checked={equip?.view}
										onChange={(e) => setEquip({ ...equip, "view": e.target.checked })}
									/>
								</Col>
								<Col xs={6}>
									<Form.Check
										label={"Point-VE"}
										className='mb-0'
										id='ve'
										checked={equip?.chargingPoint}
										onChange={(e) => setEquip({ ...equip, "chargingPoint": e.target.checked })}
									/>
								</Col>
								<Col xs={6}>
									<Form.Check
										label={t('villas.filter.equipment.pets')}
										className='mb-0'
										id='pets'
										checked={equip?.pets}
										onChange={(e) => setEquip({ ...equip, "pets": e.target.checked })}
									/>
								</Col>
							</Row>
						</div>
						<div>
							<p className='mb-2 inter-medium mb-3'>{t('villas.filter.features')}</p>
							<VillasFeatures id={"MOBILE"} bedrooms={bedrooms} updateBedrooms={setBedrooms} beds={beds} updateBeds={setBeds} baths={baths} updateBaths={setBaths} />
						</div>
						<Button type="submit" variant='none' className='btn-dark-green-outline inter-medium'>{t('villas.filter.button')}</Button>
					</div>
				</Form>
			</TzoSheet>
			<TzoSheet title={t('villas.filter.mobileSearcher')} show={budgetSheetShow} close={() => setBudgetSheetShow(false)} stickyHead>
				<div className='d-flex flex-column gap-0 p-4 villa-mobile-filters budget-sheet'>
					{/* <div className='d-flex flex-column'>
                            <p className='mb-2 inter-medium'>Destino</p>
                            <DestinationSelect
                                id={"destination-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                outsideTarget={"destination-container-mobile"}
                                noLabel
                                chevron
                            />
                        </div> */}
					<div className='d-flex flex-row justify-content-between mb-3 border-bottom border-1 border-light-coal pb-3'>
						<div className='d-flex flex-row gap-2'>
							<div className='d-flex justify-content-center align-items-center bg-primary align-self-center px-2 py-1 villa-rating'>
								<span className='inter-bold'>{villaDetail?.rating}</span>
							</div>
							<div className='align-self-center'>
								<p className='inter-bold mb-0 lh-sm'>Bien</p>
								<p className='mb-0 lh-sm'>{villaDetail?.rates?.length} {t('villas.detail.reviews')}</p>
							</div>
						</div>
						<div className='d-flex flex-column align-items-center justify-content-center align-items-end'>
							{(villaBudget && !villaBudget?.error) ? (
								<div>

									<p className='inter-bold h3 mb-0'>{villaBudget?.amount}€</p>
									{/* <p className='mb-0 text-end'>/noche</p> */}
								</div>
							) : (
								<p className='inter-bold h4 mb-0 text-end'>{villaDetail?.nightPrice}€ <span className='fs-6 inter-medium'>/ {t('villas.map.prices.nights')}</span></p>
							)}
						</div>
					</div>
					{!villaBudget && <p className='text-center mb-3'>{t('villas.detail.budget.filterClaim')}</p>}
					<div>
						<CheckInOutSelect
							id={"calendar-mobile"}
							className={"border-1 border border-coal-semi-transparent"}
							containerClasssName={"villa-input"}
							blockDates={villaExtendedDetail?.blockedDates}
							blockReset
						/>
						<GuestSelect
							id={"guest-mobile"}
							className={"border-1 border-start border-end border-bottom border-coal-semi-transparent"}
							containerClasssName={"villa-input"}
							outsideTarget={"guest-container"}
						/>
					</div>
					{!villaBudget && <p className='text-center inter-regular small mt-1 text-dark-green mb-0'>{t('villas.filter.minStay')} <span className='inter-semi-bold'>{villaExtendedDetail?.baseNightMin + 1 || ""}</span></p>}
					{(villaBudget && !villaBudget?.error) && <div className='d-flex flex-column gap-1 pt-4 pb-2 mb-1 border-1 border-bottom border-coal-semi-transparent'>
						<div className='d-flex flex-row justify-content-between'>
							{(villaBudget && !villaBudget?.error) && (
								<>
									<span className='inter-regular'>{(villaBudget?.amount / totalDays).toFixed(2)}€ X {totalDays} {t('villas.map.prices.nights')}</span>
									<span className='inter-regular'>{villaBudget?.amount}€</span>
								</>
							)}
						</div>
					</div>}
					{(villaBudget && !villaBudget?.error) && (
						<FormCheck id='termsMobile' label={t('villas.landing.termsConditions.label')} className='mt-3' checked={acceptTerms} onChange={() => {
							if (acceptTerms) {
								setAcceptTerms(false)
							} else {
								setAcceptTerms(true)
								setModalShow(true)
							}
						}} />
					)}
					<div>
						<Button disabled={!villaBudget || villaBudget?.error || !acceptTerms} onClick={() => saveAnalyticBudget()} variant='none' className='btn-dark-green-outline inter-bold py-2 text-center text-white text-uppercase mt-3 w-100 booking-btn'>{t(!villaBudget ? 'villas.detail.budget.disponibility' : 'villas.detail.budget.button')}</Button>
						{villaBudget && <p className='inter-regular text-center small mt-2 mb-0'>{t('villas.detail.budget.claim')}</p>}
					</div>
					{(villaBudget && villaBudget?.error) && (
						<div className='d-flex flex-column gap-1 mt-4'>
							<div className='d-flex flex-row justify-content-center'>
								<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.property_not_available}>
									{t('villas.detail.budget.error.propertyNotAvailable')}
								</Alert>
								<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.max_guests_exceeded}>
									{t('villas.detail.budget.error.maxGuestsExceeded')}
								</Alert>
								<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.stay_not_met}>
									<p className='mb-0 inter-medium'>{t('villas.detail.budget.error.minStayNotMet.text1')}</p>
									<p className='text-center inter-medium'>{t('villas.detail.budget.error.minStayNotMet.text2')} {(villaBudget.minNights + 1)}</p>
								</Alert>
								<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.invalid_dates}>
									{t('villas.detail.budget.error.invalidDates')}
								</Alert>
								<Alert variant={"none"} className='inter-medium mb-0 text-danger p-0' show={errors.server}>
									{t("register.errors.server")}
								</Alert>
							</div>
						</div>
					)}
				</div>
			</TzoSheet>
			<Modal show={modalShow} centered size='lg' className='villa-terms-modal'>
				{/* <Modal.Header>
					<Modal.Title className='inter-medium'></Modal.Title>
				</Modal.Header> */}
				<Modal.Body className='d-flex flex-column p-4 p-lg-5'>
					<FontAwesomeIcon icon={"fa-solid fa-xmark"} className='position-absolute top-0 end-0 pe-3 pt-2 cursor-pointer' height={26} onClick={() => setModalShow(false)} />
					<p className='inter-medium h4 mb-4'>{t('villas.landing.termsConditions.title')}</p>
					<div className='content mb-3'>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block1.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block1.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block1.text2')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block1.text3')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block2.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block2.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block2.text2')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block2.text3')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block3.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block3.text1')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block4.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block4.text1')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block5.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block5.text1')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block5.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block5.text1')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block6.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block6.text1')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block7.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block7.text1')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block8.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block8.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block8.text2')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block9.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block9.text1')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block9.text2')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block9.text3')}</p>
						<p className='inter-medium h5'>{t('villas.landing.termsConditions.block10.title')}</p>
						<p className='inter-regular'>{t('villas.landing.termsConditions.block10.text1')}</p>
					</div>
					<FormCheck id='terms2' name='terms2' label={t('villas.landing.termsConditions.label')} className='inter-medium mb-4' checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} />
					<Button variant='none' className='btn-dark-green-outline px-5 shadow-none text-white text-center align-self-center' onClick={() => (!desktopBooking && !budgetSheetShow) ? automatizeBooking() : setModalShow(false)} disabled={!acceptTerms}>Continuar</Button>
				</Modal.Body>
			</Modal>
		</main >
	)
}

export default VillaMap
