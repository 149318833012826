import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import Link from "../../components/Link";
import { useTranslation } from 'react-i18next'

function useHandleSlider() {
    const [activeSlider, setActiveSlider] = useState(0);
    const [swipeDistance, setSwipeDistance] = useState({
        start: 0,
        end: 0,
        top: 0,
        bottom: 0
    });
    const [blockScroll, setBlockScroll] = useState();
    const [delayRedirect, setDelayRedirect] = useState(false)
    const swipeTolerance = 30;

    const checkIndex = (trigger, totalSliders, infinite, slideIndex) => {
        if (trigger === "left") {
            if ((activeSlider - 1) < 0) {
                infinite && setActiveSlider(totalSliders)
            } else {
                setActiveSlider(prevState => prevState - 1);
            }
        } else if (trigger === "right") {
            if ((activeSlider + 1) > totalSliders) {
                infinite && setActiveSlider(0)
            } else {
                setActiveSlider(prevState => prevState + 1);
            }
        } else if (trigger === "slideTo") {
            setActiveSlider(slideIndex)
        }
    }

    const handleTouchStart = (event) => {
        setSwipeDistance(prevState => ({ ...prevState, start: event.touches?.[0]?.clientX, top: event.touches?.[0]?.clientY }));
    }

    const handleTouchMove = (event) => {
        setSwipeDistance(prevState => ({ ...prevState, end: event.touches?.[0]?.clientX }));
        let checkSwipeDown = Math.abs((event.touches?.[0]?.clientY - swipeDistance.top)) // touch down (scrolling up)
        let checkSwipeUp = Math.abs((swipeDistance.top - event.touches?.[0]?.clientY)) // touch up (scrolling down)

        if (blockScroll === undefined && (checkSwipeDown > 20) && (checkSwipeUp > 20)) {
            setBlockScroll(true)
        }

        if ((event.touches?.[0]?.clientX - swipeDistance.start) > swipeTolerance) {
            if (!blockScroll) {
                document.body.style.overflowY = "hidden"
                blockScroll === undefined && setBlockScroll(false)
            }
        } else if ((swipeDistance.start - event.touches?.[0]?.clientX) > swipeTolerance) {
            if (!blockScroll) {
                document.body.style.overflowY = "hidden"
                blockScroll === undefined && setBlockScroll(false)
            }
        }
    }

    const handleTouchEnd = (totalSliders, infinite) => {
        if (Math.abs(swipeDistance.end) > 0 && !blockScroll) { // Avoid click & change slide on scroll not blocked
            if ((swipeDistance.end - swipeTolerance) >= swipeDistance.start) {
                checkIndex("left", totalSliders, infinite)
            } else if ((swipeDistance.end + swipeTolerance) <= swipeDistance.start) {
                checkIndex("right", totalSliders, infinite)
            }
        }

        document.body.removeAttribute("style")
        setSwipeDistance({ start: 0, end: 0 })
        setBlockScroll()
    }

    const handleRedirectBlock = () => {
        setDelayRedirect(true);

        setTimeout(() => {
            setDelayRedirect(false)
        }, 650);
    }

    return {
        activeSlider,
        delayRedirect,
        changeSlider: checkIndex,
        touchStart: handleTouchStart,
        touchMove: handleTouchMove,
        touchEnd: handleTouchEnd,
        handleRedirectBlock
    }
}

function VillaCard({ data, title, description, images, baths, beds, nights, rating, type, city, charger, capacity, sliderInfinite, featured, noRedirect, cardAction, blockTouch, theme }) {

    const { t, i18n } = useTranslation();
    const { activeSlider, delayRedirect, changeSlider, touchStart, touchMove, touchEnd, handleRedirectBlock } = useHandleSlider();
    const [villaSlider, setVillaSlider] = useState([
        "/img/bestof-home.png",
        "/img/bg-section-2.png",
        "/img/home-image.jpg",
        "/img/landscape.png",
        "/img/landscape-v1.png",
    ])

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    useEffect(() => {
        const data = shuffle(villaSlider)
        setVillaSlider(data)
    }, [])

    useEffect(() => {
        if ((activeSlider === 0 && !sliderInfinite) || (activeSlider === villaSlider?.length - 1 && !sliderInfinite)) {
            handleRedirectBlock();
        }
    }, [activeSlider])

    return (
        <Link href={`${t("pages.villa")}/${t("pages.villaDetail")}/${data?.slug}`} className={"text-dark-green"} noRedirect={delayRedirect || noRedirect}>
            <div className='villa-card' onClick={cardAction}>
                <div className='position-relative'>
                    <Button variant={"none"} className={`position-absolute top-50 translate-middle-y position-absolute p-0 shadow-none border-0 arrow left ${(activeSlider === 0 && !sliderInfinite) ? "d-none" : ''}`.trim()} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        changeSlider("left", villaSlider.length - 1, sliderInfinite)
                    }}>
                        <FontAwesomeIcon icon={"fa-solid fa-chevron-left"} width={18} className='text-white' />
                    </Button>
                    <Button variant={"none"} className={`position-absolute top-50 translate-middle-y position-absolute p-0 shadow-none border-0 arrow right ${(activeSlider === villaSlider?.length - 1 && !sliderInfinite) ? "d-none" : ''}`.trim()} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        changeSlider("right", villaSlider.length - 1, sliderInfinite)
                    }}>
                        <FontAwesomeIcon icon={"fa-solid fa-chevron-right"} width={18} className='text-white' />
                    </Button>
                    {data?.nightPrice && <div className='price-tag lh-sm'>
                        <span className='inter-medium small'>{t('villas.map.prices.from')}</span>
                        <p className='mb-0 inter-bold'>{data?.nightPrice}€ / <span className='small'>{t('villas.map.prices.nights')}</span></p>
                    </div>}
                    <div className={`slider ${featured ? "featured" : ""}`.trim()}>
                        {/* {villaSlider.map((slide, i) =>
                            <img key={i} src={slide} className={activeSlider === i ? "active" : null} onTouchStart={() => !blockTouch && touchStart} onTouchMove={() => !blockTouch && touchMove} onTouchEnd={() => !blockTouch && touchEnd(villaSlider.length - 1, sliderInfinite)} draggable={false} />
                        )} */}
                        {data?.images.map((slide, i) =>
                            <img key={i} src={slide} className={activeSlider === i ? "active" : null} onTouchStart={() => !blockTouch && touchStart} onTouchMove={() => !blockTouch && touchMove} onTouchEnd={() => !blockTouch && touchEnd(villaSlider.length - 1, sliderInfinite)} draggable={false} />
                        )}
                    </div>
                    <div className='d-flex flex-row gap-2 position-absolute start-50 translate-middle-x dot-control' onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                    }}>
                        {data?.images.map((slide, i) =>
                            <Button key={i} variant={"none"} className={`p-0 shadow-none border-0 dot ${activeSlider === i ? "active" : ''}`.trim()} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                changeSlider("slideTo", data?.images.length - 1, false, i)
                            }} />
                        )}
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between py-2 px-3'>
                    <div className='d-flex flex-row gap-3'>
                        <div className='d-flex align-items-center gap-1'>
                            <Image src={"/img/svg/guests.svg"} height={18} />
                            <span className='inter-semi-bold'>{data?.capacity}</span>
                        </div>
                        <div className='d-flex align-items-center gap-1'>
                            <Image src={"/img/svg/beds.svg"} height={18} />
                            <span className='inter-semi-bold'>{data?.numBedroom}</span>
                        </div>
                        <div className='d-flex align-items-center gap-1'>
                            <Image src={"/img/svg/baths.svg"} height={18} />
                            <span className='inter-semi-bold'>{data?.numBathroom}</span>
                        </div>
                        {data?.chargingPoint && <div className='d-flex align-items-center gap-1'>
                            <Image src={"/img/svg/car-ve.svg"} height={18} />
                            {/* <span>{data?.numBathroom}</span> */}
                        </div>}
                    </div>
                    <div className='d-flex flex-row gap-2 align-items-center justify-content-center inter-semi-bold'>
                        <span className='inter-semi-bolder'>{data?.rating}/10</span>
                        <FontAwesomeIcon icon="fa-solid fa-star" className="text-primary" height={18} />
                    </div>
                </div>
                <div className='d-flex flex-column gap-2 flex-grow-1 justify-content-center py-2 px-3 text-center'>
                    <div>
                        <p className='inter-bold mb-1 clamp-1'>{data?.title}</p>
                        <p className='inter-regular clamp-1 mb-1 text-center'>{data?.shortDescription}</p>
                    </div>
                    <span className={`text-uppercase new-link-${theme} inter-regular letter-spacing-2 mb-2 align-self-center`}>{t('general.components.seeMore')}</span>
                </div>
            </div>
        </Link>
    )
}

export default VillaCard