import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";


export default function useSwipe () {

    const scrollVisibilityApiType = React.useContext(VisibilityContext);

    const [touchStart, setTouchStart] = React.useState(null);
    const [touchEnd, setTouchEnd] = React.useState(null);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 15;

    const onTouchStart = (ev) => {
        setTouchEnd(null);
        setTouchStart(ev.targetTouches?.[0].clientX);
    };

    const onTouchMove = (ev) => {
        setTouchEnd(ev.targetTouches?.[0].clientX);
    };

    const onTouchEnd = (scrollVisibilityApiType) => () => {
        if (!touchStart || !touchEnd) return false;
        const distance = touchStart - touchEnd;
        const isSwipe = Math.abs(distance) > minSwipeDistance;
        const isLeftSwipe = distance < minSwipeDistance;
        if (isSwipe) {
            if (isLeftSwipe) {
                scrollVisibilityApiType.scrollPrev();
            } else {
                scrollVisibilityApiType.scrollNext();
            }
        }
    };

    return { onTouchStart, onTouchEnd, onTouchMove };
};
