import React, { useContext, useEffect } from 'react'
import SeoHelmet from '../../components/seo-helmet/SeoHelmet'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import HubspotForm from 'react-hubspot-form'
import DestinationSelect from '../../components/selects/DestinationSelect';
import CheckInOutSelect from '../../components/selects/CheckInOutSelect';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import GuestSelect from '../../components/selects/GuestSelect';
import VillaMobileFilter from '../../components/selects/VillaMobileFilter';
import { Swiper, SwiperSlide } from 'swiper/react';
import VillaCard from '../../components/cards/VillaCard';
import { useState } from 'react';
import TzoSheet from '../../components/mobile-sheet/TzoSheet';
import CustomMosaic from '../../components/mosaic/CustomMosaic';
import Slider from 'rc-slider';
import VillasFeatures from '../../components/villas/VillasFeatures';
import { useRef } from 'react';
import { useCallback } from 'react';
import VillasService from '../../services/villas.service';
import { useNavigate } from 'react-router-dom';
import { VillasContext } from '../../Contexts';

function VillaLanding() {

    const { villasState: { filter, equipVilla, features }, villasUpdate } = useContext(VillasContext);

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    const [villas, setVillas] = useState();
    const [sheetShow, setSheetShow] = useState();
    const [bedrooms, setBedrooms] = useState(features?.bedrooms || 0);
    const [beds, setBeds] = useState(features?.beds || 0);
    const [baths, setBaths] = useState(features?.baths || 0);

    const [equip, setEquip] = useState({
        wifi: equipVilla?.wifi || false,
        barbeque: equipVilla?.barbeque || false,
        television: equipVilla?.television || false,
        garden: equipVilla?.garden || false,
        view: equipVilla?.view || false,
        chargingPoint: equipVilla?.chargingPoint || false,
        pets: equipVilla?.pets || false
    })

    const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 })

    const delayRef = useRef();

    const handlePrice = useCallback((price) => {
        clearTimeout(delayRef.current)

        delayRef.current = setTimeout(() => {
            setPriceRange({ min: price?.[0], max: price?.[1] })
        }, 25);
    }, [delayRef, priceRange])

    const newsletterFormLang = {
        es: "7e9cacf1-5001-4357-b1f6-fd2f6f9e910e",
        en: "22093985-7dbd-4927-bd94-dab2bb1217a3",
        de: "994aabc2-9f24-4ee7-a77f-9e48ff813079"
    }

    const getVillas = async () => {
        try {
            const data = await VillasService.getVillas({
                length: 6,
            })

            setVillas(data.content)

            if (sheetShow) {
                document.getElementsByClassName('mobile-sheet')?.[0]?.classList?.replace("active", "close")

                setTimeout(() => {
                    setSheetShow(false)
                }, 625);
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        villasUpdate({
            equipVilla: {
                wifi: equip?.wifi,
                barbeque: equip?.barbeque,
                television: equip?.television,
                garden: equip?.garden,
                view: equip?.view,
                chargingPoint: equip?.chargingPoint,
                pets: equip?.pets
            }
        })

    }, [equip])

    useEffect(() => {
        getVillas()
    }, [])

    return (
        <>
            <SeoHelmet title={t('seo.home.title')} metaTitle={t('seo.home.metaTitle')} metaDescription={t('seo.home.metaDescription')} ogImage={'/img/home-image.jpg'} noSuffix />
            <main>
                <Container fluid as={"section"} className='px-0 position-relative villa-header'>
                    <Image fluid src='/img/villa/villa-header.webp' className='villa-bg' />
                    <div className='villa-overlay d-flex py-5 py-lg-0'>
                        <div className='container d-flex flex-row flex-grow-1 justify-content-between align-items-center'>
                            <div className='text-white'>
                                <h1 className='inter-regular letter-spacing-1 fs-5 text-uppercase mb-1 mb-lg-4'>{t('villas.landing.header.title')}</h1>
                                <h2 className='fitzgerald-regular h1 mb-4'>{t('villas.landing.header.subtitle')}</h2>
                                <p className='inter-regular fs-5 mb-0'>{t('villas.landing.header.description')}</p>
                                <div className='mt-5 d-lg-none'>
                                    <div className='bg-white text-coal border border-coal-semi-transparent' onClick={() => setSheetShow(true)}>
                                        <div className='d-flex flex-column px-3 py-2 border-bottom border-coal-semi-transparent'>
                                            <div className='d-flex flex-row gap-3'>
                                                <span className='inter-bold'>{t('villas.filter.checkInOutInput.in')}</span>
                                                <span className='inter-bold'>/</span>
                                                <span className='inter-bold'>{t('villas.filter.checkInOutInput.out')}</span>
                                            </div>
                                            {(filter?.startDate && filter?.endDate) ? <div className='d-flex flex-row gap-2'>
                                                <span className='inter-regular'>{filter?.startDate?.toLocaleDateString()}</span>
                                                <span className='ps-1 inter-regular'>-</span>
                                                <span className='inter-regular'>{filter?.endDate?.toLocaleDateString()}</span>
                                            </div> : (
                                                <div>
                                                    <span className='inter-regular'>{t('villas.filter.checkInOutPlaceholder')}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='d-flex flex-column px-3 py-2'>
                                            <div className='d-flex flex-row gap-3 '>
                                                <span className='inter-bold'>{t('villas.filter.guests.name')}</span>
                                            </div>
                                            <div className='d-flex flex-row gap-3'>
                                                <span className='inter-regular'>{filter?.totalGuest} {t('villas.filter.guests.title')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Button variant={"none"} className="btn-dark-green-outline inter-regular text-white px-5 fs-5 mt-2 w-100" onClick={() => navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}`)}>
                                        {t('villas.filter.button')}
                                    </Button>
                                </div>
                            </div>
                            <div className='px-3 pb-2 pt-3 bg-secondary featured d-none d-lg-block'>
                                <p className='mb-0 inter-regular letter-spacing-1 text-dark-green text-center fs-5 mb-3 text-uppercase'>{t('villas.landing.featured')}</p>
                                <VillaCard data={villas?.[0]} sliderInfinite featured theme={"coal"} />
                            </div>
                        </div>
                    </div>
                </Container>
                <section className='bg-dark-green py-5 d-none d-lg-block'>
                    <Container className='d-flex flex-column p-4'>
                        {/* <DestinationSelect
                            id={"destination"}
                            className={"border border-1 villa-select"}
                            containerClasssName={"villa-input"}
                            outsideTarget={"destination-container"}
                        /> */}
                        <div className='d-flex flex-row pb-1 radius gap-3 w-100'>
                            <span className='flex-grow-1 flex-basis-0 inter-medium mb-1 text-white'>{t('villas.filter.destination')}</span>
                            <span className='flex-grow-1 flex-basis-0 inter-medium mb-1 text-white'>{t('villas.filter.checkInOutLabel')}</span>
                            <span className='flex-grow-1 flex-basis-0 inter-medium mb-1 text-white'>{t('villas.filter.guests.title')}</span>
                            <span className='inter-medium mb-1 text-white px-5 fs-5 invisible lh-1'>{t('villas.filter.button')}</span>
                        </div>
                        <div className='d-flex flex-row radius gap-3 w-100'>
                            <div className='d-flex flex-grow-1 flex-column villa-input'>

                                <div className={`d-flex flex-grow-1 flex-basis-0 position-relative border border-1 border border-1 villa-select py-2`}>
                                    <div className={`d-flex flex-row flex-grow-1 justify-content-center position-relative px-3 py-2 user-select-none`}>
                                        <div className='d-flex flex-column align-items-center justify-content-center'>
                                            <span className='inter-semi-bold mb-0 fs-5'>Mallorca</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-grow-1 flex-basis-0'>
                                <CheckInOutSelect
                                    id={"calendar"}
                                    className={"border border-1 villa-select fs-5"}
                                    containerClasssName={"villa-input"}
                                    noLabel
                                />
                            </div>
                            <div className='d-flex flex-column flex-grow-1 flex-basis-0'>
                                <GuestSelect
                                    id={'guest'}
                                    className={"border border-1 villa-select fs-5"}
                                    containerClasssName={"villa-input"}
                                    outsideTarget={"guest-container"}
                                    noLabel
                                />
                            </div>
                            <Button variant={"none"} className="btn-laurel inter-regular text-white px-5 fs-5" onClick={() => navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}`)}>
                                {t('villas.filter.button')}
                            </Button>
                        </div>
                    </Container>
                </section>
                {/* <section className='d-lg-none'>
                    <VillaMobileFilter containerClassName={'p-4 border-bottom border-1 border-coal-semi-transparent'} sheet={sheetShow} action={() => setSheetShow(true)} />
                </section> */}
                <Container className='mt-5' as={"article"}>
                    <h2 className='fs-2 text-coal fitzgerald-bold opacity-75 text-center mb-5'>{t('villas.landing.list.title')}</h2>
                    <Row className='g-2 gy-3 g-lg-4'>
                        {villas?.map((villa, i) =>
                            <Col key={i} xs={12} lg={4}>
                                <VillaCard
                                    data={villa}
                                    theme={"laurel"}
                                />
                            </Col>
                        )}
                    </Row>
                </Container>
                <Container className='py-5 villa-info-section d-lg-block' as={"section"}>
                    <h2 className='mb-4 mb-lg-5 title fitzgerald-regular lh-sm text-center'>{t('villas.landing.section1.title')}</h2>
                    {/* <Image src='/img/icons/eco-house.png' className='title-image' /> */}
                    <Row className='gx-5 mb-4 mb-lg-5'>
                        <Col xs={12} lg={6} className='mb-4 mb-lg-0'>
                            <Image src='/img/villa/villa-landing-content-1.webp' className='picture' />
                        </Col>
                        <Col xs={12} lg={6} className='d-flex flex-column justify-content-center'>
                            <h3 className='fitzgerald-bold mb-3 fs-2 text-coal opacity-75'>{t('villas.landing.section1.block1.title')}</h3>
                            {/* <p className='tt-norms-bold mb-2 h3 text-coal opacity-75 mb-3 mb-lg-0'>{t('villas.landing.section1.block1.subtitle')}</p> */}
                            <p className='inter-regular mb-2 text-coal opacity-75'>{t('villas.landing.section1.block1.text')}</p>
                        </Col>
                    </Row>
                    <Row className='gx-5 flex-column-reverse flex-lg-row gap-4 gap-lg-0'>
                        <Col xs={12} lg={6} className='d-flex flex-column justify-content-center'>
                            <h3 className='fitzgerald-bold mb-3 fs-2 text-coal opacity-75'>{t('villas.landing.section1.block2.title')}</h3>
                            {/* <p className='tt-norms-bold mb-2 h3 text-coal opacity-75 mb-3 mb-lg-0'>{t('villas.landing.section1.block2.subtitle')}</p> */}
                            <p className='inter-regular mb-2 text-coal opacity-75'>{t('villas.landing.section1.block2.text')}</p>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Image src='/img/villa/villa-landing-content-2.webp' className='picture' />
                        </Col>
                    </Row>
                </Container>
                <div className='villa-banner'>
                    <Image fluid src='/img/villa/villa-banner.webp' className='banner-img' />
                    <div className='content py-5'>
                        <Container className='px-0 px-lg-4 py-lg-5'>
                            <h3 className='fitzgerald-bold text-white mb-3 h1 text-start px-4'>{t('villas.landing.section2.title')}</h3>
                            <p className='inter-regular mb-5 text-white h3 text-start px-4'>{t('villas.landing.section2.subtitle')}</p>
                            <div className='d-flex flex-row justify-content-between d-none d-lg-flex'>
                                <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2'>
                                    <Image src='/img/villa/benefit-1.png' width={80} className='mb-3' />
                                    <h4 className='inter-semi-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card1.title')}</h4>
                                    <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card1.text')}</p>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2'>
                                    <Image src='/img/villa/benefit-2.png' width={80} className='mb-3' />
                                    <h4 className='inter-semi-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card2.title')}</h4>
                                    <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card2.text')}</p>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2'>
                                    <Image src='/img/villa/benefit-3.png' width={80} className='mb-3' />
                                    <h4 className='inter-semi-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card3.title')}</h4>
                                    <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card3.text')}</p>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2'>
                                    <Image src='/img/villa/benefit-4.png' width={80} className='mb-3' />
                                    <h4 className='inter-semi-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card4.title')}</h4>
                                    <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card4.text')}</p>
                                </div>
                            </div>
                            <Swiper
                                breakpoints={{
                                    768: {
                                        slidesPerView: 2.5
                                    }
                                }}
                                spaceBetween={8}
                                slidesPerView={1.5}
                                slidesOffsetBefore={24}
                                slidesOffsetAfter={24}
                                className='w-100 d-lg-none'
                                shortSwipes
                                longSwipes
                            >
                                <SwiperSlide>
                                    <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2 h-100'>
                                        <Image src='/img/villa/benefit-1.png' width={80} className='mb-3' />
                                        <h4 className='inter-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card1.title')}</h4>
                                        <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card1.text')}</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2 h-100'>
                                        <Image src='/img/villa/benefit-2.png' width={80} className='mb-3' />
                                        <h4 className='inter-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card2.title')}</h4>
                                        <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card1.text')}</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2 h-100'>
                                        <Image src='/img/villa/benefit-3.png' width={80} className='mb-3' />
                                        <h4 className='inter-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card3.title')}</h4>
                                        <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card1.text')}</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='d-flex flex-column justify-content-center align-items-center p-4 bg-white card gap-2 h-100'>
                                        <Image src='/img/villa/benefit-4.png' width={80} className='mb-3' />
                                        <h4 className='inter-bold text-coal opacity-75 mb-0 h4 text-center'>{t('villas.landing.section2.card4.title')}</h4>
                                        <p className='inter-regular small text-center mb-0'>{t('villas.landing.section2.card1.text')}</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </Container>
                    </div>
                </div>
                <Container className="my-5" as={"section"}>
                    <aside>
                        <div className='position-relative newsletter-banner'>
                            <Image src='/img/home/banner-form.webp' fluid className='background' />
                            <Row className='overlay g-0'>
                                <Col lg={5} className='d-flex align-items-center justify-content-center mb-4 mb-lg-0'>
                                    <div>
                                        <span className="fitzgerald-regular h1 text-white mb-0 letter-spacing-1">{t('home.newsletterBanner.text1')} </span>
                                        <span className="fitzgerald-regular h1 text-white mb-0 letter-spacing-1"><span className='fitzgerald-bold'>{t('home.newsletterBanner.text2.highlight')}</span>{t('home.newsletterBanner.text2.text')} </span>
                                        <span className="fitzgerald-regular h1 text-white mb-0 letter-spacing-1">{t('home.newsletterBanner.text3')}</span>
                                    </div>
                                </Col>
                                <Col lg={5} className='offset-lg-2'>
                                    <div className='bg-white p-4'>
                                        <HubspotForm
                                            region="eu1"
                                            portalId="26078053"
                                            formId={newsletterFormLang[i18n.language]}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </aside>
                </Container>
                <TzoSheet title={t('villas.filter.mobileTitle')} show={sheetShow} close={() => setSheetShow(false)} stickyHead>
                    <div className='d-flex flex-column gap-4 p-4 villa-mobile-filters'>
                        {/* <div className='d-flex flex-column'>
                            <p className='mb-2 tt-norms-semi-bolder'>Destino</p>
                            <DestinationSelect
                                id={"destination-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                outsideTarget={"destination-container-mobile"}
                                noLabel
                                chevron
                            />
                        </div> */}
                        <div className='d-flex flex-column'>
                            <p className='mb-2 inter-semi-bold'>{t('villas.filter.checkInOutLabel')}</p>
                            <CheckInOutSelect
                                id={"calendar-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                noLabel
                                chevron
                            />
                        </div>
                        <div className='d-flex flex-column'>
                            <p className='mb-2 inter-semi-bold'>{t('villas.filter.guests.title')}</p>
                            <GuestSelect
                                id={"guest-mobile"}
                                className={"border border-1 villa-select"}
                                containerClasssName={"villa-input"}
                                outsideTarget={"guest-container-mobile"}
                                noLabel
                                chevron
                            />
                        </div>
                        {/* <div>
                            <p className='mb-2 tt-norms-semi-bolder'>Precio</p>
                            <div className='d-flex justify-content-center mb-2'>
                                <Slider range allowCross={false} defaultValue={[20, 90]} min={0} max={100} pushable={10}
                                    // onAfterChange={(val) => setPriceRange({min: val?.[0], max: val?.[1]})}
                                    onChange={(val) => handlePrice(val)}
                                />
                            </div>
                        </div> */}
                        {/* <div>
                            <CustomMosaic />
                        </div> */}
                        <div className='mt-3'>
                            <p className='mb-2 inter-semi-bold'>{t('villas.filter.equipment.title')}</p>
                            <Row className='gy-2'>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.wifi')}
                                        className='mb-0'
                                        id='wifi'
                                        checked={equip?.wifi}
                                        onChange={(e) => setEquip({ ...equip, "wifi": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.barbecue')}
                                        className='mb-0'
                                        id='bbq'
                                        checked={equip?.barbeque}
                                        onChange={(e) => setEquip({ ...equip, "barbeque": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={"TV"}
                                        className='mb-0'
                                        id='tv'
                                        checked={equip?.television}
                                        onChange={(e) => setEquip({ ...equip, "television": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.garden')}
                                        className='mb-0'
                                        id='garden'
                                        checked={equip?.garden}
                                        onChange={(e) => setEquip({ ...equip, "garden": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.views')}
                                        className='mb-0'
                                        id='views'
                                        checked={equip?.view}
                                        onChange={(e) => setEquip({ ...equip, "view": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={"Point-VE"}
                                        className='mb-0'
                                        id='ve'
                                        checked={equip?.chargingPoint}
                                        onChange={(e) => setEquip({ ...equip, "chargingPoint": e.target.checked })}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Check
                                        label={t('villas.filter.equipment.pets')}
                                        className='mb-0'
                                        id='pets'
                                        checked={equip?.pets}
                                        onChange={(e) => setEquip({ ...equip, "pets": e.target.checked })}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <p className='mb-2 inter-semi-bold mb-3'>{t('villas.filter.features')}</p>
                            <VillasFeatures id={"MOBILE"} bedrooms={bedrooms} updateBedrooms={setBedrooms} beds={beds} updateBeds={setBeds} baths={baths} updateBaths={setBaths} />
                        </div>
                        <Button variant={"none"} className="btn-laurel inter-medium" onClick={() => navigate(`/${i18n.language}/${t("pages.villa")}/${t("pages.villaSearcher")}`)}>
                            {t('villas.filter.button')}
                        </Button>
                    </div>
                </TzoSheet>
            </main>
        </>
    )
}

export default VillaLanding